import { useFormik } from "formik";

import { ChangeEmailProps, PaymentMethodResponse } from "../types";
import { changeEmailSchema } from "../validation";

const useUpdateEmailForm = (action: CallableFunction, billingDetails?: PaymentMethodResponse) => {
  return useFormik<ChangeEmailProps>({
    initialValues: {
      email: billingDetails?.billing_details.email || "",
    },
    validateOnChange: false,
    validationSchema: changeEmailSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useUpdateEmailForm;
