import React, { ReactElement, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";

const StripeWrapper = ({ children }: { children: ReactElement }) => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const isStripeEnabled = process.env.REACT_APP_STRIPE_ENABLE === "true";

  useEffect(() => {
    const loadStripeScript = async () => {
      if (isStripeEnabled) {
        const stripeScript = document.createElement("script");
        stripeScript.src = process.env.REACT_APP_STRIPE_SCRIPT_URL || "";
        stripeScript.async = true;
        stripeScript.onload = () => {
          if (window.Stripe) {
            const stripePromise = window.Stripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
            setStripePromise(stripePromise);
          }
        };
        document.body.appendChild(stripeScript);
      }
    };

    loadStripeScript();

    return () => {
      const stripeScript = document.querySelector(
        `script[src="${process.env.REACT_APP_STRIPE_SCRIPT_URL}"]`
      );
      if (stripeScript) {
        stripeScript.remove();
      }
    };
  }, [isStripeEnabled]);

  if (isStripeEnabled) {
    return <Elements stripe={stripePromise}>{children}</Elements>;
  }

  return children;
};

export default StripeWrapper;
