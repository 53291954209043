import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedRisksFileListResponse } from "../types";

export default function getRisksFiles(
  permissions: TeamParameter,
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<PaginatedRisksFileListResponse>> {
  const teamParameter = permissions.isAllTeam ? "all" : permissions.team;

  return api.get<PaginatedRisksFileListResponse>(`risks/risks-files/`, {
    params: {
      ...{ team: teamParameter, company: permissions.company, ...hasNameOrdering(requestParams) },
    },
  });
}
