import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export default function deleteIncidentsById(
  { incidentId }: { incidentId: number },
  permissions: { team: TeamId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`risks/incidents/${incidentId}/`, { params: { ...permissions } });
}
