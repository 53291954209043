import React from "react";

import clsx from "clsx";
import toString from "lodash/toString";

import { CompanyProps } from "shared/context/types/company";
import { useCompany } from "shared/context/CompanyProvider";
import styles from "assets/css/sidebar.module.css";

import Avatar from "../avatar";
import Icon from "../icon/Icon";

type CompaniesListProps = {
  companies: CompanyProps[];
  onCompanyClick: (company: CompanyProps) => void;
};

const CompaniesList = ({ companies, onCompanyClick }: CompaniesListProps) => {
  const { currentCompany } = useCompany();

  return (
    <>
      {companies?.map((company: CompanyProps) => (
        <div
          data-testid="companies-list"
          className={clsx(
            styles.sidebarTop,
            "!mb-0 border-b border-antiflashwhite dark:border-thunders p-3.5 pb18 hover:bg-gray-100 dark:hover:bg-thunders cursor-pointer"
          )}
          key={company.id}
          onClick={() => onCompanyClick(company)}
        >
          <div className={styles.sidebarDetails}>
            <div className="mr-3">
              <Avatar
                name={company?.name}
                avatarFor="company"
                avatarType="squared"
                size="regular"
                url={toString(company?.image)}
              />
            </div>
            <div className={styles.sidebarTopDetails}>
              <h3 data-testid="company-name" className="dark:text-greychateau maxMd:!text-sm">
                {company?.name}
              </h3>
            </div>
          </div>
          <div className="ml-3 flex flex-col">
            {company?.id === currentCompany?.id ? (
              <Icon type="checkmark" fill={true} size="icon-sm" className="sidebar_icon" />
            ) : null}
          </div>
        </div>
      ))}
    </>
  );
};

export default CompaniesList;
