export const isLexicalJson = (payload: string) => {
  const isValidString = `${payload}`.startsWith(`{"root":{"children"`);
  if (!isValidString) {
    return false;
  }

  try {
    const o = JSON.parse(payload);
    if (o && typeof o === "object" && (o?.root?.children || []).length > 0) {
      return true;
    }
  } catch (e) {
    console.warn(e);
  }

  return false;
};
