import React, { useCallback } from "react";

import { useParams } from "react-router";
import { useMutation } from "@tanstack/react-query";

import { useTeam } from "shared/store/settings";

import { TeamResponse } from "modules/settings/teams/types";

import { LexicalEditorProviderProps, ProviderData } from "../types";
import {
  prepareUploadFileFormDataFactory,
  getFileResponsePreparer,
  getFileDownloader,
  getFileUploader,
} from "./helpers";

export const LexicalEditorContext = React.createContext({} as ProviderData);

export const LexicalEditorProvider = ({ children, type }: LexicalEditorProviderProps) => {
  const params = useParams();
  const currentTeam = useTeam((state) => state.currentTeam);

  const { mutateAsync: getFileUploaderFn } = useMutation(
    async ({ type, team }: { type: string; team: TeamResponse }) => {
      if (!team?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }
      return getFileUploader(type, team);
    }
  );

  const uploader = async (data: FormData | null) => {
    const fileUploader = await getFileUploaderFn({ type, team: currentTeam });
    return fileUploader(data);
  };

  const prepareForm = useCallback(
    (file: File) => {
      const prepareFormData = prepareUploadFileFormDataFactory(type, currentTeam, params);
      return prepareFormData(file);
    },
    [type, params, currentTeam]
  );

  const prepareResponse = useCallback(
    (data: unknown) => {
      const preparer = getFileResponsePreparer(type);
      return preparer(data);
    },
    [type]
  );

  const { mutateAsync: getFileDownLoaderFn } = useMutation(
    async ({ type, team }: { type: string; team: TeamResponse }) => {
      if (!team?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }
      return getFileDownloader(type, team);
    }
  );

  const downloader = async (id: string) => {
    const fileDownloader = await getFileDownLoaderFn({ type, team: currentTeam });
    return fileDownloader(id);
  };

  const prepareDownloadResponse = useCallback(
    (data: unknown) => {
      const preparer = getFileResponsePreparer(type);
      return preparer(data);
    },
    [type]
  );

  return (
    <LexicalEditorContext.Provider
      value={{
        type,
        uploader,
        prepareForm,
        prepareResponse,
        downloader,
        prepareDownloadResponse,
      }}
    >
      {children}
    </LexicalEditorContext.Provider>
  );
};

export const useLexicalEditor = () => {
  return React.useContext(LexicalEditorContext);
};
