import React, { useState } from "react";

import { Label, Spinner, TextInput } from "flowbite-react";
import { FormikProps } from "formik";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import { useHome } from "shared/store/home";
import { useCompany } from "shared/context/CompanyProvider";
import ServerErrors from "shared/components/server-errors";

import style from "assets/css/settings.module.css";

import { MyAccountProps } from "./types";
import { updateProfile } from "./api";
import useUpdateProfile from "./hooks/useUpdateProfile";
import ChangePicture from "./change-picture/ChangePicture";
import ChangeEmail from "./change-email/ChangeEmail";
import ChangePassword from "./change-password/ChangePassword";
import DeleteAccount from "./delete-account/DeleteAccount";
import { getUsername } from "shared/helpers/util";

export default function MyAccount() {
  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const [profile, setProfile] = useHome((state) => [state.profile, state.setProfile]);

  const { refetch } = useCompany();

  const formik: FormikProps<MyAccountProps> = useUpdateProfile(profile, () =>
    updateProfileFn(formik.values)
  );

  const {
    isLoading,
    mutate: updateProfileFn,
    isError,
    error,
  } = useMutation(
    (data: MyAccountProps) => updateProfile({ ...data, profile_picture: profilePicture }),
    {
      onSuccess: (res) => {
        setProfile({ ...profile, ...res.data });
        toast("Profile Updated successfully.", { type: "success" });
        refetch();
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  function onCancel() {
    formik.resetForm();
    setProfilePicture(null);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={style.setting_layout}>
          <div className={style.layout_card_info}>
            <Label
              value="Personal Info"
              className="!text-mirage text-base dark:!text-white font-inter-medium"
            />
            <div className="text-sm mt-4 mb-2 dark:text-white">Preferred Name*</div>
            <TextInput
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              color={formik.errors.name ? "failure" : "gray"}
            />
          </div>
          <div className={style.layout_card_info}>
            <ChangePicture
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              title="Photo"
              description="Upload an image. It will show up in your sidebar and notifications."
              imageURL={profile.profile_picture}
              name={getUsername(profile)}
            />
          </div>
          <div className={style.layout_card_info}>
            <ChangeEmail />
          </div>
          <div className={style.layout_card_info}>
            <ChangePassword />
          </div>
          <div className={style.layout_card_info}>
            <DeleteAccount />
          </div>
          <div className={style.layout_card_info}>
            {isError ? <ServerErrors className="mb-4" error={error} /> : null}
            <div className="md:flex justify-end">
              <div>
                <button
                  type="button"
                  className="btn_secondary md:mr-4 maxMd:w-full maxMd:mb-[18px]"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn_primary maxMd:w-full">
                  <Spinner
                    size="md"
                    light={true}
                    hidden={!isLoading}
                    className="mr-3 fill-crayolasblue stroke-crayolasblue"
                  />
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
