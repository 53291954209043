import { Permission, PermissionLevel } from "../../types";
import ActorInfo from "./ActorInfo";
import PermissionChanger from "./PermissionChanger";

export type PermissionItemProps = {
  permission: Permission;
  onDelete?: (permission: Permission) => void;
  onUpdate?: (permission: Permission, level: PermissionLevel) => void;
};

export default function PermissionItem({
  permission,
  onUpdate: handleUpdate,
  onDelete: handleDelete,
}: PermissionItemProps) {
  return (
    <div data-testid="permission-item" className="flex flex-row justify-between">
      <ActorInfo user={permission.user || null} team={permission.team || null} />
      <PermissionChanger permission={permission} onUpdate={handleUpdate} onDelete={handleDelete} />
    </div>
  );
}
