import React, { Fragment, useState } from "react";
import Icon from "shared/components/icon/Icon";
import clsx from "clsx";

import * as Dropdown from "@radix-ui/react-dropdown-menu";

type Option = { icon?: string; name: string; value: string };

const CustomDropdown = ({
  options,
  isGeneralAccess,
  value,
  onChange,
  isEditAccess,
  isOwner,
  isTransferable,
  isUsers,
}: {
  options: Option[];
  isGeneralAccess: boolean;
  value: string;
  onChange: (value: string) => void;
  isEditAccess?: boolean;
  isOwner?: boolean;
  isTransferable?: boolean;
  isUsers?: boolean;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleOptionSelect = (option: Option) => {
    onChange(option.value);
    setDropdownOpen && setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    if (isOwner) {
      return;
    }

    if (!isEditAccess) {
      return;
    }
    setDropdownOpen(!dropdownOpen);
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Dropdown.Root open={dropdownOpen} onOpenChange={isEditAccess ? setDropdownOpen : () => false}>
      <Dropdown.Trigger
        asChild
        onClick={() => {
          if (!isEditAccess) return;
          toggleDropdown();
        }}
      >
        <div className={clsx("dropdown_header custom_select p-2 h-[1.75rem] w-auto")}>
          <div className="flex gap-2 items-center">
            <p
              className={clsx(
                "dark:text-white font-inter-medium text-richblack",
                {
                  "!text-red-500 font-inter-medium dark:text-red-500":
                    selectedOption?.name === options[3]?.name && !isGeneralAccess && !isOwner,
                },
                { " dark:text-white select-none font-inter-medium text-richblack": isOwner }
              )}
            >
              {selectedOption?.name || "Owner"}
            </p>
          </div>
          <Icon size="icon-sm" type="chevron-down" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content className="drop_down_card" sideOffset={5} side="bottom" align="end">
        <div className="flex flex-col gap-y-0.5">
          {options.map((option, index) => (
            <Fragment key={option.name}>
              {(index === options.length - 1 || (index == options.length - 2 && isUsers)) &&
              !isGeneralAccess ? (
                <hr className="content-divider m-0.5" />
              ) : null}
              <Dropdown.Item
                className={clsx("DropdownMenuItem !py-1 !px-2", {
                  "!py-1": index - options.length,
                })}
              >
                <div
                  key={index}
                  className={clsx("option", {})}
                  onClick={() => handleOptionSelect(option)}
                >
                  <div className="flex justify-start items-center gap-2">
                    <span className="drop_down_icon">
                      {option.icon ? (
                        <Icon
                          type={option?.icon}
                          fill={true}
                          size="icon-xs"
                          className={clsx({ remove_access_icon: index === options.length - 1 })}
                        />
                      ) : null}
                    </span>
                    <span
                      className={clsx("dark:text-greychateau font-inter-medium text-richblack", {
                        "!text-red-500 font-inter-medium dark:text-red-500":
                          index === options.length - 1 && !isGeneralAccess,
                        "opacity-50 cursor-not-allowed select-none":
                          !isTransferable && option.value === "TRANSFER_OWNER",
                      })}
                    >
                      {option?.name}
                    </span>
                  </div>
                </div>
              </Dropdown.Item>
            </Fragment>
          ))}
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default CustomDropdown;
