import React from "react";

import TagChip from "../gridview-chip/TagChip";

export type GridCardTagsProps = {
  tag: string;
  onClick?: (tag: string) => void;
  className?: string;
};

const GridCardTag: React.FC<GridCardTagsProps> = ({
  tag,
  onClick: handleClick,
  className,
}: GridCardTagsProps) => {
  return (
    <TagChip
      key={tag}
      tag={tag}
      onClick={(e) => {
        e.stopPropagation();
        if (handleClick) {
          handleClick(tag);
        }
      }}
      className={className}
    />
  );
};

export default GridCardTag;
