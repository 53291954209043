import { useEffect } from "react";

import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import CantLogin from "shared/components/cant-login/CantLogin";
import Spinner from "shared/components/spinner/Spinner";
import { setRefreshToken, setToken } from "shared/helpers/util";

import { microsoftLogin } from "modules/auth/signIn/api";
import { MicrosoftSigninRequestParams } from "modules/auth/signIn/types";

const MicrosoftAuth = () => {
  const [params] = useSearchParams();
  let code = params.get("code");
  const navigate = useNavigate();
  const hash = useLocation().hash;

  if (!code) {
    code = new URLSearchParams(hash).get("#code");
  }

  const { mutate, isError } = useMutation(
    (data: MicrosoftSigninRequestParams) => microsoftLogin(data),
    {
      onSuccess: (res) => {
        setToken(res.data.access_token);
        setRefreshToken(res.data.refresh_token);
        navigate("/check-permissionw", { replace: true });
      },
    }
  );

  useEffect(() => {
    if (!code) return;
    mutate({ code });
  }, [code]);

  if (isError) {
    return <CantLogin />;
  }

  return <Spinner />;
};

export default MicrosoftAuth;
