import { Value } from "../types";

export default function fromSelectId(id: string): Value {
  const [type, value] = id.split("-");

  if (type === "team") {
    return {
      team: parseInt(value, 10),
    };
  }

  return {
    user: parseInt(value, 10),
  };
}
