import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { type PaginatedResponse, type InstitutionType } from "shared/typesV2";

export default async function getInstitutionTypes(params: {
  search?: string;
  page?: number;
}): Promise<AxiosResponse<PaginatedResponse<InstitutionType>>> {
  return api.get<PaginatedResponse<InstitutionType>>(`/horizon-scanning/institution-types`, {
    params,
  });
}
