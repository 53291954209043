import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { ReadBookmarkResponseProps } from "../types";

export default function articleBookmark(
  articleId: number
): Promise<AxiosResponse<ReadBookmarkResponseProps>> {
  return api.patch<ReadBookmarkResponseProps>(`articles/${articleId}/remove-bookmark/`, {});
}
