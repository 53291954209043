import { useArticleDetails } from "../../ArticleDetails";
import ArticleSection from "../article-section";

const ArticleSummary = () => {
  const { article } = useArticleDetails();

  return (
    <ArticleSection title="Summary" icon="document-check">
      <span className="border-l-2 border-brightgray ml-6 pl-6 leading-relaxed text-base text-davygrey font-medium">
        {article.description}
      </span>
    </ArticleSection>
  );
};

export default ArticleSummary;
