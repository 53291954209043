import { useQuery } from "@tanstack/react-query";
import { useCompany } from "shared/context/CompanyProvider";
import getRegulations from "../api/getRegulations";

const useRegulationsQuery = () => {
  const { currentCompany } = useCompany();

  const { data, isLoading, refetch } = useQuery(["get-regulations"], () =>
    getRegulations({
      company: currentCompany?.id,
    })
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
  };
};

export default useRegulationsQuery;
