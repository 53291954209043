import { useEffect, useState } from "react";

import { optionType } from "shared/components/metadata/types";
import TagsField from "shared/components/tags-field";

import TaskLabel from "../shared/TaskLabel";

export const TaskTags = ({
  tags,
  onFieldChanged,
  teamId,
}: {
  tags?: Array<string>;
  onFieldChanged: (fieldName: string, data: optionType[]) => void;
  teamId: number;
}) => {
  const [selectedTags, setSelectedTags] = useState<Array<optionType>>([]);

  useEffect(() => {
    const tagsOption: Array<optionType> = [];
    tags?.forEach((item: string) => {
      tagsOption.push({ value: item, label: item });
    });
    setSelectedTags(tagsOption);
  }, [tags]);

  return (
    <div>
      <TaskLabel title="Tags" />
      <TagsField
        teamId={teamId}
        values={selectedTags || []}
        onChange={(tags) => onFieldChanged("tags", [...tags])}
      />
    </div>
  );
};
