import { FilterConditon, RefetchOptions } from "../types";
import { SEARCH } from "../constants";
import { IN_OPERATOR } from "../../../helpers/constant";
import getFieldForCondition from "./getFieldForCondition";

/**
 * Transforms a filter condition into a refetch option with type search.
 * Mark the refetch option as multiple if the operator is IN_OPERATOR.
 * @param condition
 */
export default function toRefetchSearchOption(condition: FilterConditon): RefetchOptions {
  return {
    field: getFieldForCondition(condition),
    value: condition.value,
    multiple: condition.operator === IN_OPERATOR,
    type: SEARCH,
  };
}
