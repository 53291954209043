import { useFormik } from "formik";

import { ChangeVATorGSTProps, PaymentMethodResponse } from "../types";
import { changeVATorGSTSchema } from "../validation";

const useUpdateVATorGSTForm = (
  action: CallableFunction,
  billingDetails?: PaymentMethodResponse
) => {
  return useFormik<ChangeVATorGSTProps>({
    initialValues: {
      eu_vat: billingDetails?.billing_details.eu_vat || "",
    },
    validateOnChange: false,
    validationSchema: changeVATorGSTSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useUpdateVATorGSTForm;
