import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { AgreementFilesType } from "../types";
import { show_all_objects } from "shared/helpersV2/constant";

export default function getAgreementFiles(
  permissions: { team: TeamParameter },
  agreementId: number,
  requestParams?: {
    ordering?: string;
  }
): Promise<AxiosResponse<AgreementFilesType[]>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<AgreementFilesType[]>(`agreements/${agreementId}/files/`, {
    params: {
      ...{ team: teamParameter, company: permissions.team.company },
      ...hasNameOrdering(requestParams),
      show_all_objects,
    },
  });
}
