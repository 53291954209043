import React from "react";

import clsx from "clsx";
import { TextInput } from "flowbite-react";
import { Link } from "react-router-dom";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";

import style from "assets/css/settings.module.css";

import { CompanyGroupResponse } from "../types";

export default function GroupsHeader({
  setIsFiltered,
  setFilteredGroups,
  groupList,
}: {
  setIsFiltered: (data: boolean) => void;
  setFilteredGroups: (data: Array<CompanyGroupResponse>) => void;
  groupList: Array<CompanyGroupResponse>;
}) {
  const { currentCompany } = useCompany();

  const onSearchGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setIsFiltered(false);
      return;
    }
    setIsFiltered(true);
    const groups = groupList?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setFilteredGroups(groups);
  };

  return (
    <div className={style.layout_card_info}>
      <div className="flex gap-4">
        <div className="w-2/5 relative">
          <TextInput
            className={style?.search_field}
            placeholder="Search"
            onChange={onSearchGroup}
          />
          <Icon type="search" className={clsx(style.search_img, "seeting_icon")} />
        </div>
        <div>
          <Link
            to={`/settings/${currentCompany.id}/groups/create`}
            className="btn_primary maxMd:mt-3.5 maxMd:w-full flex gap-2 items-center"
          >
            <Icon type="plus" className={style.create_group_icon} />
            Add new group
          </Link>
        </div>
      </div>
    </div>
  );
}
