import { useCallback } from "react";
import { AxiosError, isAxiosError } from "axios";
import clsx from "clsx";
import { toast } from "react-toastify";

import { RelatedObjectType } from "modules/tasks/overview/types";
import headerStyles from "assets/css/common-header.module.css";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import { MetaTable } from "../types";
import PermissionForm from "./permission-form";
import usePermissionList from "./usePermissionList";
import { Alert } from "flowbite-react";
import PermissionItem from "./permission-item";
import usePermissionUpdateMutation from "./usePermissionUpdateMutation";
import usePermissionDeleteMutation from "./usePermissionDeleteMutation";

export type PermissionsProps = {
  type: MetaTable;
  relatedObject: RelatedObjectType;
};

export default function Permissions({ type, relatedObject }: PermissionsProps) {
  const query = usePermissionList({ type, relatedObject });
  const updateMutation = usePermissionUpdateMutation({
    type,
    relatedObject,
  });
  const deleteMutation = usePermissionDeleteMutation({
    type,
    relatedObject,
  });

  const handlePermissionCreated = useCallback(() => {
    toast.success("Permission created successfully");
    query.refetch();
  }, []);

  const handlePermissionError = useCallback((error: AxiosError | Error) => {
    if (!isAxiosError(error) || error.response?.status !== 400) {
      toast.error("Something went wrong");
    }
  }, []);

  return (
    <div className="metadata-tabs h-screen" data-testid="permissions">
      <h1 className={clsx(headerStyles.subscriptionTitle, "metadata-tabs__header")}>Permissions</h1>
      <div className="permission-content">
        <div className="permission-form">
          <PermissionForm
            type={type}
            permissions={query.isSuccess ? query.data : []}
            relatedObject={relatedObject}
            onSuccess={handlePermissionCreated}
            onError={handlePermissionError}
          />
        </div>
        <div
          id="permission-list"
          className={clsx("permission-list", "mt-4")}
          data-testid="permission-list"
        >
          {query.isLoading ? <CentralSpinner /> : null}
          {query.isSuccess
            ? query.data.map((permission) => (
                <div
                  key={permission.id}
                  data-testid={`permission-item-${permission.id}`}
                  className={clsx("permission-item", "py-2")}
                >
                  <PermissionItem
                    permission={permission}
                    onUpdate={(permission, level) => {
                      updateMutation.mutate({ permission, level });
                    }}
                    onDelete={(permission) => {
                      deleteMutation.mutate(permission);
                    }}
                  />
                </div>
              ))
            : null}
          {query.isError ? (
            <Alert color="failure" className="mt-4">
              Cannot load permissions
            </Alert>
          ) : null}
        </div>
      </div>
    </div>
  );
}
