import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId, TeamId } from "shared/types";
import { getUrlFromType } from "shared/helpers/getUrlFromType";
import { ARTICLE_MODULE } from "shared/helpers/ability";

import { MetaTable, TeamActionResposne } from "../types";
import convertMetadataResponse from "../helpers/convertMetadataResponse";
import { AGREEMENT_TABLE } from "shared/helpers/constant";
import { show_all_objects } from "shared/helpersV2/constant";

export default async function getTeamActions({
  permission,
  type,
  typeId,
}: {
  permission: { teamId: TeamId; companyId: CompanyId; isAllTeam: boolean };
  type: MetaTable;
  typeId: string;
}): Promise<AxiosResponse<TeamActionResposne>> {
  const { teamId, companyId, isAllTeam } = permission;

  const typeUrl = getUrlFromType(type);

  if (typeUrl === "default") {
    return Promise.reject(new Error("Module is not selected"));
  }

  const teamPermissionPayload = { team: teamId, company: companyId, isAllTeam: isAllTeam };

  const teamParameter = teamPermissionPayload?.isAllTeam ? "all" : teamPermissionPayload?.team;

  const URL =
    type === ARTICLE_MODULE ? `${typeUrl}/${typeId}/team-actions/` : `${typeUrl}/${typeId}/`;

  let response = await api.get<TeamActionResposne>(URL, {
    params: {
      ...{ team: teamParameter, company: teamPermissionPayload.company },
      show_all_objects: type === AGREEMENT_TABLE ? show_all_objects : undefined,
    },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertMetadataResponse(response.data),
    };
  }

  return response;
}
