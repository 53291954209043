import React from "react";
import { useCallback, useRef } from "react";

import { Dropdown } from "flowbite-react";
import { useOnClickOutside } from "usehooks-ts";
import clsx from "clsx";
import get from "lodash/get";

import { FAVORITE_TYPE, ICONTAINS_OPERATOR } from "shared/helpers/constant";
import {
  useTableFilter,
  useFavoriteFilter,
  TableFilterTypeProps,
} from "shared/store/table-column-selection";
import { FiltersProp } from "shared/components/common-table/types";
import getFilterById from "shared/components/common-table/api/getFilterById";

import styles from "assets/css/commonTable.module.css";
import headerStyles from "assets/css/common-header.module.css";

import { FilterProps, SelectProps } from "./types";
import InputFavorite from "./InputFavorite";
import useFavorite from "./hooks/useFavorite";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import FilterModal from "../filter-modal/FilterModal";
import Icon from "../icon/Icon";

import { ReactComponent as Star } from "assets/images/icons/star.svg";

const Favorites = ({
  type,
  filterOptions,
  displayFavorites,
  searchText,
}: {
  type: string;
  filterOptions: FiltersProp[];
  displayFavorites: boolean;
  searchText: string;
}) => {
  const { response, deleteFavorite, setIsDeleteModalOpen, isDeleteModalOpen } = useFavorite();

  const [selectedFilters, setSelectedFilters, setSelectedFiltersForModules] = useTableFilter(
    (state) => [state.selectedFilters, state.setSelectedFilters, state.setSelectedFiltersForModules]
  );

  const setSelectedFavoriteFilter = useFavoriteFilter((state) => state.setSelectedFilters);
  const clickedFavoriteFilter = useFavoriteFilter((state) => state.selectedFilters);
  const setIsFavoriteModalOpen = useFavoriteFilter((state) => state.setIsModalOpen);
  const isFavoriteModalOpen = useFavoriteFilter((state) => state.isModalOpen);

  const deleteId = useRef<number>(0);

  const favoriteRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideOfFavorite = () => {
    favoriteRef?.current
      ?.querySelectorAll<HTMLButtonElement>("[aria-describedby]  button")
      ?.forEach((button) => button?.click());
  };

  const clickEditFavoriteFilter = (filterId: number, teamId: number) => {
    getFilterById(filterId, teamId).then((response) => {
      setSelectedFavoriteFilter((): FilterProps => {
        return response.data;
      });
      setIsFavoriteModalOpen(() => true);
    });
  };

  useOnClickOutside(favoriteRef, handleClickOutsideOfFavorite);

  const removeFilter = (filter: FilterProps) => {
    setSelectedFilters((prevFilters: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      const data = Object.assign({}, prevFilters, {
        [type]: get(prevFilters, type)?.filter((item: SelectProps) => {
          return !(item.value === filter.id && item.key === filter.name);
        }),
      });
      return data;
    });
  };

  const onFavoriteClick = useCallback(
    (filter: FilterProps) => {
      filter.id
        ? getFilterById(filter.id, filter.team).then((response) => {
            const data: TableFilterTypeProps = Object.assign({}, selectedFilters, {
              [type]: [
                {
                  label: (
                    <>
                      <div className="!px-0 !py-0 flex items-center bg-cyanblue !pr-2 rounded-md">
                        <span className="mr-2 bg-newcar !py-1 !px-1.5 inline-block rounded-l-[5px]">
                          <Icon
                            type="star"
                            fill={true}
                            size="icon-smd"
                            className="themesvg_white"
                          />
                        </span>
                        <span
                          className="mr-2 text-newcar text-sm"
                          onClick={() => {
                            if (response.data.id != undefined) {
                              clickEditFavoriteFilter(response.data.id, response.data.team);
                            }
                          }}
                        >
                          {response.data.name}
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            removeFilter({ ...response?.data });
                          }}
                        >
                          <Icon
                            type="close"
                            fill={true}
                            size="icon-xs"
                            className="close_filter_icon"
                          />
                        </span>
                      </div>
                    </>
                  ),
                  conditions: response.data.conditions,
                  key: response.data.name,
                  value: response.data.id,
                  type: FAVORITE_TYPE,
                },
              ],
            });
            setSelectedFiltersForModules(data);
            handleClickOutsideOfFavorite();
          })
        : null;
    },
    [setSelectedFiltersForModules]
  );

  const isFavoritesSelected = (filter: FilterProps) => {
    const selectedFilter = get(selectedFilters, type)?.find(
      (_search: SelectProps) => _search.type === FAVORITE_TYPE && _search.value === filter.id
    );
    return !!selectedFilter;
  };

  const filterSearchIfAlreadyDeleted = () => {
    const newSearches = get(selectedFilters, type)?.filter(
      (_search: SelectProps) =>
        !(_search.type === FAVORITE_TYPE && parseInt(_search.value || "") === deleteId.current)
    );

    const data: TableFilterTypeProps = Object.assign({}, selectedFilters, {
      [type]: newSearches,
    });
    setSelectedFiltersForModules(data);
  };

  const filters =
    response?.data.results.filter((result: FilterProps) => result.object_type === type) || [];

  const favoriteSearchData = () => {
    if (searchText?.length > 0) {
      return [
        ...(get(selectedFilters, type) || []),
        {
          label: (
            <div className="!px-0 !py-0 flex items-center bg-cyanblue !pr-2 rounded-md">
              <span className=" mr-2 bg-newcar !py-1 !px-1.5 inline-block rounded-l-[5px]">
                <Icon type="filter" fill={true} size="icon-smd" className="themesvg_white" />
              </span>
              <span className="mr-2 text-newcar text-sm">Search</span>
            </div>
          ),
          value: `${searchText}`,
          field: "search",
          key: searchText,
          operator: ICONTAINS_OPERATOR,
          conditions: [
            {
              field: "search",
              operator: ICONTAINS_OPERATOR,
              value: searchText,
            },
          ],
        },
      ];
    } else {
      return get(selectedFilters, type);
    }
  };

  const dropdownItem = () => {
    return (
      <Dropdown
        label="Save Current Search"
        className={clsx("!w-[12.5rem] !left-[13.5rem] dark:bg-thunders")}
        inline
        placement="right-start"
        dismissOnClick={false}
      >
        <Dropdown.Item className="dark:hover:!bg-thunders">
          <div className={headerStyles.items}>
            <InputFavorite
              search={favoriteSearchData()}
              type={type}
              onFavoriteClick={onFavoriteClick}
            />
          </div>
        </Dropdown.Item>
      </Dropdown>
    );
  };

  return (
    <>
      {displayFavorites ? (
        <>
          <div className={headerStyles.actionTag} ref={favoriteRef}>
            <Dropdown
              data-testid="favorit_dropdown"
              label={
                <>
                  <Star className="si fill w-5 h-5" />
                  <span data-testid="favorites-label">Favorites</span>
                </>
              }
              className={clsx(
                headerStyles.favoriteDropdown,
                " favorites",
                {
                  [styles.disableFavorite]:
                    (!get(selectedFilters, type) || get(selectedFilters, type)?.length === 0) &&
                    (!searchText || searchText.length === 0),
                },
                "save_current_search"
              )}
              inline
              arrowIcon={false}
              dismissOnClick={false}
            >
              {dropdownItem()}
              {filters?.map((filter: FilterProps) => (
                <Dropdown.Item
                  key={filter.id}
                  onClick={() => onFavoriteClick(filter)}
                  className={clsx({
                    "bg-antiflashwhite dark:bg-darkjunglegreenss ": isFavoritesSelected(filter),
                  })}
                >
                  <div className="flex justify-between w-full" data-testid="favorit-subdropdown">
                    <span>{filter.name}</span>
                    <span
                      className="pl-1.5"
                      data-testid="delete-favorits"
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsDeleteModalOpen(true);
                        deleteId.current = Number(filter?.id);
                      }}
                    >
                      <Icon type="trash" fill={true} size="icon-sm" className="setingright_icon" />
                    </span>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
          <ConfirmModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            action={async () => {
              deleteFavorite(deleteId.current);
              filterSearchIfAlreadyDeleted();
            }}
          />
          {clickedFavoriteFilter.id ? (
            <FilterModal
              type={type}
              filterOptions={filterOptions}
              filter={clickedFavoriteFilter}
              filterApp={type}
              isOpen={isFavoriteModalOpen}
              setIsOpen={setIsFavoriteModalOpen}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Favorites;
