import React from "react";

import Spinner from "./Spinner";

const CentralSpinner = () => {
  return (
    <div
      data-testid="central-spinner"
      className="fixed inset-0 flex justify-center items-center z-10 "
    >
      <Spinner />
    </div>
  );
};

export default CentralSpinner;
