import toString from "lodash/toString";

function serializeParams(params: Record<string, unknown> | URLSearchParams) {
  if (params instanceof URLSearchParams) {
    console.warn("Deperacted usage of serializeParams with URLSearchParams");
    console.warn("Api call should be updated to use object instead of URLSearchParams");
    return params.toString();
  }

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      searchParams.append(key, value.map(toString).join(","));
    } else {
      searchParams.append(key, toString(value));
    }
  });
  return searchParams.toString();
}

export default serializeParams;
