import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { KanbanOrderResultType } from "../types";
import { TeamId } from "shared/types";

export const kanbanOrderUpdate = ({
  requestOrder,
  status,
  order,
  permission,
}: {
  requestOrder?: KanbanOrderResultType;

  status: number | string;
  order: Array<number>;
  permission?: { team: TeamId };
}): Promise<AxiosResponse<KanbanOrderResultType>> => {
  const payload = {
    team: permission?.team,
    status: status,
    order: order,
  };

  if (requestOrder)
    return api.patch<KanbanOrderResultType>(
      `tasks/kanban-order/${requestOrder?.id}/`,
      { ...payload },
      { params: { ...permission } }
    );
  else
    return api.post<KanbanOrderResultType>(
      `tasks/kanban-order/`,
      { ...payload },
      { params: { ...permission } }
    );
};
