import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import { PaginatedCompanyGroupResponse } from "../types";

export const getGroups = async (
  permissions: TeamParameter
): Promise<AxiosResponse<PaginatedCompanyGroupResponse>> => {
  const result = await api.get<PaginatedCompanyGroupResponse>(`companies/groups/`, {
    params: { company: permissions.company },
  });

  return result;
};
