import { useCompany } from "shared/context/CompanyProvider";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getServerErrors } from "shared/helpers/util";
import { toast } from "react-toastify";
import getRiskById from "../api/getRiskById";

const useRiskQuery = (riskId?: number) => {
  const { currentCompany } = useCompany();

  const query = useQuery(
    ["get-risk-by-id", riskId],
    () =>
      getRiskById(
        {
          riskId: riskId || 0,
        },
        {
          company: currentCompany.id,
        }
      ),
    {
      enabled: !!riskId,
      onError: (error: AxiosError) => {
        getServerErrors(error)?.forEach((err: string) =>
          toast(err, {
            type: "error",
            className: "toast_v2",
            bodyClassName: "toast_v2_body",
            theme: "dark",
          })
        );
      },
    }
  );

  const { data, isLoading, isError, refetch } = query;

  return { data: data?.data, isLoading, isError, refetch };
};

export default useRiskQuery;
