import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { RegisterResponseType } from "../types";
import { convertRiskData } from "../helpers";

export default async function connectMitigations(
  riskId: number,
  mitigationIds: number[],
  permissions: TeamParameter
): Promise<AxiosResponse<RegisterResponseType>> {
  const teamParameter = permissions?.isAllTeam ? "all" : permissions?.team;

  let response = await api.patch<RegisterResponseType>(
    `risks/${riskId}/`,
    { mitigations: mitigationIds },
    {
      params: { ...{ team: teamParameter, company: permissions.company } },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertRiskData(response.data),
    };
  }

  return response;
}
