import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { DocumentCommentResponse } from "../types";

export default function createDocumentsComments(
  document: number,
  tempData: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<DocumentCommentResponse>> {
  return api.post<DocumentCommentResponse>(`documents/comments/`, tempData, {
    params: { document, ...permissions },
  });
}
