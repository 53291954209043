import React from "react";

import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import clsx from "clsx";
import { AxiosError } from "axios";

import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import ServerErrors from "shared/components/server-errors";
import { CompanyId } from "shared/types";

import styles from "assets/css/task.module.css";

import { useManagePresetForm } from "../hooks/useManagePresetForm";
import { CreatePresetRequest } from "../types";

const PresetModal = ({
  preset,
  isModalOpen,
  handleClose,
  handleSubmitPreset,
  currentCompany,
  isLoading,
  isError,
  error,
}: {
  preset?: CreatePresetRequest;
  isModalOpen: boolean;
  handleClose: (state: boolean) => void;
  handleSubmitPreset: (preset: CreatePresetRequest) => void;
  isLoading?: boolean;
  currentCompany: CompanyId;
  isError?: boolean;
  error?: AxiosError | null;
}) => {
  const { documentBodyRef } = useDocumentBodyRef();

  const isEdit = preset ? true : false;

  const { handleSubmit, values, errors, handleChange, resetForm } = useManagePresetForm(
    currentCompany,
    preset,
    () => {
      handleSubmitPreset({ ...values });
      resetForm();
    }
  );

  const handleCLoseModal = () => {
    resetForm();
    handleClose(false);
  };

  return (
    <>
      <div data-testid={isEdit ? "edit-preset" : "add-preset"}>
        <Modal
          root={documentBodyRef}
          show={isModalOpen}
          size="2xl"
          popup={true}
          onClose={() => {
            handleClose(false);
            resetForm();
          }}
          dismissible={true}
          className={styles.taskModalStyle}
        >
          <Modal.Header
            className={clsx(styles.taskModalHeader, "dark:!border-thunders mac13Inch:items-center")}
          >
            <div className="text-lg mac13Inch:text-base font-sans-medium dark:!text-white !text-mirage">
              {isEdit ? "Rename Preset" : "Add New Preset"}
            </div>
          </Modal.Header>
          <form onSubmit={handleSubmit} data-testid="preset_form">
            <Modal.Body className="!py-[30px] mac13Inch:!py-6">
              <div className="space-y-6 ">
                <div className="mb-4">
                  <Label value="Preset Name*" className="font-medium mb-2 block !text-mirage" />
                  <TextInput
                    data-testid="name-input"
                    name="name"
                    placeholder="Enter name"
                    value={values.name}
                    onChange={handleChange}
                    color={errors.name ? "failure" : "gray"}
                  />
                </div>
                {isError && error ? <ServerErrors className="mb-4" error={error} /> : null}
              </div>
            </Modal.Body>
            <Modal.Footer className="border-t dark:!border-thunders mac13Inch:py-4">
              <button
                className="btn_secondary flex ml-auto"
                type="button"
                onClick={handleCLoseModal}
              >
                Cancel
              </button>
              <button className="btn_primary flex ml-auto" type="submit">
                {isEdit ? "Update" : "Add"}
                <Spinner
                  hidden={!isLoading}
                  size="sm"
                  className="ml-1 !fill-mirage stroke-mirage"
                />
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default PresetModal;
