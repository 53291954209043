import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { IncidentsResponseType, IncidentInputData } from "../types";
import { convertIncidentData } from "../helpers";

export default async function createIncident(
  data: IncidentInputData,
  team: TeamParameter
): Promise<AxiosResponse<IncidentsResponseType>> {
  let response = await api.post<IncidentsResponseType>("risks/incidents/", data, {
    params: {
      team: team.isAllTeam ? "all" : team?.team,
      company: team.company,
    },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertIncidentData(response.data),
    };
  }

  return response;
}
