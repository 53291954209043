import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { PaginatedCompanyMember } from "../types";

export default function getMemberByEmail(
  companyId: number,
  requestParams?: { ordering?: string; page?: number; search?: string }
): Promise<AxiosResponse<PaginatedCompanyMember>> {
  return api.get(`companies/members/?company=${companyId}`, {
    params: requestParams,
  });
}
