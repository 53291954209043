import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import { getFederatedGroups } from "../api";

const useGetGroupList = (sso: number) => {
  const { currentCompany } = useCompany();

  const { data: response, refetch } = useQuery(["get-federated-groups"], () =>
    getFederatedGroups({ company: currentCompany.id, sso })
  );

  return { response, refetch };
};

export default useGetGroupList;
