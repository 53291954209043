import { AxiosResponse } from "axios";

import { TeamId } from "shared/types";
import api from "shared/api/v1";

import { CreateNoteData, NoteResponse } from "../types";
import { convertNoteData } from "../helpers";

export default async function createNote(
  data: CreateNoteData,
  teamId: TeamId,
  permissions: { team: TeamId }
): Promise<AxiosResponse<NoteResponse>> {
  let response = await api.post<NoteResponse>(
    `notes/`,
    { ...data, team: teamId },
    {
      params: {
        ...permissions,
      },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertNoteData(response.data),
    };
  }

  return response;
}
