import { QUESTIONNAIRE_MODULE, REPORT_MODULE, VENDOR_MODULE } from "shared/helpers/ability";

export default function getConfirmModalV2(moduleName: string) {
  const showNewModalModules = [VENDOR_MODULE, REPORT_MODULE, QUESTIONNAIRE_MODULE];

  if (showNewModalModules.includes(moduleName)) {
    return true;
  } else {
    return false;
  }
}
