import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { SubscribeResponse, SubscriptionProps } from "../types";

export const addSubscription = async (
  data: SubscriptionProps,
  id: number
): Promise<AxiosResponse<SubscribeResponse>> => {
  return await api.post(`companies/${id}/subscribe/?company=${id}`, data);
};
