import React from "react";

import Icon from "shared/components/icon/Icon";

type SpotlightSearchBottomControlsProps = {
  resultLength: number;
};

const SpotlightSearchBottomControls = ({ resultLength }: SpotlightSearchBottomControlsProps) => {
  const searchResults = resultLength > 0 ? resultLength : "No";

  return (
    <ul className="flex" data-testid="search-bottom-controls">
      <li className="dark:text-stormdust flex items-center" data-testid="search-result-length">
        {searchResults} results
      </li>
      <li className="flex items-center maxMd:hidden">
        <Icon type="updowns" fill={false} size="icon-xs" className="mr-1" />
        <span>Select</span>
      </li>
      <li className="flex items-center maxMd:hidden">
        <Icon type="reply" fill={false} size="icon-xs" className="mr-1" />
        <span>Open</span>
      </li>
      <li className="flex items-center maxMd:hidden">
        <Icon type="command" fill={true} size="icon-xs" className="mr-1" />
        <Icon type="reply" fill={false} size="icon-xs" className="mr-1" />
        <span>Open in new tab</span>
      </li>
    </ul>
  );
};

export default SpotlightSearchBottomControls;
