import { useQuery } from "@tanstack/react-query";
import { useCompany } from "shared/context/CompanyProvider";
import getTopics from "../api/getTopics";

const useTopicsQuery = () => {
  const { currentCompany } = useCompany();

  const { data, isLoading, refetch } = useQuery(["get-topics"], () =>
    getTopics({
      company: currentCompany?.id,
    })
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
  };
};

export default useTopicsQuery;
