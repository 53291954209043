import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { FormikErrors } from "formik";

import Icon from "shared/components/icon/Icon";
import transform from "shared/helpers/transform";
import revert from "shared/helpers/revert";
import { MAX_PRESET_LEVELS } from "shared/helpers/constant";
import removeExtraDecimals from "shared/helpers/removeExtraDecimals";

import { getRiskColors } from "modules/risks/shared/helpers/getRiskColors";
import { PresetLevelType, PresetScaleType } from "modules/risks/register/types";

import styles from "assets/css/settings.module.css";

import { MultiSlider } from "./MultiSlider";
import PresetLevels from "./PresetLevels";
import { RisksSettingForm } from "../types";
import ScaleTitle from "./ScaleTitle";

function PresetCart({
  errors,
  presetData,
  levels,
  handleChange,
  isCustomPreset,
  fieldName,
  scale,
  scaleValue,
  handleScaleChange,
}: {
  errors: FormikErrors<RisksSettingForm>;
  fieldName: string;
  presetData: {
    title: string;
    scale: string;
    description: string;
    scaleType: PresetScaleType;
  };
  scaleValue: PresetScaleType;
  scale: Array<PresetScaleType>;
  isCustomPreset: boolean;
  levels: Array<PresetLevelType>;
  handleChange: (value: Array<PresetLevelType>) => void;
  handleScaleChange: (scale: PresetScaleType) => void;
}) {
  const [sortedLevels, setSortedLevels] = useState<Array<PresetLevelType>>(levels);

  useEffect(() => {
    setSortedLevels(levels.sort((a, b) => a.level - b.level));
  }, [levels]);

  const isSimpleScale = useMemo(() => (scaleValue === "simple" ? true : false), [scaleValue]);

  return (
    <div>
      <h3 className="mb-4 dark:text-white">{presetData.title}</h3>
      <div className="flex flex-col gap-4">
        <div>
          <h3 className={clsx(styles.levelTitle, "mb-2")}>Scale</h3>

          <div className="flex gap-4">
            {scale.map((scale) => (
              <ScaleTitle
                isCustomPreset={isCustomPreset}
                value={scaleValue}
                handleChange={handleScaleChange}
                type={scale}
              />
            ))}
          </div>
        </div>
        <div className="md:w-2/4">
          <MultiSlider
            isCustomPreset={isCustomPreset}
            values={sortedLevels
              .slice(0, -1)
              .map((data) =>
                isSimpleScale ? removeExtraDecimals(data.value) : revert(data.value)
              )}
            handleChange={(values) => {
              const data = sortedLevels.reduce<Array<PresetLevelType>>(
                (prevData, currData, index) => {
                  if (currData.level !== values[index] && index !== sortedLevels.length - 1) {
                    currData.value = isSimpleScale ? values[index] : transform(values[index]);
                  }
                  prevData.push(currData);
                  return prevData;
                },
                []
              );

              handleChange(data);
            }}
            min={isSimpleScale ? 0 : 0.00002}
            max={isSimpleScale ? 1 : 237}
            step={isSimpleScale ? 0.0001 : undefined}
            ringColors={getRiskColors(sortedLevels.length, "ring")}
            primaryColors={getRiskColors(sortedLevels.length)}
            scaleType={scaleValue}
            labels={sortedLevels.map((data) => data.name)}
          />
        </div>
        <div className="grid grid-cols-2">
          <div>
            <PresetLevels
              isCustomPreset={isCustomPreset}
              fieldName={fieldName}
              errors={errors}
              handleChange={(data) => {
                const arrangeLevels = [
                  ...sortedLevels.filter((prevLevel) => prevLevel.level !== data.level),
                  { ...data },
                ].sort((a, b) => a.level - b.level);

                setSortedLevels(arrangeLevels);
                handleChange(arrangeLevels);
              }}
              data={sortedLevels}
              levelPlaceholder="Enter Level name"
              descriptionPlaceholder="Enter Description"
              handleDelete={(index) => {
                const newLevelList = sortedLevels
                  .filter((_, i) => i !== index)
                  .sort((a, b) => a.level - b.level)
                  .map((prevLevel, index) => {
                    return { ...prevLevel, level: index + 1 };
                  });
                newLevelList[newLevelList.length - 1].value = 1;

                setSortedLevels(newLevelList);
                handleChange(newLevelList);
                toast("Level deleted successfully", { type: "info" });
              }}
            />
            <button
              disabled={!isCustomPreset}
              type="button"
              className={clsx(
                {
                  "pointer-events-none opacity-50": sortedLevels.length === MAX_PRESET_LEVELS,
                  "cursor-not-allowed": !isCustomPreset,
                },
                "flex items-center p-4 w-full mt-[10px] border-dashed border rounded-lg dark:border-liver",
                styles.levelValue
              )}
              onClick={() => {
                const newLevel = {
                  level: sortedLevels.length + 1,
                  description: "",
                  name: "",
                  value: 1,
                };
                handleChange([...sortedLevels, newLevel]);
                setSortedLevels((prev) => [...prev, newLevel]);
              }}
            >
              <Icon type="plus-alt" /> <span className="ml-2">Add Level</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresetCart;
