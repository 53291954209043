import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export default function deleteRisksById(
  { riskId }: { riskId: number },
  permissions: { team: TeamId }
): Promise<AxiosResponse<string>> {
  return api.delete<string>(`risks/${riskId}/`, { params: { ...permissions } });
}
