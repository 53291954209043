import { FileViewerProps } from "./types";

export default function PdfFileViewer({ document }: FileViewerProps) {
  return (
    <div
      data-testid="pdf-file-viewer"
      className="flex flex-col justify-center items-center w-full h-full pdf-mobile-scroll"
    >
      <iframe src={document.file} title={document.filename} className="w-full h-full" />
    </div>
  );
}
