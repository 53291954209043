import { useFormik } from "formik";

import { ChangeAddressProps, PaymentMethodResponse } from "../types";
import { changeAddressSchema } from "../validation";

const useUpdateAddressForm = (action: CallableFunction, billingDetails?: PaymentMethodResponse) => {
  const data = billingDetails?.billing_details.address;

  return useFormik<ChangeAddressProps>({
    initialValues: {
      name: billingDetails?.billing_details.name || "",
      address: {
        line1: data?.line1 || "",
        line2: data?.line2 || "",
        city: data?.city || "",
        postal_code: data?.postal_code || 0,
        state: data?.state || "",
        country: data?.country || "",
      },
    },
    validateOnChange: false,
    validationSchema: changeAddressSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useUpdateAddressForm;
