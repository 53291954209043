import { PaginatedResponse } from "shared/types";

/**
 * Load all pages from a paginated API
 */
export default function loadAll<T>(
  loader: (page: number) => Promise<PaginatedResponse<T>>
): Promise<T[]> {
  let results: T[] = [];

  function load(page = 1): Promise<T[]> {
    return loader(page).then((response) => {
      results = [...results, ...response.results];
      if (response.next) {
        return load(page + 1);
      }
      return results;
    });
  }

  return load();
}
