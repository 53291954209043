import { useState } from "react";

import { flexRender, Row, Table } from "@tanstack/react-table";
import { Button, Checkbox } from "flowbite-react";
import clsx from "clsx";
import { get } from "lodash";

import Icon from "../icon/Icon";

import { useTeam } from "shared/store/settings";
import useSetTableview from "shared/hooks/useSetTableview";

import styles from "assets/css/commonTable.module.css";

import CentralSpinner from "../spinner/CentralSpinner";

function SimpleTable<TData>({
  table,
  onRowClick,
  isLoading,
  className,
  rowClickable = true,
  rowSelection = true,
  columnSelection = false,
  handleChangeSelection,
}: {
  table: Table<TData>;
  onRowClick?: (id: string, data: TData, index: number, itemIds: string[]) => void;
  isLoading: boolean;
  className?: string;
  rowSelection?: boolean;
  rowClickable?: boolean;
  columnSelection?: boolean;
  handleChangeSelection?: (status: boolean) => void;
}) {
  const { currentTeam } = useTeam();

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((state) => !state);
    if (handleChangeSelection) {
      handleChangeSelection(!open);
    }
  };

  const markRowAsRead = (row: Row<TData>) => {
    if (!row.getVisibleCells()[9]) return false;
    if (row.getVisibleCells()[9]?.column.id === "read" && row.getVisibleCells()[9].getValue()) {
      return true;
    }
    return false;
  };

  useSetTableview();

  return (
    <div className={clsx(className, "overflow-auto w-full relative")}>
      {isLoading ? <CentralSpinner /> : null}

      <table className={clsx(styles.commonTable, { columnSpacing: !rowSelection })}>
        <thead>
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {rowSelection ? (
                <th className="row-selection">
                  {table?.getCoreRowModel()?.rows?.length === 0 ? (
                    ""
                  ) : (
                    <Checkbox
                      data-testid="select-all-checkbox"
                      className={styles.checkbox}
                      checked={table.getIsAllPageRowsSelected()}
                      onChange={table.getToggleAllPageRowsSelectedHandler()}
                      disabled={currentTeam.is_all_team}
                    />
                  )}
                </th>
              ) : null}
              {headerGroup?.headers?.map((header, index) => {
                return (
                  <th key={index} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: clsx({
                              flex: !header.column.getCanSort(),
                              "select-none flex font-medium font-inter-medium":
                                header.column.getCanSort(),
                              "cursor-pointer": onRowClick && header.column.getCanSort(),
                            }),
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          {{
                            asc: (
                              <Icon
                                type="arrow-up"
                                fill={false}
                                size="icon-smd"
                                className="themestroke_white"
                              />
                            ),
                            desc: (
                              <Icon
                                type="arrow-down"
                                fill={false}
                                size="icon-smd"
                                className="themestroke_white"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </>
                    )}
                    {columnSelection && header.column.id === "column_selection" ? (
                      <Button
                        className="column-selection__toggle ml-auto pr-4"
                        color="white"
                        onClick={toggle}
                      >
                        <Icon type="threedots" fill={true} size="icon-sm" />
                      </Button>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={styles.commonTableBody}>
          {!(table.getCoreRowModel().rows.length === 0)
            ? table.getCoreRowModel().rows?.map((row) => {
                return (
                  <tr
                    key={row.id + "row"}
                    className={clsx(
                      "relative",
                      { mark_row_read: markRowAsRead(row) },
                      {
                        "cursor-pointer": onRowClick && rowClickable,
                        relative: !open,
                      }
                    )}
                    onClick={() =>
                      onRowClick &&
                      onRowClick(
                        `${get(row.original, "id")}`,
                        row.original,
                        row.index,
                        table.getCoreRowModel().rows?.map((row) => {
                          return `${get(row.original, "id")}`;
                        })
                      )
                    }
                  >
                    {rowSelection ? (
                      <td className="row-selection">
                        <Checkbox
                          className={styles.checkbox}
                          checked={row.getIsSelected()}
                          onClick={(e) => e.stopPropagation()}
                          onChange={row.getToggleSelectedHandler()}
                          disabled={currentTeam.is_all_team}
                        />
                      </td>
                    ) : null}
                    {row?.getVisibleCells()?.map((cell, index) => {
                      return (
                        <td key={index}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {table.getCoreRowModel().rows.length === 0 && !isLoading ? (
        <div className="py-10 text-sm text-center border-b dark:border-thunders dark:text-boulder ">
          No Data Available
        </div>
      ) : null}
    </div>
  );
}

export default SimpleTable;
