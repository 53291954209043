export const ObjectTypeToPathMapping = (objectType: string, objectId: string) => {
  switch (objectType) {
    case "article":
      return `/articles/overview/${objectId}`;
    case "agreement":
      return `/agreements/overview/${objectId}/view`;
    case "agreement_category":
      return `/agreements/categories/${objectId}/view`;
    case "agreement_type":
      return `/agreements/types/${objectId}/view`;
    case "supplier":
      return `/agreements/suppliers/${objectId}/view`;
    case "internal_entity":
      return `/agreements/entities/${objectId}/view`;
    case "task":
      return `/tasks/${objectId}/view`;
    case "task_status":
      return `/tasks/statuses/${objectId}/view`;
    case "directory":
      return `/documents/${objectId}`;
    case "document":
      return `/document/${objectId}`;
    case "policy":
      return `/policies/overview/${objectId}/view`;
    default:
      return "/";
  }
};

export const getLabelForSearchResult = (objectType: string) => {
  switch (objectType) {
    case "agreement_category":
      return "Agreement Category";
    case "agreement_type":
      return "Agreement Type";
    case "internal_entity":
      return "Internal Entity";
    case "task_status":
      return "Task Status";
    default:
      return `${objectType[0].toUpperCase()}${objectType.slice(1)}`;
  }
};

export function scrollListToDown(
  modalElement: HTMLDivElement,
  searchContainer: HTMLDivElement[],
  selectedIndex: number
) {
  if (
    modalElement?.getBoundingClientRect().bottom +
      49 -
      searchContainer[selectedIndex].getBoundingClientRect().bottom <
    146
  ) {
    searchContainer[selectedIndex + 1].scrollIntoView();
  }
}
