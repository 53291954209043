import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { AgreementCommentResponse } from "../types";
import { show_all_objects } from "shared/helpersV2/constant";

export default function createAgreementComments(
  agreement: number,
  tempData: FormData,
  permissions: { team: TeamId; company: number }
): Promise<AxiosResponse<AgreementCommentResponse>> {
  return api.post<AgreementCommentResponse>(`agreements/comments/`, tempData, {
    params: {
      agreement,
      ...permissions,
      show_all_objects,
    },
  });
}
