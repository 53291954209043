import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import clsx from "clsx";

import { DropdownOptionType } from "shared/types";

import styles from "assets/css/settings.module.css";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import useCompanyInfo from "shared/hooks/useCompanyInfo";
import { useSettings } from "shared/store/settings";
import CompanyInfo from "modules/auth/on-boarding/CompanyInfo";
import { CountryResponseParams, IndustryResponseParams } from "modules/auth/on-boarding/types";

import { updateCompany } from "./api";
import { CompanyProps } from "./types";
import DeleteCompany from "./delete-company/DeleteCompany";
import useCompanyForm from "./hooks/useCompanyForm";
import ChangePicture from "./change-picture/ChangePicture";

const CompanySettings = () => {
  const { currentCompany, updateCurrentCompany } = useCompany();
  const [companyPicture, setCompanyPicture] = useState<File | null>(null);

  const { industries, countries } = useCompanyInfo({
    industryKey: "getIndustriesForInfo",
    countryKey: "getCountriesForInfo",
  });

  const setIsModalOpen = useSettings((state) => state.setIsModalOpen);

  const [selectedIndustries, setSelectedIndustries] = useState<DropdownOptionType[]>([]);

  const [selectedCountries, setSelectedCountries] = useState<DropdownOptionType[]>([]);

  const {
    isLoading,
    mutate: companyUpdateFn,
    isError,
    error,
  } = useMutation((name: CompanyProps) => updateCompany(name, currentCompany.id, companyPicture), {
    onSuccess: (res) => {
      toast("Company updated successfully", { type: "success" });
      updateCurrentCompany(res.data);
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, handleChange, errors, setFieldValue } = useCompanyForm(() =>
    companyUpdateFn(values)
  );

  useEffect(() => {
    if (!countries || !industries) return;
    const _filteredIndustry = industries?.filter((industry: IndustryResponseParams) =>
      currentCompany.industries.includes(industry.id)
    );
    setSelectedIndustries(
      _filteredIndustry?.map((industry: IndustryResponseParams) => {
        return { label: industry.name, value: industry.id, data: industries };
      })
    );
    const _filteredCountry = countries?.filter((country: CountryResponseParams) =>
      currentCompany.countries.includes(country.id)
    );
    setSelectedCountries(
      _filteredCountry?.map((country: CountryResponseParams) => {
        return { label: country.name, value: country.id, data: country };
      })
    );
  }, [countries, industries]);

  return (
    <>
      <div className={styles.company_settings}>
        <form onSubmit={handleSubmit}>
          <div className={clsx(styles.account_content, "dark:border-thunders")}>
            <div className="text-sm mb-2 dark:text-white capitalize font-medium">name*</div>
            <TextInput
              type="text"
              placeholder="You can use your name or the name of your team. Keep it simple."
              value={values.name}
              name="name"
              onChange={handleChange}
              color={errors.name ? "failure" : "gray"}
            />
            <div className="max-w-[42.75rem]">
              <CompanyInfo
                setFieldValue={setFieldValue}
                selectedIndustries={selectedIndustries}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                setSelectedIndustries={setSelectedIndustries}
              />
            </div>
          </div>
          <ChangePicture companyPicture={companyPicture} setCompanyPicture={setCompanyPicture} />
          <DeleteCompany />
          {isError && (
            <div className="mx-11 mt-4">
              <ServerErrors className="mb-4" error={error} />
            </div>
          )}
          <div className="md:flex justify-end md:pr-11 px-4 pt-4 pb-4">
            <button
              onClick={() => setIsModalOpen(false)}
              className="btn_secondary md:mr-4 maxMd:mb-[18px] maxMd:w-full"
            >
              Cancel
            </button>
            <button className="btn_primary maxMd:w-full">
              <Spinner
                size="md"
                light={true}
                hidden={!isLoading}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CompanySettings;
