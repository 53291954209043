import { FilterValues } from "shared/components/common-table/types";

import { ArticleFilter } from "../types";

export default function articleFilterToFilterValues(filter: ArticleFilter): FilterValues {
  return {
    key: filter.id,
    value: filter.name,
  };
}
