import {
  PRESET_B_COLORS,
  RISK_HEAT_MAP_COLORS,
  RISK_HEAT_MAP_TEXT_COLORS,
} from "modules/risks/constants";

export const getAllRiskHeatMapColors = (
  probabilityNLevels: number,
  impactNLevels: number,
  type: "background" | "text" = "background"
) => {
  const max = Math.max(probabilityNLevels, impactNLevels);

  const colors = type === "background" ? RISK_HEAT_MAP_COLORS : RISK_HEAT_MAP_TEXT_COLORS;

  switch (max) {
    case 3:
      return [colors[0], colors[2], colors[4]];
    case 4:
      return type === "background" ? PRESET_B_COLORS : [colors[0], colors[2], colors[3], colors[4]];
    default:
      return [colors[0], colors[1], colors[2], colors[3], colors[4]];
  }
};
