import React, { Suspense, useEffect, useRef } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import NotFound from "shared/components/not-found";
import Spinner from "shared/components/spinner/Spinner";

import Task from "./overview";
import Kanban from "./overview/grid-view";
import Statuses from "./statuses";
import EditStatus from "./statuses/components/EditStatus";
import ViewStatus from "./statuses/components/ViewStatus";
import { useTeam } from "shared/store/settings";

const EditTask = React.lazy(() => import("./overview/components/EditTask"));
const ViewTask = React.lazy(() => import("./overview/view/ViewTask"));

const Tasks = () => {
  const { currentTeam, teams, setCurrentTeam, taskTeam } = useTeam((state) => state);
  const dedupe = useRef(false);

  useEffect(() => {
    if (
      (currentTeam && currentTeam?.id === taskTeam?.id) ||
      !teams?.length ||
      dedupe.current ||
      !taskTeam
    ) {
      return;
    }

    dedupe.current = true;

    const prevTeam = currentTeam;

    setCurrentTeam(taskTeam);

    return () => {
      if (prevTeam) {
        setCurrentTeam(prevTeam);
      } else {
        setCurrentTeam(teams.find((_) => _.is_general_team) || teams[0]);
      }

      dedupe.current = false;
    };
  }, [teams]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {currentTeam && currentTeam?.id === taskTeam?.id && (
          <>
            <Route index element={<Navigate to="/tasks/overview" />} />
            <Route path="/overview" element={<Task />} />
            <Route path="/gridview" element={<Kanban />} />
            <Route path=":taskId/edit" element={<EditTask />} />
            <Route path=":taskId/view" element={<ViewTask />} />
            <Route path="/statuses">
              <Route index element={<Statuses />} />
              <Route path=":statusId/view" element={<ViewStatus />} />
              <Route path=":statusId/edit" element={<EditStatus />} />
            </Route>
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Tasks;
