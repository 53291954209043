import React from "react";
import { colorClassFromName } from "shared/helpers/util";
import { AvatarData } from "./types";
import clsx from "clsx";

const Avatar = ({
  defaultName,
  name,
  url,
  avatarType,
  onClick,
  size = "regular",
  avatarFor,
  className,
}: AvatarData) => {
  const { backgroundColor, color } =
    avatarFor === "user"
      ? colorClassFromName(name || "")
      : { backgroundColor: "bg-antiflashwhite", color: "text-mirage" };

  return (
    <button
      onClick={onClick}
      className={clsx(`avatar_${size} border-none`, className)}
      data-testid="avatar-button"
      type="button"
    >
      {url ? (
        <img
          className={`w-full h-full avatar_${avatarType}`}
          src={url}
          alt={name || "avatar"}
          data-testid="avatar-image"
        />
      ) : (
        <div
          className={`avatar avatar_${avatarType} ${backgroundColor} ${color}`}
          data-testid="avatar-name"
        >
          {name ? (defaultName ? defaultName : name[0].toUpperCase()) : ""}
        </div>
      )}
    </button>
  );
};

export default Avatar;
