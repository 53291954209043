import React, { useEffect, useState } from "react";

import { TextInput } from "flowbite-react";
import clsx from "clsx";
import toString from "lodash/toString";
import includes from "lodash/includes";
import orderBy from "lodash/orderBy";

import headerStyles from "assets/css/common-header.module.css";
import { FILTER_TYPE } from "shared/helpers/constant";

import ShowableItem from "modules/risks/shared/associated-connect-modal/ShowableItem";

import { FilterOptionType, FilterValues } from "../../types";

export type TypeaheadFilterProps = {
  field: string;
  operator: string;
  items: FilterValues[];
  single_option: boolean;
  onClick: (data: FilterOptionType) => void;
};

function getFilterPropChecker(filterProp: string) {
  const filterPropLower = filterProp.toLowerCase();
  return (item: FilterValues) => includes(item.value.toLowerCase(), filterPropLower);
}

/**
 * TypeaheadFilter uses a TextInput to filter the items and then renders the filtered items.
 * When an item is clicked, it calls the onClick function with the item's data.
 * Uses instead of DropdownFilter when there are too many items to render.
 */
export default function TypeaheadFilter({
  field,
  operator,
  items,
  single_option,
  onClick: handleClick,
}: TypeaheadFilterProps) {
  const [searchString, setSearchString] = useState<string>("");

  const [offset, setOffset] = useState<number>(1);

  useEffect(() => {
    return () => {
      setSearchString("");
    };
  }, [field]);

  return (
    <>
      <TextInput
        data-testid="filterInput"
        className={clsx(headerStyles.formInput, "px-4")}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <ul className="py-1 overflow-y-auto overflow-x-hidden px-4 max-h-[390px]">
        {orderBy(items, [(item) => item.value.toLowerCase()])
          .filter(getFilterPropChecker(searchString))
          .slice(0, items.length)
          .map((item) => (
            <li
              key={toString(item.key)}
              className={clsx(
                "flex",
                "items-center",
                "justify-start",
                "py-2",
                "md:px-4",
                "text-sm",
                "text-gray-700",
                "cursor-pointer",
                "hover:bg-gray-100",
                "dark:text-gray-200",
                "dark:hover:bg-gray-600",
                "dark:hover:text-white"
              )}
              aria-label="button"
              onClick={() => {
                setSearchString("");
                handleClick({
                  field,
                  operator,
                  key: item.key,
                  label: <span>{item.value}</span>,
                  value: item.value,
                  type: FILTER_TYPE,
                  conditions: [
                    {
                      field,
                      operator,
                      value: toString(item.key),
                    },
                  ],
                  single_option: single_option,
                });
              }}
            >
              <span className="filter_search_truncate">{item.value}</span>
            </li>
          ))}

        {offset * items.length < items.length ? (
          <ShowableItem
            onShow={() => {
              setOffset((prev) => prev + 1);
            }}
          />
        ) : null}
      </ul>
    </>
  );
}
