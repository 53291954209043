import api from "shared/api/v1";

import { SubscriptionProps } from "../types";

export const getPlans = () => api.get("companies/plans/");

export const addSubscription = async (data: SubscriptionProps, id: number) =>
  await api.post(`companies/${id}/subscribe/?company=${id}`, data);

export const unsubscribe = async (id: number) =>
  await api.get(`companies/${id}/unsubscribe/?company=${id}`);

export const getReactivate = (id: number) => api.get(`companies/${id}/reactivate/?company=${id}`);
