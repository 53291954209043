import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetStatusRequestParams, PaginatedStatusListResponse } from "../types";

export const getStatus = (
  requestParams: GetStatusRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PaginatedStatusListResponse>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<PaginatedStatusListResponse>(`tasks/status/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{ team: teamParameter, company: permissions.team?.company },
    },
  });
};
