import { array, bool, object, string } from "yup";

export const addProviderSchema = object({
  sso_type: string().required(),
  domains: array()
    .min(1)
    .of(
      string()
        .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/)
        .trim()
        .required()
    ),
  name: string().required(),
});

export const addGroupMappingSchema = object({
  name: string().required(),
  group_id: string().required(),
  set_user_as_admin: bool().required(),
  teams: array().when("set_user_as_admin", {
    is: false,
    then: array().min(1).required(),
  }),
});
