import React from "react";
import { Navigate } from "react-router-dom";

import { CompanyProvider, useCompany } from "shared/context/CompanyProvider";
import { UserProvider } from "shared/context/UserProvider";
import Spinner from "shared/components/spinner/Spinner";

import { getToken } from "./util";

const withAuthentication = (ComposedComponent: () => JSX.Element) => {
  const Component: React.FC = (props) => {
    const { isLoading } = useCompany();

    if (getToken()) {
      if (isLoading)
        return (
          <div className="loader">
            <Spinner />
          </div>
        );
      return <ComposedComponent {...props} />;
    }
    return <Navigate to={"/signin"} />;
  };
  return (
    <UserProvider>
      <CompanyProvider>
        <Component />
      </CompanyProvider>
    </UserProvider>
  );
};

export default withAuthentication;
