import React from "react";
import { useMutation } from "@tanstack/react-query";
import { Modal, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";

import styles from "assets/css/settings.module.css";
import { useUpdateCard } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import useUpdateVATorGSTForm from "../hooks/useUpdateVATorGSTForm";
import { BillingDetailsModalProps, UpdateBillingData } from "../types";
import { updatePaymentMethod } from "../api";
import clsx from "clsx";

const UpdateVATorGST = ({ billingDetails, refetchBillingDetails }: BillingDetailsModalProps) => {
  const { currentCompany } = useCompany();

  const isUpdateVATorGSTModalOpen = useUpdateCard((state) => state.isUpdateVATorGSTModalOpen);

  const setIsUpdateVATorGSTModalOpen = useUpdateCard((state) => state.setIsUpdateVATorGSTModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading, mutate: updatePaymentMethodFn } = useMutation(
    (data: UpdateBillingData) => {
      if (!billingDetails?.id) {
        return Promise.reject(new Error("Billing Details are not valid."));
      }
      return updatePaymentMethod({ company: currentCompany?.id, data, id: billingDetails?.id });
    },
    {
      onSuccess: () => {
        refetchBillingDetails();
        toast("VAT/GST Updated Successfully.", { type: "success" });
        setIsUpdateVATorGSTModalOpen(false);
      },
    }
  );

  const { values, handleChange, errors, handleSubmit, resetForm } = useUpdateVATorGSTForm(
    () =>
      updatePaymentMethodFn({
        billing_details: {
          ...billingDetails?.billing_details,
          eu_vat: values.eu_vat,
        },
      }),
    billingDetails
  );

  const onCloseModal = () => {
    resetForm();
    setIsUpdateVATorGSTModalOpen(false);
  };

  return (
    <Modal
      root={documentBodyRef}
      dismissible={true}
      show={isUpdateVATorGSTModalOpen}
      onClose={onCloseModal}
      className={clsx(styles.upgrade_card_details, "modal_mobileview_center changepassword_modal")}
    >
      <Modal.Header className="text-lg dark:!border-thunders maxMd:p-4">
        Change VAT/GST Number
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="maxMd:p-4">
          <TextInput
            data-testid="eu-vat"
            value={values.eu_vat}
            name="eu_vat"
            onChange={handleChange}
            color={errors.eu_vat ? "failure" : "gray"}
          />
          <p className="text-xs mt-1 text-aurometalsaurus dark:text-greychateau">
            Please include your country code
          </p>
        </Modal.Body>
        <Modal.Footer className="dark:!border-thunders maxMd:p-4 maxMd:flex-wrap">
          <button
            className="btn_secondary ml-auto maxMd:w-full"
            onClick={onCloseModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn_primary md:flex item-center maxMd:w-full maxMd:mt-2 maxMd:!ml-0"
            type="submit"
          >
            <Spinner hidden={!isLoading} size="sm" className="mr-2 !fill-mirage stroke-mirage" />
            {isLoading ? "Adding" : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateVATorGST;
