import getFileSizeFromBtoMB from "shared/helpers/getFileSizeFromBtoMB";
import { MAXIMUM_FILE_SIZE_MB } from "shared/helpers/constant";

import { getDocumentFileType } from "../../../helpers";
import ImageFileViewer from "./ImageFileViewer";
import PdfFileViewer from "./PdfFileViewer";
import SpreadsheetFileViewer from "./SpreadsheetFileViewer";
import DefaultFileViewer from "./DefaultFileViewer";
import DocumentFileViewer from "./DocumentFileViewer";
import { FileViewerProps } from "./types";
import NoPreviewAvailable from "./NoPreviewAvailable";

/**
 * Component to render the document viewer that fits the file type
 */
export default function FileViewer({ document, ...props }: FileViewerProps) {
  const fileType = getDocumentFileType(document);

  switch (fileType) {
    case "pdf": {
      return <PdfFileViewer document={document} {...props} />;
    }
    case "image": {
      return <ImageFileViewer document={document} {...props} />;
    }
    case "spreadsheet": {
      return <SpreadsheetFileViewer document={document} {...props} />;
    }
    case "document": {
      if (document && getFileSizeFromBtoMB(document?.filesize) >= MAXIMUM_FILE_SIZE_MB) {
        return <NoPreviewAvailable document={document} />;
      }
      return <DocumentFileViewer document={document} {...props} />;
    }
    default: {
      return <DefaultFileViewer document={document} {...props} />;
    }
  }
}
