import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetAllAgreementsRequestParams, PaginatedAgreementList } from "../types";
import convertAgreementData from "../helpers/convertAgreementData";
import { show_all_objects } from "shared/helpersV2/constant";

export default async function getAgreements(
  requestParams: GetAllAgreementsRequestParams,
  permissions: { team?: TeamParameter }
): Promise<AxiosResponse<PaginatedAgreementList>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  let response = await api.get<PaginatedAgreementList>(`agreements/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{ team: teamParameter, company: permissions.team?.company },
      show_all_objects,
    },
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertAgreementData(data);
          }) || [],
      },
    };
  }
  return response;
}
