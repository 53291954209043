import React, { useState } from "react";

import clsx from "clsx";
import { Table, Tooltip } from "flowbite-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import truncate from "lodash/truncate";

import styles from "assets/css/agreement.module.css";
import { useTeam } from "shared/store/settings";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { convertDate, getFileNameFromS3URL } from "shared/helpers/util";
import Icon from "shared/components/icon/Icon";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import getFileName from "shared/helpers/getFileName";
import useScreen from "shared/hooks/useScreen";
import Spinner from "shared/components/spinner/Spinner";
import { deleteTaskFile } from "../overview/api/deleteTaskFile";
import { getTaskFiles } from "../overview/api/getTaskFiles";

const FileListing = ({
  displayDeleteIcon = true,
  newFiles = [],
  setNewFiles,
  title,
  deleteCallback,
  taskId,
}: {
  displayDeleteIcon?: boolean;
  newFiles?: File[];
  setNewFiles?: (files: File[]) => void;
  title?: string;
  deleteCallback?: (id: number) => void;
  taskId?: number;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<{
    id: number;
    type: "cloud" | "local";
  } | null>(null);

  const currentTeam = useTeam((state) => state.currentTeam);

  const displayType = useScreen();

  const { refetch: refecthTaskFiles, data: taskFiles } = useQuery(
    ["getTaskFiles", currentTeam?.id, taskId],
    () => getTaskFiles(taskId || 0, { team: currentTeam?.id }),
    { enabled: !!taskId && !!currentTeam?.id }
  );

  const noFiles = !taskFiles?.data.results?.length && !newFiles?.length;

  const { mutate: deleteFileFn, isLoading: isDeleteLoading } = useMutation(
    (data: { fileId: number; teamId: number }) =>
      deleteTaskFile(data.fileId, { team: data.teamId }),
    {
      onSuccess: (_, param) => {
        refecthTaskFiles();
        deleteCallback && deleteCallback(param.fileId);
      },
      onError: () => toast("Something went wrong", { type: "error" }),
    }
  );

  const deleteFile = async (fileId: number) => {
    deleteFileFn({ fileId: fileId, teamId: currentTeam?.id });
  };

  const downloadFile = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.setAttribute("download", getFileNameFromS3URL(fileUrl));
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {title ? (
        <div className={clsx(styles.agreementFields, "!mt-4 md:ml-1")}>
          <h3>{title}</h3>
        </div>
      ) : null}

      <div className={clsx(styles.agreementCommonTable, "relative")}>
        <Table hoverable={true} className="dark:border-thunders">
          <Table.Head>
            <Table.HeadCell className="!pl-4 md:!pl-8 table-heading">File Name</Table.HeadCell>
            <Table.HeadCell>Uploaded</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {newFiles?.map((file, index) => (
              <Table.Row
                className="bg-white  dark:border-gray-700 dark:bg-darkjunglegreenss"
                key={`${index}-${file.name}`}
              >
                <Table.Cell className="whitespace-nowrap text-aurometalsaurus font-medium dark:text-white">
                  <span
                    className="cursor-pointer"
                    onClick={() => downloadFile(URL.createObjectURL(file))}
                  >
                    <Tooltip className="text_break" content={file.name}>
                      {truncate(file.name)}
                    </Tooltip>
                  </span>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-aurometalsaurus font-medium dark:text-white">
                  -
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center justify-end gap-2">
                    <span
                      className="cursor-pointer"
                      onClick={() => downloadFile(URL.createObjectURL(file))}
                    >
                      <Icon type="download" fill={false} size="icon-smd" />
                    </span>
                    {displayDeleteIcon ? (
                      <span
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setToBeDeleted({ id: index, type: "local" });
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <Icon type="trash" fill={true} size="icon-smd" />
                      </span>
                    ) : null}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
            {taskFiles?.data.results?.map((file) => (
              <Table.Row
                className="bg-white  dark:border-gray-700 dark:bg-darkjunglegreenss"
                key={file.id}
              >
                <Table.Cell className="whitespace-nowrap text-aurometalsaurus font-medium dark:text-white">
                  <span className="cursor-pointer" onClick={() => downloadFile(file.attachment)}>
                    <Tooltip
                      className="text_break"
                      content={
                        displayType === MOBILE_DEVICE
                          ? getFileName(getFileNameFromS3URL(file.attachment))
                          : getFileNameFromS3URL(file.attachment)
                      }
                    >
                      {displayType === MOBILE_DEVICE
                        ? getFileName(getFileNameFromS3URL(file.attachment))
                        : truncate(getFileNameFromS3URL(file.attachment))}
                    </Tooltip>
                  </span>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-aurometalsaurus font-medium dark:text-white">
                  {convertDate(file?.updated_at)}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center justify-end gap-2">
                    <span className="cursor-pointer" onClick={() => downloadFile(file.attachment)}>
                      <Icon type="download" fill={false} size="icon-smd" />
                    </span>
                    {displayDeleteIcon ? (
                      <span
                        data-testid={`{delete-file-${file.id}}`}
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setToBeDeleted({
                            id: file.id,
                            type: "cloud",
                          });
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <Icon type="trash" fill={true} size="icon-smd" />
                      </span>
                    ) : null}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {isDeleteLoading ? (
          <div
            data-testid="loading"
            className={clsx(
              " bg-opacity-50 absolute top-0 left-0 w-full h-full bg-ghostwhite dark:bg-iridium flex justify-center items-center z-50"
            )}
          >
            <Spinner />
          </div>
        ) : null}
        {noFiles ? (
          <div className="text-center w-full border-b !py-[18px] dark:border-thunders maxMd:!px-4">
            <h3 className="text-sm text-aurometalsaurus dark:text-greychateau">
              No attachments found
            </h3>
          </div>
        ) : null}
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={async () => {
          if (toBeDeleted?.type === "local" && setNewFiles) {
            const newFilesCopy = newFiles?.filter((_, index) => index !== toBeDeleted?.id);
            setNewFiles(newFilesCopy);
          }

          if (toBeDeleted?.type === "cloud") {
            await deleteFile(toBeDeleted?.id);
          }

          setIsDeleteModalOpen(false);
          setToBeDeleted(null);
        }}
      />
    </>
  );
};

export default FileListing;
