import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { RegisterResponseType } from "../types";
import { convertRiskData } from "../helpers";

export const getRiskRegisterById = async (
  { riskId }: { riskId: number },
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<RegisterResponseType>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  let response = await api.get<RegisterResponseType>(`risks/${riskId}/`, {
    params: { ...{ team: teamParameter, company: permissions.team?.company } },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertRiskData(response.data),
    };
  }
  return response;
};
