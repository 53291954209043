import React, { useCallback, useEffect } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useLockedBody } from "usehooks-ts";
import { Spinner } from "flowbite-react";
import _, { truncate } from "lodash";

import BreadCrumb from "shared/components/bread-crumb/BreadCrumb";
import { getServerErrors } from "shared/helpers/util";
import { useCompany } from "shared/context/CompanyProvider";
import { useTeam } from "shared/store/settings";
import { useStatusEditFormStore } from "shared/store/tasks";
import GrandSpinner from "shared/components/spinner/Spinner";
import NotFound from "shared/components/not-found";

import { getCurrentStatus, updateStatus } from "../api";
import { StatusResultType } from "../types";

import EditForm from "./EditForm";

const EditStatus = () => {
  const { statusId } = useParams();

  const navigate = useNavigate();

  const { currentCompany } = useCompany();
  useEffect(() => {
    if (statusId && currentCompany && currentCompany?.trial_expired) {
      navigate(`/tasks/statuses/${statusId}/view`);
    }
  }, [currentCompany?.trial_expired, statusId]);

  const currentTeam = useTeam((state) => state.currentTeam);

  const statusEditForm = useStatusEditFormStore((state) => state.statusEditForm);

  useLockedBody(true);

  const { data: response, isLoading: isStatusLoading } = useQuery(
    ["getCurrentStatus", statusId, currentCompany.id, currentTeam.id],
    () =>
      getCurrentStatus(parseInt(`${statusId}`), {
        team: currentTeam.id,
        company: currentTeam.company,
        isAllTeam: currentTeam.is_all_team,
      }),
    {
      enabled: !!currentTeam.id,
    }
  );

  const {
    data: status,
    isLoading,
    mutate: updateStatusFn,
  } = useMutation((data: StatusResultType) => updateStatus(data), {
    onSuccess: () => {
      toast("Status updated successfully.", { type: "success" });
      navigate(`/tasks/statuses/${statusId}/view`);
    },
    onError: (error: AxiosError) => {
      {
        getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
      }
    },
  });

  const getBreadCrumb = useCallback(() => {
    return [
      { id: null, name: "Tasks", link: "/tasks/overview" },
      { id: null, name: "Statuses", link: "/tasks/statuses" },
      {
        id: response?.data.id || null,
        name: truncate(status?.data.name || response?.data.name),
      },
    ];
  }, [response, status]);

  const onSubmit = async () => {
    const errors = await Promise.all([statusEditForm].map((ele) => ele?.validateForm())).then(
      (res) => res
    );

    const isValid = _.every(errors, (err) => _.isEmpty(err));

    if (!isValid) {
      return;
    }

    if (statusEditForm) {
      updateStatusFn({
        ...statusEditForm.values,
        team: currentTeam.id,
      });
    }
  };

  const statusData = status?.data || response?.data;

  return (
    <div>
      <div className="p-4 md:px-5 md:py-2 bg-ghostwhite flex border-b border-brightgray dark:bg-darkjunglegreenss dark:border-thunders">
        <BreadCrumb breadCrumb={getBreadCrumb()} />
        <div className="ml-auto maxMd:hidden">
          <button
            className="btn_secondary mr-4"
            type="button"
            onClick={() => navigate(`/tasks/statuses/${statusId}/view`)}
          >
            Cancel
          </button>
          <button className="btn_primary" type="button" onClick={onSubmit}>
            Submit
            <Spinner hidden={!isLoading} size="sm" className="ml-1 !fill-mirage stroke-mirage" />
          </button>
        </div>
      </div>
      <div className="md:hidden pt-4 px-4 flex flex-col gap-4">
        <button className="btn_primary" type="button" onClick={onSubmit}>
          Submit
          <Spinner hidden={!isLoading} size="sm" className="ml-1 !fill-mirage stroke-mirage" />
        </button>
        <button
          className="btn_secondary"
          type="button"
          onClick={() => navigate(`/tasks/statuses/${statusId}/view`)}
        >
          Cancel
        </button>
      </div>
      <div className="flex">
        <div className="w-full">
          {statusData ? (
            <EditForm status={statusData} />
          ) : !isStatusLoading ? (
            <NotFound />
          ) : (
            <GrandSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStatus;
