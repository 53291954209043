import React from "react";

import { AuthHeader } from "shared/components/header";

import CreateCompany from "./CreateCompany";
import InviteTeammates from "./InviteTeammates";
import { useLocation } from "react-router-dom";

const OnBoarding = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const OnBoardingScreen = () => {
    const company = query.get("company");

    if (company) {
      return <InviteTeammates company={Number.parseInt(company)} />;
    }

    return <CreateCompany />;
  };

  return (
    <div>
      <AuthHeader />
      {OnBoardingScreen()}
    </div>
  );
};

export default OnBoarding;
