import moment from "moment";

import { getUsername } from "shared/helpers/util";

import LexicalEditor from "../lexical-editor";
import MetadataEditor from "../lexical-editor/metadata-editor/MetadataEditor";
import { CommentType } from "../metadata/types";
import Attachment from "../metadata/comments/Attachment";
import Avatar from "../avatar";

const Comment = ({ comment }: { comment: CommentType }) => {
  return (
    <li key={comment.id}>
      <div className="flex" data-testid="comment-section">
        <div className="mr-3">
          <Avatar
            name={getUsername(comment?.created_by)}
            avatarFor="user"
            avatarType="rounded"
            size="sm"
            url={comment?.created_by?.profile_picture}
          />
        </div>
        <div>
          <h1 data-testid="comment-user-name">{getUsername(comment?.created_by)}</h1>
        </div>
        <span>
          {moment(comment.created_at).format("D MMMM")} at{" "}
          {moment(comment.created_at).format("hh:mm A")}
        </span>
      </div>
      {comment?.text ? (
        <div className="metadata-view-container">
          <LexicalEditor editorState={comment?.text || ""} editable={false} className="pl-10">
            {() => <MetadataEditor />}
          </LexicalEditor>
        </div>
      ) : null}
      {comment?.attachments?.map((attachment) => (
        <Attachment attachment={attachment} key={attachment.url} />
      ))}
    </li>
  );
};

export default Comment;
