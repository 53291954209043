import React, { MouseEventHandler } from "react";

import clsx from "clsx";

import gridStyles from "assets/css/gridview.module.css";

import { colorClassFromName } from "../../helpers/util";

function TagChip({
  tag,
  onClick: handleClick,
  className,
  style,
}: {
  tag: string;
  onClick?: MouseEventHandler;
  className?: string;
  style?: React.CSSProperties;
}) {
  const { backgroundColor, color } = colorClassFromName(tag || "");

  const tagChipClass = className || "";

  return (
    <span
      data-testid="tag-chip"
      className={clsx(gridStyles.gridTagChip, `${backgroundColor}`, `${color}`, tagChipClass)}
      onClick={handleClick}
      style={style}
    >
      {tag}
    </span>
  );
}

export default TagChip;
