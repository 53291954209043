import { Dispatch, SetStateAction } from "react";

import clsx from "clsx";
import { Button, Modal } from "flowbite-react";

import styles from "assets/css/settings.module.css";

interface DeleteModalProp {
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  isDeleteModalOpen: boolean;
}

const DeleteModal = ({ setIsDeleteModalOpen, isDeleteModalOpen }: DeleteModalProp) => {
  return (
    <Modal
      show={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header className={clsx(styles.account_header, "dark:!border-thunders")}>
        Delete Group
      </Modal.Header>
      <Modal.Body className="maxMd:p-4">
        <div>
          <p className="text-base mac13Inch:text-sm leading-relaxed text-gray-500 dark:text-greychateau">
            Are you sure you want to delete this group? It cannot be reverted.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end maxMd:p-4 mac13Inch:py-4 dark:!border-thunders flex-wrap-reverse">
        <button
          className="btn_secondary font-inter-medium maxMd:w-full maxMd:mt-[18px]"
          onClick={() => setIsDeleteModalOpen(false)}
        >
          No, Cancel
        </button>
        <Button className="!bg-lavared md:!ml-4 hover:!bg-red-800 !rounded-full font-inter-medium focus:!ring-0 focus:!ring-transparent font-xs-mac13 maxMd:w-full maxMd:!ml-0">
          Yes, I’m sure
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
