import { MouseEventHandler, ReactNode, useCallback } from "react";

import styles from "assets/css/task.module.css";

type AddTaskComponentProps = {
  label: string;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const AddTaskComponent = ({ label, icon, onClick }: AddTaskComponentProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <button
      data-testid="add-task-component"
      type="button"
      className={styles.addTaskButton}
      onClick={handleClick}
    >
      {icon}
      {label}
    </button>
  );
};

export default AddTaskComponent;
