import { DOCUMENT_EDITOR_OPEN_URL } from "../constants";

/**
 * Convert a docx file to a SFDT file to be used by the document editor.
 * Raises error if warn data passed
 */
export default function convertToSfdt(file: File): Promise<string> {
  const formData: FormData = new FormData();
  formData.append("files", file);
  return fetch(DOCUMENT_EDITOR_OPEN_URL, {
    method: "POST",
    body: formData,
    mode: "cors",
  }).then((response) => response.text());
}
