import React from "react";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { MetaDataToggleButtonProps } from "./types";
import Icon from "../icon/Icon";

const MetaDataToggleButton = ({
  isMetaDataOpen,
  onClick,
}: MetaDataToggleButtonProps): JSX.Element => {
  return (
    <button
      data-testid="meta-data-toggle"
      onClick={onClick}
      className={clsx({ "arrow-toggle": !isMetaDataOpen }, "metadata-arrow")}
    >
      <Tooltip
        data-testid="meta-data-toggle-tooltip"
        content={!isMetaDataOpen ? "Expand" : "Collapse"}
        placement="bottom"
        className="custom_tooltip"
      >
        <Icon type="doubleleft" fill={false} size="icon-md" className="viewdetail_arrow" />
      </Tooltip>
    </button>
  );
};

export default MetaDataToggleButton;
