import React from "react";

import { useSearchParams } from "react-router-dom";

import RiskMatrix from "./RiskMatrix";
import { CompanyPresetResponse } from "../../register/types";

const currentCompanyPreset = {
  id: 1479,
  preset_info: {
    id: 2,
    probability_levels: [
      {
        level: 0,
        name: "Låg",
        description:
          "Exponeringen mot och/eller sannolikheten för bristande hantering av risken bedöms vara låg eller obefintlig och Bolaget rekommenderas att hantera och kontrollera risken i den utsträckning det är lämpligt",
        value: 0.25,
      },
      {
        level: 1,
        name: "Medelhög",
        description:
          "Exponeringen mot och/eller sannolikheten för bristande hantering av risken bedöms vara medelhög och Bolaget rekommenderas att prioritera hantering och kontroll av risken.",
        value: 0.5,
      },
      {
        level: 2,
        name: "Hög",
        description:
          "Risken kan resultera i betydande myndighetssanktioner, skadeståndskrav, ekonomiska förluster eller förlorat anseende/kundflykt. ",
        value: 0.029,
      },
    ],
    impact_levels: [
      {
        level: 0,
        name: "Låg",
        description:
          "Risken bedöms inte resultera i myndighetssanktioner, skadeståndskrav, ekonomiska förluster eller förlorat anseende/kundflykt. Endast påpekanden från tillsynsmyndigheten.",
        value: 0.00015,
      },
      {
        level: 1,
        name: "Medelhög",
        description:
          "Risken kan i viss utsträckning resultera i myndighetssanktioner, skadeståndskrav, ekonomiska förluster eller förlorat anseende/kundflykt.",
        value: 0.0033,
      },
      {
        level: 2,
        name: "Hög",
        description:
          "Risken kan resultera i betydande myndighetssanktioner, skadeståndskrav, ekonomiska förluster eller förlorat anseende/kundflykt. ",
        value: 0.029,
      },
    ],
    created_at: "2023-09-21T14:24:15.255464+02:00",
    updated_at: "2023-10-16T16:34:41.086247+02:00",
    name: "Default B",
    impact_scale_type: "busd",
    impact_description: "",
    probability_scale_type: "simple",
    probability_description: "",
    company: null,
  },
  created_at: "2023-10-19T20:23:13.542000+02:00",
  updated_at: "2023-11-06T11:15:53.993472+01:00",
  company: 281,
  preset: 2,
} as unknown as CompanyPresetResponse;

// ex. /risks/overview/matrix/public?formattedData=[{"xPos":1,"yPos":2,"reference":10},{"xPos":1,"yPos":2,"reference":20}]

const RiskMatrixUnauthorizedPage = () => {
  const [searchParams] = useSearchParams({ formattedData: [] });
  const formattedData = searchParams.get("formattedData");
  const language = searchParams.get("lang");

  const config = searchParams.get("config") || undefined;

  const getConfigData = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const configData:
    | {
        x: {
          title: string;
          values: string[];
        };
        y: {
          title: string;
          values: string[];
        };
      }
    | undefined = getConfigData(config || "");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedData = JSON.parse(formattedData || "[]").map((_: any) => {
    _.name ||= _.reference;
    return _;
  });

  const isRiskAreas = parsedData.some((_: { reference: string | number }) => isNaN(+_.reference));

  return (
    <RiskMatrix
      config={configData}
      currentCompanyPreset={currentCompanyPreset}
      formattedData={parsedData}
      tableData={null}
      unauthorized={true}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      language={language as any}
      isRiskAreaVisible={isRiskAreas}
    />
  );
};

export default RiskMatrixUnauthorizedPage;
