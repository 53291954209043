import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { type PaginatedResponse, type Jurisdiction } from "shared/typesV2";

export default async function getJurisdictions(params: {
  search?: string;
  page?: number;
}): Promise<AxiosResponse<PaginatedResponse<Jurisdiction>>> {
  return api.get<PaginatedResponse<Jurisdiction>>(`/horizon-scanning/jurisdictions`, {
    params,
  });
}
