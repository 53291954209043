import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { InstructionsProps } from "../types";

export default function getPolicyInstructions(
  id: string | number,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<InstructionsProps[]>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<InstructionsProps[]>(`policies/${id}/instructions/`, {
    params: { ...{ team: teamParameter, company: permissions.team?.company } },
  });
}
