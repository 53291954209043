import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedCountryList } from "../types";
import convertPaginatedData from "shared/helpers/convertPaginatedData";

export default async function getCountries(requestParams?: {
  ordering?: string;
  page?: number;
}): Promise<AxiosResponse<PaginatedCountryList>> {
  let response = await api.get<PaginatedCountryList>("core/countries/", {
    params: { ...hasNameOrdering(requestParams) },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertPaginatedData(response.data),
    };
  }
  return response;
}
