import { AxiosResponse } from "axios";

import { TeamId } from "shared/types";
import api from "shared/api/v1";

export default function deleteNoteById(
  noteId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<void>> {
  return api.delete(`notes/${noteId}/`, { params: permissions });
}
