import React from "react";

import clsx from "clsx";

import {
  BUSD,
  BUSD_SCALE_TYPE,
  PERCENTS_SCALE_TYPE,
  SIMPLE,
  SIMPLE_SCALE_TYPE,
} from "shared/helpers/constant";

import { PresetScaleType } from "modules/risks/register/types";

import styles from "assets/css/settings.module.css";

export default function ScaleTitle({
  isCustomPreset,
  value,
  type,
  handleChange,
}: {
  isCustomPreset: boolean;
  value: string;
  type: PresetScaleType;
  handleChange: (scale: PresetScaleType) => void;
}) {
  function getScaleType(type?: PresetScaleType) {
    switch (type) {
      case SIMPLE:
        return SIMPLE_SCALE_TYPE;

      case BUSD:
        return BUSD_SCALE_TYPE;

      default:
        return PERCENTS_SCALE_TYPE;
    }
  }

  return (
    <div className={"radioButtonContainer flex gap-2 items-center"}>
      <input
        disabled={!isCustomPreset}
        id={`${type}`}
        type="radio"
        checked={value === type ? true : false}
        name={type}
        className={clsx("radioButtons", {
          "cursor-not-allowed": !isCustomPreset,
          "cursor-pointer": isCustomPreset,
        })}
        onChange={() => handleChange(type)}
      />
      <label
        htmlFor={`${type}`}
        className={clsx(styles.levelValue, {
          "cursor-not-allowed": !isCustomPreset,
          "cursor-pointer": isCustomPreset,
        })}
      >
        {getScaleType(type)}
      </label>
    </div>
  );
}
