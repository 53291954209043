import React, { useEffect, useState } from "react";
import Avatar from "shared/components/avatar";
import CustomDropdown from "shared/components/custom-dropdown";
import { team_options } from "shared/helpers/constant";
import { SharedObject, SharedObjectRole } from "../../../types";
import { deleteSharedObject, updateSharedObjectRole } from "../api";
import { useCompany } from "../../../context/CompanyProvider";

import { toast } from "react-toastify";
import { AxiosError } from "axios";
import plural from "../../../helpers/plural";
import clsx from "clsx";

const TeamDetails = ({
  sharedObject,
  triggerUpdate,
  isEditAccess,
}: {
  sharedObject: SharedObject;
  triggerUpdate: () => Promise<void>;
  isEditAccess: boolean;
}) => {
  const [isTeamDetailsViewOpen, setIsTeamDetailsViewOpen] = useState<boolean>(false);
  const { currentCompany } = useCompany();
  const [selectedOptionBuffer, setSelectedOptionBuffer] = useState<string>(sharedObject.role);
  useEffect(() => {
    setSelectedOptionBuffer(sharedObject.role);
  }, [sharedObject.role]);

  const handleTeamDetailsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsTeamDetailsViewOpen(!isTeamDetailsViewOpen);
  };

  return (
    <>
      <div className="team_container py-1 px-2 rounded-lg">
        <div className="team_description_container">
          <div className="avatar_container_team_details">
            <div className="sub_team_view w-full">
              <div className="!w-full flex justify-between items-center cursor-default">
                <div className="flex items-center gap-2" onClick={(e) => handleTeamDetailsClick(e)}>
                  <div className="flex items-center gap-2">
                    <Avatar
                      name={sharedObject.shared_with_group.name || "Group Avatar"}
                      avatarFor="team"
                      avatarType="squared"
                      size="sm"
                      url={sharedObject.shared_with_group.group_picture}
                    />
                    <div className="team_title_name_container">
                      <p className="text-xs font-medium dark:text-white">
                        {sharedObject.shared_with_group.name}
                      </p>
                      <span className="text-sm font-normal text-santagrey dark:text-greychateau">
                        {sharedObject.shared_with_group.users.length}{" "}
                        {plural(sharedObject.shared_with_group.users.length, "member", "members")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="access_select_container">
                  <div
                    className={clsx("custom_select cursor-pointer", {
                      "cursor-not-allowed opacity-50": !isEditAccess,
                    })}
                  >
                    <CustomDropdown
                      options={team_options}
                      isGeneralAccess={false}
                      isEditAccess={isEditAccess}
                      value={selectedOptionBuffer}
                      onChange={async (value: string) => {
                        if (value === "REMOVE_ACCESS") {
                          try {
                            await deleteSharedObject(currentCompany.id, sharedObject.id);
                            toast.success("Access removed");
                            await triggerUpdate();
                          } catch (ex) {
                            toast.error(
                              (ex as AxiosError<{ error: string }>)?.response?.data?.error ||
                                "Something went wrong"
                            );
                          }
                        } else {
                          try {
                            setSelectedOptionBuffer(value);
                            await updateSharedObjectRole(
                              currentCompany.id,
                              sharedObject.id,
                              value as SharedObjectRole
                            );
                            toast.success("Access updated");
                            await triggerUpdate();
                          } catch (ex) {
                            setSelectedOptionBuffer(sharedObject.role);
                            toast.error(
                              (ex as AxiosError<{ error: string }>)?.response?.data?.error ||
                                "Something went wrong"
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetails;
