export const agreementsCustomTooltip = {
  details: {
    name: "For example Consultancy Agreement for Audit Services or Purchase Agreement for merchandise",
    status: "Whether the agreement is active or has expired/been terminated",
    agreement_type: "Agreement type",
    start_date: "The start date as specified in the agreement",
    party_to_agreement: "Hoist entity that is party to the agreement",
    notice_period_supplier: "End date minus notice period = Last date for agreement renewal",
    supplier_type: "Name of the external or internal supplier",
    has_end_date: "Select if the agreement has a specific end date or is on open ended",
    end_date: "The end date of the agreement",
    renewal_date:
      "The date on which action needs to be taken either in order to renew or to prevent automatic renewal",
    receivers_provided_service: "Hoist entity/entities that will receive the services or goods",
    notice_period_internal_entity: "End date minus notice period = Last date for agreement renewal",
    archiving_reference:
      "If the agreement has an internal archiving reference number, please add it here (only relevant for Poland)",
    internal_reference:
      "Email address(es) of others within Hoist who are involved in the product/services that the agreement is covering",
    latest_review: "Date of the latest internal review",
    next_review:
      "The date for the next internal review of the agreement, if such date has been set",
    governing_law: "This information can usually be found at the end of the agreement",
    estimated_budget: "The estimated annual budget cost",
    total_value: "The total cost of the agreement",
    validations: "Validations of the agreement",
    enterprise_validation: "This feature is reserved for the enterprise plan",
    description:
      "For an outsourcing agreement please describe the outsourced function, including the data that is outsourced",
  },
  relatedAgreement: {
    has_parent_agreement: "Is the agreement a child to a parent agreement",
    parent: "Name of parent agreement",
    other_relationship: "Other relationships of agreement",
    supplier: "Name of the external or internal supplier",
  },
  personalData: {
    locations_personal_data_transferred: "Country or region if data is transferred outside EU/EEA",
    personal_data_processed:
      "Whether or not personal data is processed in this agreement with the supplier",
    personal_data_transferred:
      "Whether or not personal data has been transferred to/stored with a third party outside EU/EEA",
    role: "The internal entity's role as defined in GDPR",
    party_to_agreement: "The internal entity that is the contractual party",
    data_protection_documentation_included: "If No, upload the documentation needed",
  },
  serviceData: {
    cloud_service: "Is the service provided as a cloud service?",
    nature_of_data: "Nature of the data held",
    countries_service_performed: "Countries where the service is performed",
    locations_data_stored: "Countries or regions where the data is stored",
    deployment_model: "Deployment model of the service provided",
    subcontractors: "Add subcontractors when applicable",
  },
  npap: {
    product_approval_required:
      "Is a New Product Approval Process (NPAP) required for the service provided",
    product_approval_completed: "Has an NPAP been performed and completed",
    product_approval_summary: "Summary of the NPAP",
  },
  cmp: {
    change_management_required: "Is a change management process required for the service provided",
    change_management_completed: "Has an change management process been performed and completed",
    change_management_summary: "Summary of the change management process",
  },
  aml: {
    aml_related_activity: "Click Yes if this is related to AML activity, otherwise No",
  },
  riskAssessment: {
    risk_assessment_level: "Select risk level",
    latest_risk_assessment: "Date of the latest risk assessment",
    next_risk_assessment: "Date of the next risk assessment",
    risk_assessment_summary: "Summary of the latest risk assessment",
  },
  assessmentAgreement: {
    outsourcing_agreement: "Is the service provided considered an outsourcing arrangement",
    category: "Agreement category",
    critical_or_important_assessment_complete: "Result of the critical or important assessment",
    latest_critical_or_important_assessment: "Date of the latest critical or important assessment",
    assessment_summary: "Summary of the critical or important assessment",
    authority_notified: "Has the competent authority been notified?",
    authority_date_notified: "Date of competent authority notification",
    authority_summary: "Description of the notification made to the competent authority",
    critical_or_important: "Is the outsourced function critical or important?",
    risk_assessment_completed: "Is the risk assessment complete?",
    final_approval:
      "Specify the individual or decision-making body (e.g. the management body) that approved the outsourcing agreement",
    latest_audit: "Date of the latest audit",
    next_audit: "Date of the next scheduled audit",
    audit_notes:
      "Notes from the latest audit e.g. who performed the audit and a brief from the audit",
    exit_strategy:
      "Outcome of the assessment of the service provider's substitutability (as easy, difficult or impossible), the possibility of reintegrating a critical or important function into the institution or the payment institution or the impact of discontinuing the critical or important function",
    alternative_service_providers: "Identification of alternative service providers",
    time_critical_operation:
      "Does the outsourced function supports business operations that are time-critical",
  },
  reslovability: {
    resolution_agreement:
      "Whether the service is provided from inside or outside of the resolution group",
    relevant_entity: "Specify name of entity for which the service is relevant",
    resolution_resilient_agreement: "One of the 3 options must be chosen from the dropdown menu",
    critical_business_function:
      "Specify the critical business function for which the service is relevant",
    critical_business_function_description: "Critical business function details",
    core_business_lines: "Specify the Core business lines for which the service is relevant",
    degree_of_criticality: "Degree of criticality",
    jurisdictions: "Jurisdiction(s) where services are provided",
    existence_of_automatic_renewal_clauses: "Existence of automatic renewal clauses, if any",
    predictable_pricing_structure:
      "Pricing structure is predictable, transparent and set on arms length basis",
    conditions_of_payment: "Conditions of payment",
    quantitative_and_qualitative_performance_targets:
      "Settings and qualitative performance risk-targets for the provider",
    effects_of_breaches: "Effects of breaches, termination or payment defaults",
    triggers_early_termination: "Trigger(s) for early termination",
    parties_allowed_to_terminate: "Party(ies) allowed to terminate",
    time_for_substitutability: "Estimated time for substitutability",
    post_termination_assist: "The duration of post-termination assistance, if any",
    resolvability_alternative_service_providers: "Alternative Service Providers",
  },
};
export const agreementsDefaultTooltip = {
  details: {
    name: "Name of the agreement",
    status: "Status of the agreement",
    agreement_type: "Agreement type",
    start_date: "The start date of the agreement",
    party_to_agreement: "The internal entity that is the contractual party",
    notice_period_supplier: "The notice period for the supplier",
    supplier_type: "Name of the external or internal supplier",
    has_end_date: "Select if the agreement has a specific end date or is on open ended",
    end_date: "The end date of the agreement",
    renewal_date: "The next contract renewal date",
    receivers_provided_service: "Internal entities receiving the service or goods",
    notice_period_internal_entity: "The notice period for the internal contractual party",
    archiving_reference: "Internal archiving reference, if any",
    internal_reference:
      "The internal reference party for the agreement i.e. function, person, email address, telephone number",
    latest_review: "Date of the latest internal review",
    next_review: "Date of the next internal review",
    governing_law: "The governing law of the agreement",
    estimated_budget: "The estimated annual budget cost",
    total_value: "The total cost of the agreement",
    validations: "Validations of the agreement",
    enterprise_validation: "This feature is reserved for the enterprise plan",
    description:
      "For an outsourcing agreement please describe the outsourced function, including the data that is outsourced",
  },
  relatedAgreement: {
    has_parent_agreement: "Is the agreement a child to a parent agreement",
    parent: "Name of parent agreement",
    other_relationship: "Other relationships of agreement",
    supplier: "Filter by supplier to find agreement",
  },
  personalData: {
    locations_personal_data_transferred: "Country or region if data is transferred outside EU/EEA",
    personal_data_processed:
      "Whether or not personal data is processed in this agreement with the supplier",
    personal_data_transferred:
      "Whether or not personal data has been transferred to/stored with a third party outside EU/EEA",
    role: "The internal entity’s role as defined in GDPR",
    party_to_agreement: "The internal entity that is the contractual party",
    data_protection_documentation_included: "If No, upload the documentation needed",
  },
  serviceData: {
    cloud_service: "Is the service provided as a cloud service?",
    nature_of_data: "Nature of the data held",
    countries_service_performed: "Countries where the service is performed",
    locations_data_stored: "Countries or regions where the data is stored",
    deployment_model: "Deployment model of the service provided",
    subcontractors: "Add subcontractors when applicable",
  },
  npap: {
    product_approval_required: "Is a NPAP required for the service provided",
    product_approval_completed: "Has an new product approval process been performed and completed",
    product_approval_summary: "Summary of the NPAP",
  },
  cmp: {
    change_management_required: "Is a change management process required for the service provided",
    change_management_completed: "Has an change management process been performed and completed",
    change_management_summary: "Summary of the change management process",
  },
  aml: {
    aml_related_activity: "Click Yes if this is related to AML activity, otherwise No",
  },
  riskAssessment: {
    risk_assessment_level: "Select risk level",
    latest_risk_assessment: "Date of the latest risk assessment",
    next_risk_assessment: "Date of the next risk assessment",
    risk_assessment_summary: "Summary of the latest risk assessment",
  },
  assessmentAgreement: {
    outsourcing_agreement: "Is the service provided considered an outsourcing arrangement",
    category: "Agreement category",
    critical_or_important_assessment_complete: "Result of the critical or important assessment",
    latest_critical_or_important_assessment: "Date of the latest critical or important assessment",
    assessment_summary: "Summary of the critical or important assessment",
    authority_notified: "Has the competent authority been notified?",
    authority_date_notified: "Date of competent authority notification",
    authority_summary: "Description of the notification made to the competent authority",
    critical_or_important: "Is the outsourced function critical or important?",
    risk_assessment_completed: "Is the risk assessment complete?",
    final_approval:
      "Specify the individual or decision-making body (e.g. the management body) that approved the outsourcing agreement",
    latest_audit: "Date of the latest audit",
    next_audit: "Date of the next scheduled audit",
    audit_notes:
      "Notes from the latest audit e.g. who performed the audit and a brief from the audit",
    exit_strategy:
      "Outcome of the assessment of the service provider’s substitutability (as easy, difficult or impossible), the possibility of reintegrating a critical or important function into the institution or the payment institution or the impact of discontinuing the critical or important function",
    alternative_service_providers: "Identification of alternative service providers",
    time_critical_operation:
      "Does the outsourced function supports business operations that are time-critical",
  },
  reslovability: {
    resolution_agreement:
      "Whether the service is provided from inside or outside of the resolution group",
    relevant_entity: "Specify name of entity for which the service is relevant",
    resolution_resilient_agreement: "One of the 3 options must be chosen from the dropdown menu",
    critical_business_function:
      "Specify the critical business function for which the service is relevant",
    critical_business_function_description: "Critical business function details",
    core_business_lines: "Specify the Core business lines for which the service is relevant",
    degree_of_criticality: "Degree of criticality",
    jurisdictions: "Jurisdiction(s) where services are provided",
    existence_of_automatic_renewal_clauses: "Existence of automatic renewal clauses, if any",
    predictable_pricing_structure:
      "Pricing structure is predictable, transparent and set on arms length basis",
    conditions_of_payment: "Conditions of payment",
    quantitative_and_qualitative_performance_targets:
      "Settings and qualitative performance risk-targets for the provider",
    effects_of_breaches: "Effects of breaches, termination or payment defaults",
    triggers_early_termination: "Trigger(s) for early termination",
    parties_allowed_to_terminate: "Party(ies) allowed to terminate",
    time_for_substitutability: "Estimated time for substitutability",
    post_termination_assist: "The duration of post-termination assistance, if any",
    resolvability_alternative_service_providers: "Alternative Service Providers",
  },
};
