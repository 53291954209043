import { CompanyId, TeamId, TeamParameter } from "../types";

export default function teamToQueryParams(team: TeamParameter): {
  team: "all" | TeamId;
  company: CompanyId;
} {
  return {
    team: team.isAllTeam ? "all" : team.team,
    company: team.company,
  };
}
