import { useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";

import { useTeam } from "../../../store/settings";
import { createFilter, getFilters, deleteFilter } from "../api";
import { FilterProps } from "../types";

const useFavorite = () => {
  const currentTeam = useTeam((state) => state.currentTeam);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const { data: response, refetch } = useQuery(
    ["filters", currentTeam?.id],
    () => getFilters({ team: currentTeam?.id }),
    {
      enabled: !!currentTeam?.id,
    }
  );

  const { mutate: addFavorite } = useMutation(
    (data: FilterProps) => createFilter(data, { team: currentTeam.id }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: deleteFavorite } = useMutation(
    (id: number) => deleteFilter(id, { team: currentTeam.id }),
    {
      onSuccess: () => {
        refetch();
        setIsDeleteModalOpen(false);
      },
    }
  );

  return {
    addFavorite,
    response,
    refetch,
    deleteFavorite,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  };
};

export default useFavorite;
