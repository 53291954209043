import { Alert } from "flowbite-react";

import { getServerErrors } from "shared/helpers/util";

import { ServerErrosProps } from "./types";

const ServerErrors = ({ skipError, error, ...rest }: ServerErrosProps) => {
  return (
    <div {...rest}>
      <Alert color="failure" rounded={false} className="bg-lavenderBlush !text-[#E02424] p-3">
        <ul data-testid="server-errors-list" className="space-y-1 max-w-md list-inside">
          {skipError ? (
            <>
              <li className="text-xs">Something went wrong, Try again later</li>
            </>
          ) : (
            getServerErrors(error).map((err: string, index: number) => (
              <li className="text-xs" key={index}>
                {err}
              </li>
            ))
          )}
        </ul>
      </Alert>
    </div>
  );
};

export default ServerErrors;
