import React, { useEffect, useMemo, useRef, useState } from "react";

import { Tooltip } from "flowbite-react";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { useOnClickOutside } from "usehooks-ts";
import { close as closeIntercom } from "intercom";
import { useLocation } from "react-router-dom";

import {
  resetFiltersForModules,
  resetFunctionForCommonTeam,
  resetQueryParamsForModules,
  resetTeamToLocalStorage,
} from "shared/helpers/switchTeamPopup";
import Avatar from "shared/components/avatar";
import { useQueryParams, useTableFilter } from "shared/store/table-column-selection";
import { useTeam } from "shared/store/settings";
import { COMMON } from "shared/helpers/constant";
import Icon from "shared/components/icon/Icon";
import { useHome } from "shared/store/home";
import { useSidebar } from "shared/store/sidebar";
import getAgreementActiveSidebarItems from "shared/components/sidebar/helpers/getAgreementActiveSidebarItems";
import { useCompany } from "shared/context/CompanyProvider";
import { Permissions, buildAbilityFor } from "shared/helpers/ability";
import checkEnvVar from "shared/helpers/checkEnvVar";

import { TeamResponse } from "modules/settings/teams/types";

import styles from "assets/css/header.module.css";

import { SwitchTeamModal } from "../index";

function SwitchTeamPopup() {
  const navigate = useNavigate();
  const location = useLocation();

  const [setCurrentTeam, currentTeam, teamList] = useTeam((state) => [
    state.setCurrentTeam,
    state.currentTeam,
    state.teams,
  ]);

  const [showSwitchTeam, setShowSwitchTeam] = useState<boolean>(false);

  const profile = useHome((store) => store.profile);

  const { currentCompany, setAbility, isAdmin } = useCompany();

  const [setFirstFilterForModules, setSelectedFilters] = useTableFilter((state) => [
    state.setFirstFilterForModules,
    state.setSelectedFilters,
  ]);

  const [isDropdownOpen, setIsDropDownOpen] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState<TeamResponse | null>(null);

  const [isSwitchTeamModalVisible, setIsSwitchTeamModalVisible] = useState<boolean>(false);

  const [resetSelectedQueryParams] = useQueryParams((state) => [state.resetSelectedQueryParams]);

  const { setSidebarItems: setSideBarItems } = useSidebar();

  const onTeamClick = (team: TeamResponse) => {
    closeIntercom();

    if (team?.name?.toLowerCase() === COMMON && currentTeam?.name?.toLowerCase() !== COMMON) {
      resetFunctionForCommonTeam(setSelectedTeam, team, setIsSwitchTeamModalVisible);
    } else {
      resetQueryParamsForModules(setCurrentTeam, resetSelectedQueryParams, team);
      // update permission on team change
      setAbility(buildAbilityFor(team?.custom_permissions as Permissions, isAdmin));

      resetFiltersForModules(setSelectedFilters, () => setFirstFilterForModules);
      resetTeamToLocalStorage(`${team?.id}`, navigate);
    }

    setSideBarItems(getAgreementActiveSidebarItems(currentCompany));
  };
  const switchTeamRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(switchTeamRef, () => {
    if (isDropdownOpen) {
      setIsDropDownOpen(false);
    }
  });

  const filteredTeamList = useMemo(
    () =>
      teamList.filter((team) => {
        if (team.members && team.members?.length === 0) {
          return true;
        } else {
          return team.members?.includes(profile.id);
        }
      }),
    [teamList, profile]
  );

  useEffect(() => {
    setShowSwitchTeam(!location.pathname.startsWith("/agreements/"));
  }, [location]);

  return (
    <>
      {(!checkEnvVar("REACT_APP_ENABLE_OBJECT_MODAL") || showSwitchTeam) &&
      filteredTeamList.length > 0 ? (
        <div data-testid="team-list-div" ref={switchTeamRef} className={styles.team}>
          <Tooltip
            data-testid="team_popup"
            placement="left"
            content="Switch teams"
            className={clsx(styles.teamTooltip, "custom_tooltip")}
          >
            <div
              className={clsx("max-h-[10.5rem] w-8 md:w-56 relative flex cursor-pointer")}
              onClick={() => {
                setIsDropDownOpen(!isDropdownOpen);
              }}
            >
              <Avatar
                name={currentTeam?.name}
                url={currentTeam?.team_picture}
                avatarFor="company"
                avatarType="squared"
                size="regular"
              />
              <div className="flex items-center maxMd:hidden">
                <h3 className="title-sm mx-3.5 hover:text-mirage dark:text-greychateau">
                  {currentTeam?.name}
                </h3>
                <Icon type="updown" fill={true} size="icon-md" className=" header-icon_dark" />
              </div>
            </div>
          </Tooltip>

          <div className={clsx(styles.teamDropdown, { "py-2": isDropdownOpen })}>
            {isDropdownOpen
              ? filteredTeamList?.map((team) => (
                  <div
                    data-testid={"teamlist-" + team.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDropDownOpen(false);
                      onTeamClick(team);
                    }}
                  >
                    <div
                      className={clsx(styles.teamIcon, "cursor-pointer", {
                        [styles.active_dropdown]: currentTeam?.id === team.id,
                      })}
                    >
                      <Avatar
                        name={team?.name[0]?.toUpperCase()}
                        url={team?.team_picture}
                        avatarFor="team"
                        avatarType="squared"
                        size="regular"
                      />
                      <h3 className="title-sm dark:text-greychateau">{team?.name}</h3>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
      {selectedTeam ? (
        <SwitchTeamModal
          isSwitchTeamModalVisible={isSwitchTeamModalVisible}
          setIsSwitchTeamModalVisible={setIsSwitchTeamModalVisible}
          selectedTeam={selectedTeam}
        />
      ) : null}
    </>
  );
}

export default SwitchTeamPopup;
