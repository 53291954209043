import React from "react";

import clsx from "clsx";

import headerStyles from "assets/css/common-header.module.css";

export type ModuleTitleProps = {
  label: string;
  className?: string;
  children?: React.ReactNode;
  titleClassName?: string;
};

const ModuleTitle = ({ label, className, children, titleClassName }: ModuleTitleProps) => {
  return (
    <div
      className={clsx(
        "md:flex",
        "justify-between",
        "px-4",
        "md:px-5",
        "pt-[13px]",
        "mac13Inch:pt-4",
        "sub-header",
        "bg-white",
        className
      )}
    >
      <h1 className={clsx(headerStyles.subscriptionTitle, titleClassName)}>{label}</h1>
      {children}
    </div>
  );
};

export default ModuleTitle;
