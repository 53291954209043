import React, { useEffect, useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TextInput, Label, Spinner, Checkbox } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMediaQuery } from "usehooks-ts";
import { usePostHog } from "posthog-js/react";

import { AuthHeader } from "shared/components/header";
import ServerErrors from "shared/components/server-errors";
import { setRefreshToken, setToken } from "shared/helpers/util";
import Icon from "shared/components/icon/Icon";

import GoogleAuthButton from "modules/auth/google-auth-button/GoogleAuthButton";

import MobileNavigationModal from "./components/MobileNavigationModal";
import useSigninForm from "./hooks/useSigninForm";
import { GoogleSigninRequestParams, SigninRequestParams } from "./types";
import { googlesignin, signin } from "./api";

const SignIn = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [params] = useSearchParams();
  const code: string | null = params.get("code");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const matches = useMediaQuery("(max-width: 767px)");

  const {
    isLoading,
    mutate: signInFn,
    isError,
    error,
  } = useMutation((data: SigninRequestParams) => signin(data), {
    onSuccess: (res) => {
      setToken(res.data.access);
      setRefreshToken(res.data.refresh);
      posthog.capture("userLoggedIn");
      setIsModalOpen(!isModalOpen);
      !matches && navigate("/", { replace: true });
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { isLoading: isSigning, mutate: googleSigninFn } = useMutation(
    (data: GoogleSigninRequestParams) => googlesignin(data),
    {
      onSuccess: (res) => {
        setToken(res.data.access);
        setRefreshToken(res.data.refresh);
        posthog.capture("userLoggedIn");
        setIsModalOpen(!isModalOpen);

        !matches && navigate("/", { replace: true });
      },
    }
  );

  useEffect(() => {
    if (!code) return;
    googleSigninFn({ code });
  }, [code, googleSigninFn]);

  const { handleSubmit, values, errors, handleChange } = useSigninForm(() => signInFn(values));

  return (
    <>
      <AuthHeader />
      <section className="auth-container">
        <div className="card card-auth">
          <h2 className="mb-4 md:mb-10 mac13Inch:mb-8 text-mirage md:text-3xl text-3xl mac13Inch:text-[24px] font-sans-medium dark:text-white">
            Sign In
          </h2>
          <GoogleAuthButton authType="signin" isSigning={isSigning} />
          {/* <MicrosoftLoginButton /> */}
          <Link
            className="w-full text-mirage flex justify-center bg-white hover:bg-gray-100 border border-gray-200 font-inter-medium rounded-lg px-5 py-2.5 text-center items-center dark:bg-darkjunglegreenss dark:border-iridium dark:text-white dark:hover:bg-balticsea mb-4 mac13Inch:mb-6 text-base mac13Inch:text-sm"
            to="/sso-signin"
          >
            <Icon type="key" fill={true} size="icon-smd" className="key_icon mr-4" />
            Sign in with SSO
          </Link>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 mac13Inch:mb-3">
              <Label value="Your Email*" className="font-inter-medium mb-2 block !text-mirage" />
              <TextInput
                placeholder="name@example.com"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                className={errors.email ? "is-error" : "gray"}
              />
            </div>
            <div className="mb-4 mac13Inch:mb-3">
              <Label value="Password*" className="font-inter-medium mb-2 block !text-mirage" />
              <TextInput
                placeholder="••••••••••"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                className={errors.password ? "is-error" : "gray"}
              />
            </div>
            <div className="flex items-center gap-2 mb-4 mac13Inch:mb-3 text-xs">
              <Checkbox id="agree" className="rounded custom-checkbox" />
              <Label htmlFor="agree" className="text-davygrey dark:!text-greychateau !text-xs">
                Remember me{" "}
              </Label>
              <Link
                to="/forgot-password"
                className="hover:underline ml-auto font-medium text-aurometalsaurus dark:text-greychateau "
              >
                Lost password?
              </Link>
            </div>
            {isError ? (
              <ServerErrors
                className="mb-4"
                skipError={error?.response?.status === 500}
                error={error}
              />
            ) : null}
            <button className="btn_primary w-full text-base mac13Inch:text-xs md:py-3.5">
              <Spinner
                size="md"
                light={true}
                hidden={!isLoading}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Login
            </button>
            {/*  restricting access to the app trial only */}
            <div className="mt-4 mac13Inch:mt-3">
              <Label
                value="Not registered?"
                className="!text-aurometalsaurus mac13Inch:text-xs dark:!text-greychateau"
              />
              <Link
                className="ml-1 text-sm mac13Inch:text-xs font-inter-medium text-mirage dark:text-white underline hover:no-underline"
                to="/signup"
              >
                Create account
              </Link>
            </div>
          </form>
        </div>
      </section>
      {matches ? <MobileNavigationModal isModalOpen={isModalOpen} /> : null}
    </>
  );
};

export default SignIn;
