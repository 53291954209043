import { getAllRiskHeatMapColors } from "./getAllRiskHeatMapColors";

export const getRiskHeatMapColor = (
  probability: number,
  impact: number,
  probabilityNLevels: number,
  impactNLevels: number,
  type: "background" | "text" = "background"
) => {
  const selectedColors: string[] = getAllRiskHeatMapColors(probabilityNLevels, impactNLevels, type);

  const nColors = selectedColors.length;

  const weights = nColors === 4 ? [0.24, 0.25] : [0.1, 0.1];
  const weightSum = weights.reduce((acc, weight) => acc + weight, 0);

  for (let i = 2; i < nColors; i++) {
    weights.push((1 - weightSum) / (nColors - 2));
  }

  const targetPercentage =
    ((probability + 1) * (impact + 1)) / (probabilityNLevels * impactNLevels);

  const { idx } = weights.reduce(
    (acc, weight, idx) => {
      if (acc.targetPercentage > 0) {
        return {
          targetPercentage: acc.targetPercentage - weight,
          idx,
        };
      }

      return acc;
    },
    { targetPercentage, idx: 0 }
  );

  return selectedColors[idx];
};
