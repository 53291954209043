import React from "react";

import clsx from "clsx";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardSubTitleProps = {
  title: string;
  className?: string;
};

const GridCardSubTitle: React.FC<GridCardSubTitleProps> = ({
  title,
  className,
}: GridCardSubTitleProps) => {
  return (
    <div
      data-testid="subtitle"
      className={clsx(gridStyles.gridSubTitle, className, "title_ellipsis")}
    >
      {title}
    </div>
  );
};

export default GridCardSubTitle;
