import { AxiosResponse } from "axios";

import api from "shared/api/v1";

export const deleteDataByEndpoint = (
  url: string,
  id: number | string,
  props: Record<string, unknown>
): Promise<AxiosResponse<unknown>> => {
  return api.delete(url.slice(-1) === "/" ? `${url}${id}/` : `${url}/${id}`, {
    params: props,
  });
};
