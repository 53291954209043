import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { FilterProps } from "../types";

export default function updateFilterById(
  filterId: number,
  data: FilterProps,
  team: number
): Promise<AxiosResponse<FilterProps>> {
  const params = new URLSearchParams();
  params.append("team", `${team}`);

  return api.patch<FilterProps>(`core/filters/${filterId}/`, data, { params });
}
