import { AxiosResponse } from "axios";

import api from "shared/api/v1";

export const deleteStatusById = ({
  statusId,
  teamId,
}: {
  statusId: number;
  teamId: number;
}): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();
  params.append("team", `${teamId}`);

  return api.delete<string>(`tasks/status/${statusId}/`, { params });
};
