import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { GetSubContractorProps, SubContractorResultProps } from "../types";
import { convertAgreementSubContractorData } from "../helpers";
import { show_all_objects } from "shared/helpersV2/constant";

export default async function editSubcontractor(
  data: GetSubContractorProps,
  permissions: { team: TeamId }
): Promise<AxiosResponse<SubContractorResultProps>> {
  let response = await api.patch<SubContractorResultProps>(
    `agreements/subcontractors/${data.id}/`,
    { ...data },
    { params: { ...permissions, show_all_objects } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertAgreementSubContractorData(response.data),
    };
  }

  return response;
}
