import { useFormik } from "formik";

import { SsoSigninRequestParams } from "../types";
import { ssoSigninSchema } from "../validation";

const useSsoSigninForm = (action: CallableFunction) => {
  return useFormik<SsoSigninRequestParams>({
    initialValues: {
      email: "",
    },
    validateOnChange: false,
    validationSchema: ssoSigninSchema,
    onSubmit: () => action(),
  });
};

export default useSsoSigninForm;
