import { AxiosResponse } from "axios";

import { PresetInfo, PresetLevelType } from "modules/risks/register/types";

import api from "shared/api/v1";
import { CompanyId } from "shared/types";

const normalizeLevels = (levels: PresetLevelType[]) => {
  return [...levels]
    .sort((a, b) => {
      if (a.value != b.value) {
        return a.value - b.value;
      }
      return a.level - b.level;
    })
    .map((_, i) => ({
      ..._,
      level: i,
    }));
};
export const updateCustomPreset = (
  data: PresetInfo,
  companyId: CompanyId
): Promise<AxiosResponse<PresetInfo>> => {
  if (data.probability_levels?.length) {
    data.probability_levels = normalizeLevels(data.probability_levels);
  }

  if (data.impact_levels?.length) {
    data.impact_levels = normalizeLevels(data.impact_levels);
  }

  return api.patch<PresetInfo>(
    `risks/presets/${data.id}/`,
    {
      ...data,
    },
    { params: { company: companyId } }
  );
};
