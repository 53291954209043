import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId, GetTaskResultType, TeamId } from "shared/types";

import { convertTaskData } from "../helpers";
import { TaskResultType } from "../types";

export const updateTask = async (
  data: Partial<TaskResultType>,
  permission: { team: TeamId; company: CompanyId }
): Promise<AxiosResponse<GetTaskResultType>> => {
  let response = await api.patch<GetTaskResultType>(
    `tasks/${data.id}/`,
    { ...data },
    { params: { ...permission } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertTaskData(response.data),
    };
  }

  return response;
};
