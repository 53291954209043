import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { TaskAttachmentRequest, TaskAttachmentResponse } from "../types";

export const addTaskFiles = async (
  data: TaskAttachmentRequest,
  permissions: { team: TeamId }
): Promise<AxiosResponse<TaskAttachmentResponse>> => {
  const formData = new FormData();

  formData.append("attachment", data.file);
  formData.append("name", data.file.name);
  formData.append("team", `${permissions.team}`);
  formData.append("task", `${data.taskId}`);

  return api.post("/tasks/attachments/", formData, { params: { ...permissions } });
};
