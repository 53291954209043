import { number, object, string } from "yup";

export const addTaskSchema = object({
  name: string().max(500).required(),
  company: number().required(),
  team: number().required(),
  status: number().integer().min(1).required(),
});

export const addStatusSchema = object({
  name: string().required(),
  team: number().required(),
});
