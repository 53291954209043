import React, { useMemo, useCallback } from "react";
import { FormikProps } from "formik";
import { Spinner } from "flowbite-react";
import clsx from "clsx";

import { TeamResponse } from "modules/settings/teams/types";
import { UserResponse } from "modules/home/types";
import ServerErrors from "shared/components/server-errors";

import { Value as TeamUserValue } from "../team-user-select/types";
import { PermissionFormValues } from "./types";
import TeamUserSelect from "../team-user-select";
import LevelSelect from "../level-select";
import isServerErrorStatus from "../../../../helpers/isServerErrorStatus";

export type BlankPermissionFormProps = {
  form: FormikProps<PermissionFormValues>;
  teams: TeamResponse[];
  users: UserResponse[];
};

export default function BlankPermissionForm({ form, teams, users }: BlankPermissionFormProps) {
  const teamUserSelectValue = useMemo(() => {
    if (form.values.team && !form.values.user) {
      return {
        team: form.values.team,
      };
    }
    if (form.values.user && !form.values.team) {
      return {
        user: form.values.user,
      };
    }

    return undefined;
  }, [form.values.team, form.values.user]);

  const teamUserSelectErrorText = useMemo(() => {
    return form.errors.team || form.errors.user;
  }, [form.errors.team, form.errors.user]);

  const handleTeamUserSelectChange = useCallback((value: TeamUserValue) => {
    form.setFieldValue("team", value.team);
    form.setFieldValue("user", value.user);
  }, []);

  const handleLevelChange = useCallback((value: number) => {
    form.setFieldValue("level", value);
  }, []);

  return (
    <form onSubmit={form.handleSubmit} name="permission-form" data-testid="permission-form">
      <div className="flex flex-col space-y-4">
        <div className="flex maxMd:flex-col gap-2">
          <div className="flex flex-col w-3/5 space-y-2 permission_modal">
            <TeamUserSelect
              name="team-user-select"
              teams={teams}
              users={users}
              value={teamUserSelectValue}
              onChange={handleTeamUserSelectChange}
              hasError={form.submitCount > 0 && !!teamUserSelectErrorText}
              errorText={teamUserSelectErrorText}
            />
          </div>
          <div className="flex flex-col flex-grow md:w-1/3 space-y-2 permission_modal">
            <LevelSelect
              name="level"
              value={form.values.level}
              onChange={handleLevelChange}
              hasError={form.submitCount > 0 && !!form.errors.level}
              errorText={form.errors.level}
            />
          </div>
        </div>
        {isServerErrorStatus(form.status) ? (
          <ServerErrors error={form.status.serverError} data-testid="server-errors" />
        ) : null}
        <div className="flex flex-row">
          <button
            type="submit"
            className={clsx(
              "btn",
              "btn_primary",
              "flex",
              "h-10",
              "items-center",
              "gap-x-1",
              "justify-center",
              "w-full"
            )}
            data-testid="submit-permission-button"
            disabled={form.isSubmitting}
          >
            <Spinner className={clsx("w-5", "h-5")} hidden={!form.isSubmitting} />
            Add Permission
          </button>
        </div>
      </div>
    </form>
  );
}
