import api from "shared/api/v1";
import { AxiosResponse } from "axios";
import { UpdateAgreementProps, ValidationErrorType } from "../types";
import { show_all_objects } from "shared/helpersV2/constant";

export default function getAggrementValidationsById(
  agreement: UpdateAgreementProps,
  permissions: { team: number }
): Promise<AxiosResponse<ValidationErrorType[]>> {
  const { object_owner, ...rest } = agreement;

  return api.post<ValidationErrorType[]>(
    `agreements/${agreement.id}/validate/`,
    {
      ...rest,
      object_owner: object_owner?.id,
      team: permissions.team,
    },
    { params: { ...permissions, show_all_objects } }
  );
}
