import React, { Suspense, useEffect } from "react";
import { createPortal } from "react-dom";

import { shallow } from "zustand/shallow";
import { useQuery } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import { boot as bootIntercom, load as loadIntercom, update as updateIntercom } from "intercom";
import isEmpty from "lodash/isEmpty";

import { Header } from "shared/components/header";
import { useHome } from "shared/store/home";
import { AbilityContext } from "shared/components/can/Can";
import { useCompany } from "shared/context/CompanyProvider";
import Spinner from "shared/components/spinner/Spinner";
import TrialExpiredModal from "shared/components/trial-expired-modal";
import useSetPropertiesToPosthog from "shared/hooks/useSetPropertiesToPosthog";
import { useUser } from "shared/context/UserProvider";
import { useTeam } from "shared/store/settings";

import { getProfile } from "modules//settings/my-account/api";
import StripeWrapper from "modules/settings/StripeWrapper";
import Settings from "modules/settings/Settings";

import ModuleRoutes from "./ModuleRoutes";
import ObjectPermissionModal from "shared/components/object-permission";
import { useSharedObjectModal } from "shared/store/sharedobject";

const Home = () => {
  useSetPropertiesToPosthog();
  const { ability, isAbilityLoaded } = useCompany();

  const { user } = useUser();

  const [currentTeam, setCurrentTeam, teams] = useTeam((state) => [
    state.currentTeam,
    state.setCurrentTeam,
    state.teams,
  ]);

  const [isSharedObjectModalOpen] = useSharedObjectModal((state) => [
    state.isSharedObjectModalOpen,
    state.setIsSharedObjectModalOpen,
  ]);

  const [profile, setProfile, setIsSpotlightModalopen] = useHome(
    (state) => [state.profile, state.setProfile, state.setIsSpotlightModalopen],
    shallow
  );

  useQuery(["getProfile"], getProfile, {
    onSuccess: (res) => setProfile(res.data),
  });

  const listenKey = (event: KeyboardEvent) => {
    const isCtrlSpace = event.code === "Space" && event.ctrlKey;
    const isEscape = event.key === "Escape";

    if (isCtrlSpace) {
      setIsSpotlightModalopen(true);
    }
    if (isEscape) {
      setIsSpotlightModalopen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e: KeyboardEvent) => listenKey(e));

    return () => {
      window.removeEventListener("keydown", (e: KeyboardEvent) => listenKey(e));
    };
  });

  useEffect(() => {
    loadIntercom();
    bootIntercom({
      alignment: "right",
      horizontal_padding: 80,
      hide_default_launcher: false,
      user_id: profile?.id,
      name: profile?.name,
      email: profile?.email,
    });

    const history = createBrowserHistory();
    return history.listen(() => {
      updateIntercom();
    });
  }, [profile]);

  useEffect(() => {
    const newTeam = teams.filter((team) => team?.members?.includes(user?.id));
    if (
      !isEmpty(profile) &&
      !currentTeam?.members?.includes(profile?.id) &&
      !currentTeam?.is_common_team &&
      !currentTeam?.is_general_team &&
      !currentTeam?.is_all_team
    ) {
      setCurrentTeam(newTeam[0]);
    }
  }, [currentTeam, teams, user, profile]);

  if (!isAbilityLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <main className="main-wrap">
        <AbilityContext.Provider value={ability}>
          <Header />
          <Suspense
            fallback={
              <div className="loader">
                <Spinner />
              </div>
            }
          >
            <Routes>
              <Route path="/*" element={<ModuleRoutes />} />
            </Routes>
            <StripeWrapper>
              <Settings />
            </StripeWrapper>
          </Suspense>
        </AbilityContext.Provider>
      </main>

      <TrialExpiredModal />

      {isSharedObjectModalOpen ? createPortal(<ObjectPermissionModal />, document.body) : null}
    </>
  );
};

export default Home;
