import { AxiosResponse } from "axios";

import { TeamId } from "shared/types";
import api from "shared/api/v1";

import { RiskFileResponse } from "../types";

export default function uploadRiskFile(
  data: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<RiskFileResponse>> {
  return api.post<RiskFileResponse>(`risks/risks-files/`, data, {
    params: permissions,
  });
}
