import { object, string } from "yup";

export const companySchema = object({
  name: string().required(),
});

export const inviteSchema = object({
  email1: string().required().email(),
  email2: string().email(),
  email3: string().email(),
});
