import React from "react";
import Icon from "shared/components/icon/Icon";

import { useAuthProvider } from "shared/store/settings";

const AddProviders = ({ children }: { children: JSX.Element }) => {
  const setAddProviderModal = useAuthProvider((state) => state.setAddProviderModal);

  return (
    <>
      <div className="py-3 px-4 md:px-11 md:flex justify-between border-b border-brightgray dark:border-thunders">
        <div className="authentication_content">
          <h3 className="dark:text-white">Authentication Providers</h3>
          <p className="mt-4 dark:text-greychateau maxMd:mb-4">
            Manage identity providers, assign users to organizations, and troubleshoot login issues.
          </p>
        </div>
        <div>
          <button
            className="btn_primary flex items-center"
            onClick={() => setAddProviderModal(true)}
          >
            <Icon type="plus" fill={true} size="icon-md" className="notification_icon" />
            <span>Add provider</span>
          </button>
        </div>
      </div>
      {children}
    </>
  );
};

export default AddProviders;
