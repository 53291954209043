import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { RegisterFormValueType, RegisterResponseType } from "../types";
import { convertRiskData } from "../helpers";

export const updateRegister = async (
  { register }: { register: RegisterFormValueType },
  permissions: TeamParameter
): Promise<AxiosResponse<RegisterResponseType>> => {
  const teamParameter = permissions?.isAllTeam ? "all" : permissions?.team;

  let response = await api.patch<RegisterResponseType>(
    `risks/${register.id}/`,
    { ...register },
    {
      params: { ...{ team: teamParameter, company: permissions?.company } },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertRiskData(response.data),
    };
  }

  return response;
};
