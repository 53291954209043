import { useParams } from "react-router-dom";

const useGetEntityId = () => {
  const {
    taskId,
    agreementId,
    categoryId,
    typeId,
    supplierId,
    entityId,
    articleId,
    policyId,
    noteId,
    mitigationId,
    riskId,
    id: documentId,
    incidentId,
  } = useParams();

  const id =
    taskId ||
    agreementId ||
    categoryId ||
    typeId ||
    supplierId ||
    entityId ||
    articleId ||
    policyId ||
    noteId ||
    documentId ||
    mitigationId ||
    riskId ||
    incidentId ||
    "";
  return id;
};

export default useGetEntityId;
