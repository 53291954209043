import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { SignupRequestParams, SignupResponseParams } from "../types";

export default function signup(
  data: SignupRequestParams
): Promise<AxiosResponse<SignupResponseParams>> {
  return api.post<SignupResponseParams>("auth/signup/", data);
}
