import React, { memo, useEffect, useRef } from "react";

import clsx from "clsx";

import useDynamicSVGImport from "./hooks/useDynamicSVGImport";

type IconProps = {
  type: string;
  disabled?: boolean;
  fill?: boolean;
  badgeColor?: string;
  size?:
    | "icon-2xxs"
    | "icon-1_5xxs"
    | "icon-xxs"
    | "icon-sxs"
    | "icon-xs"
    | "icon-sm"
    | "icon-smd"
    | "icon-md"
    | "icon-lg"
    | "icon-xl"
    | "icon-2xl";
  className?: string;
};

function Icon(iconProps: IconProps) {
  const { type, fill, disabled, className, badgeColor } = iconProps;

  const size = iconProps.size || "icon-xs";

  const SVGType = fill ? "fill" : "stroke";

  const iconClasses = clsx([size, "si", SVGType, className, { "disabled-cion": disabled }]);

  const { SvgIcon, loaded } = useDynamicSVGImport(type);

  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (ref.current && badgeColor) {
      const targettedElements = ref.current.getElementsByTagName("path");
      const targettedElementCircle = ref.current.getElementsByTagName("circle")[0];

      if (targettedElements.length) {
        Array.from(targettedElements).forEach((targettedElement) => {
          targettedElement.style.fill = badgeColor;
        });
      }
      if (targettedElementCircle) {
        targettedElementCircle.style.fill = badgeColor;
      }
    }
  }, [ref.current, badgeColor, SvgIcon]);

  if (!loaded) {
    return <div className={iconClasses} />;
  }

  if (SvgIcon) {
    return <SvgIcon className={iconClasses} ref={ref} />;
  }

  return null;
}

export default memo(Icon);
