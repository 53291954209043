import React from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";

import PermissionListing from "shared/components/permission";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { setId } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";
import { getTeamResponseData } from "shared/helpers/getTeamResponseData";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import { CompanyUserResponse } from "modules/home/types";

import { updateTeam } from "../api";
import { TeamResponse } from "../types";

const Permission = () => {
  const [setSelectedTeam, selectedTeam] = useTeam((state) => [
    state.setSelectedTeam,
    state.selectedTeam,
  ]);
  const { currentCompany } = useCompany();

  const {
    isLoading,
    mutate: updateTeamPermissionFn,
    isError,
    error,
  } = useMutation((data: TeamResponse) => updateTeam(currentCompany.id, data), {
    onSuccess: (res) => {
      setSelectedTeam(res.data);
      setId("teamID", res?.data?.id?.toString());

      toast("Permission changed successfully", { type: "success" });
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const updateTeamPermission = (data: TeamResponse | CompanyUserResponse) => {
    const teamData = getTeamResponseData(data);
    if (teamData && typeof teamData !== null) {
      updateTeamPermissionFn(teamData);
    }
  };

  return (
    <>
      {isLoading ? <CentralSpinner /> : null}
      <PermissionListing
        data={cloneDeep(selectedTeam)}
        updatePermissionFn={updateTeamPermission}
        isLoading={isLoading}
      />

      {isError ? (
        <div className="px-8">
          <ServerErrors className="mt-4" error={error} />
        </div>
      ) : null}
    </>
  );
};

export default Permission;
