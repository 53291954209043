import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { MetaTable, RevertVersionResponse } from "../types";
import { CompanyId, TeamId } from "shared/types";
import { getUrlFromType } from "shared/helpers/getUrlFromType";
import { AGREEMENT_TABLE } from "shared/helpers/constant";
import { show_all_objects } from "shared/helpersV2/constant";

export default function revertVersion({
  type,
  typeId,
  permissions,
  versionId,
}: {
  type: MetaTable;
  typeId: string;
  permissions: { teamId: TeamId; companyId: CompanyId; isAllTeam: boolean };
  versionId: number;
}): Promise<AxiosResponse<RevertVersionResponse>> {
  const { companyId, isAllTeam, teamId } = permissions;

  const typeUrl = getUrlFromType(type);
  if (typeUrl === "default") {
    return Promise.reject(new Error("Module is not selected"));
  }

  const teamPermissionPayload = { team: teamId, company: companyId, isAllTeam: isAllTeam };

  const teamParameter = teamPermissionPayload?.isAllTeam ? "all" : teamPermissionPayload?.team;

  return api.patch<RevertVersionResponse>(
    `${typeUrl}/${typeId}/versions/${versionId}/revert/`,
    {},
    {
      params: {
        ...{ team: teamParameter, company: teamPermissionPayload.company },
        show_all_objects: type === AGREEMENT_TABLE ? show_all_objects : undefined,
      },
    }
  );
}
