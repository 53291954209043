import { useFormik } from "formik";

import { MemberProps } from "../types";
import { addMemberSchema } from "../validation";

const useMemberForm = (action: CallableFunction) => {
  return useFormik<MemberProps>({
    initialValues: {
      email: "",
      is_admin: "Member",
    },
    validateOnChange: false,
    validationSchema: addMemberSchema,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useMemberForm;
