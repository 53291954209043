/**
 * Loads meta pixel if the environment variable is set
 */
export default function loadMetaPixel() {
  if (process.env.REACT_APP_META_PIXEL_ID) {
    const metaPixelId = process.env.REACT_APP_META_PIXEL_ID;
    fbq("init", metaPixelId);
  } else {
    // eslint-disable-next-line
    window.fbq = function (...args: any) {};
  }
}
