import React from "react";

import { Modal } from "flowbite-react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { GetTaskResultType } from "shared/types";
import { CompanyProps } from "shared/context/types/company";
import TagChip from "shared/components/gridview-chip/TagChip";
import LexicalEditor from "shared/components/lexical-editor";
import TaskCommentEditor from "shared/components/lexical-editor/task-comment-editor";
import { useTeam } from "shared/store/settings";
import { getModuleURL } from "shared/helpers/getModuleURL";

import styles from "assets/css/task.module.css";

import TaskLabel from "../shared/TaskLabel";
import TaskDisplayValue from "../shared/TaskDisplayValue";
import UserAvatar from "../shared/UserAvatar";
import TaskDisplayComments from "../shared/TaskDisplayComments";
import TaskActivityLog from "../shared/TaskActivityLog";
import { isRiskRelatedTaskType, RelatedObjectType, RelatedObjectTypeName } from "../types";
import getRelatedObjectName from "../helpers/getRelatedObjectName";
import { useCompany } from "../../../../shared/context/CompanyProvider";
import { useWorkspaceModal } from "../../../../shared/store/trial";
import TaskFileListing from "modules/tasks/shared/TaskFileListing";
import { TASK_STATUS_TAG_CLASSES } from "shared/components/constants";

export type ViewTaskModalProps = {
  isViewModalOpen: boolean;
  task: GetTaskResultType;
  company: CompanyProps;
  relatedObject?: RelatedObjectType;
  relatedObjectType?: RelatedObjectTypeName;
  onClose: () => void;
  onEdit: (task: GetTaskResultType) => void;
};

function ViewTaskModal({
  isViewModalOpen,
  task,
  company,
  relatedObject,
  relatedObjectType,
  onClose: close,
  onEdit: edit,
}: ViewTaskModalProps) {
  const { currentTeam } = useTeam();

  const navigate = useNavigate();
  const { currentCompany } = useCompany();
  const { setIsModalOpen } = useWorkspaceModal();

  return (
    <Modal
      show={isViewModalOpen}
      size="2xl"
      popup={true}
      onClose={close}
      dismissible={true}
      className={clsx(styles.taskModalStyle, "modal_height")}
    >
      <Modal.Header
        className={clsx(styles.taskModalHeader, "dark:!border-thunders mac13Inch:items-center")}
      >
        <div className="text-lg mac13Inch:text-base font-semibold dark:text-white">View Task</div>
      </Modal.Header>
      <Modal.Body className={styles.taskModalBody}>
        <div className="grid grid-rows-1 gap-4 border-b mb-2 dark:border-thunders">
          <div>
            <TaskLabel title="Task Name*" />
            <TaskDisplayValue className="!ml-0" title={task.name} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <TaskLabel title="Status" />
              <TaskDisplayValue className="!ml-0" title={task.status?.name} />
            </div>
            <div>
              <TaskLabel title="Priority" />
              <TaskDisplayValue className="!ml-0" title={task.priority} />
            </div>
          </div>
          <div>
            <TaskLabel title="Tags" />
            <div className="flex flex-wrap gap-2">
              {task.tags_object ? (
                task.tags_object.map((tag) => (
                  <TagChip key={tag.id} tag={tag.name} className={TASK_STATUS_TAG_CLASSES} />
                ))
              ) : (
                <TaskDisplayValue className="!ml-0" title="N/A" />
              )}
            </div>
          </div>
          <div>
            <TaskLabel title="Assignee" />
            <div className={styles.userAvtarContainer} data-testid="task-assignees">
              {task.assignees.length >= 0
                ? company.organization_users.map((companyUser) => {
                    return task.assignees.includes(companyUser.user.id) && companyUser?.user ? (
                      <UserAvatar key={companyUser.id} user={companyUser.user} />
                    ) : null;
                  })
                : null}
              {task.assignees.length === 0 ? (
                <TaskDisplayValue className="!ml-0" title="N/A" />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <TaskLabel title="Due Date" />
              <TaskDisplayValue className="!ml-0" title={task.due_date || "N/A"} />
            </div>
            <div>
              <TaskLabel title="Owner" />
              {task.owner ? (
                <UserAvatar user={task.owner_object} />
              ) : (
                <TaskDisplayValue className="!ml-0" title="N/A" />
              )}
            </div>
          </div>
          <div>
            <TaskLabel title="Object" />
            <TaskDisplayValue
              onClick={() => {
                if (relatedObject) {
                  close();
                  if (isRiskRelatedTaskType(relatedObject)) {
                    navigate(
                      `${getModuleURL(
                        relatedObject.id,
                        relatedObjectType,
                        relatedObject.company,
                        relatedObject.register
                      )}`
                    );
                  } else {
                    navigate(`${getModuleURL(relatedObject?.id, relatedObjectType)}`);
                  }
                }
              }}
              className="!ml-0 view_task_underline"
              title={getRelatedObjectName(relatedObject)}
            />
          </div>

          {/* task attachments */}
          <div>
            <TaskLabel title="Attachments" />
            <TaskFileListing taskId={task?.id} displayDeleteIcon={false} />
          </div>

          <div>
            <TaskLabel title="Description" />
            {isViewModalOpen ? (
              <LexicalEditor editorState={task.description} type="task" editable={false}>
                {() => (
                  <TaskCommentEditor
                    placeholder="N/A"
                    className={clsx("Placeholder__root", styles.taskCommentEditorPlaceholder)}
                  />
                )}
              </LexicalEditor>
            ) : null}
          </div>
        </div>
        {/* comments listing and comment form */}
        {!currentTeam.is_all_team && isViewModalOpen ? (
          <TaskDisplayComments currentTask={task} />
        ) : null}

        {/* activity log */}
        {!currentTeam.is_all_team && isViewModalOpen ? (
          <TaskActivityLog currentTask={task} />
        ) : null}
      </Modal.Body>
      <Modal.Footer className="border-t justify-end dark:!border-thunders mac13Inch:py-4 maxMd:p-4">
        {!currentTeam.is_all_team && (
          <button
            className="btn_primary maxMd:w-full"
            type="button"
            aria-label="Edit Button"
            onClick={(e) => {
              e.preventDefault();
              if (currentCompany?.trial_expired) {
                setIsModalOpen(true);
              } else {
                edit(task);
              }
            }}
          >
            Edit Task
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ViewTaskModal;
