import clsx from "clsx";
import { Dropdown } from "flowbite-react";
import get from "lodash/get";

import { PERMISSION_LABEL, PERMISSION_LEVELS } from "../../constants";
import { Permission, PermissionLevel } from "../../types";

export type PermissionChangerProps = {
  permission: Permission;
  onDelete?: (permission: Permission) => void;
  onUpdate?: (permission: Permission, level: PermissionLevel) => void;
};

export default function PermissionChanger({
  permission,
  onDelete,
  onUpdate,
}: PermissionChangerProps) {
  return (
    <div
      className={clsx("flex flex-row justify-between dark:text-white")}
      data-testid="permission-changer"
    >
      <Dropdown
        className="dropdown_darkmode"
        label={get(PERMISSION_LABEL, permission.level)}
        inline
      >
        {PERMISSION_LEVELS.map((level) => (
          <Dropdown.Item
            key={level}
            onClick={() => onUpdate?.(permission, level)}
            data-testid={`permission-level-${level}`}
          >
            {get(PERMISSION_LABEL, level)}
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={() => onDelete?.(permission)} className={clsx("text-red-500")}>
          Delete
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
}
