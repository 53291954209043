import { ServerErrorStatus } from "shared/types";

/**
 * Type guard to check is formik status is `ServerErrorStatus`
 */
function isServerErrorStatus(value: unknown): value is ServerErrorStatus {
  if (value === undefined) {
    return false;
  }

  return !!(value as ServerErrorStatus).serverError;
}

export default isServerErrorStatus;
