import React, { useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { useOnClickOutside } from "usehooks-ts";

import Icon from "shared/components/icon/Icon";
import { INITIAL_ROLE_LEVEL, ROLES_LIST } from "shared/helpers/constant";

import styles from "assets/css/settings.module.css";

import { UserRole } from "modules/home/types";

export default function RoleDropdown({
  loggedInUserRole,
  isDisable,
  role,
  handleChangeRole,
  isEdit,
}: {
  loggedInUserRole?: {
    level: number;
    name: string;
    value: UserRole;
  };
  isDisable: boolean;
  role: string;
  handleChangeRole?: ({ role }: { role: UserRole }) => void;
  isEdit: boolean;
}) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useOnClickOutside(dropdownRef, () => {
    if (isModalOpen && isEdit) {
      setIsModalOpen(false);
    }
  });

  const currentRole = useMemo(
    () => ROLES_LIST.find((roleDetail) => roleDetail.value === role),
    [role]
  );

  return (
    <div
      className={clsx("flex justify-between items-center relative", {
        "pointer-events-none opacity-50 ": isDisable,
      })}
      ref={dropdownRef}
    >
      <span className="border-lightSilver border font-inter-medium px-3 py-1 rounded-md">
        {currentRole?.name || role}
      </span>
      {isEdit &&
      (loggedInUserRole?.level || INITIAL_ROLE_LEVEL) >
        (currentRole?.level || INITIAL_ROLE_LEVEL) ? (
        <span
          className="cursor-pointer"
          onClick={() => {
            setIsModalOpen(!isModalOpen);
          }}
        >
          <Icon type="chevron-down" className={styles.rolesDownIcon} />
        </span>
      ) : null}

      {isEdit && isModalOpen ? (
        <ul className={clsx(styles.role_dropdown, "!top-[2rem]")}>
          {ROLES_LIST.filter(
            (roleDetail) => roleDetail.level < (loggedInUserRole?.level || INITIAL_ROLE_LEVEL)
          ).map((roleDetail) => (
            <li
              className={clsx({ [styles.active_member_role]: role == roleDetail.value })}
              onClick={() => {
                if (handleChangeRole) {
                  handleChangeRole({ role: roleDetail.value });
                  setIsModalOpen(false);
                }
              }}
            >
              {roleDetail.name}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
