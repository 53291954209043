import getFilteredAPIData from "shared/helpers/getFilteredAPIData";

const fieldsList = [
  { key: "assignees", value: "id" },
  { key: "owner", value: "id" },
];

export default function convertMetadataResponse<TData>(data: TData): TData {
  return getFilteredAPIData(data, fieldsList);
}
