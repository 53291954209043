import React from "react";

import clsx from "clsx";
import { TextInput, Textarea } from "flowbite-react";
import get from "lodash/get";
import { FormikErrors } from "formik";

import Icon from "shared/components/icon/Icon";
import { MIN_PRESET_LEVELS } from "shared/helpers/constant";

import styles from "assets/css/settings.module.css";

import { PresetLevelType } from "modules/risks/register/types";

import { RisksSettingForm } from "../types";

export default function PresetLevels({
  data,
  fieldName,
  levelPlaceholder,
  descriptionPlaceholder,
  isCustomPreset,
  handleDelete,
  handleChange,
  errors,
}: {
  data: Array<PresetLevelType>;
  fieldName: string;
  isCustomPreset: boolean;
  levelPlaceholder: string;
  descriptionPlaceholder: string;
  handleDelete: (index: number) => void;
  handleChange: (level: PresetLevelType) => void;
  errors: FormikErrors<RisksSettingForm>;
}) {
  function hasError(index: number) {
    const levelErrors = get(errors.preset, fieldName);
    if (levelErrors?.length > 0) {
      return levelErrors[index]?.name || false;
    }
    return false;
  }
  return (
    <div className="grid gap-[10px]">
      {data.map((item, index) => (
        <div className="grid gap-2" key={index + item.level}>
          <h3 className={clsx(styles.levelValue, "flex justify-between items-center")}>
            Level {index + 1}
            {isCustomPreset ? (
              <span
                className={clsx(
                  {
                    "pointer-events-none opacity-50": data.length === MIN_PRESET_LEVELS,
                  },
                  "cursor-pointer"
                )}
                onClick={() => handleDelete(index)}
              >
                <Icon type="trash-outline" />
              </span>
            ) : null}
          </h3>
          <TextInput
            disabled={!isCustomPreset}
            color={hasError(index) ? "failure" : "gray"}
            placeholder={levelPlaceholder}
            type="text"
            value={item.name}
            onChange={(event) => {
              handleChange({ ...item, name: event.target.value });
            }}
          />
          <Textarea
            disabled={!isCustomPreset}
            className="text-sm"
            placeholder={descriptionPlaceholder}
            rows={2}
            value={item.description || ""}
            onChange={(event) => {
              handleChange({ ...item, description: event.target.value });
            }}
          />
        </div>
      ))}
    </div>
  );
}
