import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { UpdatePaymentMethodInputData, UpdatePaymentMethodResponse } from "../types";

export default function updatePaymentMethod({
  company,
  data,
  id,
}: UpdatePaymentMethodInputData): Promise<AxiosResponse<UpdatePaymentMethodResponse>> {
  return api.patch<UpdatePaymentMethodResponse>(
    `companies/payment-methods/${id}/?company=${company}`,
    { id, ...data }
  );
}
