import React from "react";

import Icon from "../icon/Icon";

import gridStyles from "assets/css/gridview.module.css";

function CommentsCount({ comments_count }: { comments_count: number }) {
  return (
    <>
      <div className={gridStyles.gridCommentsCount}>
        <Icon type="comment" fill={true} size="icon-xs" />
        <span className="text-aurometalsaurus dark:text-greychateau">
          {comments_count} Comments
        </span>
      </div>
    </>
  );
}

export default CommentsCount;
