import get from "lodash/get";

import { PaginationDropdownQueryParams } from "shared/types";

export default function hasNameOrdering(
  requestParams?: PaginationDropdownQueryParams,
  fieldName?: string
) {
  let normalizedRequestParams = { ...requestParams };

  if (requestParams?.search) {
    normalizedRequestParams = {
      ...normalizedRequestParams,
      search: requestParams.search.normalize("NFD"),
    };
  }

  if (requestParams && get(requestParams, "ordering")) {
    return normalizedRequestParams;
  }
  return { ...normalizedRequestParams, ordering: fieldName || "name" };
}
