import React from "react";

import ReminderOption from "modules/settingsV2/shared/reminder-option";

const AgreementReminder = () => {
  return (
    <>
      <ReminderOption
        option="Schedule Reminders"
        subOptionTitle="End Date"
        subOptionDescription="Schedule email reminders when an agreement is about to expire."
        dropDown={[]}
      />
      <ReminderOption
        subOptionTitle="Renewal Date"
        subOptionDescription="Schedule email reminders when an agreement is about to expire."
        dropDown={[]}
      />
      <ReminderOption
        subOptionTitle="Next Review"
        subOptionDescription="Schedule email reminders for next review."
        dropDown={[]}
      />
      <ReminderOption
        subOptionTitle="Next Audit"
        subOptionDescription="Schedule email reminders for next audit."
        dropDown={[]}
      />
      <ReminderOption
        subOptionTitle="Next Risk Assessment"
        subOptionDescription="Schedule email reminders for next risk assessment"
        dropDown={[]}
      />
    </>
  );
};

export default AgreementReminder;
