import { ActionPayloadType } from "shared/components/common-table/types";

import { TaskResultType } from "modules/tasks/overview/types";

import { isTaskResultType } from "./isTaskResultType";

export const getTaskResultData = (data: ActionPayloadType[]): Array<TaskResultType> => {
  if (isTaskResultType(data)) return [...data];
  return [];
};
