import { useFormik } from "formik";

import { useCompany } from "shared/context/CompanyProvider";

import { CompanyProps } from "../types";
import { companyUpdateScheme } from "../validation";

const useCompanyForm = (action: CallableFunction) => {
  const { currentCompany } = useCompany();

  return useFormik<CompanyProps>({
    initialValues: {
      name: currentCompany?.name || "",
      industries: currentCompany.industries || [],
      countries: currentCompany.countries || [],
    },
    validateOnChange: false,
    validationSchema: companyUpdateScheme,
    onSubmit: () => action(),
  });
};

export default useCompanyForm;
