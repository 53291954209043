import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId, TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

import { MitigationFileResponse } from "../types";

export default function uploadMitigationFile(
  mitigationId: number,
  teamId: TeamId,
  file: File,
  permissions: TeamParameter
): Promise<AxiosResponse<MitigationFileResponse>> {
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("team", teamId.toString());
  data.append("mitigation", mitigationId.toString());
  return api.post<MitigationFileResponse>(`risks/mitigations-files/`, data, {
    params: {
      mitigation: mitigationId,
      ...teamToQueryParams(permissions),
    },
  });
}
