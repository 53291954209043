import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { SsoSigninRequestParams, SsoSigninResponse } from "../types";

export default function ssoSignIn(
  data: SsoSigninRequestParams
): Promise<AxiosResponse<SsoSigninResponse>> {
  const params = new URLSearchParams();
  params.append("email", data.email);
  return api.get<SsoSigninResponse>("auth/sso-for-email/", {
    params,
  });
}
