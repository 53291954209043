import { useMutation } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";
import { useTeamMemberPermission } from "shared/store/settings";
import { TeamId } from "shared/types";

import { getTeamMemberPermission } from "../api";

const useGetMembers = (successCallback?: CallableFunction) => {
  const { currentCompany } = useCompany();

  const setMemberPermission = useTeamMemberPermission((state) => state.setMemberPermission);

  const {
    data: response,
    isLoading,
    isSuccess,
    mutate: fetchMemberPermissionFn,
  } = useMutation(
    ({ memberId, teamId }: { memberId: number; teamId: TeamId }) =>
      getTeamMemberPermission(teamId, currentCompany.id, memberId),
    {
      onSuccess: (res) => {
        setMemberPermission(res.data);
        successCallback && successCallback();
      },
    }
  );

  return {
    response,
    isLoading,
    isSuccess,
    fetchMemberPermissionFn,
  };
};

export default useGetMembers;
