export const settingsAuthProviderData = {
  sso_type: "",
  metadata_url: "",
  metadata_file: "",
  domains: [],
  groups_attribute: "",
  company: null,
  name: "",
  is_active: false,
  use_federated_groups: false,
};

export const settingsGroupMappingData = {
  name: "",
  group_id: "",
  set_user_as_admin: false,
  company: null,
  teams: [],
  permission: null,
};
