import flatten from "lodash/flatten";

import toRefetchSearchOption from "./toRefetchSearchOption";
import { SelectProps, RefetchOptions } from "../types";

export default function getRefetchOptionsFromSelectProps(
  selectProps: SelectProps[]
): RefetchOptions[] {
  return flatten(
    selectProps
      .map((selectProp) => selectProp.conditions || [])
      .map((conditions) => conditions.map((condition) => toRefetchSearchOption(condition)))
  );
}
