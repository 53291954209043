import React, { memo, useRef, useState } from "react";

import { useOnClickOutside } from "usehooks-ts";

import DownloadModal from "../download-modal";
import Icon from "../icon/Icon";

const Download = ({
  downloadFn,
  downloadFields,
}: {
  downloadFn: (field: string, output_format: string) => void;
  downloadFields: Array<{ id?: number; value: string; label: string }>;
}) => {
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleClickOutsideOfFilter = () => {
    setIsConfirmDialog(false);
  };
  useOnClickOutside(formRef, handleClickOutsideOfFilter);

  return (
    <>
      <div className="download-icon z-[1]">
        <span onClick={() => setIsConfirmDialog(true)} className="cursor-pointer">
          <Icon type="download" fill={false} size="icon-md" />
        </span>
      </div>
      <DownloadModal
        action={(fields, output_format) => {
          downloadFn(fields, output_format);
          setIsConfirmDialog(false);
        }}
        isOpen={isConfirmDialog}
        setIsOpen={setIsConfirmDialog}
        column={downloadFields}
      />
    </>
  );
};

export default memo(Download);
