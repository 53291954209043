import { Link } from "react-router-dom";

/**
 * CsntLogin component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement}
 */

const CantLogin = () => {
  return (
    <section
      className="bg-white dark:bg-darkjunglegreenss h-screen flex items-center"
      data-testid="cant-login"
    >
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <p
            className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white"
            data-testid="cant-login-text"
          >
            Something went wrong!
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Sorry, we can&apos;t logged you in.
          </p>
          <Link to="/" className="my-4 btn_primary" data-testid="cant-login-link">
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CantLogin;
