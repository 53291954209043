import React from "react";
import clsx from "clsx";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/settings.module.css";
import { IS_DARK_MODE } from "shared/helpers/constant";
import { setItemToLocalStorage } from "shared/helpers/util";
import ToggleSwitch from "shared/components/toggle-switch";

const Appearance = () => {
  const { isDarkMode, toggle } = useDarkMode();

  const onChangeMode = () => {
    toggle();
    setItemToLocalStorage(IS_DARK_MODE, isDarkMode ? "false" : "true");
  };

  return (
    <>
      <div className="py-4 px-4 md:px-6 flex justify-between">
        <div>
          <h3 className="text-base font-inter-medium font-medium dark:text-white">Dark Mode</h3>
          <h3 className="text-sm text-aurometalsaurus dark:text-greychateau">
            Turn your app to light-on-dark color scheme
          </h3>
        </div>
        <ToggleSwitch
          checked={false}
          handleChange={onChangeMode}
          name="receive_email_notifications"
          toggleIconClass={clsx(styles.themeToggle, isDarkMode && styles.themeSwitchToggle)}
        />
      </div>
    </>
  );
};

export default Appearance;
