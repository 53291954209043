import { PropsWithChildren } from "react";
import Icon from "shared/components/icon/Icon";

interface IArticleSectionProps {
  title: string;
  icon?: string;
}

const ArticleSection = ({ title, children, icon }: PropsWithChildren<IArticleSectionProps>) => (
  <div className="grid gap-4">
    <div className="flex items-center gap-2 text-sm text-richblack font-medium">
      {icon ? <Icon type={icon} fill size="icon-xs" /> : null}
      {title}
    </div>
    {children}
  </div>
);

export default ArticleSection;
