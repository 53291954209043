import React from "react";

import Icon from "shared/components/icon/Icon";

const NoSearchResultFound = () => {
  return (
    <div
      className="flex flex-col items-center justify-center h-full no-searchfound px-4"
      data-testid="no-search-result-available"
    >
      <Icon type="search" fill={false} size="icon-sm" />
      <h4
        className="text-center font-inter-medium text-aurometalsaurus text-sm mt-3 dark:text-greychateau"
        data-testid="no-data-available-text"
      >
        Sorry! No results found
      </h4>
      <p className="max-w-[370px] text-center text-xs mt-2 text-santagrey dark:text-stormdust">
        We’re sorry we can’t find what you’re looking for. Please try another keyword
      </p>
    </div>
  );
};

export default NoSearchResultFound;
