import React from "react";

import { getLabelForSearchResult } from "shared/helpers/search";

import { SearchResultResponseType } from "../types";

type SearchResultRowHeaderProps = {
  searchResult: SearchResultResponseType;
  searchResultArray: SearchResultResponseType[];
  index: number;
};

const SearchResultRowHeader = ({
  searchResult,
  searchResultArray,
  index,
}: SearchResultRowHeaderProps) => {
  if (searchResult.object_type === searchResultArray[index - 1]?.object_type) {
    return null;
  }

  return (
    <h3
      className="text-aurometalsaurus text-sm pl-4 md:pl-2 mb-1 dark:text-greychateau"
      data-testid="search-result-header"
    >
      {getLabelForSearchResult(searchResult.object_type)}
    </h3>
  );
};

export default SearchResultRowHeader;
