import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { VerifyEmailResponseParams } from "../types";

export default function verifyEmail(
  key: string
): Promise<AxiosResponse<VerifyEmailResponseParams>> {
  return api.post<VerifyEmailResponseParams>(`auth/confirm-email/${key}/`);
}
