import React, { useCallback, useRef } from "react";

import { UseMutateFunction } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import trim from "lodash/trim";
import { AxiosResponse } from "axios";

import { GlobalSearchResponse, SearchResultResponseType } from "../types";
import Icon from "shared/components/icon/Icon";

type SearchInputProps = {
  globalSearchFn: UseMutateFunction<AxiosResponse<GlobalSearchResponse>, unknown, string, unknown>;
  setSelectedIndex: (state: number) => void;
  setSearchData: (state: SearchResultResponseType[]) => void;
  setSearchKey: (state: string) => void;
  searchKey: string;
};

const SearchInput = (props: SearchInputProps) => {
  const { globalSearchFn, setSelectedIndex, setSearchData, setSearchKey, searchKey } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useCallback(
    debounce((key: string) => {
      if (trim(key).length === 0) {
        return;
      }
      globalSearchFn(key);
      setSelectedIndex(0);
    }, 500),
    []
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncedSearch(value);
    setSearchKey(value);
    if (value === "" || searchKey === "") {
      setSearchData([]);
    }
  };

  return (
    <div data-testid="search-input" className="mobile_search cursor-text">
      <input
        data-testid="search-input-control"
        className="flex items-center placeholer:text-xl font-inter-medium text-mirage h-full !pl-[34px] w-full dark:!bg-darkjunglegreenss"
        placeholder="Search"
        onChange={onChange}
        value={searchKey}
        ref={inputRef}
      />
      <span data-testid="search-input-icon" className="pointer-events-none cursor-text">
        <Icon
          type="search"
          fill={false}
          size="icon-sm"
          className="seeting_icon absolute top-5 left-2"
        />
      </span>
    </div>
  );
};

export default SearchInput;
