import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export const deleteTaskById = (
  { taskId }: { taskId: number },
  permission?: { team: TeamId }
): Promise<AxiosResponse<string>> => {
  return api.delete<string>(`tasks/${taskId}/`, { params: { ...permission } });
};
