import React from "react";

const PendingRequest = () => {
  return (
    <span className="text-santagrey border border-lightSilver px-2.5 rounded-md	py-1">
      Pending request
    </span>
  );
};

export default PendingRequest;
