import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { CompanyPresetResponse } from "../types";

export const getPresetByCompanyId = async (
  { companyId }: { companyId: number },
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<CompanyPresetResponse>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  const result = await api.get<CompanyPresetResponse>(`risks/company-presets/${companyId}/`, {
    params: { team: teamParameter, company: permissions.team?.company },
  });

  return result;
};
