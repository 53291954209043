import { ActionPayloadType } from "shared/components/common-table/types";

import { TaskResultType } from "modules/tasks/overview/types";

export const isTaskResultType = (data?: ActionPayloadType[]): data is TaskResultType[] => {
  if (!data || data.length === 0) return false;
  if (
    "name" in data[0] &&
    "description" in data[0] &&
    "due_date" in data[0] &&
    "company" in data[0] &&
    "team" in data[0] &&
    "status" in data[0] &&
    "priority" in data[0] &&
    "tags" in data[0] &&
    "assignees" in data[0] &&
    "owner" in data[0]
  )
    return true;
  return false;
};
