import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { MitigationFormValueType, MitigationType } from "../types";
import { convertMitigationData } from "../helpers";

export const updateMitigation = async (
  { mitigation }: { mitigation: MitigationFormValueType },
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<MitigationType>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  let response = await api.patch<MitigationType>(
    `risks/mitigations/${mitigation.id}/`,
    { ...mitigation },
    {
      params: { ...{ team: teamParameter, company: permissions.team?.company } },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertMitigationData(response.data),
    };
  }

  return response;
};
