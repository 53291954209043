import isObject from "lodash/isObject";
import has from "lodash/has";
import every from "lodash/every";

import { NoteFileResponse } from "../types";

/**
 * Type guard to check is data is NoteFileResponse
 */
export default function isNoteFileResponse(data: unknown): data is NoteFileResponse {
  return every([
    isObject(data),
    has(data, "id"),
    has(data, "name"),
    has(data, "file"),
    has(data, "note"),
  ]);
}
