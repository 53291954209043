import { useEffect } from "react";

const useHandleKey = (selectUp: CallableFunction, selectDown: CallableFunction) => {
  const listenKey = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowUp":
        selectUp();
        break;
      case "ArrowDown":
        selectDown();
        break;
      case "Enter":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const listen = (e: KeyboardEvent) => {
      listenKey(e);
    };
    window.addEventListener("keydown", listen);
    return () => {
      window.removeEventListener("keydown", listen);
    };
  });
};

export default useHandleKey;
