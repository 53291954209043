import { useDarkMode } from "usehooks-ts";
import clsx from "clsx";

import useScreen from "shared/hooks/useScreen";
import { MOBILE_DEVICE } from "shared/helpers/constant";

import logoText from "assets/images/sidebar/logotext.svg";
import styles from "assets/css/header.module.css";
import logo from "assets/images/logo.svg";
import logoWhite from "assets/images/logo_white.svg";
import smallLogo from "assets/images/logowhite.svg";
import { ReactComponent as HamburgerMenuIcon } from "assets/images/task/menu.svg";

const AuthHeader = () => {
  const deviceType = useScreen();
  const { isDarkMode } = useDarkMode();

  return (
    <div data-testid="auth_header" className={clsx(styles.logo, "pt-4")}>
      <HamburgerMenuIcon className="subscriptions_right mr-4 md:hidden" />
      {deviceType === MOBILE_DEVICE ? (
        <img src={isDarkMode ? smallLogo : logo} alt="logo" />
      ) : (
        <img src={isDarkMode ? logoWhite : logoText} alt="logo" />
      )}
    </div>
  );
};
export default AuthHeader;
