import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { InvoiceResponseProps } from "../types";

export default function getInvoices(id: number): Promise<AxiosResponse<InvoiceResponseProps[]>> {
  const params = new URLSearchParams();
  params.append("company", `${id}`);
  return api.get<InvoiceResponseProps[]>(`companies/${id}/invoices/`, { params });
}
