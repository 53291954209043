export default function getNameForIcon(name: string): string {
  return name
    ? name
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .slice(0, 2)
        .toUpperCase()
    : "";
}
