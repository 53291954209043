import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { IncidentsResponseType } from "../types";
import { convertIncidentData } from "../helpers";

export default async function getIncidentById(
  incidentId: number,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<IncidentsResponseType>> {
  const teamParameter = permissions.team.isAllTeam ? "all" : permissions.team.team;

  let response = await api.get<IncidentsResponseType>(`risks/incidents/${incidentId}/`, {
    params: { team: teamParameter, company: permissions.team.company },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertIncidentData(response.data),
    };
  }

  return response;
}
