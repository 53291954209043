import React from "react";
import isEmpty from "lodash/isEmpty";

import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";
import { useCompany } from "shared/context/CompanyProvider";

import ViewTaskModal from "./ViewTaskModal";
import { RelatedObjectType } from "../types";
import getRelatedObjectTypeName from "../helpers/getRelatedObjectTypeName";
import useLoadRelatedObject from "../hooks/useLoadRelatedObject";

function CurrentTaskModal({
  isViewModalOpen,
  relatedObject,
  onClose: close,
  onEdit: edit,
}: {
  isViewModalOpen: boolean;
  relatedObject?: RelatedObjectType;
  onClose: (state: boolean) => void;
  onEdit: (state: boolean) => void;
}) {
  const { currentTeam } = useTeam();
  const { currentCompany } = useCompany();

  const [currentTask, setCurrentTask] = useTask((state) => [
    state.currentTask,
    state.setCurrentTask,
  ]);

  const loadedObject = useLoadRelatedObject(
    currentTask,
    { team: currentTeam?.id, company: currentTeam?.company, isAllTeam: currentTeam?.is_all_team },
    relatedObject
  );

  if (isEmpty(currentTask)) {
    return null;
  }

  return (
    <ViewTaskModal
      isViewModalOpen={isViewModalOpen}
      task={currentTask}
      company={currentCompany}
      relatedObject={loadedObject}
      relatedObjectType={getRelatedObjectTypeName(currentTask)}
      onClose={() => {
        close(false);
        setCurrentTask(null);
      }}
      onEdit={() => edit(true)}
    />
  );
}

export default CurrentTaskModal;
