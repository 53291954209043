import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId } from "shared/types";

import { CompanyUserResponse, UserRole } from "modules/home/types";
import { ADMIN_ROLE, OWNER_ROLE } from "shared/helpers/constant";

export default function updateMemberRole(
  memberId: number,
  role: UserRole,
  permissions: { company: CompanyId }
): Promise<AxiosResponse<CompanyUserResponse>> {
  const is_admin = [ADMIN_ROLE, OWNER_ROLE].includes(role);

  return api.patch<CompanyUserResponse>(
    `/companies/members/${memberId}/`,
    {
      role,
      is_admin,
    },
    {
      params: { ...permissions },
    }
  );
}
