import { useEffect } from "react";

const useSetTableview = () => {
  const mainContent = document.querySelector(".main-content");

  useEffect(() => {
    mainContent?.classList.add("main-table-bg");
    return () => mainContent?.classList.remove("main-table-bg");
  }, []);
};

export default useSetTableview;
