import { create } from "zustand";
import * as Sentry from "@sentry/react";

import { ProfileResponse } from "modules/settings/my-account/types";

/**
 * Profile type has same structure as ProfileResponse
 */
export type Profile = ProfileResponse;

interface Home {
  profile: Profile;
  setProfile: (profile: Profile) => void;
  isSpotlightModalopen: boolean;
  setIsSpotlightModalopen: (isSpotlightModalopen: boolean) => void;
}

export const useHome = create<Home>((set) => ({
  profile: {} as Profile,
  setProfile: (profile: Profile) => {
    try {
      if (profile?.id) {
        Sentry.setUser({
          id: profile.id,
        });
      } else {
        Sentry.setUser(null);
      }
    } catch (error) {
      /* empty */
    }

    set({ profile });
  },

  isSpotlightModalopen: false,
  setIsSpotlightModalopen: (isSpotlightModalopen: boolean) => set({ isSpotlightModalopen }),
}));
