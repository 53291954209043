import { UtmSourceType } from "shared/types";

export default function getUtmSource(
  search: string,
  queryParam = "utm_source"
): UtmSourceType | null | string {
  const searchParams = new URLSearchParams(search);

  switch (searchParams.get(queryParam)) {
    case "google":
      return "google";
    case "meta":
      return "meta";
    case "reddit":
      return "reddit";
    case "linkedin":
      return "linkedin";
    case "twitter":
      return "twitter";
    default:
      return searchParams.get(queryParam);
  }
}
