import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId } from "shared/types";

import { CompanyUserResponse } from "modules/home/types";
import { ADMIN_ROLE, GUEST_ROLE, MEMBER_ROLE, OWNER_ROLE } from "shared/helpers/constant";

export default function updatePermission(
  data: CompanyUserResponse,
  permissions: { company: CompanyId }
): Promise<AxiosResponse<CompanyUserResponse>> {
  if (data.role) {
    data.is_admin = [ADMIN_ROLE, OWNER_ROLE].includes(data.role);
  }

  if (data.is_admin === true && ![ADMIN_ROLE, OWNER_ROLE].includes(data.role)) {
    data.role = ADMIN_ROLE;
  }

  if (data.is_admin === false && ![MEMBER_ROLE, GUEST_ROLE].includes(data.role)) {
    data.role = MEMBER_ROLE;
  }

  return api.patch<CompanyUserResponse>(`/companies/members/${data.id}/`, data, {
    params: { ...permissions },
  });
}
