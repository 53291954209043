import { ArticleClassifications as EArticleClassifications } from "modules/horizonScanning/types";
import ArticleSection from "../article-section";
import { useArticleDetails } from "../../ArticleDetails";

const ArticleClassifications = () => {
  const { article } = useArticleDetails();

  const classifications = [
    {
      title: EArticleClassifications.relevantRegulations,
      classification: article.regulations?.map((data) => data?.name).join(" | "),
    },
    {
      title: EArticleClassifications.mitigatingEfforts,
      classification: article.mitigating_efforts?.map((data) => data?.name).join(" | "),
    },
    {
      title: EArticleClassifications.potentialImpact,
      classification: article.potential_impact?.map((data) => data?.name).join(" | "),
    },
    {
      title: EArticleClassifications.timeline,
      classification: article.timeline?.map((data) => data?.name).join(" | "),
    },

    {
      title: EArticleClassifications.institutionTypes,
      classification: article.institution_types?.map((data) => data?.name).join(" | "),
    },
    {
      title: EArticleClassifications.regulatoryJurisdiction,
      classification: article.jurisdictions?.map((data) => data?.name).join(" | "),
    },
  ];

  return (
    <ArticleSection title="Classifications" icon="squares-check">
      <div className="grid gap-2">
        {classifications.map(({ classification, title }) =>
          classification ? (
            <div
              key={title}
              className="grid gap-2 border border-antiflashwhite rounded-lg p-4 bg-ghostwhite font-medium"
            >
              <span className="text-xs text-aurometalsaurus">{title}</span>
              <span className="text-sm text-richblack">{classification}</span>
            </div>
          ) : null
        )}
      </div>
    </ArticleSection>
  );
};

export default ArticleClassifications;
