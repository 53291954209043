import React, { useRef } from "react";

import clsx from "clsx";
import { Modal } from "flowbite-react";

import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import styles from "assets/css/task.module.css";

export default function CreateModal<DataType = unknown>({
  show,
  handleClose,
  title,
  CreateModalBody,
  onSuccess,
}: {
  show: boolean;
  handleClose: () => void;
  title: string;
  CreateModalBody?: React.ForwardRefExoticComponent<
    React.RefAttributes<unknown> & { onSuccess: (data: DataType) => void }
  >;
  onSuccess: (data: DataType) => void;
}) {
  const { documentBodyRef } = useDocumentBodyRef();

  // we must have the same onSubmit function inside the CreateModalBody component then we can call from inside dropdown.
  const childRef = useRef<{ onSubmit: () => void } | null>(null);

  // Method to trigger submit method as a callback.
  const onSubmit = () => {
    if (childRef.current) {
      childRef.current.onSubmit();
    }
  };

  return (
    <Modal
      root={documentBodyRef}
      show={show}
      size="2xl"
      popup={true}
      onClose={handleClose}
      dismissible={true}
      className={styles.taskModalStyle}
    >
      <Modal.Header
        className={clsx(styles.taskModalHeader, "dark:!border-thunders mac13Inch:items-center")}
      >
        <div className="text-lg mac13Inch:text-base font-sans-medium dark:!text-white !text-mirage">
          {title}
        </div>
      </Modal.Header>

      <Modal.Body className="!py-[30px] mac13Inch:!py-6">
        <div className="space-y-6">
          {CreateModalBody ? <CreateModalBody ref={childRef} onSuccess={onSuccess} /> : null}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-t dark:!border-thunders mac13Inch:py-4">
        <button className="btn_secondary flex ml-auto" type="button" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn_primary flex ml-auto" type="submit" onClick={() => onSubmit()}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}
