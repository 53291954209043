import { useState } from "react";

import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { useQuery } from "@tanstack/react-query";
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
} from "lexical";

import Icon from "shared/components/icon/Icon";

import { useLexicalEditor } from "../context/LexicalEditorContext";
import { FileComponentProps, FilePayload, SerializedFileNode } from "../types";

function FileComponent({
  className,
  format,
  nodeKey,
  url,
  id,
  moduleType,
  name,
}: FileComponentProps) {
  const { downloader, prepareDownloadResponse } = useLexicalEditor();
  const [file, setFile] = useState({ name, url });

  useQuery(["fetch-file", id, moduleType], () => downloader(id), {
    enabled: !!id,
    onSuccess: (res) => {
      setFile(prepareDownloadResponse(res.data));
    },
  });

  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      <div className="flex justify-between border border-lightSilver dark:border-thunders rounded-lg p-4 mb-3 file_node_parent">
        <div>
          <a
            href={file.url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="font-inter-semibold text-sm md:text-base dark:text-white"
          >
            {file.name}
          </a>
        </div>
        <a
          href={file.url}
          download
          target="_blank"
          rel="noopener noreferrer"
          className="justify-center ml-2 md:ml-4"
        >
          <div className="bg-antiflashwhite dark:bg-balticsea items-center justify-center flex w-8 md:w-[70px] h-8 md:h-[70px]">
            <Icon type="download" fill={false} size="icon-smd" />
          </div>
        </a>
      </div>
    </BlockWithAlignableContents>
  );
}

function convertFileElement(domNode: HTMLElement): null | DOMConversionOutput {
  const data = domNode.getAttribute("data-lexical-file-upload");
  if (data) {
    const jsonData = JSON.parse(data);

    const node = $createFileNode({
      url: jsonData.url,
      id: jsonData.id,
      moduleType: jsonData.moduleType,
      name: jsonData.name,
    });
    return { node };
  }
  return null;
}

export class FileNode extends DecoratorBlockNode {
  __url?: string;
  __id?: number;
  __moduleType?: string;
  __name: string;

  static getType(): string {
    return "file";
  }

  static clone(node: FileNode): FileNode {
    return new FileNode(
      node.__name,
      node.__url,
      node.__id,
      node.__moduleType,
      node.__format,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedFileNode): FileNode {
    const { id, moduleType, url, name } = serializedNode;
    const node = $createFileNode({
      name,
      url,
      moduleType,
      id,
    });
    node.setFormat(serializedNode.format);

    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute(
      "data-lexical-file-upload",
      JSON.stringify({
        url: this.__url,
        id: this.__id,
        moduleType: this.__moduleType,
      })
    );
    const text = document.createTextNode(this.getTextContent());
    element.append(text);
    return { element };
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute("data-lexical-file-upload")) {
          return null;
        }
        return {
          conversion: convertFileElement,
          priority: 0,
        };
      },
    };
  }

  constructor(
    name: string,
    url?: string,
    id?: number,
    moduleType?: string,
    format?: ElementFormatType,
    key?: NodeKey
  ) {
    super(format, key);
    this.__name = name;
    this.__url = url;
    this.__id = id;
    this.__moduleType = moduleType;
  }

  exportJSON(): SerializedFileNode {
    return {
      ...super.exportJSON(),
      name: this.__name,
      url: this.__url,
      id: this.__id,
      moduleType: this.__moduleType,
      type: "file",
      version: 1,
    };
  }

  updateDOM(): false {
    return false;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };
    return (
      <FileComponent
        className={className}
        name={this.__name}
        format={this.__format}
        nodeKey={this.getKey()}
        url={this.__url as string}
        id={this.__id as number}
        moduleType={this.__moduleType as string}
      />
    );
  }
}

export function $createFileNode({ name, url, id, moduleType }: FilePayload): FileNode {
  return new FileNode(name, url, id, moduleType);
}

export function $isFileNode(node: FileNode | LexicalNode | null | undefined): node is FileNode {
  return node instanceof FileNode;
}
