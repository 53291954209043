import { get } from "lodash";

export const getModuleName = (key: string) => {
  const revisedModuleName = {
    assessments: "vendorassessment",
    agreements: "vendoragreement",
    vendor: "vendor",
    reports: "dashboard",
    risk: "risksv2.risk",
  };
  return get(revisedModuleName, key);
};
