import React, { memo } from "react";

import { Link } from "react-router-dom";

import Icon from "shared/components/icon/Icon";

import style from "assets/css/settings.module.css";

function SettingHeader() {
  return (
    <div className={style.setting_header}>
      <div className={style.setting_navigation}>
        <Link to="/" className="dark:text-greychateau">
          <Icon type="setting_home" size="icon-smd" fill={true} />
          Go back to Dashboard
        </Link>
      </div>
      <h3 className="dark:text-white">Settings</h3>
    </div>
  );
}
export default memo(SettingHeader);
