export const RISK_HEAT_MAP_COLORS = {
  "0": "#067A55",
  "1": "#32C38D",
  "2": "#FACA17",
  "3": "#FF5A1F",
  "4": "#C81D1D",
} as Record<string, string>;

export const RISK_HEAT_MAP_TEXT_COLORS = {
  "0": "#FFFFFF",
  "1": "#FFFFFF",
  "2": "#FFFFFF",
  "3": "#FFFFFF",
  "4": "#FFFFFF",
} as Record<string, string>;

export const RISK_COLORS = {
  "0": "#3F83F8",
  "1": "#057A55",
  "2": "#31C48D",
  "3": "#FACA15",
  "4": "#E3A008",
  "5": "#FF5A1F",
  "6": "#C81E1E",
  "7": "#E74694",
  "8": "#9061F9",
  "9": "#5145CD",
} as Record<string, string>;

export const RISK_RING_COLORS = {
  "0": "#C3DDFD",
  "1": "#84E1BC",
  "2": "#BCF0DA",
  "3": "#FDF6B2",
  "4": "#FCE96A",
  "5": "#FDBA8C",
  "6": "#F98080",
  "7": "#F8B4D9",
  "8": "#CABFFD",
  "9": "#8DA2FB",
} as Record<string, string>;

export const RISK_TEXT_COLORS = {
  "0": "#FFFFFF",
  "1": "#FFFFFF",
  "2": "#FFFFFF",
  "3": "#FFFFFF",
  "4": "#FFFFFF",
  "5": "#FFFFFF",
  "6": "#FFFFFF",
  "7": "#FFFFFF",
  "8": "#FFFFFF",
  "9": "#FFFFFF",
} as Record<string, string>;

export const DOWNLOAD_LANGUAGES = [
  { value: "en", label: "English" },
  { value: "sv", label: "Swedish" },
];

export const PRESET_B_COLORS = ["#067A55", "#FACA17", "#FF5A1F", "#C81D1D"];

export const PRESET_B_TEXT_COLORS = ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"];
