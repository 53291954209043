import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { TemplateDataType } from "../types";

export default function getTemplates(
  permissions: {
    team: TeamId;
  },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<TemplateDataType>> {
  return api.get<TemplateDataType>(`agreements/validations/`, {
    params: { ...permissions, ...hasNameOrdering(requestParams) },
  });
}
