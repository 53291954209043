import styles from "assets/css/sidebarV2.module.css";
import clsx from "clsx";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { MOBILE_DEVICE } from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";
import { useSideMenu } from "shared/store/rightSideBar";
import { useSidebar } from "shared/store/sidebar";

import useModuleEnabled from "shared/hooks/useModuleEnabled";
import { getSidebarItemIcon } from "../../components/icons/Icons";
import { ItemTypes, RenderListProps } from "./types";
import { RISK_MODULE, RISK_MODULE_V2 } from "shared/helpers/ability";

export default function RenderList({ sidebarItem, index, onClick }: RenderListProps) {
  const deviceType = useScreen();

  const setIsSideMenuShown = useSideMenu((state) => state.setIsSideMenuShown);

  const { checkEnabled } = useModuleEnabled();

  const { pathname } = useLocation();

  const setIsVisible = useSidebar((state) => state.setIsVisible);

  const onLinkClick = () => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    }
  };

  const module =
    sidebarItem?.subject === RISK_MODULE && sidebarItem?.path?.startsWith("/workspace/")
      ? RISK_MODULE_V2
      : sidebarItem?.subject;

  if (module && !checkEnabled(module)) {
    return null;
  }

  return (
    <li key={index}>
      <Link
        to={sidebarItem.path || pathname}
        onClick={() => {
          onClick(index);
        }}
        className={clsx(
          "flex items-center rounded-lg py-1 px-2 text-sm font-normal text-gray-700 md:hover:bg-gray-100 md:hover:text-mirage md:dark:hover:bg-balticsea md:dark:hover:text-white dark:text-gray_700",
          {
            "md:bg-gray-100 !text-gray-900 md:dark:bg-balticsea dark:!text-white":
              pathname?.startsWith(sidebarItem?.path || "") && !!sidebarItem?.path?.slice(1),
          }
        )}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <div className="flex-shrink-0 flex items-center justify-center min-w-[1.5rem]">
              <span className="vendors_icon inline-flex">
                {getSidebarItemIcon(sidebarItem?.label)}
              </span>
            </div>
            <span className="ml-3">{sidebarItem.label}</span>
          </div>
          {sidebarItem?.isBeta ? (
            <div className="flex items-center py-0.5 px-1.5 rounded-md outline-1 outline outline-santagrey bg-transparent dark:outline-gray_400">
              <h4 className="text-xs font-inter-regular font-normal text-aurometalsaurus dark:text-battleshipgray">
                Beta
              </h4>
            </div>
          ) : null}
        </div>
        {sidebarItem?.items ? (
          <svg
            sidebar-toggle-item=""
            className={clsx("w-6 h-6 ml-auto", {
              "rotate-180": sidebarItem.isOpen,
            })}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        ) : null}
      </Link>

      {sidebarItem?.items ? (
        <ul
          id="dropdown-example"
          className={clsx(
            { hidden: !sidebarItem.isOpen, block: sidebarItem.isOpen },
            styles.sidebarSubMenu,
            "md:py-2 md:space-y-1.5"
          )}
        >
          {sidebarItem.items.map((item: ItemTypes, itemIndex: number) => (
            <li key={itemIndex} className="flex pl-8 item-center">
              <Link
                to={`${item?.path || pathname}`}
                className={clsx(
                  "flex items-center md:py-1 py-1 px-3  w-full text-sm font-medium md:rounded-lg transition duration-75 group hover:bg-gray-100 dark:hover:bg-balticsea dark:hover:text-white light:text-white light:hover:bg-gray-700 text-gray-700 dark:text-greychateau maxMd:border-b border-antiflashwhite maxMd:dark:border-thunders",
                  {
                    "md:bg-gray-100 !text-gray-900 md:dark:bg-balticsea dark:!text-white":
                      pathname?.includes(item?.path as string),
                  }
                )}
                onClick={() => {
                  !pathname?.includes(item?.path as string) && setIsSideMenuShown(false);
                  onLinkClick();
                }}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}
