import React, { useState } from "react";

import { Label, TextInput, Textarea } from "flowbite-react";
import { useQuery } from "@tanstack/react-query";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyMember } from "shared/context/types/company";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import SidebarTitle from "modules/settingsV2/component/SidebarTitle";
import MemberListingTable from "modules/settingsV2/shared/member-listing";
import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import style from "assets/css/settings.module.css";
import DeleteModal from "../delete-modal/DeleteModal";

export default function GroupEditView() {
  const { currentCompany } = useCompany();

  const { data: companyMemberList, isLoading } = useQuery(
    ["getMemberByCompany", "settingGroupEdit", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const memberList: CompanyMember[] = [
    ...(companyMemberList?.data?.results || []),
    ...(currentCompany?.organization_invites ? [...currentCompany.organization_invites] : []),
  ];

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <div className={style.setting_layout}>
      {isLoading ? <CentralSpinner /> : null}
      <SidebarTitle title="Alpha Group" />
      <div className={style.layout_card_info}>
        <h5 className="font-inter-medium dark:text-white">Group Profile</h5>
        <span className="mt-2.5 inline-block text-sm font-inter-medium dark:text-white">Icon</span>
        <div className={style.group_avatar}>AT</div>
        <p className="text-aurometalsaurus dark:text-greychateau">
          Upload an image or pick an emoji. It will show up in your sidebar and notifications.
        </p>
        <button className="h-10 gap-2 flex items-center btn_secondary maxMd:justify-center maxMd:w-full mt-4">
          <Icon type="cloud-upload" fill={false} size="icon-smd" className="themestroke_white" />
          <span>Change picture</span>
        </button>
        <div className="my-4 max-w-[714px] w-full">
          <Label value="Group Name*" className="font-inter-medium mb-2 block !text-mirage" />
          <TextInput
            data-testid="add-group-name"
            placeholder="Enter Group Name"
            type="text"
            name="name"
            className="group_input"
          />
          <div className="mt-4">
            <Label
              value="Group Description"
              className="font-inter-medium mb-2 block !text-mirage"
            />
            <Textarea
              data-testid="add-group-description"
              rows={3}
              name="description"
              placeholder="Enter group description"
            />
          </div>
        </div>
      </div>

      <MemberListingTable isEdit={false} membersList={memberList} />

      <div className="p-6">
        <Label value="Danger Zone" className="font-inter-medium !text-mirage" />
        <p className="text-aurometalsaurus dark:text-greychateau text-sm mt-1">
          Deleting the group will permanently delete and disband users.
        </p>
        <button
          type="button"
          className="mt-4 text-lavared hover:text-white border border-lavared hover:bg-lavared focus:ring-0 focus:outline-none rounded-full text-sm px-5 py-2.5 mac13Inch:text-xs"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Group
        </button>
        {isDeleteModalOpen ? (
          <DeleteModal
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
          />
        ) : null}
      </div>
    </div>
  );
}
