import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

import { PermissionModule } from "../types";
import { getUrlName, getParamName } from "../helpers";

export default function deletePermission(
  module: PermissionModule,
  objectId: number,
  permissionId: number,
  team: TeamParameter
): Promise<AxiosResponse<void>> {
  return api.delete<void>(`/${getUrlName(module)}/permissions/${permissionId}/`, {
    params: {
      [getParamName(module)]: objectId,
      ...teamToQueryParams(team),
    },
  });
}
