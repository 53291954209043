import React, { useState } from "react";
import Icon from "shared/components/icon/Icon";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Button from "shared/componentsV2/form-controls/button";

interface DropdownDataProps {
  icon: string;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

interface TodoActionsProps {
  onEdit: () => void;
  onDelete: () => void;
}

const TodoRowActions = ({ onEdit, onDelete }: TodoActionsProps) => {
  const [open, setOpen] = useState(false);

  const listingDropDownData: DropdownDataProps[] = [
    {
      icon: "pencil-square",
      title: "Edit",
      onClick: onEdit,
      disabled: !onEdit,
    },
    {
      icon: "trash",
      title: "Delete",
      onClick: onDelete,
    },
  ];

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <Button
          aria-label="Customize options"
          btnType="plain"
          btnSize="sm"
          icon={
            <Icon type="ellipsis-vertical" fill={true} size="icon-xs" className="three_dots_icon" />
          }
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="DropdownMenuContent listing_dropdown_wrapper"
        sideOffset={5}
        side="left"
        align="start"
      >
        <ul>
          {listingDropDownData.map((ele: DropdownDataProps, index: number) => (
            <li className={ele.disabled ? "opacity-30 pointer-events-none" : undefined} key={index}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (ele?.onClick) {
                    ele.onClick();
                  }
                  setOpen(false);
                }}
              >
                <span>
                  <Icon type={ele.icon} fill={true} size="icon-sm" className="table_circle_icon" />
                </span>
                <span className="dark:text-silverpolish">{ele.title}</span>
              </button>
            </li>
          ))}
        </ul>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default TodoRowActions;
