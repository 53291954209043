import React from "react";

import clsx from "clsx";

import gridStyles from "assets/css/gridview.module.css";
import Icon from "../icon/Icon";

function TodayDate() {
  return (
    <>
      <div data-testid="today-date" className={clsx(gridStyles.gridDueDate, gridStyles.gridDate)}>
        <Icon type="dangerIcon" fill={false} size="icon-xs" className="mr-1.5 danger_icon" />
        <span className="mr-1">Due today</span>
      </div>
    </>
  );
}

export default TodayDate;
