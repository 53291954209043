import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import posthog from "posthog-js";

import { createComment, getComments } from "shared/components/metadata/api";
import { TASK_TABLE } from "shared/helpers/constant";
import { initialDataForList } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";
import CommentsList from "shared/components/comments-list";
import { CreateCommentType } from "shared/components/metadata/types";
import { GetTaskResultType } from "shared/types";
import { CreateCommentForm } from "shared/components/comment-form";
import TaskLabel from "./TaskLabel";

function TaskDisplayComments({ currentTask }: { currentTask: GetTaskResultType }) {
  const currentTeam = useTeam((state) => state.currentTeam);

  const {
    data: comments,
    isLoading,
    isFetching,
    refetch: refetchComments,
  } = useQuery(
    ["getComments", [], currentTeam?.id, currentTask?.id],
    () =>
      getComments(TASK_TABLE, currentTask?.id, {
        teamId: currentTeam?.id,
        companyId: currentTeam?.company,
        isAllTeam: currentTeam?.is_all_team || false,
      }),
    {
      initialData: () => ({ data: { ...initialDataForList } } as AxiosResponse),
      enabled: !!currentTask?.id,
    }
  );
  const { mutate: createCommentFn } = useMutation(
    (data: CreateCommentType) => createComment(data),
    {
      onSuccess: () => {
        posthog.capture("commentCreated");
        toast("Comment added.", { type: "success" });
        refetchComments();
      },
      onError: () => {
        toast("Something went wrong.", { type: "error" });
      },
    }
  );
  return (
    <>
      <TaskLabel title="Comments" className="!mb-0" />
      <div className="max-h-40 overflow-y-auto" data-testid="display-comment">
        <CommentsList comments={comments?.data?.results} isLoading={isLoading || isFetching} />
      </div>
      <CreateCommentForm onCommentAdd={createCommentFn} task={currentTask} />
    </>
  );
}

export default TaskDisplayComments;
