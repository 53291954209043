import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { PolicyCommentResponse } from "../types";

export default function createPolicyComments(
  policy: number,
  tempData: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PolicyCommentResponse>> {
  return api.post<PolicyCommentResponse>(`policies/comments/`, tempData, {
    params: {
      policy,
      ...permissions,
    },
  });
}
