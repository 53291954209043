import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { GoogleSigninRequestParams, GoogleSigninResponseParams } from "../types";

export default function googlesignin(
  data: GoogleSigninRequestParams
): Promise<AxiosResponse<GoogleSigninResponseParams>> {
  return api.post<GoogleSigninResponseParams>("auth/google/login/", data);
}
