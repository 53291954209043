import { useFormik } from "formik";

import { UtmSourceType } from "shared/types";

import { SignupRequestParams } from "../types";
import { signupSchema } from "../validation";

const useSignupForm = (utmSource: UtmSourceType | string | null, action: CallableFunction) => {
  return useFormik<SignupRequestParams>({
    initialValues: {
      email: "",
      password1: "",
      password2: "",
      agreeTerms: false,
      utm_source: utmSource,
    },
    validateOnChange: true,
    validationSchema: signupSchema,
    onSubmit: () => action(),
  });
};

export default useSignupForm;
