import isObject from "lodash/isObject";
import has from "lodash/has";
import every from "lodash/every";

import { RiskFileResponse } from "../register/types";

/**
 * Type guard to check is data is RiskFileResponse
 */
export default function isRiskFileResponse(data: unknown): data is RiskFileResponse {
  return every([
    isObject(data),
    has(data, "id"),
    has(data, "name"),
    has(data, "file"),
    has(data, "risk"),
  ]);
}
