import {
  PRESET_B_COLORS,
  PRESET_B_TEXT_COLORS,
  RISK_COLORS,
  RISK_RING_COLORS,
  RISK_TEXT_COLORS,
} from "modules/risks/constants";

export const getRiskColors = (n: number, type: "badge" | "ring" | "text" = "badge") => {
  const colors = {
    ring: RISK_RING_COLORS,
    badge: RISK_COLORS,
    text: RISK_TEXT_COLORS,
  }[type];

  switch (n) {
    case 1:
      return [colors[6]];
    case 2:
      return [colors[1], colors[6]];
    case 3:
      return [colors[1], colors[3], colors[6]];
    case 4:
      switch (type) {
        case "badge":
          return PRESET_B_COLORS;
        case "ring":
          return [colors[2], colors[3], colors[5], colors[6]];
        case "text":
          return PRESET_B_TEXT_COLORS;
        default:
          return [colors[2], colors[3], colors[5], colors[6]];
      }
    case 5:
      return [colors[1], colors[2], colors[3], colors[5], colors[6]];
    case 6:
      return [colors[0], colors[1], colors[2], colors[3], colors[5], colors[6]];
    default:
      return Object.values(colors).slice(0, n);
  }
};
