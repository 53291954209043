import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { KanbanOrderResultType } from "../types";

export const createKanbanOrder = (data: {
  team: number | string;
  status: string | number;
  order: number[];
}): Promise<AxiosResponse<KanbanOrderResultType>> => {
  const params = new URLSearchParams();
  params.append("team", data.team.toString());

  return api.post<KanbanOrderResultType>(`tasks/kanban-order/`, { ...data }, { params });
};
