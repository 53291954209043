import { useFormik } from "formik";

import { MyAccountProps, ProfileResponse } from "../types";
import { updateProfileSchema } from "modules/settings/my-account/validation";

const useUpdateProfile = (profile: ProfileResponse, action: CallableFunction) => {
  return useFormik<MyAccountProps>({
    initialValues: { name: profile?.name || "" },
    onSubmit: () => action(),
    enableReinitialize: true,
    validationSchema: updateProfileSchema,
    validateOnChange: false,
  });
};
export default useUpdateProfile;
