import { create } from "zustand";
import { SideBarItems } from "shared/components/sidebar/types";

interface Sidebar {
  isToggle: boolean;
  setIsToggle: (isToggle: boolean) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  sideBarItems: Array<SideBarItems>;
  setSidebarItems: (sideBarItems: Array<SideBarItems>) => void;
}

export const useSidebar = create<Sidebar>((set) => ({
  isToggle: false,
  setIsToggle: (isToggle: boolean) => set({ isToggle }),
  isVisible: false,
  setIsVisible: (isVisible: boolean) => set({ isVisible }),
  sideBarItems: [],
  setSidebarItems: (sideBarItems: Array<SideBarItems>) => {
    set({ sideBarItems });
  },
}));
