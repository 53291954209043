import * as React from "react";

import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
} from "lexical";
import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";

import { SerializedWebBookmarkNode, WebBookmarkComponentProps } from "../types";

function WebBookmarkComponent({
  className,
  format,
  nodeKey,
  title,
  description,
  image,
  url,
}: WebBookmarkComponentProps) {
  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      <div>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex maxMd:flex-wrap justify-between border border-lightSilver dark:border-thunders p-4 rounded-lg"
        >
          <div>
            <h1 className="font-bold text-base dark:text-white text-mirage">{title}</h1>
            <div className="mt-2 text-aurometalsaurus text-sm dark:text-greychateau">
              {description}
            </div>
          </div>
          <div className="maxMd:mt-2">
            {image ? <img src={image} height={150} width={220} alt={title}></img> : null}
          </div>
        </a>
      </div>
    </BlockWithAlignableContents>
  );
}

function convertWebBookmarkElement(domNode: HTMLElement): null | DOMConversionOutput {
  const data = domNode.getAttribute("data-lexical-web-bookmark");
  if (data) {
    const jsonData = JSON.parse(data);

    const node = $createWebBookmarkNode(
      jsonData.title,
      jsonData.description,
      jsonData.image,
      jsonData.url
    );
    return { node };
  }
  return null;
}

export class WebBookmarkNode extends DecoratorBlockNode {
  __title: string;
  __description: string;
  __image: string;
  __url: string;

  static getType(): string {
    return "web-bookmark";
  }

  static clone(node: WebBookmarkNode): WebBookmarkNode {
    return new WebBookmarkNode(
      node.__title,
      node.__description,
      node.__image,
      node.__url,
      node.__format,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedWebBookmarkNode): WebBookmarkNode {
    const node = $createWebBookmarkNode(
      serializedNode.title,
      serializedNode.description,
      serializedNode.image,
      serializedNode.url
    );
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedWebBookmarkNode {
    return {
      ...super.exportJSON(),
      type: "web-bookmark",
      version: 1,
      title: this.__title,
      description: this.__description,
      image: this.__image,
      url: this.__url,
    };
  }

  constructor(
    title: string,
    description: string,
    image: string,
    url: string,
    format?: ElementFormatType,
    key?: NodeKey
  ) {
    super(format, key);
    this.__title = title;
    this.__description = description;
    this.__image = image;
    this.__url = url;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute(
      "data-lexical-web-bookmark",
      JSON.stringify({
        title: this.__title,
        description: this.__description,
        image: this.__image,
        url: this.__url,
      })
    );
    const text = document.createTextNode(this.getTextContent());
    element.append(text);
    return { element };
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute("data-lexical-web-bookmark")) {
          return null;
        }
        return {
          conversion: convertWebBookmarkElement,
          priority: 2,
        };
      },
    };
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    return this.__url;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };
    return (
      <WebBookmarkComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        title={this.__title}
        description={this.__description}
        image={this.__image}
        url={this.__url}
      />
    );
  }

  isInline(): false {
    return false;
  }
}

export function $createWebBookmarkNode(
  title: string,
  description: string,
  image: string,
  url: string
): WebBookmarkNode {
  return new WebBookmarkNode(title, description, image, url);
}

export function $isWebBookmarkNode(
  node: WebBookmarkNode | LexicalNode | null | undefined
): node is WebBookmarkNode {
  return node instanceof WebBookmarkNode;
}
