import jpgImg from "assets/images/documents/jpg-icon.svg";
import docxImg from "assets/images/documents/docx-icon.svg";
import pdfImg from "assets/images/documents/pdf-icon.svg";
import pngImg from "assets/images/documents/png-icon.svg";
import pptImg from "assets/images/documents/ppt-icon.svg";
import txtImg from "assets/images/documents/txt-icon.svg";
import xlsxImg from "assets/images/documents/xlsx-icon.svg";
import { ReactComponent as CloseIcon } from "assets/images/cross.svg";

function getImage(name: string) {
  const ext = name.split(".")[1];

  switch (ext) {
    case "jpg":
    case "jpeg":
    case "svg":
      return jpgImg;
    case "docx":
    case "doc":
      return docxImg;
    case "pdf":
      return pdfImg;
    case "png":
      return pngImg;
    case "ppt":
    case "pptx":
      return pptImg;
    case "txt":
      return txtImg;
    case "xlsx":
    case "xls":
      return xlsxImg;
    default:
      return txtImg;
  }
}

function getFileType(name: string) {
  const ext = name.split(".")[1];

  switch (ext) {
    case "jpg":
    case "jpeg":
    case "svg":
      return "Image";
    case "docx":
    case "doc":
      return "Word Document";
    case "pdf":
      return "PDF";
    case "png":
      return "Image";
    case "ppt":
    case "pptx":
      return "PowerPoint";
    case "txt":
      return "Text";
    case "xlsx":
    case "xls":
      return "Excel";
    default:
      return "File";
  }
}

const FileCard = ({ name, onClose }: { name: string; onClose?: () => void }) => {
  const img = getImage(name);
  const fileType = getFileType(name);

  return (
    <div className="overflow-x-auto my-2">
      <div className="metadata_card">
        <div className="metadata_file">
          <img
            src={img}
            alt="file"
            className="md:w-10 md:h-10 w-8 h-8 object-contain mr-2 md:mr-4"
          />
          <div className="meta_content">
            <p className="maxMd:text-sm dark:text-white">{name}</p>
            <p className="text-aurometalsaurus dark:text-white text-xs md:text-sm">{fileType}</p>
          </div>
        </div>
        {onClose ? (
          <div className="closeIcon">
            <CloseIcon onClick={onClose} data-testid="close_icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FileCard;
