import { Spinner } from "flowbite-react";

import clsx from "clsx";

import { ButtonIconProps } from "./types";

const ButtonIcon = ({ children, img, isLoading = false, ...rest }: ButtonIconProps) => {
  return (
    <button
      {...rest}
      data-testid="button-icon"
      type="button"
      className={clsx("button-icon", rest.className)}
    >
      <Spinner
        data-testid="button-icon-loader"
        size="md"
        light={true}
        hidden={!isLoading}
        className="mr-3"
      />
      {img ? (
        <img
          data-testid="button-image"
          src={img}
          alt={`${img}-icon`}
          width={28}
          height={28}
          className="mr-4"
        />
      ) : null}
      {children}
    </button>
  );
};

export default ButtonIcon;
