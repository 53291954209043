import React from "react";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardFooterProps = {
  children: React.ReactNode;
};

const GridCardFooter: React.FC<GridCardFooterProps> = ({ children }: GridCardFooterProps) => {
  return (
    <div data-testid="grid-card-footer" className={gridStyles.gridFooter}>
      {children}
    </div>
  );
};

export default GridCardFooter;
