import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { PaymentMethodResponse } from "../types";

export default function getPaymentMethod(
  id: number
): Promise<AxiosResponse<PaymentMethodResponse>> {
  return api.get<PaymentMethodResponse>(`companies/payment-methods/default/?company=${id}`);
}
