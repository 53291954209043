import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { GetArticleTeamActionResponse } from "../types";
import { convertArticleData } from "../helpers";

export const getArticleTeamActionById = async ({
  actionId,
  teamId,
}: {
  actionId: number;
  teamId: number;
}): Promise<AxiosResponse<GetArticleTeamActionResponse>> => {
  const params = new URLSearchParams();
  params.append("team", teamId?.toString());

  let response = await api.get<GetArticleTeamActionResponse>(
    `/articles/team-actions/${actionId}/`,
    {
      params,
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertArticleData(response.data),
    };
  }

  return response;
};
