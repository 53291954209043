import { persist } from "zustand/middleware";
import { create } from "zustand";
import { SelectedColumnModulesProps } from "./table-column-selection";

interface ColumnStoreState {
  companyColumnPreferences: Record<number, Partial<SelectedColumnModulesProps>>;
  setCompanyColumnPreferences: (
    companyId: number,
    data: Partial<SelectedColumnModulesProps>
  ) => void;
}

const useColumnStore = create(
  persist<ColumnStoreState>(
    (set) => ({
      companyColumnPreferences: {},
      setCompanyColumnPreferences: (companyId, data) =>
        set(
          (state: {
            companyColumnPreferences: Record<number, Partial<SelectedColumnModulesProps>>;
          }) => ({
            companyColumnPreferences: {
              ...state.companyColumnPreferences,
              [companyId]: data,
            },
          })
        ),
    }),
    {
      name: "columnStore",
      getStorage: () => localStorage,
    }
  )
);

export default useColumnStore;
