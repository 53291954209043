import { number, object, string } from "yup";

export const createGroupSchema = object({
  name: string().trim().max(255).required(),
  description: string(),
});

export const createGroupMember = object({
  email: object({
    label: string().required(),
    value: number().required(),
  }).required(),
});
