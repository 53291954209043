import { INCIDENTS_TABLE, MITIGATION_TABLE } from "shared/helpers/constant";
import { getUrlFromType } from "shared/helpers/getUrlFromType";

import { MetaTable } from "../types";

export default function getAPIForFetchComments(type: MetaTable) {
  const typeUrl = getUrlFromType(type);

  switch (type) {
    case MITIGATION_TABLE:
      return "risks/mitigations-comments/";
    case INCIDENTS_TABLE:
      return "risks/incidents-comments/";
    default:
      return `${typeUrl}/comments/`;
  }
}
