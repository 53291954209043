import { create } from "zustand";

interface WorkspaceModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}
export const useWorkspaceModal = create<WorkspaceModalProps>((set) => ({
  isModalOpen: false,
  setIsModalOpen: (status) => set({ isModalOpen: status }),
}));
