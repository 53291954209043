import { useFormik } from "formik";

import { UserRole } from "modules/home/types";

import { addMemberSchema } from "../validation";

const useMemberForm = (action: CallableFunction) => {
  return useFormik<{ email: string; is_admin: string; role: UserRole }>({
    initialValues: {
      email: "",
      is_admin: "Member",
      role: "GUEST",
    },
    validateOnChange: false,
    validationSchema: addMemberSchema,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useMemberForm;
