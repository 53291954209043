export const APP_ID = "shil6594"

export const load = () => {
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
}

export const boot = (options = {}) => {
    window &&
        window.Intercom &&
        window.Intercom("boot", { api_base: "https://api-iam.intercom.io", app_id: APP_ID, ...options })
}

export const update = () => {
    window && window.Intercom && window.Intercom("update")
}
//hide intercom
export const close = () => {
    window && window.Intercom && window.Intercom("hide")
}
