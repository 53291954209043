import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyMember } from "shared/context/types/company";

import { UpdateMemberPermissionProps } from "../types";
import { ADMIN_ROLE, MEMBER_ROLE } from "shared/helpers/constant";

export default function updateMember(
  data: UpdateMemberPermissionProps
): Promise<AxiosResponse<CompanyMember>> {
  const params = new URLSearchParams();

  params.append("company", `${data.company}`);
  params.append("team", `${data.team}`);

  const role = data.is_admin ? ADMIN_ROLE : MEMBER_ROLE;

  return api.patch<CompanyMember>(
    `companies/members/${data.id}/`,
    {
      is_admin: data.is_admin,
      team: data.team,
      role,
    },
    { params }
  );
}
