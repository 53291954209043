import clsx from "clsx";

import styles from "assets/css/task.module.css";
import LexicalEditor from "shared/components/lexical-editor";
import TaskEditor from "shared/components/lexical-editor/task-editor/TaskEditor";

import TaskLabel from "../shared/TaskLabel";
import { memo, useRef } from "react";

const TaskDescriptionComponent = ({
  onFieldChanged,
  onMentionChange,
  description,
}: {
  onFieldChanged: (fieldName: string, data: string) => void;
  onMentionChange: (fieldName: string, data: string[]) => void;
  description: string;
}) => {
  // ! DO NOT REMOVE THIS REF, IT PREVENTS THE CURSOR FROM JUMPING TO THE END OF THE TEXT
  const editorState = useRef(description);

  return (
    <div className={clsx(styles.tasksEditor, "border-b dark:border-thunders create_task")}>
      <TaskLabel title="Description" />
      <LexicalEditor
        editorState={editorState.current || null}
        onChange={(content) => onFieldChanged(`description`, content)}
        type="task"
      >
        {() => (
          <TaskEditor
            onMentionChange={(mentions: string[]) => onMentionChange(`mentions`, mentions)}
            placeholder="Press '/' for commands..."
          />
        )}
      </LexicalEditor>
    </div>
  );
};

export const TaskDescription = memo(TaskDescriptionComponent);
