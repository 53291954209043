import { MultiValue } from "react-select";

import { TeamMemberOptionProps, optionType } from "shared/components/metadata/types";

export const isOptionType = (
  data?:
    | number
    | string[]
    | string
    | MultiValue<optionType>
    | MultiValue<TeamMemberOptionProps>
    | null
): data is optionType[] => {
  if (
    data &&
    typeof data !== "number" &&
    typeof data !== null &&
    data?.length !== 0 &&
    typeof data[0] !== "string"
  )
    if ("label" in data[0] && "value" in data[0]) return true;

  return false;
};
