import React, { SetStateAction } from "react";

import ModuleTitle from "shared/components/module-title/ModuleTitle";
import Icon from "shared/components/icon/Icon";
import { useCompany } from "../../../../shared/context/CompanyProvider";
import { useWorkspaceModal } from "../../../../shared/store/trial";

const StatusTitle = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (state: SetStateAction<boolean>) => void;
}) => {
  const { currentCompany } = useCompany();
  const setIsTrialModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  return (
    <ModuleTitle label="Task Statuses">
      <button
        onClick={() =>
          currentCompany?.trial_expired
            ? setIsTrialModalOpen(true)
            : setIsModalOpen((isModalOpen: boolean) => !isModalOpen)
        }
        className="maxMd:mt-2.5 maxMd:w-full maxMd:justify-center h-10 btn_primary flex items-center gap-x-1"
        type="button"
      >
        <Icon type="plus" fill={true} size="icon-smd" className="themesvg_white" />
        Create Status
      </button>
    </ModuleTitle>
  );
};
export default StatusTitle;
