import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { SubContractorResultProps } from "../types";
import { convertAgreementSubContractorData } from "../helpers";
import { show_all_objects } from "shared/helpersV2/constant";

export default async function getSubContractors(
  agreementId: number,
  permissions: { team: TeamParameter },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<SubContractorResultProps[]>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<SubContractorResultProps[]>(
    `agreements/${agreementId}/subcontractors/`,
    {
      params: {
        ...{
          team: teamParameter,
          company: permissions.team?.company,
          ...hasNameOrdering(requestParams),
          show_all_objects,
        },
      },
    }
  );
  if (response && response.data && response.data?.length > 0) {
    response = {
      ...response,
      data:
        response.data?.map((data) => {
          return convertAgreementSubContractorData(data);
        }) || [],
    };
  }
  return response;
}
