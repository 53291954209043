import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export default function deleteFilter(
  id: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`core/filters/${id}/`, { params: { ...permissions } });
}
