import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { NotificationType } from "shared/store/header";

export const updateNotification = (
  id: number,
  isRead: boolean
): Promise<AxiosResponse<NotificationType>> => {
  return api.patch<NotificationType>(`/notifications/${id}/`, { is_read: isRead });
};
