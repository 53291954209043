import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetAllIncidentsRequestParams, PaginatedIncidentsListResponse } from "../types";
import { convertIncidentData } from "../helpers";

export const getIncidents = async (
  requestParams: GetAllIncidentsRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PaginatedIncidentsListResponse>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<PaginatedIncidentsListResponse>(`risks/incidents/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{
        team: teamParameter,
        company: permissions.team?.company,
      },
    },
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertIncidentData(data);
          }) || [],
      },
    };
  }
  return response;
};
