import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { CompanyUserResponse } from "modules/home/types";

import { PermissionProps } from "../../authentication/types";
import { TeamResponse, TeamInputData } from "../types";

export const addTeam = (data: TeamInputData, company: number, teamIcon?: File | null) => {
  const params = new URLSearchParams();
  params.append("company", company.toString());
  const formData = new FormData();
  formData.append("name", data["name"]);
  formData.append("description", data["description"]);
  formData.append("company", JSON.stringify(company));
  if (teamIcon) {
    formData.append("team_picture", teamIcon);
  }
  return api.post("auth/teams/", formData, { params });
};

export const getTeam = (id: number, requestParams?: { ordering?: string }) => {
  return api.get(`auth/teams/?company=${id}`, { params: { ...hasNameOrdering(requestParams) } });
};

export const addTeamMembers = (id: number | null, companyId: number, data: number[]) =>
  api.patch(`/auth/teams/${id}/add-members/?company=${companyId}`, { members: data });

export const updateTeam = (companyId: number, data: TeamResponse) => {
  return api.patch(`auth/teams/${data.id}/?company=${companyId}`, {
    custom_permissions: data.custom_permissions,
  });
};

export const updateTeamProfile = (
  id: number,
  companyId: number,
  data: TeamInputData,
  team_picture: File | null
) => {
  const formData = new FormData();
  formData.append("name", data["name"]);
  formData.append("description", data["description"]);
  if (team_picture) {
    formData.append("team_picture", team_picture);
  }
  return api.patch(`auth/teams/${id}/?company=${companyId}`, formData);
};

export const deleteTeam = (id: number, companyId: number) => {
  return api.delete(`auth/teams/${id}/?company=${companyId}`);
};

export const updateTeamMemberPermission = (
  teamId: number,
  data: CompanyUserResponse | TeamResponse,
  companyId: number,
  user?: number
) => {
  return api.patch(`auth/teams/${teamId}/set-member-permissions/?company=${companyId}`, {
    user,
    custom_permissions: data.custom_permissions,
  });
};

export const getTeamMemberPermission = (
  team: number,
  company: number,
  member: number
): Promise<AxiosResponse<PermissionProps>> => {
  const params = new URLSearchParams();
  params.append("company", `${company}`);
  params.append("user", `${member}`);
  return api.get<PermissionProps>(`auth/teams/${team}/get-member-permissions`, { params });
};

export const removeTeamMember = (
  id: number,
  companyId: number,
  members: number[]
): Promise<AxiosResponse<{ success: boolean }>> => {
  return api.patch<{ success: boolean }>(`auth/teams/${id}/remove-members/?company=${companyId}`, {
    members,
  });
};
