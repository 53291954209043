import React, { useEffect, useState } from "react";

import isNumber from "lodash/isNumber";

import headerStyles from "assets/css/common-header.module.css";

import { FILTER_TYPE, GTE_OPERATOR } from "shared/helpers/constant";

import { FilterOptionType, FilterValues } from "./types";
import UnitValueInput from "./UnitValueInput";

export default function UnitValueFilter({
  field,
  operator,
  onClick,
  single_option,
  values,
  label,
}: {
  field: string;
  operator?: string;
  onClick: (e: FilterOptionType) => void;
  single_option: boolean;
  values?: FilterValues[];
  label: string;
}) {
  const [unitField, valueField] = field.split(" ");
  const [selectedUnit, setSelectedUnit] = useState<string>(
    values && values?.length > 0 ? values[0]?.value : ""
  );
  const [selectedOperator, setSelectedOperator] = useState<string>(GTE_OPERATOR);
  const [value, setValue] = useState<number | string | null>("");

  const displayName = () => {
    return `${label} ${values?.find((data) => data.value === selectedUnit)?.displayName} ${
      isNumber(value) ? (selectedOperator === GTE_OPERATOR ? " >= " : " <= ") + value : ""
    }`;
  };

  const getConditions = () => {
    const unitCondition = [
      {
        field: unitField,
        operator: operator,
        value: selectedUnit,
      },
    ];
    if (isNumber(value)) {
      return [
        ...unitCondition,
        {
          field: valueField,
          operator: selectedOperator,
          value: `${value}`,
        },
      ];
    }

    return unitCondition;
  };

  useEffect(() => {
    setSelectedUnit(values ? values[0]?.value || "" : "");

    return () => {
      setSelectedOperator(GTE_OPERATOR);
      setValue("");
    };
  }, [values, field]);

  return (
    <div className={headerStyles.unitFilter}>
      <UnitValueInput
        field={field}
        value={value}
        selectedOperator={selectedOperator}
        selectedUnit={selectedUnit}
        unitValues={values}
        handleChangeUnitValue={({ unit, operator, value }) => {
          setSelectedUnit(unit);
          setSelectedOperator(operator);
          setValue(value);
        }}
      />
      <div className="!w-fit maxMd:!w-full mt-2">
        <button
          type="submit"
          className={headerStyles.btn_primary__imp}
          data-testid="filter_submit"
          onClick={() => {
            onClick({
              key: `${selectedUnit} ${selectedOperator} ${value}`,
              label: <span>{displayName()}</span>,
              value: `${value}`,
              field: valueField,
              type: FILTER_TYPE,
              operator: operator,
              conditions: getConditions(),
              single_option: single_option,
            });
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
