import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { GetTaskResultType } from "shared/types";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { convertTaskData } from "../helpers";
import { GetAllTaskRequestParams } from "../types";

export const getAllTasks = async (
  requestParams: GetAllTaskRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<Array<GetTaskResultType>>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<Array<GetTaskResultType>>(`tasks/all/`, {
    params: {
      ...requestParams,
      ...{
        team: teamParameter,
        company: permissions.team?.company,
        ...hasNameOrdering(requestParams),
      },
    },
  });

  if (response && response.data && response.data?.length > 0) {
    response = {
      ...response,
      data:
        response.data?.map((data) => {
          return convertTaskData(data);
        }) || [],
    };
  }

  return response;
};
