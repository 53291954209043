import { CompanyProps } from "shared/context/types/company";

/**
 * Should show upgrade button if company is not enterprise or company has not got active subscription
 * @param company
 */
export default function showUpgradeButton(company: CompanyProps): boolean {
  if (company.is_enterprise) {
    return false;
  }
  return !(company.subscription && company.subscription.status === "active");
}
