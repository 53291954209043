import getFilteredAPIData from "shared/helpers/getFilteredAPIData";

const fieldsList = [
  { key: "agreement_type", value: "id" },
  { key: "category", value: "id" },
  { key: "party_to_agreement", value: "id" },
  { key: "internal_supplier", value: "id" },
  { key: "external_supplier", value: "id" },
  { key: "enterprise_validation", value: "id" },
  { key: "parent", value: "id" },
  { key: "assignees", value: "id" },
  { key: "subcontractors", value: "id" },
  { key: "receivers_provided_service", value: "id" },
  { key: "tags", value: "slug" },
];

export default function convertAgreementData<TData>(data: TData): TData {
  return getFilteredAPIData(data, fieldsList);
}
