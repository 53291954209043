import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";

import {
  ActionsType,
  FiltersProp,
  RefetchOptions,
  SearchFields,
} from "shared/components/common-table/types";
import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  DATETIME_FILTER,
  DATE_FILTER,
  ICONTAINS_OPERATOR,
  IN_OPERATOR,
  PAGINATED_FILTER,
  TEXT_FILTER,
} from "shared/helpers/constant";
import { useTeam } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import getQueryParamsFromRefetchOptions from "shared/helpers/getQueryParamsFromRefetchOptions";
import { GetTaskResultType } from "shared/types";
import { getTaskResultData } from "shared/helpers/getTaskResultData";
import useTagsList from "shared/hooks/useTagsList";
import { usePaginationSearch } from "shared/store/commonTable";
import convertMemberIdToUserId from "shared/helpers/convertMemberIdToUserId";

import { TaskStatusResultType } from "modules/tasks/statuses/types/index";

import trash from "assets/images/icons/trash.svg";
import edit from "assets/images/edit.svg";

import { KanbanOrderResultType, TaskResultType } from "../types/index";
import { getKanbanOrder, getKanbanTasks } from "../api";
import useGetStatus from "./useGetStatus";

const useTaskGridView = () => {
  const { currentCompany, usersList } = useCompany();
  const currentTeam = useTeam((state) => state.currentTeam);
  const { paginationSearchData } = usePaginationSearch();

  const {
    data: tagsResponse,
    isLoading: isLoadingTags,
    hasNextPage: hasTagsNextPage,
    isFetchingNextPage: isFetchingTagsNextPage,
    fetchNextPage: fetchTagsNextPage,
    isFetching: isTagsFetching,
  } = useTagsList(
    currentTeam?.id,
    currentTeam.company,
    currentTeam.is_all_team,
    paginationSearchData?.task?.tags
  );

  const permission = {
    team: currentTeam?.id,
  };
  const {
    data: statusResponse,
    hasNextPage: hasStatusNextPage,
    isFetchingNextPage: isFetchingStatusNextPage,
    fetchNextPage: fetchStatusNextPage,
    isLoading: isStatusLoading,
    isFetching: isStatusFetching,
  } = useGetStatus(
    "getStatusGridview",
    { team: currentTeam?.id, company: currentTeam.company, isAllTeam: currentTeam.is_all_team },
    currentCompany?.id,
    paginationSearchData?.task?.status
  );

  const [filterOptions, setFilterOptions] = useState<RefetchOptions[]>([]);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<TaskResultType[]>([]);
  const [statusId, setStatusId] = useState<number>(0);
  const [taskList, setTaskList] = useState<TaskStatusResultType[]>([]);
  const [kanbanOrder, setKanbanOrder] = useState<KanbanOrderResultType[]>([]);

  const navigate = useNavigate();

  const filters: FiltersProp[] = useMemo(
    () => [
      {
        label: "Status",
        field: "status",
        values: statusResponse?.pages?.flatMap((item) =>
          item?.data?.results?.map((status: { id: number; name: string }) => ({
            key: status?.id,
            value: status?.name,
          }))
        ),
        type: PAGINATED_FILTER,
        operator: IN_OPERATOR,
        single_option: false,
        isLoading: isStatusLoading,
        isFetchingNextPage: isFetchingStatusNextPage,
        hasNextPage: hasStatusNextPage,
        fetchNextPage: fetchStatusNextPage,
        isFetchingData: isStatusFetching,
        responseDataDetails: {
          endpoint: "tasks/status/",
          params: {
            team: currentTeam?.is_all_team ? "all" : currentTeam?.id,
            company: currentTeam.company,
          },
        },
      },
      {
        label: "Name",
        field: "name",
        type: TEXT_FILTER,
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Description",
        field: "description",
        type: TEXT_FILTER,
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Due Date",
        field: "due_date",
        type: DATE_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
      {
        label: "Tags",
        field: "tags",
        values: tagsResponse?.pages?.flatMap((item) =>
          item?.data?.results?.map((tagRes: { id: number; name: string }) => ({
            key: tagRes?.id,
            value: tagRes?.name,
          }))
        ),
        type: PAGINATED_FILTER,
        operator: IN_OPERATOR,
        single_option: false,
        isLoading: isLoadingTags,
        hasNextPage: hasTagsNextPage,
        fetchNextPage: fetchTagsNextPage,
        isFetchingData: isTagsFetching,
        isFetchingNextPage: isFetchingTagsNextPage,
        responseDataDetails: {
          endpoint: "core/tags/",
          params: {
            team: currentTeam?.is_all_team ? "all" : currentTeam?.id,
            company: currentTeam.company,
          },
        },
      },
      {
        label: "Created At",
        field: "created_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
      {
        label: "Updated At",
        field: "updated_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
    ],
    [statusResponse, tagsResponse]
  );

  const columns = useMemo<ColumnDef<TaskStatusResultType, unknown>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Description",
        accessorKey: "description",
        cell: (info) => info.getValue() || "N/A",
      },
      {
        header: "Deadline",
        accessorKey: "due_date",
        accessorFn: (row: GetTaskResultType) => {
          return row?.due_date;
        },
      },
      {
        header: "Status",
        accessorFn: (row: GetTaskResultType) => {
          return row?.status?.name ? row?.status?.name : "N/A";
        },
        accessorKey: "status",
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        accessorFn: (row: GetTaskResultType) => {
          return row?.created_at;
        },
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        accessorFn: (row: GetTaskResultType) => {
          return row?.updated_at;
        },
      },
    ],
    []
  );

  const {
    isLoading: orderLoading,
    isFetching: orderFetching,
    refetch: refetchOrderData,
  } = useQuery(
    ["getKanbanOrder", currentCompany.id, currentTeam?.id],
    () => getKanbanOrder(currentCompany.id, currentTeam?.id),
    {
      onSuccess: (res) => {
        setKanbanOrder(res?.data);
      },
      enabled: false,
    }
  );

  const {
    isLoading: taskLoading,
    isFetching: taskFetching,
    refetch: refetchTasksData,
  } = useQuery(
    ["getKanbanTasks", currentCompany.id, currentTeam?.id, filterOptions],
    () =>
      getKanbanTasks(
        getQueryParamsFromRefetchOptions(
          convertMemberIdToUserId({ usersList, options: filterOptions })
        ),
        permission
      ),
    {
      onSuccess: (res) => {
        refetchOrderData();

        setTaskList(Object.assign([], [], res.data));
      },
      enabled: !!currentTeam?.id,
    }
  );
  const refetchTasks = useCallback((options: RefetchOptions[]) => {
    setFilterOptions(options);
  }, []);

  const deleteTask = (data: TaskResultType[]) => {
    setToBeDeleted([...data]);
    setIsDeleteModalOpen(true);
  };

  const editTask = (data: TaskResultType[]) => {
    if (data?.length) {
      navigate(`/tasks/${data[0].id}/edit`);
    }
  };

  const actions: ActionsType<TaskStatusResultType>[] = useMemo(
    () => [
      { key: "Edit", icon: edit, action: (data) => editTask(getTaskResultData(data)) },
      { key: "Delete", icon: trash, action: (data) => deleteTask(getTaskResultData(data)) },
    ],
    []
  );

  const searchFields = useMemo<SearchFields[]>(
    () => [
      {
        label: "Name",
        value: "name",
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Description",
        value: "description",
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
    ],
    []
  );

  const hasStatusInOrder = (statusId: number | string) => {
    return kanbanOrder?.find((order) => order.status?.toString() === statusId?.toString());
  };
  const statuses = useMemo(() => {
    return statusResponse?.pages?.flatMap((item) => item?.data?.results || []);
  }, [statusResponse]);

  return {
    hasStatusInOrder,
    refetchOrderData,
    taskList,
    setTaskList,
    filterOptions: filterOptions,
    statuses,
    statusId,
    setStatusId,
    isLoading: taskLoading || taskFetching || orderLoading || orderFetching,
    columns,
    filters,
    actions,
    searchFields,
    isEditModalOpen,
    setIsEditModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    toBeDeleted,
    refetchTasksData,
    refetchTasks,
  };
};

export default useTaskGridView;
