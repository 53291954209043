import React from "react";

import style from "assets/css/settings.module.css";

import SettingHeader from "../component/SettingHeader";
import SettingsWithSidebarRoutes from "../SettingsWithSidebarRoutes";

const WithSettingSidebar = () => (
  <>
    <SettingHeader />
    <div className={style.setting_container}>
      <SettingsWithSidebarRoutes />
    </div>
  </>
);

export default WithSettingSidebar;
