import { create } from "zustand";

interface questionnaireMenuBar {
  toggleMenuBar: boolean;
  setToggleMenuBar: (isToggle: boolean) => void;
}

export const useQuestionnaireMenuBar = create<questionnaireMenuBar>((set) => ({
  toggleMenuBar: false,
  setToggleMenuBar: (toggleMenuBar: boolean) => set({ toggleMenuBar }),
}));
