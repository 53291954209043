import { CommentAttachmentType } from "../types";
import FileCard from "./FileCard";
import ImageCard from "./ImageCard";

const Attachment = ({ attachment }: { attachment: CommentAttachmentType }) => {
  const isImage = attachment.filetype.match(/(jpeg|jpg|gif|png|svg)$/) !== null;

  return (
    <div>
      {isImage ? (
        <ImageCard
          download_url={attachment.url}
          filename={attachment.filename}
          url={attachment.url}
        />
      ) : null}
      {!isImage ? (
        <a href={attachment.url} target="_blank" rel="noopener noreferrer">
          <FileCard name={attachment.filename} />
        </a>
      ) : null}
    </div>
  );
};

export default Attachment;
