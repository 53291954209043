import React, { useState } from "react";

import clsx from "clsx";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { ArticleImpactColor, ArticleImpact } from "modules/horizonScanning/types";
import { DropdownItemProps } from "shared/typesV2";
import Icon from "shared/components/icon/Icon";
import { useArticleDetails } from "modules/horizonScanning/article-details/ArticleDetails";

const ArticleImpactDropdown = ({ disabled }: { disabled?: boolean }) => {
  const [openComboBox, setOpenComboBox] = useState(false);
  const { article, updateArticle } = useArticleDetails();

  const impactDropdownData: DropdownItemProps[] = Object.entries(ArticleImpact).map(
    ([article_impact, label]) => ({
      id: article_impact,
      title: label,
      iconClassName: ArticleImpactColor[article_impact as keyof typeof ArticleImpact],
    })
  );

  return (
    <DropdownMenu.Root open={openComboBox} onOpenChange={setOpenComboBox}>
      <DropdownMenu.Trigger
        asChild
        disabled={disabled || !article.is_active}
        className="rounded-lg"
      >
        <button
          disabled={disabled || !article.is_active}
          className="flex w-fit px-0 py-0.5 border-none rounded-lg justify-between items-center disabled:text-santagrey"
        >
          <div className="flex items-center gap-2">
            {typeof article.impact === "string" &&
            article.impact &&
            article.impact !== "not_set" ? (
              <>
                {impactDropdownData?.map((item) => {
                  if (item.id === article.impact) {
                    const { id, title, iconClassName } = item;

                    return (
                      <div className="flex gap-2 items-center" key={id}>
                        <Icon type="arrows-pointing-in" fill badgeColor={iconClassName} />
                        <span
                          className={clsx("text-sm font-medium", {
                            "text-richblack dark:text-lighthouse": !disabled,
                            "text-santagrey dark:text-gray_400": disabled,
                          })}
                        >
                          {title}
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
                <Icon type="chevron-down" size="icon-sm" fill={true} />
              </>
            ) : (
              <>
                <span className="text-sm font-inter-medium text-santagrey dark:text-gray_400">
                  Set the impact
                </span>
                <Icon type="chevron-down" size="icon-sm" fill={true} />
              </>
            )}
          </div>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="DropdownMenuContent min-w-[10rem] w-fit"
        sideOffset={5}
        side={"bottom"}
        align={"start"}
      >
        {impactDropdownData?.map((option) => {
          const { id, title, iconClassName } = option;

          return (
            <DropdownMenu.Item
              key={id}
              className="DropdownMenuItem"
              onClick={() => {
                updateArticle?.({ impact: String(id) });
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center">
                  <Icon type="arrows-pointing-in" fill badgeColor={iconClassName} />
                  <span>{title}</span>
                </div>
                {id === article.impact ? (
                  <Icon type="checkmark" fill={true} size="icon-sm" />
                ) : null}
              </div>
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ArticleImpactDropdown;
