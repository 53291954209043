import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PolicyFileResponse } from "../types";
import { TeamParameter } from "shared/types";

export default function getPolicyFiles(
  id: string | number,
  permissions: { team: TeamParameter },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<PolicyFileResponse[]>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<PolicyFileResponse[]>(`policies/${id}/files/`, {
    params: {
      ...{
        team: teamParameter,
        company: permissions.team?.company,
        ...hasNameOrdering(requestParams),
      },
    },
  });
}
