import React, { useEffect, useState } from "react";

import { Spinner, TextInput } from "flowbite-react";
import clsx from "clsx";
import toString from "lodash/toString";

import headerStyles from "assets/css/common-header.module.css";
import { FILTER_TYPE } from "shared/helpers/constant";
import { PaginatedFetchNextPageResultType } from "shared/types";
import { DEBOUNCE_DELAY } from "shared/helpersV2/constant";
import { usePaginationSearch } from "shared/store/commonTable";

import ShowableItem from "modules/risks/shared/associated-connect-modal/ShowableItem";

import { FilterOptionType, FilterValues } from "../../types";
import { getPaginatedSearchDataResult } from "../../helpers";

export type PaginatedFilterProps = {
  field: string;
  operator: string;
  items: FilterValues[];
  single_option: boolean;
  onClick: (data: FilterOptionType) => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => PaginatedFetchNextPageResultType;
  name: string;
  isLoading?: boolean;
  refetchPaginationData?: CallableFunction;
  isFetchingData?: boolean;
};

export default function PaginatedFilter({
  field,
  operator,
  items,
  single_option,
  onClick: handleClick,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  name,
  isLoading,
  isFetchingData,
}: PaginatedFilterProps) {
  const [searchString, setSearchString] = useState<string>("");
  const [data, setData] = useState<FilterValues[]>(items || []);
  const [offset, setOffset] = useState<number>(1);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [paginationSearchData, setPaginationSearchData] = usePaginationSearch((state) => [
    state.paginationSearchData,
    state.setPaginationSearchData,
  ]);

  useEffect(() => {
    setData(items);
    setIsFetching(false);
  }, [items]);

  useEffect(() => {
    handleSearch("");
    return () => {
      refetchData("");
      setSearchString("");
    };
  }, [field]);

  const handleScroll = async (e: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (
      scrollHeight - scrollTop <= clientHeight * 1.2 &&
      hasNextPage &&
      !isFetchingNextPage &&
      !isFetching &&
      fetchNextPage
    ) {
      setIsFetching(true);
      await fetchNextPage();
    }
  };

  const refetchData = (searchData: string) => {
    setPaginationSearchData(
      getPaginatedSearchDataResult(paginationSearchData, name, field, searchData)
    );
  };

  const handleSearch = (searchData: string) => {
    setSearchString(searchData);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimer = setTimeout(() => {
      refetchData(searchData);
    }, DEBOUNCE_DELAY);

    setDebounceTimeout(newTimer);
  };

  return (
    <>
      <TextInput
        data-testid="filterInput"
        className={clsx(headerStyles.formInput, "px-4")}
        value={searchString}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <ul
        className="py-1 overflow-y-auto overflow-x-hidden px-4 max-h-[390px]"
        onScroll={handleScroll}
      >
        {data?.length > 0 && !isLoading
          ? data.map((item) => (
              <li
                key={toString(item.key)}
                className={clsx(
                  "flex",
                  "items-center",
                  "justify-start",
                  "py-2",
                  "md:px-4",
                  "text-sm",
                  "text-gray-700",
                  "cursor-pointer",
                  "hover:bg-gray-100",
                  "dark:text-gray-200",
                  "dark:hover:bg-gray-600",
                  "dark:hover:text-white"
                )}
                aria-label="button"
                onClick={() => {
                  setSearchString("");
                  handleClick({
                    field,
                    operator,
                    key: item.key,
                    label: <span>{item.value}</span>,
                    value: item.value,
                    type: FILTER_TYPE,
                    conditions: [
                      {
                        field,
                        operator,
                        value: toString(item.key),
                      },
                    ],
                    single_option: single_option,
                  });
                }}
              >
                <span className="filter_search_truncate">{item.value}</span>
              </li>
            ))
          : null}
        {isFetchingData || isLoading ? (
          <div className="flex w-full justify-center items-center">
            <Spinner size="sm" className="ml-1 !fill-mirage stroke-mirage " />
          </div>
        ) : null}
        {data?.length === 0 && !isLoading && !isFetchingData ? (
          <span
            className={clsx(
              "flex",
              "items-center",
              "justify-center",
              "py-2",
              "md:px-4",
              "text-sm",
              "text-gray-700",
              "cursor-default",
              "dark:text-gray-200"
            )}
          >
            No Data Available
          </span>
        ) : null}
        {offset * items.length < items.length ? (
          <ShowableItem
            onShow={() => {
              setOffset((prev) => prev + 1);
            }}
          />
        ) : null}
      </ul>
    </>
  );
}
