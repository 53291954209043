import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Tooltip } from "flowbite-react";

import Icon from "shared/components/icon/Icon";

import Button from "../form-controls/button";
import { ReactNode } from "react";

export type FavoriteDropdownProps = {
  disableShare?: boolean;
  disableDelete?: boolean;
  onAction?: (action: "share" | "delete") => void;
  additionalActions?: ReactNode;
};
function FavoriteDropDown({
  onAction,
  disableShare,
  disableDelete,
  additionalActions,
}: FavoriteDropdownProps) {
  const hideMenu = disableShare && disableDelete && !additionalActions;

  if (hideMenu) {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div>
          <Tooltip content="More options" className="metadata_tooltip" arrow={false}>
            <Button
              btnType="plain"
              btnSize="sm"
              className="focus:!border-transparent focus:!bg-transparent aria-expanded:!bg-antiflashwhite dark:aria-expanded:!bg-tricornblack"
              icon={
                <Icon
                  type="ellipsis-vertical"
                  fill={true}
                  size="icon-smd"
                  className="three_dots_icon"
                />
              }
            />
          </Tooltip>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="DropdownMenuContent w-[208px]"
          sideOffset={5}
          side="bottom"
          align="end"
        >
          {disableShare ? null : (
            <DropdownMenu.Item className="DropdownMenuItem" onClick={() => onAction?.("share")}>
              <div className="flex gap-2 items-center">
                <span>
                  <Icon type="user-plus" fill={true} size="icon-sm" className="theme_icon" />
                </span>
                <span>Share</span>
              </div>
            </DropdownMenu.Item>
          )}
          {disableDelete ? null : (
            <DropdownMenu.Item className="DropdownMenuItem" onClick={() => onAction?.("delete")}>
              <div className="flex gap-2 items-center">
                <span>
                  <Icon type="trash-new" fill={true} size="icon-sm" className="theme_icon" />
                </span>
                <span>Delete</span>
              </div>
            </DropdownMenu.Item>
          )}
          {additionalActions}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
export default FavoriteDropDown;
