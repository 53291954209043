import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { PaginationDropdownQueryParams } from "shared/types";

import { PaginatedIndustries } from "../types";

export default function getIndustries(
  requestParams?: PaginationDropdownQueryParams
): Promise<AxiosResponse<PaginatedIndustries>> {
  return api.get<PaginatedIndustries>("core/industries/", {
    params: { ...hasNameOrdering(requestParams) },
  });
}
