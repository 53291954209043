import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { DeleteAggrementByIdProps } from "../types";
import { show_all_objects } from "shared/helpersV2/constant";

export default function deleteAggrementById(
  data: DeleteAggrementByIdProps,
  permissions: { team: TeamId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`agreements/${data.agreementId}/`, {
    params: { ...permissions, show_all_objects },
  });
}
