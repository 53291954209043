import { useFormik } from "formik";

import { changeEmailSchema } from "../../validation";
import { ChangeEmailProps } from "../types";

const useChangeEmail = (action: CallableFunction) => {
  return useFormik<ChangeEmailProps>({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: false,
    validationSchema: changeEmailSchema,
    onSubmit: () => action(),
  });
};

export default useChangeEmail;
