export default function generateUniqueId(size = 21) {
  let id = "";
  const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;

  for (let i = 0; i < size; i++) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return id;
}
