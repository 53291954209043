import React from "react";

import clsx from "clsx";
import { LexicalEditor as LexicalEditorType } from "lexical";

import styles from "assets/css/task.module.css";

import { SubmitTaskComment } from ".";
import LexicalEditor from "../lexical-editor";
import { CreateCommentType } from "../metadata/types";
import TaskCommentEditor from "../lexical-editor/task-comment-editor";
import { GetTaskResultType } from "shared/types";

interface CreateCommentProps {
  onCommentAdd: (data: CreateCommentType) => void;
  task: GetTaskResultType;
}

function CreateCommentForm({ onCommentAdd, task }: CreateCommentProps) {
  return (
    <div className={clsx(styles.taskCommentEditor, "border-b dark:border-thunders")}>
      <LexicalEditor>
        {() => (
          <TaskCommentEditor placeholder="Add a comment">
            {(editor: LexicalEditorType) => (
              <SubmitTaskComment
                onCommentAdd={onCommentAdd}
                editor={editor}
                type="task"
                id={task?.id?.toString() || ""}
              />
            )}
          </TaskCommentEditor>
        )}
      </LexicalEditor>
    </div>
  );
}

export default CreateCommentForm;
