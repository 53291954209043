import { create } from "zustand";

import { Directory, Document, DocumentResponse } from "modules/documents/type";

interface Documents {
  documentsList: DocumentResponse;
  setDocumentsList: (documentsList: DocumentResponse) => void;
  isTableView: boolean;
  setIsTableView: (isTableView: boolean) => void;
  selectedDocuments: (Document | Directory | undefined)[];
  setSelectedDocuments: (selectedDocuments: (Document | Directory | undefined)[]) => void;
  selectedDirectory: Directory | null;
  setSelectedDirectory: (selectedDirectory: Directory | null) => void;
}

export const useDocuments = create<Documents>((set) => ({
  documentsList: {} as DocumentResponse,
  setDocumentsList: (documentsList: DocumentResponse) => set({ documentsList }),
  isTableView: true,
  setIsTableView: (isTableView: boolean) => set({ isTableView }),
  selectedDocuments: [],
  setSelectedDocuments: (selectedDocuments: (Document | Directory | undefined)[]) =>
    set({ selectedDocuments }),
  selectedDirectory: null,
  setSelectedDirectory: (selectedDirectory: Directory | null) => set({ selectedDirectory }),
}));

interface DirecotryModal {
  isDirectoryModalopen: boolean;
  setIsDirectoryModalopen: (isDirectoryModalopen: boolean) => void;
}

export const useDirectory = create<DirecotryModal>((set) => ({
  isDirectoryModalopen: false,
  setIsDirectoryModalopen: (isDirectoryModalopen: boolean) => set({ isDirectoryModalopen }),
}));

export type DirectoryTreeProps = {
  id: number | null;
  name: string;
  children?: DirectoryTreeProps[];
};
interface DirectoryTree {
  directoryTree: DirectoryTreeProps;
  setDirectoryTree: (isDirectoryModalopen: DirectoryTreeProps) => void;
}

export const useDirectoryTree = create<DirectoryTree>((set) => ({
  directoryTree: {} as DirectoryTreeProps,
  setDirectoryTree: (directoryTree: DirectoryTreeProps) => set({ directoryTree }),
}));
