import { useFormik } from "formik";
import { object, string } from "yup";

const useAddDateFilter = (action: CallableFunction) => {
  return useFormik<{ from: string; to: string }>({
    initialValues: {
      from: "",
      to: "",
    },
    validateOnChange: false,
    validationSchema: addDateSchema,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useAddDateFilter;
export const addDateSchema = object({
  from: string().required(),
  to: string().required(),
});
