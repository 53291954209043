import React, { useCallback, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useLockedBody, useWindowSize } from "usehooks-ts";
import { Label, Tabs } from "flowbite-react";
import { truncate } from "lodash";

import { useTeam } from "shared/store/settings";
import BreadCrumb from "shared/components/bread-crumb/BreadCrumb";
import { getElementOffSetTop } from "shared/helpers/util";
import { useCompany } from "shared/context/CompanyProvider";

import { getCurrentStatus } from "../api";
import TasksByType from "./TasksByStatus";
import Icon from "shared/components/icon/Icon";
import { useWorkspaceModal } from "shared/store/trial";

const ViewStatus = () => {
  const { statusId } = useParams();

  const { height } = useWindowSize();

  const navigate = useNavigate();

  const { currentCompany } = useCompany();

  const currentTeam = useTeam((state) => state.currentTeam);

  const setTrialExpired = useWorkspaceModal((state) => state.setIsModalOpen);

  useLockedBody(true);

  const handleEditClick = () => {
    if (currentCompany?.trial_expired) {
      setTrialExpired(true);
      return;
    }
    navigate(`/tasks/statuses/${statusId}/edit`);
  };

  const categoryViewRef = useRef<HTMLDivElement | null>(null);

  const { data: response } = useQuery(
    ["getCurrentStatus", statusId, currentCompany.id, currentTeam.id],
    () =>
      getCurrentStatus(parseInt(`${statusId}`), {
        team: currentTeam.id,
        company: currentTeam.company,
        isAllTeam: currentTeam.is_all_team,
      }),
    {
      enabled: !!currentTeam,
    }
  );

  const getBreadCrumb = useCallback(() => {
    return [
      { id: null, name: "Tasks", link: "/tasks/overview" },
      { id: null, name: "Statuses", link: "/tasks/statuses" },
      { id: response?.data.id || null, name: truncate(response?.data.name) },
    ];
  }, [response]);

  return (
    <div>
      <div className="p-4 md:px-5 md:py-2 bg-ghostwhite flex border-b border-brightgray dark:bg-darkjunglegreenss dark:border-thunders">
        <BreadCrumb breadCrumb={getBreadCrumb()} />
        {!currentTeam.is_all_team && (
          <button className="btn_primary ml-auto maxMd:hidden" onClick={() => handleEditClick()}>
            Edit
          </button>
        )}
      </div>
      <div className="pt-4 px-4 md:hidden ">
        <button className="btn_primary w-full" onClick={() => handleEditClick()}>
          Edit
        </button>
      </div>
      <div className="flex">
        <div
          className="w-full"
          ref={categoryViewRef}
          style={{
            height: height - getElementOffSetTop(categoryViewRef.current),
          }}
        >
          <div className="details_sub_container">
            <h3 className="details_heading">Details</h3>
            <div className="flex items-center mt-6">
              <Label value="Name*" className="block !text-mirage mr-2 font-inter-medium" />
              <Icon type="question-outline" fill={false} size="icon-smd" />
            </div>
            <p className="mt-2 text-sm dark:text-boulder">{response?.data.name || ""}</p>
          </div>
          <div className="tabs_details_container">
            <Tabs.Group className="tabs_details">
              <Tabs.Item title="Tasks" active={true}>
                <TasksByType />
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStatus;
