import { useMemo } from "react";

import truncate from "lodash/truncate";

import { GetTaskResultType } from "shared/types";

import CommentsCount from "../gridview-chip/CommentsCount";
import GridCard from "../grid-card";
import DueDate from "../gridview-chip/DueDate";
import { TASK_STATUS_TAG_CLASSES, TASK_PRIORITY_TAG_CLASSES } from "../constants";
import Icon from "../icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { useWorkspaceModal } from "shared/store/trial";

function TasksCardView({
  task,
  className,
  onCardClick,
  onEdit,
  onDelete,
}: {
  task: GetTaskResultType;
  className?: string;
  onCardClick?: (task: GetTaskResultType) => void;
  onEdit: (task: GetTaskResultType) => void;
  onDelete: (task: GetTaskResultType) => void;
}) {
  const { currentCompany } = useCompany();
  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const taskTags = useMemo(() => {
    if (task.tags_object) {
      return task?.tags_object.map((tag) => tag.name);
    }
    return [];
  }, [task]);

  const shouldTagsWrapperRender = ["high", "medium", "low"].includes(task?.priority);

  return (
    <div
      data-testid="task-card-view"
      className="mb-4"
      data-status-id={task?.status?.id}
      data-task-id={task.id}
      id={`kanban-list-${task?.id}`}
    >
      <GridCard className={className} onClick={() => onCardClick && onCardClick(task)}>
        <GridCard.Body>
          <GridCard.Title title={truncate(task.name, { length: 25 })} selectable={false} />
          {task?.owner_object?.email ? (
            <GridCard.SubTitle title={task?.owner_object?.email} />
          ) : null}
          {shouldTagsWrapperRender ? (
            <GridCard.TagsWrapper>
              {task?.priority == "low" ? (
                <GridCard.Tag tag={"Low Priority"} className={TASK_PRIORITY_TAG_CLASSES} />
              ) : null}
              {task?.priority == "medium" ? (
                <GridCard.Tag tag={"Medium Priority"} className={TASK_PRIORITY_TAG_CLASSES} />
              ) : null}
              {task?.priority == "high" ? (
                <GridCard.Tag tag={"High Priority"} className={TASK_PRIORITY_TAG_CLASSES} />
              ) : null}
            </GridCard.TagsWrapper>
          ) : null}
          <GridCard.Tags tags={taskTags} className={TASK_STATUS_TAG_CLASSES} />
        </GridCard.Body>
        <GridCard.Footer>
          <GridCard.FooterMain>
            <CommentsCount comments_count={task.comments_count} />
            <DueDate dueDate={task.due_date} />
          </GridCard.FooterMain>
          <GridCard.FooterSide>
            <div className="flex gap-[6px] ml-auto">
              <span
                data-testid="delete-task-button"
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(task);
                }}
              >
                <Icon
                  type="trash-outline"
                  fill={false}
                  size="icon-xs"
                  className="global_hover_icon"
                />
              </span>
              <span
                data-testid="edit-task-button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (currentCompany?.trial_expired) {
                    setWorkspaceModalOpen(true);
                    return;
                  } else {
                    onEdit(task);
                  }
                }}
              >
                <Icon type="pencil-alt" fill={false} size="icon-xs" className="global_hover_icon" />
              </span>
            </div>
          </GridCard.FooterSide>
        </GridCard.Footer>
      </GridCard>
    </div>
  );
}

export default TasksCardView;
