import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import moment from "moment";
import { ReactNode, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../shared/components/icon/Icon";
import Spinner from "../../../../shared/components/spinner/Spinner";
import Badge from "../../../../shared/componentsV2/form-controls/badge";
import Button from "../../../../shared/componentsV2/form-controls/button";
import Checkbox from "../../../../shared/componentsV2/form-controls/checkbox";
import { useCompany } from "../../../../shared/context/CompanyProvider";
import ShareArticleModal from "../../shared/components/share-article-modal";
import { format } from "date-fns";
import { ArticleAction, FeedArticle } from "../../types";

const FeedItems = ({
  articles,
  selectedItems,
  onCheckboxChange,
  onAction,
  onLoadMore,
  isLoading,
  hasNextPage,
  hideActions,
}: {
  articles: FeedArticle[];
  selectedItems: string[];
  onCheckboxChange: (id: string) => void;
  onAction: (article: FeedArticle, action: ArticleAction) => void;
  onLoadMore: () => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  hideActions?: boolean;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<FeedArticle | null>(null);

  const groupedArticles = articles.reduce((acc, article) => {
    const timestamp = Number(article.publication_date) * 1000;

    const date =
      !isNaN(timestamp) && article.publication_date
        ? moment(timestamp).format("YYYY-MM-DD")
        : "No Date";

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(article);
    return acc;
  }, {} as Record<string, FeedArticle[]>);

  const sortedDates = Object.keys(groupedArticles).sort((a, b) => {
    if (a === "No Date") return 1; // Move "No Date" to the end
    if (b === "No Date") return -1; // Keep other dates above "No Date"
    return moment(b).diff(moment(a)); // Descending order for valid dates
  });

  const handleAction = (article: FeedArticle, action: ArticleAction) => {
    onAction(article, action);
    if (action === "share") {
      setSelectedArticle(article);
      setShareModalOpen(true);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="grid gap-2 px-8 py-3 border-y border-antiflashwhite dark:border-thunders rounded-lg">
      {sortedDates.map((date) => (
        <div key={date} className="grid gap-2">
          {groupedArticles[date].map((item, itemIndex) => (
            <FeedItem
              selected={selectedItems.includes(item.object_id)}
              key={itemIndex}
              item={item}
              onToggle={onCheckboxChange}
              onAction={handleAction}
              hideActions={hideActions}
            />
          ))}
          <div className="flex items-center my-4">
            <div className="flex-grow border-t border-gray-300 border-dashed"></div>
            <span className="flex-shrink mx-4 text-sm text-gray-700 font-medium">{date}</span>
            <div className="flex-grow border-t border-gray-300 border-dashed"></div>
          </div>
        </div>
      ))}
      {hasNextPage && (
        <div className="flex items-center justify-center">
          <Button
            btnType="secondary"
            btnSize="sm"
            btnTitle="Load 10 more articles"
            onClick={onLoadMore}
            className="w-fit"
          />
        </div>
      )}
      <ShareArticleModal
        article={selectedArticle}
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
      />
    </div>
  );
};

export const FeedItem = ({
  item,
  selected,
  hideActions,
  onToggle,
  onAction,
}: {
  item: FeedArticle;
  hideActions?: boolean;
  selected?: boolean;
  onToggle?: (id: string) => void;
  onAction: (article: FeedArticle, action: ArticleAction) => void;
}) => {
  const navigate = useNavigate();
  const { currentCompany, usersList } = useCompany();

  const readByUser =
    usersList.find(({ user }) => user.id === Number(item.read_by))?.user.name ?? item.read_by;

  const getPublicationDate = useCallback(() => {
    try {
      return item.publication_date
        ? format(new Date(Number(item.publication_date) * 1000), "YYY-MM-d")
        : "";
    } catch (e) {
      return "";
    }
  }, [item.publication_date]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "pending":
        return "bg-gray-400";
      case "in_progress":
        return "bg-orange-400";
      case "completed":
        return "bg-green-400";
      default:
        return "bg-orange-400";
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "in_progress":
        return "In Progress";
      case "completed":
        return "Completed";
      default:
        return "";
    }
  };

  const getImpactColor = (impact: string) => {
    switch (impact) {
      case "not_set":
        return "bg-gray-400";
      case "low":
        return "bg-green-400";
      case "medium":
        return "bg-orange-400";
      case "high":
        return "bg-red-400";
      default:
        return "";
    }
  };

  const getImpactLabel = (impact: string) => {
    switch (impact) {
      case "low":
        return "Low";
      case "medium":
        return "Medium";
      case "high":
        return "High Priority";
      default:
        return "";
    }
  };

  const goToArticle = (item: FeedArticle) => {
    if (hideActions) {
      return;
    }
    navigate(
      `/workspace/${currentCompany.id}/horizon-scanning/feeds/${item.feed_id}/article/${item.object_id}`
    );
  };

  return (
    <div className="border boder-gray-200 shadow-sm rounded-lg px-3 py-4 bg-white">
      <div className="flex gap-3 items-start">
        <div
          className={clsx("hover:opacity-100", selected ? "opacity-100" : "opacity-0", {
            "invisible pointer-events-none": !onToggle,
          })}
        >
          <Checkbox
            checked={selected}
            wrapperClassName="!pr-0"
            onChange={() => {
              onToggle?.(item.object_id);
            }}
          />
        </div>
        <div className="grid w-full">
          <div className="flex items-center justify-between">
            <h5 className="text-xs font-medium text-gray-500">{item.publisher.name}</h5>
            <div className="flex items-center gap-2">
              <h5 className="text-xs font-medium text-gray-500">{getPublicationDate()}</h5>
              {/* <ArticleDropdown onAction={handleDropdownAction} article={item} /> */}
            </div>
          </div>
          <div className="h-2" />
          <h2
            className="text-lg font-semibold text-gray-900 cursor-pointer"
            onClick={() => goToArticle(item)}
          >
            {item.title}
          </h2>
          <div className="h-3" />
          <h4 className="text-sm font-normal text-gray-600">
            <span className="font-medium text-gray-900">Original Title: </span>
            {item.original_title}
          </h4>
          <div className="h-3" />
          <p className="text-sm font-normal text-gray-600 break-words">
            <span className="font-medium text-gray-900">Summary: </span>
            {item.description}
          </p>
          <div className="h-4" />
          {!hideActions && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                {item.tags?.map((tag) => (
                  <Badge key={tag} badgeTitle={tag} badgeColor="#1C64F2" className="!bg-lavender" />
                ))}
              </div>
              <div className="flex items-center gap-3">
                {item.status && (
                  <ItemBadge
                    label={getStatusLabel(item.status)}
                    icon={
                      <span className={clsx("w-2 h-2 rounded-xl", getStatusColor(item.status))} />
                    }
                    className="capitalize"
                  />
                )}
                {item.impact && item.impact !== "not_set" && (
                  <ItemBadge
                    label={getImpactLabel(item.impact)}
                    icon={
                      <Icon
                        type="arrows-pointing-in"
                        fill={true}
                        size="icon-xs"
                        className="feed-item-impact-icon"
                        badgeColor={getImpactColor(item.impact)}
                      />
                    }
                  />
                )}
                <ItemBadge label={item.publisher.name} icon={null} />
                {item.has_classification && (
                  <Badge
                    badgeTitle="Classified"
                    icon={<Icon type="squares-check" fill={true} size="icon-xs" />}
                    badgeColor="#4B5563"
                  />
                )}
                <div className="flex items-center gap-1">
                  <Icon
                    type="chat-bubble-oval-left-ellipsis"
                    fill={true}
                    size="icon-xs"
                    className="three_dots_icon"
                  />
                  <span className="text-xs font-medium text-gray-500">{item.comments_count}</span>
                </div>
                <Tooltip content={item.is_saved ? "Saved" : "Save"} arrow={false} placement="top">
                  <span onClick={() => onAction(item, "save")} className="cursor-pointer">
                    <Icon
                      type="bookmark"
                      fill={false}
                      size="icon-xs"
                      className={item.is_saved ? "icon-blue-600" : ""}
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  content={
                    item.is_read
                      ? `This article was marked as read${readByUser ? ` by ${readByUser}` : ""}`
                      : "Mark as read"
                  }
                  arrow={false}
                  placement="top"
                >
                  <span onClick={() => onAction(item, "read")} className="cursor-pointer">
                    <Icon
                      type="book-open"
                      fill={true}
                      size="icon-xs"
                      className={item.is_read ? "icon-blue-600" : ""}
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  content={item.is_archived ? "Ignored and archived" : "Ignore and archive"}
                  arrow={false}
                  placement="top"
                >
                  <span onClick={() => onAction(item, "archive")} className="cursor-pointer">
                    <Icon
                      type="archive-box"
                      fill={false}
                      size="icon-xs"
                      className={item.is_archived ? "icon-blue-600" : ""}
                    />
                  </span>
                </Tooltip>
                <Tooltip content="Share" arrow={false} placement="top">
                  <span onClick={() => onAction(item, "share")} className="cursor-pointer">
                    <Icon type="share-2" fill={false} size="icon-xs" />
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ItemBadge = ({
  label,
  icon,
  onClick,
  className,
}: {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <span
      className={clsx(
        "inline-flex items-center gap-1 border border-gray-200 dark:border-thunders rounded-md text-xs text-gray-600 py-0.5 px-1.5 cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      {icon}
      {label}
    </span>
  );
};

export default FeedItems;
