import { ExcelResponseSpreadsheet } from "../type";

/**
 * Check if the response is an Excel spreadsheet from syncfusion server.
 * We don't know structure of workbook, so we just check if it's an object.
 */
export default function isExcelResponseSpreadsheet(
  response: unknown
): response is ExcelResponseSpreadsheet {
  return (
    typeof response === "object" &&
    response !== null &&
    typeof (response as ExcelResponseSpreadsheet).jsonObject === "object" &&
    (response as ExcelResponseSpreadsheet).jsonObject !== null &&
    typeof (response as ExcelResponseSpreadsheet).jsonObject.Workbook === "object" &&
    (response as ExcelResponseSpreadsheet).jsonObject.Workbook !== null
  );
}
