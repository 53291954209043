import React from "react";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardTitleProps = {
  title: string;
  selected?: boolean;
  selectable?: boolean;
  onSelect?: (selected: boolean) => void;
};

const GridCardTitle: React.FC<GridCardTitleProps> = ({
  title,
  selected,
  selectable,
  onSelect: select,
}: GridCardTitleProps) => {
  return (
    <div className={gridStyles.titleContainer}>
      <div className={gridStyles.gridTitle}>
        <div className="gap-2 items-start min-w-0">
          <div className={gridStyles.gridTitleContent}>{title}</div>
        </div>
        {selectable ? (
          <input
            type="checkbox"
            data-testid="grid-card-select"
            readOnly
            className="w-4 h-4 bg-ghostwhite rounded-full border-lightSilver cursor-pointer"
            checked={selected}
            onClick={(e) => {
              e.stopPropagation();
              if (select) {
                select(!selected);
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GridCardTitle;
