import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { PolicyInputData, PolicyResponse } from "../types";
import { convertPolicyData } from "../helpers";

export default async function createPolicy(
  data: PolicyInputData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PolicyResponse>> {
  let response = await api.post<PolicyResponse>(`policies/`, data, { params: { ...permissions } });

  if (response && response.data) {
    response = {
      ...response,
      data: convertPolicyData(response.data),
    };
  }
  return response;
}
