import React from "react";
import NotFound from "shared/components/not-found";

export default function CanAccess({
  isVisible,
  children,
}: {
  isVisible: boolean;
  children: JSX.Element;
}) {
  if (!isVisible) return <NotFound />;

  return children;
}
