import React from "react";

import { Route, Routes } from "react-router-dom";

import {
  AGREEMENTS,
  BILLING,
  INSTITUTION_TYPES,
  RISKS,
  RISKS_REMINDERS,
  UPGRADE,
} from "shared/helpers/constant";
import NotFound from "shared/components/not-found";

import style from "assets/css/settings.module.css";

import MyAccount from "./my-account";
import { Notification } from "./notification/Notification";
import Appearance from "./appearance";
import Groups from "./groups";
import useGetSidebarItems from "./hooks/useGetSidebarItems";
import SettingSidebar from "./component/SettingSidebar";
import SidebarTitle from "./component/SidebarTitle";
import RiskReminder from "./risk-reminder/component/RiskReminder";
import AgreementReminder from "./agreement-reminder/component";
import GroupEditView from "./groups/edit";
import GroupDetailView from "./groups/view";
import Upgrade from "./upgrade";
import Billing from "./billing";
import Preset from "./risk/preset";
import Members from "./members";
import WorkspaceSettings from "./workspace-settings";
import { useCompany } from "shared/context/CompanyProvider";
import TeamsSettings from "modules/settings/teams/TeamsSettings";

export default function SettingsWithSidebarRoutes() {
  const { accountSettings, companySettings } = useGetSidebarItems();
  const { currentUserRole: role } = useCompany();

  const allSettings = [...accountSettings, ...companySettings];

  return (
    <>
      <div className={style.setting_container}>
        <div className={style.setting_modal}>
          <SettingSidebar />
          <div className={style.setting_body}>
            <div className={style.sidebar_component}>
              <Routes>
                <Route
                  path="my-account"
                  element={
                    <>
                      <SidebarTitle title="My Account" /> <MyAccount />
                    </>
                  }
                />

                <Route
                  path="notification"
                  element={
                    <>
                      <SidebarTitle title="Notification" /> <Notification />
                    </>
                  }
                />

                <Route
                  path="appearance"
                  element={
                    <>
                      <SidebarTitle title="Appearance" />
                      <Appearance />
                    </>
                  }
                />
                {role !== "GUEST" && (
                  <>
                    <Route
                      path=":companyId/workspace-settings"
                      element={
                        <>
                          <SidebarTitle title="Workspace Settings" />
                          <WorkspaceSettings />
                        </>
                      }
                    />

                    <Route
                      path=":companyId/members"
                      element={
                        <>
                          <SidebarTitle title="Members" />
                          <Members />
                        </>
                      }
                    />

                    <Route path=":companyId/groups/">
                      <Route
                        index
                        element={
                          <>
                            <SidebarTitle title="Groups" />
                            <Groups />
                          </>
                        }
                      />
                      <Route path=":groupId/edit" element={<GroupEditView />} />
                      <Route path=":groupId/details" element={<GroupDetailView />} />
                    </Route>

                    <Route path=":companyId/teams/">
                      <Route
                        index
                        element={
                          <>
                            <SidebarTitle title="Teams" />
                            <TeamsSettings />
                          </>
                        }
                      />
                    </Route>

                    {allSettings.find((route) => route.slug === AGREEMENTS && route.isVisible()) ? (
                      <Route path=":companyId/agreement/">
                        <Route
                          path="reminders"
                          element={
                            <>
                              <SidebarTitle title="Agreement Reminders" />
                              <AgreementReminder />
                            </>
                          }
                        />
                      </Route>
                    ) : null}
                    <Route path=":companyId/risk/">
                      {allSettings
                        .find((route) => route.slug === RISKS && route.isVisible())
                        ?.children?.find((route) => route.slug === RISKS_REMINDERS)
                        ?.isVisible() ? (
                        <Route
                          path="reminders"
                          element={
                            <>
                              <SidebarTitle title="Risk Reminders" />
                              <RiskReminder />
                            </>
                          }
                        />
                      ) : null}
                      <Route
                        path="preset"
                        element={
                          <>
                            <SidebarTitle title="Preset" />
                            <Preset />
                          </>
                        }
                      />
                    </Route>

                    {allSettings.find(
                      (route) => route.slug === INSTITUTION_TYPES && route.isVisible()
                    ) ? (
                      <Route
                        path="institutation_types"
                        element={
                          <>
                            <SidebarTitle title="Institution Types" />
                            <div className="dark:text-white">{INSTITUTION_TYPES}</div>
                          </>
                        }
                      />
                    ) : null}

                    {allSettings.find((route) => route.slug === UPGRADE)?.isVisible ? (
                      <Route
                        path="upgrade"
                        element={
                          <>
                            <SidebarTitle title="Upgrade" />
                            <Upgrade />
                          </>
                        }
                      />
                    ) : null}

                    {allSettings.find((route) => route.slug === BILLING)?.isVisible ? (
                      <Route
                        path="billing"
                        element={
                          <>
                            <SidebarTitle title="Billing" />
                            <Billing />
                          </>
                        }
                      />
                    ) : null}
                  </>
                )}

                <Route path="/*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
