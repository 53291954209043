import React from "react";

import { Modal, Spinner, TextInput } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import clsx from "clsx";

import styles from "assets/css/settings.module.css";
import { useUpdateCard } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import useUpdateEmailForm from "../hooks/useUpdateEmailForm";
import { updatePaymentMethod } from "../api";
import { BillingDetailsModalProps, UpdateBillingData } from "../types";

const UpdateEmail = ({ billingDetails, refetchBillingDetails }: BillingDetailsModalProps) => {
  const { currentCompany } = useCompany();

  const isUpdateEmailModalOpen = useUpdateCard((state) => state.isUpdateEmailModalOpen);

  const setIsUpdateEmailModalOpen = useUpdateCard((state) => state.setIsUpdateEmailModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading, mutate: updatePaymentMethodFn } = useMutation(
    (data: UpdateBillingData) => {
      if (!billingDetails?.id) {
        return Promise.reject(new Error("Billing Details are not valid."));
      }
      return updatePaymentMethod({ company: currentCompany?.id, data, id: billingDetails?.id });
    },
    {
      onSuccess: () => {
        refetchBillingDetails();
        toast("Email Updated Successfully.", { type: "success" });
        setIsUpdateEmailModalOpen(false);
      },
    }
  );

  const { values, handleChange, errors, handleSubmit, resetForm } = useUpdateEmailForm(
    () =>
      updatePaymentMethodFn({
        billing_details: {
          ...billingDetails?.billing_details,
          email: values.email,
        },
      }),
    billingDetails
  );

  const onCloseModal = () => {
    resetForm();
    setIsUpdateEmailModalOpen(false);
  };

  return (
    <Modal
      root={documentBodyRef}
      dismissible={true}
      show={isUpdateEmailModalOpen}
      onClose={onCloseModal}
      className={clsx(styles.upgrade_card_details, "modal_mobileview_center changepassword_modal")}
    >
      <Modal.Header className="text-lg dark:!border-thunders maxMd:p-4">
        Change Billing Email
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="maxMd:p-4">
          <TextInput
            data-testid="billing-email"
            value={values.email}
            name="email"
            onChange={handleChange}
            color={errors.email ? "failure" : "gray"}
          />
        </Modal.Body>
        <Modal.Footer className="dark:!border-thunders maxMd:p-4 maxMd:flex-wrap">
          <button
            className="btn_secondary ml-auto maxMd:w-full"
            onClick={onCloseModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn_primary md:flex item-center maxMd:!ml-0 maxMd:w-full maxMd:mt-2"
            type="submit"
          >
            <Spinner hidden={!isLoading} size="sm" className="mr-2 !fill-mirage stroke-mirage" />
            {isLoading ? "Updating" : "Update"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateEmail;
