import React from "react";

import clsx from "clsx";
import { TextInput } from "flowbite-react";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import plural from "shared/helpers/plural";

import style from "assets/css/settings.module.css";

export default function MembersHeader({
  setFilterKey,
  memberCount,
}: {
  setFilterKey: (data: string | null) => void;
  memberCount: number;
}) {
  const { currentCompany } = useCompany();

  const remainingSeats = Math.max(currentCompany.seats - memberCount, 0);

  const onSearchMember = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setFilterKey(null);
      return;
    }
    setFilterKey(e.target.value);
  };

  return (
    <div className="flex">
      <div className="w-2/5 relative">
        <TextInput className={style?.search_field} placeholder="Search" onChange={onSearchMember} />
        <Icon type="search" className={clsx(style.search_img, "seeting_icon")} />
      </div>
      <div className="w-3/5 text-right">
        <h6 className="text-base mac13Inch:text-sm font-inter-medium dark:text-white">
          {memberCount} current {plural(memberCount, "member", "members")}
        </h6>
        <h4 className="text-sm mac13Inch:text-xs font-inter-regular text-aurometalsaurus dark:text-greychateau">
          {remainingSeats} available {plural(remainingSeats, "seat", "seats")}
        </h4>
      </div>
    </div>
  );
}
