import React from "react";

import clsx from "clsx";
import capitalize from "lodash/capitalize";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import moment from "moment";

import { NotificationType } from "shared/store/header";
import {
  ARTICLETEAMACTION,
  ARTICLE_COMMENT,
  ASSESSMENT_COMMENT,
  COMPANY_INVITATION,
  OTHER,
  TEAM,
  VENDOR,
  VENDOR_AGREEMENT,
  VENDOR_ASSESSMENT,
  VENDOR_COMMENT,
  RISKV2,
  RISK_REGISTER,
} from "shared/helpersV2/constant";

export type NotificationItemProps = {
  notification: NotificationType;
  onClick?: () => void;
  onReminderClick?: (notification?: NotificationType) => void;
};

const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
  const getNotificationType = (type: string) => {
    if (
      type === TEAM ||
      type === OTHER ||
      type === VENDOR ||
      type === VENDOR_COMMENT ||
      type === VENDOR_ASSESSMENT ||
      type === VENDOR_AGREEMENT ||
      type === RISKV2 ||
      type === RISK_REGISTER
    ) {
      return true;
    }
    return false;
  };

  const getObjectName = (type: string): string => {
    switch (type) {
      case COMPANY_INVITATION:
        return "company invitation";
      case VENDOR_COMMENT:
        return "vendor comment";
      case ARTICLE_COMMENT:
        return "article comment";
      case ASSESSMENT_COMMENT:
        return "assessment comment";
      case ARTICLETEAMACTION:
        return "article team action";
      case VENDOR_AGREEMENT:
        return "vendor agreement";
      case VENDOR_ASSESSMENT:
        return "vendor assessment";
      default:
        return type;
    }
  };

  return (
    <>
      <DropdownMenuItem
        className={clsx("DropdownMenuItem !rounded-md", {
          "cursor-default":
            (getNotificationType(notification?.object_type) && notification.is_read) ||
            notification?.company === null,
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
      >
        <div className="flex gap-2 items-start">
          <div className="flex items-center py-1.5">
            <span
              className={clsx("h-2 w-2 rounded-full", {
                /*TODO: We need change dot color in the future for reminder*/
                // "bg-valentinered": notification?.reminder && !notification?.is_read,
                "bg-dodgerblue": !notification?.is_read,
              })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-xs leading-relaxed text-aurometalsaurus dark:text-nickel">
              {capitalize(getObjectName(notification?.name))}
            </span>
            <div className="break-words space-x-1">
              <span
                className={clsx("text-xs font-inter-medium font-medium", {
                  "text-richblack dark:text-whiteSmoke": !notification?.is_read,
                  "text-brightgrey dark:text-brushedmetal": notification?.is_read,
                })}
              >
                {notification?.description}
              </span>
              <span className="text-xs text-aurometalsaurus dark:text-nickel">
                {moment(moment(notification?.created_at)).fromNow()}
              </span>
            </div>
            {/*TODO: We need to open reminder modal in the future onclick of button*/}
            {/* {notification?.reminder ? (
              <Button
                btnType="secondary"
                btnSize="xs"
                btnTitle=" Edit reminder"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onReminderClick) {
                    onReminderClick(notification);
                  }
                }}
                className="!rounded-md w-fit py-0.5 !px-1.5 !text-xs"
              />
            ) : null} */}
          </div>
        </div>
      </DropdownMenuItem>
    </>
  );
};

export default NotificationItem;
