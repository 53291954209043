import { getCompanies } from "../api";
import { CompanyResponse } from "../types";

/**
 * Loads all companies for user with apply pagination till the end.
 */
function getAllCompanies(): Promise<CompanyResponse[]> {
  let companies: CompanyResponse[] = [];

  function loadCompanies(page = 1): Promise<CompanyResponse[]> {
    return getCompanies(page).then((response) => {
      companies = [...companies, ...response.data.results];
      if (response.data.next) {
        return loadCompanies(page + 1);
      }
      return companies;
    });
  }

  return loadCompanies();
}

export default getAllCompanies;
