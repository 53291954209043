import React from "react";

const WithoutSettingSidebar = (ComposedComponent: () => JSX.Element) => {
  const Component: React.FC = () => {
    return <ComposedComponent />;
  };
  return <Component />;
};

export default WithoutSettingSidebar;
