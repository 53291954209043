import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { ResetPasswordRequestProps, ResetPasswordResponseProps } from "../types";

export default function resetPassword(
  data: ResetPasswordRequestProps
): Promise<AxiosResponse<ResetPasswordResponseProps>> {
  return api.post<ResetPasswordResponseProps>("auth/password/reset/confirm/", data);
}
