import React from "react";

import moment from "moment";

import styles from "assets/css/metadata.module.css";

import { ActivityType } from "../metadata/types";

export const ActivityLog = ({
  activity,
  index,
  onItemClick,
}: {
  activity: ActivityType;
  index: number;
  onItemClick: (flag: boolean, activity: ActivityType) => void;
}) => {
  return (
    <>
      <li
        className="cursor-pointer"
        onClick={() => onItemClick(true, activity)}
        data-testid={activity?.version_id || 0}
      >
        <div className={styles.activityLogBox}>
          <h4>T{index + 1}</h4>
        </div>
        <div className="ml-[10px] activityText">
          <p>{moment(activity.created_at).format("YYYY-MM-DD hh:mm")}</p>
          {activity?.comment == "" ? (
            <p>
              {activity?.user?.email} created version {activity?.version_id}
            </p>
          ) : (
            <p>
              {activity?.user?.email} {activity?.comment}
            </p>
          )}
        </div>
      </li>
    </>
  );
};
