import { ReactComponent as Filter } from "assets/images/icons/filter.svg";
import clsx from "clsx";

export default function FilterDropDownLabel({ displayFilters }: { displayFilters: boolean }) {
  return (
    <div className={clsx(!displayFilters, "flex items-center")}>
      <Filter className="mr-2 si fill w-5 h-5" />
      <span className="text-aurometalsaurus dark:text-greychateau text-sm font-inter-medium">
        Filter
      </span>
    </div>
  );
}
