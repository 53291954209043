import React from "react";

import { FileViewerProps } from "./types";
import Icon from "shared/components/icon/Icon";

export default function DefaultFileViewer({ document }: FileViewerProps) {
  return (
    <div
      data-testid="default-file-viewer"
      className="flex flex-col justify-center items-center w-full doc_preview maxMd:p-4"
    >
      <p className="text-aurometalsaurus mb-4">No preview available</p>
      <a
        href={document.file}
        className="maxMd:justify-center  h-10 btn_primary flex items-center gap-x-1"
        target={"_blank"}
        download
        rel="noopener noreferrer"
      >
        <Icon type="download" fill={false} size="icon-smd" className="notification_icon" />
        Download
      </a>
    </div>
  );
}
