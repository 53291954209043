import getUtmSource from "shared/helpers/getUtmSource";
import isUtmSource from "shared/helpers/isUtmSource";

export default function trackSignUp(search: string, queryParam = "utm_source") {
  const utmSource = getUtmSource(search, queryParam);

  if (!isUtmSource(utmSource)) {
    return;
  }

  if (utmSource === "google") {
    window.gtag("event", "conversion", {
      send_to: process.env.REACT_APP_GOOGLE_SIGN_UP_EVENT_ID,
    });
  }

  if (utmSource === "meta") {
    window.fbq("track", "CompleteRegistration");
  }

  if (utmSource === "reddit") {
    window.rdt("track", "SignUp", {
      currency: "USD",
      value: 1,
    });
  }

  if (utmSource === "linkedin") {
    window.lintrk("track", { conversion_id: process.env.REACT_APP_LINKEDIN_SIGN_UP_CONVERSION_ID });
  }

  if (utmSource === "twitter") {
    window.twq("track", process.env.REACT_APP_TWITTER_SIGN_UP_EVENT_ID, {});
  }
}
