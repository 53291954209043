import React, { ReactElement, useRef } from "react";

import moment from "moment";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";

import WarningComponent from "shared/components/display-warning";
import { CommonDatePickerProps } from "shared/types";

import styles from "assets/css/datePickerV1.module.css";
import clsx from "clsx";

const CommonDatePicker = ({
  handleChange,
  date,
  className,
  errClasssName,
  warningLabel,
  placeholder,
  disabled,
  minDate,
  maxDate,
  defaultDate,
  fullWidth,
}: CommonDatePickerProps) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const MyContainer = ({ className, children }: { className: string; children: ReactElement }) => {
    return (
      <CalendarContainer className={className}>
        <div className="relative">{children}</div>
        <div className="bg-white dark:bg-thunders py-[0.4rem] clear-both flex justify-between px-4">
          <button
            type="button"
            className="btn_primary w-2/5 dark:!text-mirage text-white !ml-0 h-[42px] !text-center"
            onClick={() => datePickerRef?.current && datePickerRef.current?.setOpen(false)}
          >
            Ok
          </button>
          <button
            type="button"
            className="btn_secondary w-2/5 right-2 mb-2.5 !text-center"
            onClick={() => {
              if (defaultDate) {
                handleChange(defaultDate);
              } else {
                handleChange(null);
              }
              datePickerRef?.current && datePickerRef.current?.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </CalendarContainer>
    );
  };

  return (
    <>
      <ReactDatePicker
        ref={datePickerRef}
        placeholderText={placeholder || "Select Date"}
        className={`form-control  dark:!text-greychateau ${
          className ? className : ""
        } ${errClasssName}`}
        selected={date ? moment(date).toDate() : null}
        onChange={(date: Date | null) => handleChange(date ? date : null)}
        dateFormat="yyyy/MM/dd"
        useWeekdaysShort
        shouldCloseOnSelect={false}
        closeOnScroll={false}
        popperClassName={styles.datePickerV1}
        calendarContainer={MyContainer}
        autoComplete="off"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        wrapperClassName={clsx({
          "!w-full": fullWidth,
        })}
      />
      {!date ? <WarningComponent warningLabel={warningLabel} /> : null}
    </>
  );
};

export default CommonDatePicker;
