import { useFormik } from "formik";

import { addStatusSchema } from "modules/tasks/overview/components/validation";

import { StatusResultType } from "../types";

const useStatusEditForm = (status: StatusResultType) => {
  return useFormik<StatusResultType>({
    initialValues: {
      id: status.id,
      name: status?.name || "",
      team: status?.team,
      description: status.description || "",
      is_default: status?.is_default,
      created_at: status.created_at,
      updated_at: status.updated_at,
      is_deleted: false,
    },
    validateOnChange: false,
    validationSchema: addStatusSchema,
    enableReinitialize: true,
    onSubmit: () => {
      return;
    },
  });
};

export default useStatusEditForm;
