import React from "react";

import Icon from "shared/components/icon/Icon";

import { Document } from "modules/documents/type";

export default function NoPreviewAvailable({ document }: { document: Partial<Document> }) {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h2 className="text-aurometalsaurus mb-4"> No Preview Available</h2>
      <button
        className="h-10 btn_primary flex items-center justify-center gap-x-1"
        type="button"
        onClick={() => {
          window.open(document?.file, "_blank");
        }}
      >
        <Icon type="download" fill={false} size="icon-smd" className="risk-download" />
        Download
      </button>
    </div>
  );
}
