import React, { useCallback, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { useTeam } from "shared/store/settings";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import style from "assets/css/settings.module.css";

import GroupsHeader from "./components/GroupsHeader";
import GroupListing from "./components/GroupListing";
import { getGroups } from "./api";
import { CompanyGroupResponse } from "./types";

export default function Groups() {
  const currentTeam = useTeam((state) => state.currentTeam);

  const [isFiltered, setIsFiltered] = useState(false);

  const [filteredGroups, setFilteredGroups] = useState<Array<CompanyGroupResponse>>([]);

  const handleChangeFilter = useCallback((state: boolean) => {
    setIsFiltered(state);
  }, []);

  const handleChangeFilterGroup = useCallback((groups: Array<CompanyGroupResponse>) => {
    setFilteredGroups(groups);
  }, []);

  const { isLoading, data: groupsData } = useQuery(["getGroups"], () => {
    if (!currentTeam?.company) {
      return Promise.reject(new Error("Company is not selected"));
    }

    return getGroups({
      team: currentTeam?.id,
      company: currentTeam?.company,
      isAllTeam: currentTeam.is_all_team,
    });
  });

  const groupsList = isFiltered ? filteredGroups : groupsData?.data.results || [];

  return (
    <>
      {isLoading ? <CentralSpinner /> : null}
      <div className={style.setting_layout}>
        <GroupsHeader
          setIsFiltered={handleChangeFilter}
          setFilteredGroups={handleChangeFilterGroup}
          groupList={groupsData?.data.results || []}
        />
        <GroupListing groupsData={groupsList} />
      </div>
    </>
  );
}
