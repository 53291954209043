import React, { useEffect } from "react";

import { TextInput } from "flowbite-react";

import headerStyles from "assets/css/common-header.module.css";

import { FILTER_TYPE } from "../../helpers/constant";
import useAddInputFilter from "./hooks/useAddInputFilter";
import { FilterOptionType, FiltersProp, MagicWandProps } from "./types";
import Icon from "../icon/Icon";
import { useMutation } from "@tanstack/react-query";
import { set } from "lodash";
import clsx from "clsx";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

function InputFilter({
  onClick,
  item,
  magicWandProps,
}: {
  onClick: (data: FilterOptionType) => void;
  item: FiltersProp;
  magicWandProps?: MagicWandProps;
}) {
  const { handleSubmit, values, errors, handleChange, resetForm } = useAddInputFilter(() => {
    onClick({
      key: values?.name,
      label: (
        <span>
          {item.label} {" contains "}`{values?.name}`
        </span>
      ),
      value: values?.name,
      field: item.field,
      type: FILTER_TYPE,
      operator: item.operator,
      conditions: [
        {
          field: item.field,
          operator: item.operator,
          value: values.name,
        },
      ],
      single_option: item.single_option,
    });
  });

  const { mutate: magicWandFilter, isLoading } = useMutation(
    async () => {
      if (magicWandProps && magicWandProps.magicWandFn) {
        return await magicWandProps.magicWandFn(values.name);
      }
    },
    {
      onSuccess: (res) => {
        set(values, "name", res?.data?.generated_magic_wand_filter);
      },
      onError: (err: AxiosError<{ error: string }>) => {
        toast.error(err.response?.data?.error);
      },
    }
  );

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [item.field]);

  return (
    <>
      <form onSubmit={handleSubmit} className="grid gap-2 mt-2">
        <div className="relative">
          <TextInput
            className={clsx(headerStyles.formInput, {
              [headerStyles.magicWand]: magicWandProps?.visible,
            })}
            value={values?.name}
            name="name"
            onChange={handleChange}
            color={errors?.name ? "failure" : "gray"}
            autoComplete="off"
            data-testid="filter_name"
          />
          {magicWandProps?.visible ? (
            <button
              className="absolute right-3 top-3"
              type="button"
              onClick={() => magicWandFilter()}
            >
              <Icon type="magic-wand" fill={true} />
            </button>
          ) : null}
        </div>
        <div className="!w-fit maxMd:!w-full">
          <button
            type="submit"
            className={headerStyles.btn_primary__imp}
            data-testid="filter_submit"
            disabled={isLoading}
          >
            Apply
          </button>
        </div>
      </form>
    </>
  );
}

export default InputFilter;
