import { useMemo } from "react";
import { RelatedObjectType } from "modules/tasks/overview/types";
import { useCompany } from "shared/context/CompanyProvider";

import { MetaTable, PermissionCreatedResponse, Permission } from "../../types";
import usePermissionForm from "./usePermissionForm";
import BlankPermissionForm from "./blank-permission-form";
import getAvailableTeams from "../helpers/getAvailableTeams";
import getAvailableUsers from "../helpers/getAvailableUsers";
import getTeamIdsFromPermissions from "../helpers/getTeamIdsFromPermissions";
import getUserIdsFromPermissions from "../helpers/getUserIdsFromPermissions";

export type PermissionFormProps = {
  type: MetaTable;
  relatedObject: RelatedObjectType;
  permissions?: Permission[];
  onSuccess?: (data: PermissionCreatedResponse) => void;
  onError?: (error: Error) => void;
};

export default function PermissionForm({
  type,
  relatedObject,
  permissions = [],
  onSuccess: handleSuccess,
  onError: handleError,
}: PermissionFormProps) {
  const { currentCompany } = useCompany();

  const availableTeams = useMemo(
    () => getAvailableTeams(currentCompany, relatedObject, getTeamIdsFromPermissions(permissions)),
    [currentCompany, relatedObject, permissions]
  );

  const availableUsers = useMemo(
    () => getAvailableUsers(currentCompany, relatedObject, getUserIdsFromPermissions(permissions)),
    [currentCompany, relatedObject, permissions]
  );

  const formik = usePermissionForm({
    type,
    relatedObject,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return (
    <div data-testid="permission-form">
      <BlankPermissionForm form={formik} teams={availableTeams} users={availableUsers} />
    </div>
  );
}
