import { AxiosResponse } from "axios";
import { useFormik } from "formik";

import { ChangeAddressProps } from "../types";
import { changeAddressSchema } from "../validation";

const useUpdateAddressForm = (action: CallableFunction, billingDetails?: AxiosResponse) => {
  const data = billingDetails?.data.billing_details.address || {};

  return useFormik<ChangeAddressProps>({
    initialValues: {
      name: billingDetails?.data.billing_details.name || "",
      address: {
        line1: data.line1 || "",
        line2: data.line2 || "",
        city: data.city || "",
        postal_code: data.postal_code || "",
        state: data.state || "",
        country: data.country || "",
      },
    },
    validateOnChange: false,
    validationSchema: changeAddressSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useUpdateAddressForm;
