import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Button, Checkbox, ListGroup } from "flowbite-react";
import { Table } from "@tanstack/react-table";
import clsx from "clsx";

import headerStyles from "assets/css/common-header.module.css";
import styles from "assets/css/commonTable.module.css";

import Icon from "../icon/Icon";

function ColumnSelection<TData>({ table }: { table: Table<TData> }) {
  const [open, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);

  const toggle = () => {
    setOpen((state) => !state);
  };

  useOnClickOutside(divRef, () => {
    setOpen(false);
  });

  if (table.getAllLeafColumns().length === 0) {
    return null;
  }

  const getColumsForSelection = () => {
    return table
      .getAllLeafColumns()
      .filter(
        (ele) =>
          ele.id !== "read" &&
          ele.id !== "column_selection" &&
          ele.id !== "name" &&
          ele.id !== "title"
      );
  };

  return (
    <div className="column-selection maxMd:ml-1" ref={divRef}>
      <Button className="column-selection__toggle" color="white" onClick={toggle}>
        <Icon type="threedots" fill={true} size="icon-sm" />
      </Button>
      {open ? (
        <ListGroup className={clsx(headerStyles.actionDropDown)}>
          {getColumsForSelection().map((column) => {
            return (
              <ListGroup.Item key={column.id}>
                <label>
                  <Checkbox
                    className={styles.checkbox}
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />
                  <span className="cursor-pointer ml-2">{column.columnDef.header?.toString()}</span>
                </label>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}
    </div>
  );
}

export default ColumnSelection;
