import { useParams, useNavigate } from "react-router-dom";

import CentralSpinner from "shared/components/spinner/CentralSpinner";
import NoDataAvailable from "shared/components/no-data-available";
import useScreen from "shared/hooks/useScreen";
import { MOBILE_DEVICE } from "shared/helpers/constant";

import logoText from "assets/images/sidebar/logotext.svg";
import { ReactComponent as CircleShape } from "assets/images/circle.svg";
import logo from "assets/images/logo.svg";
import logoWhite from "assets/images/logo_white.svg";
import smallLogo from "assets/images/logowhite.svg";

import { useDarkMode } from "usehooks-ts";
import ArticleDetails from "./ArticleDetails";
import usePublicArticleQuery from "./hooks/usePublicArticleQuery";
import { IClassificationData } from "../types";
import useTopicsQuery from "../shared/hooks/useTopicsQuery";
import useRegulationsQuery from "../shared/hooks/useRegulationsQuery";
import useJurisdictionsQuery from "../shared/hooks/useJurisdictionsQuery";
import useInstitutionTypesQuery from "../shared/hooks/useInstitutionTypesQuery";

const extractClassification = (
  idArray?: number[],
  classifications?: { name: string; id: number | string }[]
): IClassificationData[] =>
  idArray?.flatMap((institutionTypeId) => {
    const classification = classifications?.find(({ id }) => institutionTypeId === id);

    if (!classification) {
      return [];
    }

    return {
      id: String(classification.id),
      name: classification.name,
    };
  }) ?? [];

const ArticleDetailsPublicPage = () => {
  const { isDarkMode } = useDarkMode();
  const { articleId } = useParams();
  const navigate = useNavigate();
  const deviceType = useScreen();

  const { data, isLoading: isLoadingArticle } = usePublicArticleQuery({
    articleId: articleId ? Number(articleId) : undefined,
    enabled: !!articleId,
  });

  const { data: topics, isLoading: isLoadingTopics } = useTopicsQuery();
  const { data: regulations, isLoading: isLoadingRegulations } = useRegulationsQuery();
  const { data: jurisdictions, isLoading: isLoadingJurisdictions } = useJurisdictionsQuery();
  const { data: institutionTypes, isLoading: isLoadingInstitutionTypes } =
    useInstitutionTypesQuery();

  const isLoading =
    isLoadingArticle ||
    isLoadingTopics ||
    isLoadingRegulations ||
    isLoadingJurisdictions ||
    isLoadingInstitutionTypes;
  const article = data?.data
    ? {
        ...data.data,
        id: String(data.data.id),
        object_id: String(data.data.id),
        institution_types: extractClassification(
          data.data.institution_types,
          institutionTypes?.results
        ),
        jurisdictions: extractClassification(data.data.jurisdictions, jurisdictions?.results),
        regulations: extractClassification(data.data.regulations, regulations?.results),
        topics: extractClassification(data.data.topics, topics?.results),
      }
    : undefined;

  if (deviceType === MOBILE_DEVICE) {
    document.querySelector("body")?.classList.add("overflow-y-auto");
  }

  return (
    <>
      {!isLoading && article ? (
        <div>
          <div className="ml-4 md:ml-[3.75rem] mt-[1rem] cursor-pointer">
            {deviceType === MOBILE_DEVICE ? (
              <img src={isDarkMode ? smallLogo : logo} alt="logo" onClick={() => navigate("/")} />
            ) : (
              <img
                src={isDarkMode ? logoWhite : logoText}
                alt="logo"
                onClick={() => navigate("/")}
              />
            )}
          </div>

          <div className="flex flex-col items-center mt-5 md:mt-[3.75rem] px-4">
            <ArticleDetails article={article} hideForm hideToDos hideNonShareActions headless />
            <div className="w-full md:max-w-[40rem] mx-auto mt-6 mb-[49px] bg-mirage text-white text-center md:p-[48px] p-4 rounded-2xl">
              <h1 className="text-3xl md:text-[42px] leading-[38px] mb-3 md:mb-6 font-[GrandSansDisplay]">
                Let’s make <br /> compliance
                <span className="relative inline-block">
                  &nbsp;fun <CircleShape className="absolute top-0 left-[-10px] maxMd:hidden" />
                </span>
                &nbsp;again
              </h1>
              <p className="mb-6">
                We are reinventing GRC for the finance community. Combining AI-enabled solutions
                with human expertise in our next gen compliance platform.
              </p>
              <button onClick={() => navigate("/signup")} className="btn_secondary font-semibold">
                Sign up for free
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {!article && !isLoading ? (
        <div className="h-screen">
          <NoDataAvailable />
        </div>
      ) : null}
      {isLoading ? <CentralSpinner /> : null}
    </>
  );
};

export default ArticleDetailsPublicPage;
