import React from "react";

import { Link } from "react-router-dom";
import { TextInput, Label, Spinner } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";

import { AuthHeader } from "shared/components/header";
import ServerErrors from "shared/components/server-errors";

import { SsoSigninRequestParams } from "./types";
import { ssoSignIn } from "./api";
import useSsoSigninForm from "./hooks/useSsoSigninForm";

const SsoSignIn = () => {
  const posthog = usePostHog();

  const {
    isLoading,
    mutate: ssoSignInFn,
    isError,
    error,
  } = useMutation((data: SsoSigninRequestParams) => ssoSignIn(data), {
    onSuccess: (res) => {
      posthog.capture("userLoggedIn");

      const url = `${process.env.REACT_APP_BACKEND_URL}${res.data.url}`;
      window.location.replace(url);
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, errors, handleChange } = useSsoSigninForm(() =>
    ssoSignInFn(values)
  );

  return (
    <>
      <AuthHeader />
      <section className="auth-container">
        <div className="card card-auth">
          <h2 className="mb-4 md:mb-10 text-mirage dark:text-white md:text-3xl text-3xl font-sans-medium">
            Sign in with SSO
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Label value="Your Email*" className="font-inter-medium mb-2 block text-mirage" />
              <TextInput
                placeholder="name@example.com"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                className={errors.email ? "is-error" : "gray"}
              />
            </div>
            {isError ? <ServerErrors className="mb-4" error={error} /> : null}
            <button className="btn_primary w-full text-base md:py-3.5">
              <Spinner
                size="md"
                light={true}
                hidden={!isLoading}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Login
            </button>
            <div className="mt-4">
              <Label value="Not registered?" className="!text-aurometalsaurus" />
              <Link
                className="ml-1 text-sm font-inter-medium text-mirage dark:text-white underline hover:no-underline"
                to="/signup"
              >
                Create account
              </Link>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SsoSignIn;
