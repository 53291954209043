import api from "shared/api/v1";

export const downloadFile = ({
  fields,
  output_format,
  teamId,
}: {
  teamId: number;
  fields: string;
  output_format: string;
}) => {
  const params = new URLSearchParams();
  params.append("team", teamId?.toString());
  params.append("output_format", output_format);
  params.append("fields", fields);
  return api.get<BlobPart>(`/tasks/export/`, {
    params,
    responseType: "blob",
  });
};
