import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { InputDataDeletePolicy } from "../types";

export default function deletePolicyById(
  { policyId }: InputDataDeletePolicy,
  permissions: { team: TeamId }
): Promise<AxiosResponse<string>> {
  return api.delete<string>(`policies/${policyId}/`, { params: { ...permissions } });
}
