import React, { memo, Suspense, useRef, useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { Tooltip } from "flowbite-react";
import { useOnClickOutside } from "usehooks-ts";
import clsx from "clsx";

import { NotificationType, useNotification } from "shared/store/header";
import Spinner from "shared/components/spinner/Spinner";

import { getNotifications, updateNotification } from "modules/notifications/api";
import styles from "assets/css/header.module.css";
import Icon from "shared/components/icon/Icon";

const Notification = React.lazy(() => import("modules/notifications"));

const NotificationItem = () => {
  const notificationRef = useRef(null);
  const [notificationToggle, setNotificationToggle] = useState(false);

  const { setNotifications, notificationList } = useNotification();

  const getnotificationCount = (): number => {
    const unReadNotification = notificationList.filter((item) => !item.is_read);
    return unReadNotification.length || 0;
  };

  useQuery(["getNotification"], getNotifications, {
    onSuccess: (res) =>
      setNotifications(
        res.data?.results?.sort(function (a: NotificationType, b: NotificationType) {
          const c = new Date(a.created_at).getTime();
          const d = new Date(b.created_at).getTime();
          return c < d ? 1 : -1;
        })
      ),
  });

  const { mutate: readNotification } = useMutation(
    (id: number) => {
      return updateNotification(id, true);
    },
    {
      onSuccess: ({ data }) => {
        const index = notificationList?.findIndex((item: NotificationType) => item.id === data?.id);
        notificationList.splice(index, 1, data);
        setNotifications([...notificationList]);
      },
    }
  );

  const closeNotification = (status: boolean) => {
    setNotificationToggle(status);
    if (!status)
      notificationList.forEach((item: NotificationType) => {
        !item.is_read && readNotification(item.id);
      });
  };

  useOnClickOutside(notificationRef, () => closeNotification(false));

  return (
    <div data-testid="notification_comp">
      <button
        ref={notificationRef}
        className={`${styles.notificationBtn}  ${
          notificationToggle ? styles.notificationBtnOpen : ""
        }`}
        onClick={() => closeNotification(!notificationToggle)}
      >
        <Tooltip
          content="View notifications"
          className={clsx(styles.notificationTooltiop, "custom_tooltip")}
        >
          <Icon type="bell" fill={true} size="icon-md" className="header-icon_dark" />
        </Tooltip>
        {getnotificationCount() > 0 ? (
          <div className="inline-flex absolute top-0 -right-2.5 justify-center items-center w-5 h-5 text-[11px] font-bold text-white bg-mirage rounded-full dark:bg-white dark:text-darkjunglegreenss">
            {getnotificationCount()}
          </div>
        ) : (
          ""
        )}
      </button>
      {/* Notification list */}
      {notificationToggle ? (
        <div className={styles.notification}>
          <Suspense
            fallback={
              <div className="h-screen flex flex-col justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Notification
              onClose={() => {
                closeNotification(false);
              }}
            />
          </Suspense>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(NotificationItem);
