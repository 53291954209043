import React, { MouseEventHandler, PropsWithChildren } from "react";

import { Tooltip } from "flowbite-react";
import clsx from "clsx";

export type HeaderItemProps = PropsWithChildren<{
  tooltip: string;
  onClick?: MouseEventHandler;
  className?: string;
  testId?: string;
}>;

/**
 * Base component for header items with tooltip (right elements)
 */
function HeaderItem({ children, tooltip, onClick, className, testId }: HeaderItemProps) {
  return (
    <button
      className={clsx("p-1.5 md:p-3", className)}
      aria-label={tooltip}
      onClick={onClick}
      data-testid={testId}
    >
      <Tooltip content={tooltip} className="custom_tooltip">
        {children}
      </Tooltip>
    </button>
  );
}

export default HeaderItem;
