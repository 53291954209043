import { useMemo } from "react";

import { useCompany } from "shared/context/CompanyProvider";

export default function useCheckCompanyPlan() {
  const { currentCompany } = useCompany();

  const isFreePlan = useMemo(
    () => ({
      hasFreePlan: !currentCompany?.subscription,
      hasBusinessPlan:
        currentCompany?.subscription &&
        !currentCompany.subscription.cancel_at_period_end &&
        currentCompany.subscription.status === "active",
      hasDowngradedPlan:
        !currentCompany?.subscription ||
        currentCompany.subscription.cancel_at_period_end ||
        currentCompany.subscription.status !== "active",
    }),
    [currentCompany]
  );
  return isFreePlan;
}
