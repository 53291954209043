import { AxiosResponse } from "axios";
import { DownloadAgreementsProps } from "modules/agreements/overview/types";

import api from "shared/api/v1";

export default function downloadRisks(
  params: DownloadAgreementsProps
): Promise<AxiosResponse<Blob>> {
  return api.get<Blob>(`/risks/export/`, {
    params: { ...params },
    responseType: "blob",
  });
}
