import isArray from "lodash/isArray";

export default function convertPaginatedData<TData>(data: TData) {
  if (isArray(data)) {
    return {
      results: data,
      count: data.length,
      next: null,
      previous: null,
      page_limit: data.length,
    };
  }
  return data;
}
