import React, { useState } from "react";

import clsx from "clsx";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { ArticleStatusColor, ArticleStatus } from "modules/horizonScanning/types";
import { DropdownItemProps } from "shared/typesV2";
import Icon from "shared/components/icon/Icon";
import { useArticleDetails } from "modules/horizonScanning/article-details/ArticleDetails";
import { useTodosFormState } from "../../../article-to-dos-modal/ArticleToDosModal";
import { toast } from "react-toastify";

const ArticleStatusDropdown = ({ disabled }: { disabled?: boolean }) => {
  const [openComboBox, setOpenComboBox] = useState(false);
  const { article, updateArticle } = useArticleDetails();
  const [todos] = useTodosFormState();

  const hasUncompletedTodos = todos.some(
    ({ is_completed, is_deleted }) => !is_completed && !is_deleted
  );

  const statusDropdownData: DropdownItemProps[] = Object.entries(ArticleStatus).map(
    ([article_status, label]) => ({
      id: article_status,
      title: label,
      iconClassName: ArticleStatusColor[article_status as keyof typeof ArticleStatus],
    })
  );

  return (
    <DropdownMenu.Root open={openComboBox} onOpenChange={setOpenComboBox}>
      <DropdownMenu.Trigger
        asChild
        disabled={disabled || !article.is_active}
        className="rounded-lg"
      >
        <button
          disabled={disabled || !article.is_active}
          className="flex w-fit px-0 py-0.5 border-none rounded-lg justify-between items-center disabled:text-santagrey"
        >
          <div className="flex items-center gap-2">
            {typeof article.status === "string" && article.status ? (
              <>
                {statusDropdownData?.map((item) => {
                  if (item.id === article.status) {
                    const { id, title, iconClassName } = item;

                    return (
                      <div className="flex gap-2 items-center" key={id}>
                        <span
                          className={clsx(
                            "w-2 h-2 rounded-full",
                            { "opacity-70": disabled },
                            iconClassName
                          )}
                        ></span>
                        <span
                          className={clsx("text-sm font-medium", {
                            "text-richblack dark:text-lighthouse": !disabled,
                            "text-santagrey dark:text-gray_400": disabled,
                          })}
                        >
                          {title}
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
                <Icon type="chevron-down" size="icon-sm" fill={true} />
              </>
            ) : (
              <>
                <span className="text-sm font-inter-medium text-santagrey dark:text-gray_400">
                  Select
                </span>
                <Icon type="chevron-down" size="icon-sm" fill={true} />
              </>
            )}
          </div>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="DropdownMenuContent min-w-[10rem] w-fit"
        sideOffset={5}
        side={"bottom"}
        align={"start"}
      >
        {statusDropdownData?.map((option) => {
          const { id, title, iconClassName } = option;

          return (
            <DropdownMenu.Item
              key={id}
              className="DropdownMenuItem"
              onClick={() => {
                if (hasUncompletedTodos && id === "completed") {
                  toast("Complete all to-dos before marking this article as completed.", {
                    type: "error",
                  });
                  return;
                }

                updateArticle?.({ status: String(id) });
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center">
                  <span className={clsx("w-2 h-2 rounded-full", iconClassName)}></span>
                  <span>{title}</span>
                </div>
                {id === article.status ? (
                  <Icon type="checkmark" fill={true} size="icon-sm" />
                ) : null}
              </div>
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ArticleStatusDropdown;
