import { useFormik } from "formik";

import { SigninRequestParams } from "../types";
import { signinSchema } from "../validation";

const useSigninForm = (action: CallableFunction) => {
  return useFormik<SigninRequestParams>({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: false,
    validationSchema: signinSchema,
    onSubmit: () => action(),
  });
};

export default useSigninForm;
