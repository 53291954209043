import { useGoogleLogin } from "@react-oauth/google";

import google from "assets/images/icons/google.svg";
import ButtonIcon from "shared/components/elements/button-icon/ButtonIcon";
import getUtmSource from "shared/helpers/getUtmSource";

export type AuthType = "signup" | "signin";

function getState(authType: AuthType): string {
  if (authType === "signup") {
    return getUtmSource(window.location.search) || "";
  }
  return "";
}

/**
 * Auth button with Google. If authType is 'signup' then state will be UTM source else empty string
 */
const GoogleAuthButton = ({
  authType = "signin",
  isSigning = false,
}: {
  authType: "signup" | "signin";
  isSigning?: boolean;
}) => {
  const loginWithGoogle = useGoogleLogin({
    redirect_uri: `${process.env.REACT_APP_GOOGLE_REDIRECT_URL}`,
    state: getState(authType),
    flow: "auth-code",
    ux_mode: "redirect",
  });

  return (
    <ButtonIcon
      img={google}
      isLoading={isSigning}
      onClick={() => loginWithGoogle()}
      className="dark:hover:bg-balticsea"
    >
      <span className="text-mirage dark:text-white">Continue with Google</span>
    </ButtonIcon>
  );
};

export default GoogleAuthButton;
