import React, { useRef } from "react";
import clsx from "clsx";
import { Modal, Spinner } from "flowbite-react";
import set from "lodash/set";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import { TeamId, GetTaskResultType } from "shared/types";
import styles from "assets/css/task.module.css";
import { EDIT_TASK_FORM } from "shared/helpers/constant";
import { useCompany } from "shared/context/CompanyProvider";
import ServerErrors from "shared/components/server-errors";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import { TaskForm } from "../components/TaskForm";
import { RelatedObjectType, RelatedObjectTypeName, TaskResultType } from "../types";
import { useTaskForm } from "../hooks/useTaskForm";
import { updateTask } from "../api";
import { toastTaskUpdated } from "../helpers";

export type EditTaskModalProps = {
  isOpen: boolean;
  task: GetTaskResultType;
  teamId: TeamId;
  relatedObject?: RelatedObjectType;
  relatedObjectType?: string;
  onClose: () => void;
  onUpdated: (task: GetTaskResultType) => void;
  handleForceClose?: () => void;
  relatedObjectTypeName?: RelatedObjectTypeName;
};

export default function EditTaskModal({
  isOpen,
  task,
  teamId,
  relatedObject,
  relatedObjectType,
  onClose: close,
  onUpdated: handleUpdated,
  handleForceClose,
  relatedObjectTypeName,
}: EditTaskModalProps) {
  const { currentCompany } = useCompany();
  const [responseError, setResponseError] = React.useState<AxiosError | null>(null);

  const { documentBodyRef } = useDocumentBodyRef();

  const { mutateAsync: updateTaskFn } = useMutation(
    (payload: Partial<TaskResultType>) => {
      if (!payload.team) {
        return Promise.reject(new Error("Team is not selected"));
      }
      if (!payload.company) {
        return Promise.reject(new Error("Company is not selected"));
      }

      if (payload.tags?.length) {
        payload.tags = Object.keys(
          Object.fromEntries(payload.tags?.map((tag) => [tag, tag]) || [])
        );
      }
      return updateTask(payload, { team: payload.team, company: payload.company });
    },
    {
      onSuccess: (response) => {
        toastTaskUpdated(response.data);
        handleUpdated(response.data);
      },
      onError: (error: AxiosError) => {
        setResponseError(error);
      },
    }
  );

  const onTaskUpdated = useRef<null | ((task: GetTaskResultType) => Promise<void>)>();

  const setOnTaskUpdated = (onUpdated: null | ((task: GetTaskResultType) => Promise<void>)) => {
    onTaskUpdated.current = onUpdated;
  };

  const taskFormHook = useTaskForm(
    teamId,
    async () => {
      const payload = {
        id: task.id,
        ...taskFormHook.values,
        company: currentCompany.id,
      };

      if (relatedObject && relatedObjectType) {
        set(payload, relatedObjectType, relatedObject.id);
      }

      const result = await updateTaskFn(payload);

      if (onTaskUpdated.current) {
        await onTaskUpdated.current(result.data);
      }
      if (handleForceClose) {
        handleForceClose();
      }
      return result;
    },
    task
  );

  return (
    <Modal
      show={isOpen}
      size="2xl"
      popup={true}
      onClose={close}
      onClick={(event) => event.stopPropagation()}
      dismissible={true}
      root={documentBodyRef}
      className="modal_height"
    >
      <Modal.Header
        className={clsx(styles.taskModalHeader, "dark:!border-thunders mac13Inch:items-center")}
      >
        <div className="text-lg mac13Inch:text-base font-semibold dark:text-white">
          Edit Task: {task.name}
        </div>
      </Modal.Header>
      <Modal.Body className={styles.taskModalBody}>
        <TaskForm
          formName={EDIT_TASK_FORM}
          taskForm={taskFormHook}
          relatedObject={relatedObject}
          relatedObjectType={relatedObjectTypeName}
          teamId={teamId}
          companyId={currentCompany?.id}
          task={task}
          setOnSuccess={setOnTaskUpdated}
          handleClose={close}
        />
        {responseError ? <ServerErrors className="mb-4" error={responseError} /> : null}
      </Modal.Body>
      <Modal.Footer className="border-t justify-end dark:!border-thunders mac13Inch:py-4 maxMd:p-4">
        <button
          className="btn_primary maxMd:w-full"
          data-testid="save_task_btn"
          type="submit"
          form={EDIT_TASK_FORM}
          disabled={taskFormHook.isSubmitting}
        >
          <Spinner
            size="md"
            light={true}
            hidden={!taskFormHook.isSubmitting}
            className="mr-3 fill-crayolasblue stroke-crayolasblue"
          />
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
