import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { NotesCommentResponse } from "../types";

export default function createNotesComments(
  note: number,
  tempData: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<NotesCommentResponse>> {
  return api.post<NotesCommentResponse>(`notes/comments/`, tempData, {
    params: {
      note,
      ...permissions,
    },
  });
}
