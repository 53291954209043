import React from "react";

export type GridCardTagsWrapperProps = {
  children: React.ReactNode;
};
import gridStyles from "assets/css/gridview.module.css";

const GridCardTagsWrapper: React.FC<GridCardTagsWrapperProps> = ({
  children,
}: GridCardTagsWrapperProps) => {
  return (
    <div data-testid="grid-card-tags-wrapper" className={gridStyles.gridTags}>
      {children}
    </div>
  );
};

export default GridCardTagsWrapper;
