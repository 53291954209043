import { useQuery } from "@tanstack/react-query";
import getInstitutionTypes from "../api/getInstitutionTypes";

const useInstitutionTypesQuery = () => {
  const { data, isLoading, refetch } = useQuery(["get-institution-types"], () =>
    getInstitutionTypes({})
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
  };
};

export default useInstitutionTypesQuery;
