import React from "react";

import { Modal, ModalBodyProps } from "flowbite-react";
import clsx from "clsx";

const ModalBodyWrapper: React.FC<ModalBodyProps> = (props) => {
  return (
    <Modal.Body {...props} className={clsx("common_modal_body", props.className)}>
      {props.children}
    </Modal.Body>
  );
};
export default ModalBodyWrapper;
