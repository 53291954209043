import { ActionPayloadType } from "shared/components/common-table/types";
import { GetTaskResultType } from "shared/types";

export const isGetTaskResultType = (data?: ActionPayloadType[]): data is GetTaskResultType[] => {
  if (!data || data.length === 0) return false;
  if (
    "id" in data[0] &&
    "name" in data[0] &&
    "description" in data[0] &&
    "assignees" in data[0] &&
    "tags" in data[0] &&
    "priority" in data[0] &&
    "comments_count" in data[0]
  )
    return true;
  return false;
};
