import React, { useState } from "react";

import clsx from "clsx";

import style from "assets/css/settings.module.css";

import { SettingTypes } from "../types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "shared/components/icon/Icon";

export default function SidebarDropdown({ sidebarItem }: { sidebarItem: SettingTypes }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        key={sidebarItem.slug}
        className={clsx(style.sidebar_item, "justify-between")}
        onClick={() => {
          setIsOpen(!isOpen);
          if (!isOpen && sidebarItem?.children) {
            navigate(
              `/settings/${
                sidebarItem?.children?.filter((item) => item.isVisible())[0]?.redirectTo || ""
              }`
            );
          }
        }}
      >
        <div className="flex gap-3">
          {sidebarItem.icon ? <span className={style.sidebar_icon}>{sidebarItem.icon}</span> : null}
          <span>{sidebarItem.title}</span>
        </div>
        <Icon type="chevron-up" className={clsx({ [style.sidebar_icon_rotate]: !isOpen })} />
      </div>
      <div className={style.sidebar_inner_item}>
        {isOpen
          ? sidebarItem.children
              ?.filter((item) => item.isVisible())
              ?.map((item) => (
                <Link
                  key={item.slug}
                  to={`/settings/${item.redirectTo}`}
                  className={clsx(style.sidebar_item, "mt-2", {
                    [style.sidebar_item_active]:
                      item.redirectTo && location.pathname.endsWith(`/settings/${item.redirectTo}`),
                  })}
                >
                  {item.icon ? <span className={style.sidebar_icon}>{item.icon}</span> : null}
                  <span className="ml-9">{item.title}</span>
                </Link>
              ))
          : null}
      </div>
    </>
  );
}
