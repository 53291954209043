import { create } from "zustand";

interface RouteBlock {
  isBlocking: boolean;
  setIsBlocking: (isBlocking: boolean) => void;
}

export const useRouteBlock = create<RouteBlock>((set) => ({
  isBlocking: false,
  setIsBlocking: (isBlocking: boolean) => set({ isBlocking }),
}));
