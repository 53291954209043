import React from "react";

import { Label } from "flowbite-react";
import clsx from "clsx";

import { useSettings } from "shared/store/settings";
import CommonDropdownV2 from "shared/componentsV2/common-dropdownV2";

import { CompanyInfoProps } from "./types";

const CompanyInfo = ({
  setFieldValue,
  selectedIndustries,
  selectedCountries,
  setSelectedCountries,
  setSelectedIndustries,
  className,
}: CompanyInfoProps) => {
  const isModalOpen = useSettings((state) => state.isModalOpen);

  return (
    <div className={className}>
      <div className="mt-4 mac13Inch:mt-3">
        <Label
          value="Country"
          className={clsx(
            { "font-inter-medium": !isModalOpen },
            "!text-sm mb-2 block !text-mirage"
          )}
        />
        <CommonDropdownV2
          selectedValue={selectedCountries.map((data) => ({ ...data, data }))}
          onChange={(countriesData) => {
            setSelectedCountries(countriesData);
            setFieldValue(
              "countries",
              countriesData.map((data) => Number(data.value))
            );
          }}
          apiDetails={{ url: "core/countries/" }}
          isMultiSelect={true}
          placeholder="Select Country"
        />
      </div>
      <div className="mt-4 mac13Inch:mt-3">
        <Label
          value="Industry"
          className={clsx(
            { "font-inter-medium": !isModalOpen },
            "!text-sm mb-2 block !text-mirage"
          )}
        />
        <CommonDropdownV2
          selectedValue={selectedIndustries.map((data) => ({ ...data, data }))}
          onChange={(industriesData) => {
            setSelectedIndustries(industriesData);
            setFieldValue(
              "industries",
              industriesData.map((data) => Number(data.value))
            );
          }}
          apiDetails={{ url: "core/industries/" }}
          isMultiSelect={true}
          placeholder="Select Industry"
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
