import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { SSOGroupMappingProps, SSOProviderProps, SSOUserFederatedProps } from "../types";

const manageProviderFormData = (data: SSOProviderProps) => {
  const formData = new FormData();

  formData.append("sso_type", data?.sso_type);
  formData.append("company", `${data?.company}`);
  formData.append("name", `${data?.name}`);
  formData.append("is_active", `${data?.is_active}`);
  formData.append("company", `${data?.company}`);
  formData.append("groups_attribute", `${data?.groups_attribute}`);
  formData.append("metadata_url", data.metadata_url);
  if (data.metadata_file) {
    formData.append("metadata_file", data.metadata_file);
  }
  data.domains.forEach((domain) => formData.append("domains", domain));
  return formData;
};

export const addProvider = (data: SSOProviderProps) => {
  return api.post(`auth/ssos/?company=${data.company}`, manageProviderFormData(data));
};

export const updateProvider = (data: SSOProviderProps, id: number) => {
  return api.patch(`auth/ssos/${id}/?company=${data.company}`, manageProviderFormData(data));
};

export const updateProviderFederated = (data: SSOUserFederatedProps, id: number) => {
  return api.patch(`auth/ssos/${id}/?company=${data.company}`, data);
};

export const getProviders = (company: number, requestParams?: { ordering?: string }) => {
  return api.get(`auth/ssos/?company=${company}`, {
    params: { ...hasNameOrdering(requestParams) },
  });
};

export const deleteProvider = (company: number, providerId: number | null) => {
  return api.delete(`auth/ssos/${providerId}/?company=${company}`);
};

export const addFederatedGroup = (data: SSOGroupMappingProps, sso: number) => {
  const teams = data.teams?.map((team) => team.id);
  return api.post(`auth/federated-groups/?company=${data.company}&sso=${sso}`, {
    ...data,
    teams,
    sso,
  });
};

export const updateFederatedGroup = (request: {
  data: SSOGroupMappingProps;
  sso: number;
  id: number;
}) => {
  const teams = request.data.teams?.map((team) => team.id);
  return api.patch(
    `auth/federated-groups/${request.id}/?company=${request.data.company}&sso=${request.sso}`,
    { ...request.data, teams, sso: request.sso }
  );
};

export const getFederatedGroups = (request: { company: number; sso: number }) => {
  return api.get(`auth/federated-groups/?company=${request.company}&sso=${request.sso}`);
};

export const deleteFederatedGroup = (request: {
  company: number;
  sso: number;
  id: number | null;
}) => {
  return api.delete(
    `auth/federated-groups/${request.id}/?company=${request.company}&sso=${request.sso}`
  );
};
