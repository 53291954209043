import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

import { MitigationType } from "../types";
import { convertMitigationData } from "../helpers";

export default async function connectRisks(
  mitigationId: number,
  riskIds: number[],
  permissions: TeamParameter
): Promise<AxiosResponse<MitigationType>> {
  let response = await api.patch<MitigationType>(
    `risks/mitigations/${mitigationId}/`,
    { risks: riskIds },
    {
      params: { ...teamToQueryParams(permissions) },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertMitigationData(response.data),
    };
  }

  return response;
}
