import getFilteredAPIData from "shared/helpers/getFilteredAPIData";

const fieldsList = [
  { key: "categories", value: "id" },
  { key: "assignees", value: "id" },
  { key: "tags", value: "slug" },
];

export default function convertPolicyData<TData>(data: TData): TData {
  return getFilteredAPIData(data, fieldsList);
}
