import React, { useState } from "react";

import { TextInput, Label, Spinner } from "flowbite-react";
import clsx from "clsx";
import { FormikProps, useFormik } from "formik";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import styles from "assets/css/settings.module.css";
import ServerErrors from "shared/components/server-errors";
import { useHome } from "shared/store/home";
import { useSettings } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";

import { MyAccountProps } from "./types";
import { updateProfile } from "./api";
import ChangeEmail from "./change-email/ChangeEmail";
import ChangePassword from "./change-password/ChangePassword";
import DeleteAccount from "./delete-account/DeleteAccount";
import ChangePicture from "./change-picture/ChangePicture";
import { updateProfileSchema } from "./validation";

export const MyAccount = () => {
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const profile = useHome((state) => state.profile);
  const setProfile = useHome((state) => state.setProfile);

  const { refetch } = useCompany();

  const setIsModalOpen = useSettings((state) => state.setIsModalOpen);

  const {
    isLoading,
    mutate: updateProfileFn,
    isError,
    error,
  } = useMutation(
    (data: MyAccountProps) => updateProfile({ ...data, profile_picture: profilePicture }),
    {
      onSuccess: (res) => {
        setProfile({ ...profile, ...res.data });
        toast("Profile Updated successfully.", { type: "success" });
        refetch();
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const formik: FormikProps<MyAccountProps> = useFormik<MyAccountProps>({
    initialValues: { name: profile?.name || "" },
    onSubmit: (values) => updateProfileFn(values),
    enableReinitialize: true,
    validationSchema: updateProfileSchema,
    validateOnChange: false,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={clsx(styles.account_content, "dark:border-thunders")}>
          <Label
            htmlFor="email1"
            value="Personal Info"
            className="!text-mirage dark:!text-white font-inter-medium"
          />
          <div className="text-sm mt-4 mb-2 dark:text-white">Preferred Name*</div>
          <TextInput
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            color={formik.errors.name ? "failure" : "gray"}
          />
        </div>
        <ChangePicture profilePicture={profilePicture} setProfilePicture={setProfilePicture} />
        <ChangeEmail />
        <ChangePassword />
        <DeleteAccount />
        <div className={clsx(styles.account_content, "flex justify-end flex-col pb-4")}>
          {isError ? <ServerErrors className="mb-4" error={error} /> : null}
          <div className="md:flex justify-end">
            <div>
              <button
                type="button"
                className="btn_secondary md:mr-4 maxMd:w-full maxMd:mb-[18px]"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
            <div>
              <button className="btn_primary maxMd:w-full">
                <Spinner
                  size="md"
                  light={true}
                  hidden={!isLoading}
                  className="mr-3 fill-crayolasblue stroke-crayolasblue"
                />
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
