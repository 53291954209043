import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { CreateStatusType, StatusResultType } from "../types";

export const addStatus = (data: CreateStatusType): Promise<AxiosResponse<StatusResultType>> => {
  const params = new URLSearchParams();
  params.append("team", `${data.team}`);

  return api.post<StatusResultType>(`tasks/status/`, { ...data }, { params });
};
