import React from "react";

import { Route, Routes } from "react-router-dom";

import useSetSettingsBg from "shared/hooks/useSetSettingsBg";

import style from "assets/css/settings.module.css";

import WithoutSettingSidebar from "./hoc/WithoutSettingSidebar";
import WithSettingSidebar from "./hoc/WithSettingSidebar";
import CreateGroup from "./groups/create";

export default function SettingsRoutes() {
  useSetSettingsBg();

  return (
    <Routes>
      <Route
        path="/:companyId/groups/create"
        element={WithoutSettingSidebar(() => (
          <div className={style.withoutSidebarWrapper}>
            <CreateGroup />
          </div>
        ))}
      />

      <Route path="/*" element={<WithSettingSidebar />} />
    </Routes>
  );
}
