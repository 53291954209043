import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { DownloadArticlesProps } from "../types";
import { GetAllAgreementsRequestParams } from "modules/agreements/overview/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

export default function downloadArticles(
  requestParams: GetAllAgreementsRequestParams,
  params: DownloadArticlesProps
): Promise<AxiosResponse<Blob>> {
  return api.get<Blob>(`/articles/export/`, {
    params: { ...params, ...hasNameOrdering(requestParams, "-publication_date") },
    responseType: "blob",
  });
}
