import React, { useEffect, useState } from "react";

import { Spinner, TextInput } from "flowbite-react";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import { useCompany } from "shared/context/CompanyProvider";
import useCompanyInfo from "shared/hooks/useCompanyInfo";
import { getServerErrors } from "shared/helpers/util";
import { DropdownOptionType } from "shared/types";

import CompanyInfo from "modules/auth/on-boarding/CompanyInfo";
import { CompanyProps } from "modules/settings/company/types";
import { updateCompany } from "modules/settings/company/api";
import { CountryResponseParams, IndustryResponseParams } from "modules/auth/on-boarding/types";

import style from "assets/css/settings.module.css";

import ChangePicture from "../my-account/change-picture/ChangePicture";
import useCompanyForm from "./hooks/useCompanyForm";
import DeleteCompanyModal from "./components/DeleteCompanyModal";

export default function WorkspaceSettings() {
  const { currentCompany, updateCurrentCompany } = useCompany();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const [selectedIndustries, setSelectedIndustries] = useState<DropdownOptionType[]>([]);

  const [selectedCountries, setSelectedCountries] = useState<DropdownOptionType[]>([]);

  const { industries, countries } = useCompanyInfo({
    industryKey: "getIndustriesForInfo",
    countryKey: "getCountriesForInfo",
  });

  const { isLoading, mutate: companyUpdateFn } = useMutation(
    (name: CompanyProps) => updateCompany(name, currentCompany.id, profilePicture),
    {
      onSuccess: (res) => {
        toast("Company updated successfully", { type: "success" });
        updateCurrentCompany(res.data);
      },
      onError: (error: AxiosError) => {
        getServerErrors(error).forEach((err: string) => toast(err, { type: "error" }));
      },
    }
  );

  const { handleSubmit, values, handleChange, errors, setFieldValue, resetForm } = useCompanyForm(
    () => companyUpdateFn(values)
  );

  const setIndustries = () => {
    if (!industries) return;
    const _filteredIndustry = industries?.filter((industry: IndustryResponseParams) =>
      currentCompany.industries.includes(industry.id)
    );
    setSelectedIndustries(
      _filteredIndustry?.map((industry: IndustryResponseParams) => {
        return { label: industry.name, value: industry.id, data: industry };
      })
    );
  };

  const setCountries = () => {
    if (!countries) return;
    const _filteredCountry = countries?.filter((country: CountryResponseParams) =>
      currentCompany.countries.includes(country.id)
    );
    setSelectedCountries(
      _filteredCountry?.map((country: CountryResponseParams) => {
        return { label: country.name, value: country.id, data: country };
      })
    );
  };

  useEffect(() => {
    if (!countries || !industries) return;
    setIndustries();
    setCountries();
  }, [countries, industries]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={style.setting_layout}>
          <div className={style.layout_card_info}>
            <div className="text-sm mb-2 dark:text-white capitalize font-medium">name*</div>
            <TextInput
              type="text"
              placeholder="You can use your name or the name of your team. Keep it simple."
              value={values.name}
              name="name"
              onChange={handleChange}
              color={errors.name ? "failure" : "gray"}
            />
            <CompanyInfo
              className={style.card_info_input}
              setFieldValue={setFieldValue}
              selectedIndustries={selectedIndustries}
              setSelectedIndustries={setSelectedIndustries}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
            />
          </div>
          <div className={style.layout_card_info}>
            <ChangePicture
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              title="Icon"
              description="Upload an image or pick an emoji. It will show up in your sidebar and notifications."
              imageURL={currentCompany.image}
              name={currentCompany.name}
            />
          </div>
          <div className={clsx(style.layout_card_info, "dark:border-thunders")}>
            <div className="text-sm mb-2 capitalize font-medium dark:text-white">Danger zone</div>
            <button
              type="button"
              onClick={() => setIsDeleteModalOpen(true)}
              className=" text-lavared hover:text-white border border-lavared hover:bg-lavared rounded-full text-sm px-5 py-2.5 mac13Inch:text-xs"
            >
              Delete Company
            </button>
          </div>
          <div className={style.layout_card_info}>
            <div className="justify-end flex gap-4">
              <button
                type="button"
                onClick={() => {
                  setIndustries();
                  setCountries();
                  setProfilePicture(null);
                  resetForm();
                }}
                className="btn_secondary"
              >
                Cancel
              </button>
              <button className="btn_primary" type="submit">
                <Spinner
                  size="md"
                  light={true}
                  hidden={!isLoading}
                  className="mr-3 fill-crayolasblue stroke-crayolasblue"
                />
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
      {isDeleteModalOpen ? (
        <DeleteCompanyModal
          isDeleteModalOpen={isDeleteModalOpen}
          handleClose={() => setIsDeleteModalOpen(false)}
        />
      ) : null}
    </>
  );
}
