import get from "lodash/get";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { CompanyUserResponse, UserRole } from "modules/home/types";
import Avatar from "shared/components/avatar";
import Icon from "shared/components/icon/Icon";
import CommonDropdownV2 from "shared/componentsV2/common-dropdownV2";
import { getUsername } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import { GUEST_ROLE } from "shared/helpers/constant";
import { tsAwareBooleanFilter } from "shared/helpersV2/tsAwareBooleanFilter";
import clsx from "clsx";
import { useArticleDetails } from "modules/horizonScanning/article-details/ArticleDetails";
import { useMemo } from "react";
import { SharedObject } from "shared/types";

type TArticleAssigneesDropdownProps = {
  readonly disabled?: boolean;
};

const ArticleAssigneesDropdown = ({ disabled }: TArticleAssigneesDropdownProps) => {
  const currentTeam = useTeam((state) => state.currentTeam);
  const { usersList, currentCompany } = useCompany();
  const { article, feed, updateArticle } = useArticleDetails();

  const apiDetails = useMemo(() => {
    switch (feed?.section) {
      case "public":
        return {
          url: "companies/members/",
          props: {
            company: currentTeam.company,
          },
        };
      case "private":
        return undefined;
      case "private_to_group":
        return {
          url: `/horizon-scanning/feeds/${feed?.id}/sharing`,
          props: {
            company: currentTeam.company,
            show_all_objects: true,
          },
        };
      default:
        return undefined;
    }
  }, [feed?.section]);

  const accessKey = useMemo(() => {
    switch (feed?.section) {
      case "public":
        return "user";
      case "private":
        return "user";
      case "private_to_group":
        return "shared_with_user";
      default:
        return "user";
    }
  }, [feed?.section]);

  const feedOwner = usersList.find(({ user }) => user.id === feed?.object_owner);

  const defaultOptions = feedOwner?.user
    ? [
        {
          value: feedOwner?.user.id,
          name: feedOwner?.user.name || feedOwner?.user.email,
          label: feedOwner?.user.name || feedOwner?.user.email,
          user: feedOwner?.user,
          shared_with_user: feedOwner?.user,
          data: {
            id: feedOwner?.user.id,
            user: feedOwner?.user,
            shared_with_user: feedOwner?.user,
            created: "",
            modified: "",
            is_admin: false,
            custom_permissions: feedOwner?.user.custom_permissions ?? {},
            organization: currentCompany.id,
            role: GUEST_ROLE as UserRole,
          },
        },
      ]
    : undefined;

  const usersListHashMap = Object.fromEntries(
    usersList.map((user) => [String(user.user.id), user.user])
  );

  const selectedAssignees =
    article.assignees
      ?.map((userId) => {
        const user = usersListHashMap[String(userId)];

        return user
          ? {
              value: String(userId),
              label: user.name || user.email,
              data: {
                id: userId,
                user: user,
                shared_with_user: user,
                created: "",
                modified: "",
                is_admin: false,
                custom_permissions: user.custom_permissions ?? {},
                organization: currentCompany.id,
                role: GUEST_ROLE as UserRole,
              },
            }
          : null;
      })
      .filter(tsAwareBooleanFilter) ?? [];

  return (
    <>
      <div className="flex gap-4 flex-wrap min-h-[1.5rem]">
        {selectedAssignees
          .map((assignee) => {
            const profilePicture = assignee.data.user.profile_picture || "";
            return (
              <div key={assignee.value} className="flex items-center gap-1.5">
                {profilePicture ? (
                  <img
                    className={clsx("w-5 h-5 rounded-full", { "opacity-70": disabled })}
                    src={profilePicture}
                    alt="avatar"
                  />
                ) : (
                  <Avatar
                    name={getUsername(assignee.data.user)}
                    size="smd"
                    avatarType="rounded"
                    avatarFor="user"
                    className={clsx({ "opacity-70 cursor-default": disabled })}
                  />
                )}
                <span
                  className={clsx("text-sm", {
                    "text-richblack dark:text-lighthouse": !disabled,
                    "text-santagrey dark:text-gray_400": disabled,
                  })}
                >
                  {assignee.label}
                </span>
                {!disabled ? (
                  <button
                    disabled={!article.is_active}
                    className=""
                    onClick={() => {
                      const filteredAssignees = selectedAssignees.filter(
                        (item) => item.value !== assignee.value
                      );

                      updateArticle?.({
                        assignees: filteredAssignees.map((assignee) => Number(assignee.value)),
                      });
                    }}
                  >
                    <Icon type="close-small" fill={true} size="icon-xs" />
                  </button>
                ) : null}
              </div>
            );
          })
          .concat([
            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild disabled={disabled || !article.is_active}>
                <button
                  aria-label="Customise options"
                  title="Change View"
                  disabled={disabled || !article.is_active}
                  className="focus-visible:outline-none"
                >
                  {selectedAssignees?.length > 0 ? (
                    <span className="block add_btn p-0.5 border border-brightgray rounded shadow-sm">
                      {!disabled ? <Icon type="plus-btn" fill={true} size="icon-xs" /> : null}
                    </span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <span className="text-santagrey dark:text-gray_300 text-sm">
                        Select assignees
                      </span>
                      <Icon type="chevron-down" size="icon-sm" fill={true} />
                    </div>
                  )}
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="member_search_dropdown"
                align="start"
                sideOffset={5}
                side="bottom"
              >
                <CommonDropdownV2<CompanyUserResponse | SharedObject>
                  isMultiSelect={true}
                  className="common_dropdown_v2"
                  classNamePrefix="combobox_dropdown"
                  placeholder="Search Collaborators..."
                  isDisabled={disabled || !article.is_active}
                  selectedValue={[]}
                  onChange={(data) => {
                    const assignees = selectedAssignees.find(
                      (assignee) => assignee.value === data[0].value
                    )
                      ? selectedAssignees
                      : [...selectedAssignees, ...data];

                    updateArticle?.({
                      assignees: assignees.map((assignee) => Number(assignee.value)),
                    });
                  }}
                  menuIsOpen={true}
                  constantOptions={
                    feed?.section !== "private_to_group" && feed?.section !== "public"
                      ? defaultOptions
                      : undefined
                  }
                  defaultOptions={feed?.section === "private_to_group" ? defaultOptions : undefined}
                  apiDetails={apiDetails}
                  searchKey="search"
                  defaultOrderingField={
                    feed?.section === "public" ? "user__name,user__email" : "name,description"
                  }
                  optionField={{
                    label: `${accessKey}.name,${accessKey}.email`,
                    value: `${accessKey}.id`,
                  }}
                  OptionComponent={(data) => {
                    const profilePicture = get(data, `data.data.${accessKey}.profile_picture`, "");
                    return (
                      <div className="flex gap-2 items-center w-full justify-between">
                        <div className="flex gap-2 items-center">
                          {profilePicture ? (
                            <img
                              className="w-4 h-4 rounded-full"
                              src={profilePicture}
                              alt="avatar"
                            />
                          ) : (
                            <Avatar
                              name={getUsername(get(data, `data.data.${accessKey}`))}
                              size="smd"
                              avatarType="rounded"
                              avatarFor="user"
                            />
                          )}
                          <span className="text-brightgrey text-sm dark:text-uniquegrey whitespace-nowrap">
                            {get(data, "data.label", "")}
                          </span>
                        </div>
                        {selectedAssignees.find(
                          (assignee) => assignee.value === get(data, "data.value", "")
                        ) ? (
                          <Icon
                            type="checkmark"
                            fill={true}
                            size="icon-xs"
                            className="checkmark_icon"
                          />
                        ) : null}
                      </div>
                    );
                  }}
                />
              </DropdownMenu.Content>
            </DropdownMenu.Root>,
          ])}
      </div>
    </>
  );
};

export default ArticleAssigneesDropdown;
