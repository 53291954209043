import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

export const isTeamResponse = (data: TeamResponse | CompanyUserResponse): data is TeamResponse => {
  if (!data) return false;
  if (
    "id" in data &&
    "company" in data &&
    "custom_permissions" in data &&
    "owner" in data &&
    "description" in data &&
    "name" in data &&
    "team_picture" in data &&
    "members" in data &&
    "is_common_team" in data &&
    "is_general_team" in data
  )
    return true;
  return false;
};
