import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { PolicyResponse, PolicyUpdateData } from "../types";
import { convertPolicyData } from "../helpers";

export default async function updatePolicy(
  data: PolicyUpdateData | undefined,
  policyId: string | undefined,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PolicyResponse>> {
  let response = await api.patch<PolicyResponse>(
    `policies/${policyId}/`,
    { ...data },
    { params: { ...permissions } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertPolicyData(response.data),
    };
  }
  return response;
}
