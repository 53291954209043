import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GeographiesResultType } from "../types";

export default function getAgreementGeographies(requestParams?: {
  ordering?: string;
}): Promise<AxiosResponse<GeographiesResultType[]>> {
  return api.get<GeographiesResultType[]>(`agreements/geographies/`, {
    params: {
      ...hasNameOrdering(requestParams),
    },
  });
}
