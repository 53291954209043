import { AxiosResponse } from "axios";

import { TeamParameter } from "shared/types";
import api from "shared/api/v1";

import { CompanyGroupResponse, CompanyGroupInputData } from "../types";

export const createGroup = async (
  data: CompanyGroupInputData,
  permissions: TeamParameter
): Promise<AxiosResponse<CompanyGroupResponse>> => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("company", JSON.stringify(permissions.company));
  if (data.description) {
    formData.append("description", data.description);
  }
  if (data.group_picture) {
    formData.append("group_picture", data.group_picture);
  }

  return api.post<CompanyGroupResponse>(`companies/groups/`, formData, {
    params: { company: permissions?.company },
  });
};
