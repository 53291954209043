import { metaDataPriority } from "shared/store/agreements";
import StaticDropdown from "shared/components/common-dropdown/static-dropdown";

import TaskLabel from "../shared/TaskLabel";

export const TaskPriority = ({
  onFieldChanged,
  priority,
}: {
  onFieldChanged: (fieldName: string, data: string) => void;
  priority: string;
}) => {
  return (
    <div>
      <TaskLabel title="Priority" />
      <StaticDropdown
        placeholder="No Option Selected"
        handleChange={(data) => {
          onFieldChanged("priority", data || "");
        }}
        value={priority}
        data={metaDataPriority}
      />
    </div>
  );
};
