import { AxiosResponse } from "axios";
import { MitigationType } from "../types";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

import { convertMitigationData } from "../helpers";

export default async function connectIncidents(
  mitigationId: number,
  incidentIds: number[],
  permissions: TeamParameter
): Promise<AxiosResponse<MitigationType>> {
  let response = await api.patch<MitigationType>(
    `risks/mitigations/${mitigationId}/`,
    { incidents: incidentIds },
    {
      params: { ...teamToQueryParams(permissions) },
    }
  );
  if (response && response.data) {
    response = {
      ...response,
      data: convertMitigationData(response.data),
    };
  }

  return response;
}
