import React, { Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";

import { Tooltip } from "flowbite-react";
import Select, { MenuListProps, NoticeProps, OptionProps, components } from "react-select";
import clsx from "clsx";
import { useDarkMode } from "usehooks-ts";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

import Icon from "shared/components/icon/Icon";
import { selectControlStyle } from "shared/helpers/selectStyle";
import { OptionDataProps } from "shared/types";

import styles from "assets/css/agreement.module.css";
import { ReactComponent as CreateIcon } from "assets/images/icons/plus.svg";

import { SelectOptionProps } from "modules/settings/teams/types";
import { useCompany } from "shared/context/CompanyProvider";
import { useWorkspaceModal } from "shared/store/trial";

const CustomNoOptionComponent = ({
  props,
  title,
}: {
  props: NoticeProps;
  toggleModal: () => void;
  title: string;
}) => {
  return (
    <>
      <components.NoOptionsMessage {...props}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="!w-full"
        >
          {title}
        </div>
      </components.NoOptionsMessage>
    </>
  );
};

const CustomMenuComponent = ({
  props,
  toggleModal,
  title,
  toggalExpiredModal,
  trialExpired,
}: {
  props: MenuListProps;
  toggleModal: () => void;
  title: string;
  toggalExpiredModal: () => void;
  trialExpired: boolean;
}) => {
  return (
    <>
      <components.MenuList {...props}>
        <div className={"!w-full"}>
          {props.children}
          <button
            className="common_create_risk_addIcon cursor-pointer"
            onClick={() => {
              if (trialExpired) {
                toggalExpiredModal();
                return;
              }
              toggleModal();
            }}
          >
            <span>
              <CreateIcon />
            </span>
            {title}
          </button>
        </div>
      </components.MenuList>
    </>
  );
};

const CustomOptionMenu = ({
  props,
  setIsDeleteModalOpen,
  handleDeleteValue,
}: {
  props: OptionProps<OptionDataProps>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  handleDeleteValue: (value: number) => void;
}) => {
  const isCompanyNotNull = isNumber(props.data.company);

  return (
    <>
      <components.Option {...props}>
        <div className="flex justify-between">
          <span>{props.label}</span>
          {isCompanyNotNull ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteValue(props.data.value);
                setIsDeleteModalOpen(true);
              }}
            >
              <Icon
                type="trash-outline"
                fill={false}
                size="icon-xs"
                className="global_hover_icon"
              />
            </button>
          ) : null}
        </div>
      </components.Option>
    </>
  );
};

function CommonCreateTable<T extends object>({
  value,
  fieldName,
  dropDownClassName,
  labelClassName,
  title,
  tooltip,
  placeholder,
  handleSelectChange,
  optionsRisks,
  isModalOpen,
  setIsModalOpen,
  NoOptionsTitle,
  CustomMenuTitle,
  setIsDeleteModalOpen,
  handleDeleteValue,
  hasCustomValue,
}: {
  value: string | number | SelectOptionProps;
  fieldName: keyof T;
  dropDownClassName?: string;
  labelClassName?: string;
  title: string | ReactElement;
  warningLabel?: string;
  tooltip?: string;
  placeholder?: string;
  hasCustomValue?: boolean;

  handleSelectChange: (fieldname: string, risk_id: number) => void;
  optionsRisks: {
    label: string | JSX.Element;
    value: number;
    company?: number | null;
    name?: string;
    isFirstNonNullCompany: boolean;
  }[];
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  NoOptionsTitle: string;
  CustomMenuTitle: string;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  handleDeleteValue: (value: number) => void;
}) {
  const { isDarkMode } = useDarkMode();

  const [selectedValue, setSelectedValue] = useState<string>("");

  const { currentCompany } = useCompany();
  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const modifiedOptions = useMemo(() => {
    const defaultData = optionsRisks.filter((option) => !option.company);
    const customData = optionsRisks.filter((option) => option.company);
    return [
      {
        label: "Default",
        options: defaultData,
      },
      {
        label: "Custom",
        options: customData,
      },
    ];
  }, [optionsRisks]);

  const customFilter = (
    option: {
      label: string | JSX.Element;
      value: number;
      name?: string;
      company?: number | null | undefined;
      isFirstNonNullCompany: boolean;
    },
    searchText: string
  ) => {
    const searchFieldValue = isString(option.label) ? option.label : option.name;
    return searchFieldValue?.toLowerCase().includes(searchText.toLowerCase()) || false;
  };

  const handleInputChange = (inputValue: string) => {
    return inputValue;
  };

  return (
    <>
      <div className={clsx(styles.agreementsFormFields, "maxMd:flex-col z-50")}>
        <div className={labelClassName}>
          <label className="!text-sm" htmlFor={fieldName?.toString()}>
            {title}
          </label>
          {typeof title === "string" && tooltip ? (
            <Tooltip content={tooltip} style="dark" className="custom_tooltip">
              <Icon type="question-outline" fill={false} size="icon-smd" />
            </Tooltip>
          ) : null}
        </div>
        <div className={styles.agreementsFormSelect}>
          <Select
            classNamePrefix="multitag multitag_spacing risk_multitag"
            value={
              hasCustomValue
                ? value
                : optionsRisks.find((option) => option.value === value) ||
                  optionsRisks.find((option) => {
                    if (option.label === selectedValue) {
                      return option.label;
                    }
                  })
            }
            options={modifiedOptions || []}
            placeholder={placeholder}
            className={clsx(dropDownClassName, "text-sm")}
            styles={selectControlStyle(isDarkMode)}
            components={{
              IndicatorSeparator: () => null,
              NoOptionsMessage: (props) => (
                <CustomNoOptionComponent
                  props={props}
                  toggleModal={() => setIsModalOpen(!isModalOpen)}
                  title={NoOptionsTitle}
                />
              ),
              MenuList: (props) => (
                <CustomMenuComponent
                  props={props}
                  toggleModal={() => setIsModalOpen(!isModalOpen)}
                  title={CustomMenuTitle}
                  toggalExpiredModal={() => setWorkspaceModalOpen(true)}
                  trialExpired={currentCompany?.trial_expired}
                />
              ),

              Option: (props) => (
                <CustomOptionMenu
                  props={props}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                  handleDeleteValue={handleDeleteValue}
                />
              ),
            }}
            onChange={(dataType) => {
              handleSelectChange(fieldName.toString(), dataType?.value);
              setSelectedValue(dataType?.label);
            }}
            onInputChange={handleInputChange}
            filterOption={(data, searchText) => {
              return customFilter(data.data, searchText);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CommonCreateTable;
