import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { CurrencyType } from "../types";
import convertCurrencyData from "shared/helpersV2/convertCurrencyData";

export default async function getAgreementCurrencies(requestParams?: {
  ordering?: string;
}): Promise<AxiosResponse<CurrencyType[]>> {
  let response = await api.get<CurrencyType[]>(`agreements/currencies/`, {
    params: {
      ...hasNameOrdering(requestParams),
    },
  });
  if (response && response.data) {
    response = {
      ...response,
      data: convertCurrencyData(response.data),
    };
  }
  return response;
}
