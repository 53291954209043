import React from "react";
import clsx from "clsx";

import styles from "assets/css/task.module.css";

import { TaskLabelType } from "../types";

function TaskLabel({ className, title }: TaskLabelType) {
  return <h3 className={clsx(styles.fieldLabel, className)}>{title}</h3>;
}
export default TaskLabel;
