import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { GetTaskResultType } from "shared/types";
import { useTeam } from "shared/store/settings";
import ActivityLogList from "shared/components/activity-log";
import { TASK_TABLE } from "shared/helpers/constant";
import RevertVersionModal from "shared/components/metadata/activity-log/ActivityRevertModel";
import { ActivityType } from "shared/components/metadata/types";
import Icon from "shared/components/icon/Icon";
import { getActivityLog } from "shared/components/metadata/api";

function TaskActivityLog({ currentTask }: { currentTask: GetTaskResultType }) {
  const currentTeam = useTeam((state) => state.currentTeam);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [versionId, setVersionId] = useState<number>();

  const onItemClick = (flag: boolean, activity: ActivityType) => {
    setVersionId(activity?.version_id);
    setIsModalOpen(flag);
  };

  const {
    data: activityLog,
    isLoading,
    isFetching,
  } = useQuery(
    ["getActivityLog", [], currentTeam?.id, currentTask?.id],
    () =>
      getActivityLog(TASK_TABLE, currentTask?.id?.toString() || "", {
        teamId: currentTeam?.id,
        companyId: currentTeam?.company,
        isAllTeam: currentTeam?.is_all_team || false,
      }),
    {
      enabled: !!currentTask?.id,
    }
  );

  return (
    <>
      <div className="my-3 flex gap-2 items-center">
        <Icon type="activityLog" fill={false} size="icon-sm" />
        <h3 className=" text-sm text-mirage font-medium dark:text-white">Activity Logs</h3>
      </div>
      <div className="max-h-40 md:overflow-y-auto">
        <ActivityLogList
          activityLog={activityLog?.data || []}
          isLoading={isLoading || isFetching}
          onItemClick={onItemClick}
        />
      </div>
      <RevertVersionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        versionId={versionId || 0}
        id={currentTask?.id?.toString() || ""}
        permissions={{
          teamId: currentTeam?.id,
          companyId: currentTeam?.company,
          isAllTeam: currentTeam?.is_all_team || false,
        }}
        type={TASK_TABLE}
      />
    </>
  );
}

export default TaskActivityLog;
