import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { ArticleCommentResponse } from "../types";

export default function createArticlesComments(
  article: number,
  tempData: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<ArticleCommentResponse>> {
  return api.post<ArticleCommentResponse>(`articles/comments/`, tempData, {
    params: {
      article,
      ...permissions,
    },
  });
}
