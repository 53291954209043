import { AxiosResponse } from "axios";

import api from "shared/api/v1";

export default function getObjectById(
  url: string,
  defaultParams?: Record<string, unknown>
): Promise<AxiosResponse<unknown>> {
  return api.get(url, {
    params: {
      ...defaultParams,
    },
  });
}
