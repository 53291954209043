import React from "react";

import { Badge } from "flowbite-react";
import clsx from "clsx";

import useCheckCompanyPlan from "modules/settings/hooks/useCheckCompanyPlan";

import { ReactComponent as Remove } from "assets/images/cross.svg";
import styles from "assets/css/settings.module.css";

import { MemberListItemProps } from "../types";

const MemberListItem = ({
  member,
  onRemoveClick,
  onMemberClick,
  memberList,
}: MemberListItemProps) => {
  const isAdmin = (id: number): boolean => {
    const admin = memberList?.find((member) => {
      if (member.user?.id === id && member.is_admin) {
        return member;
      }
    });
    if (admin) return true;
    return false;
  };

  const isFreePlan = useCheckCompanyPlan();

  return (
    <li
      data-testid="member-list-component"
      className={clsx("py-3 dark:!border-t-thunders", { " cursor-pointer": !isFreePlan })}
      onClick={() => {
        if (!isFreePlan) {
          onMemberClick && onMemberClick(member);
        }
      }}
    >
      <div className="flex items-center space-x-4">
        <div className="flex items-center w-full">
          {member?.profile_picture ? (
            <img src={member?.profile_picture} className="rounded w-8 h-8 mr-3" />
          ) : (
            <div className="bg-gainsboro dark:bg-iridium dark: rounded w-8 h-8 flex justify-center items-center mr-3 dark:text-white">
              {member?.name[0]?.toUpperCase()}
            </div>
          )}
          <div>
            <h2 className="text-base mac13Inch:text-sm flex items-center font-inter-medium text-gray-900 dark:text-white">
              {member?.name}
              {isAdmin(Number(member?.id)) && (
                <Badge
                  color="gray"
                  className="text-xs font-regular ml-1.5 dark:!text-white dark:bg-thunders"
                >
                  Company owner
                </Badge>
              )}
            </h2>
            <h4 className="text-sm text-aurometalsaurus dark:!text-greychateau">{member?.email}</h4>
          </div>
          {!isAdmin(Number(member?.id)) && (
            <button
              data-testid="remove-member"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick(member);
              }}
              className="ml-auto mr-2 border-none"
            >
              <Remove className={styles.remove_team_member} />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default MemberListItem;
