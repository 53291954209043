import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId, TeamId } from "shared/types";

import { CommentReturnType, MetaTable } from "../types";
import getAPIForFetchComments from "./getAPIForFetchComments";
import { AGREEMENT_TABLE } from "shared/helpers/constant";
import { show_all_objects } from "shared/helpersV2/constant";

function getComments(
  type: MetaTable,
  typeId: number | string,
  {
    teamId,
    companyId,
    isAllTeam,
  }: {
    teamId: TeamId;
    companyId: CompanyId;
    isAllTeam: boolean;
  }
): Promise<AxiosResponse<CommentReturnType>> {
  const teamPermissionPayload = { team: teamId, company: companyId, isAllTeam: isAllTeam };

  const teamParameter = teamPermissionPayload?.isAllTeam ? "all" : teamPermissionPayload?.team;

  const URL = getAPIForFetchComments(type);

  return api.get<CommentReturnType>(URL, {
    params: {
      ...{
        team: teamParameter,
        company: teamPermissionPayload.company,
        [type]: typeId,
        show_all_objects: type === AGREEMENT_TABLE ? show_all_objects : undefined,
      },
    },
  });
}

export default getComments;
