import { CompanyMember } from "shared/context/types/company";

import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

export const isCompanyUserResponse = (
  data: CompanyMember | CompanyUserResponse | TeamResponse
): data is CompanyUserResponse => {
  if (!data) return false;
  if (
    "id" in data &&
    "user" in data &&
    "custom_permissions" in data &&
    "organization" in data &&
    "created" in data &&
    "modified" in data &&
    "is_admin" in data &&
    "role" in data
  )
    return true;
  return false;
};
