import React from "react";

import { AuthHeader } from "shared/components/header";

const SignupThanks = () => {
  return (
    <>
      <AuthHeader />
      <section className="h-[calc(100vh-50px)] flex items-center justify-center flex-col">
        <h1 className="text-4xl font-inter-semibold dark:text-white">Thanks for signing up!</h1>
        <p className="text-lg mt-5 dark:text-greychateau">
          You will shortly receive a confirmation email with an activation link.
        </p>
      </section>
    </>
  );
};

export default SignupThanks;
