import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { SigninRequestParams, SigninResonseParams } from "../types";

export default function signin(
  data: SigninRequestParams
): Promise<AxiosResponse<SigninResonseParams>> {
  return api.post<SigninResonseParams>("auth/login/", data);
}
