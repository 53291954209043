import { useCallback, useEffect, useRef, useState, MouseEvent } from "react";

import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import {
  DocumentEditorContainerComponent,
  Toolbar,
  ToolbarItem,
  WordExport,
} from "@syncfusion/ej2-react-documenteditor";
import { Spinner } from "flowbite-react";
import * as Sentry from "@sentry/react";

import { useTeam } from "shared/store/settings";
import { useUser } from "shared/context/UserProvider";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import { ReactComponent as DownloadIcon } from "assets/images/articles/download.svg";

import { FileViewerProps } from "./types";
import { updateDocument, UpdateDocumentProps } from "../../../api";
import { wordProcessorServiceUrl } from "../../../constants";
import fetchDocument from "../../../helpers/fetchDocument";

DocumentEditorContainerComponent.Inject(Toolbar, WordExport);

const toolbarItems: ToolbarItem[] = [
  "Separator",
  "Undo",
  "Redo",
  "Separator",
  "Image",
  "Table",
  "Hyperlink",
  "Bookmark",
  "TableOfContents",
  "Separator",
  "Header",
  "Footer",
  "PageSetup",
  "PageNumber",
  "Break",
  "InsertFootnote",
  "InsertEndnote",
  "Separator",
  "Find",
  "Separator",
  "Comments",
];

export default function DocumentFileViewer({
  document: grandDocument,
  onUpdated: handleUpdate,
  buttonsContainer,
}: FileViewerProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const documentEditorContainerRef = useRef<DocumentEditorContainerComponent | null>(null);
  const documentContainerRef = useRef<HTMLDivElement | null>(null);

  const currentTeam = useTeam((state) => state.currentTeam);

  const [isLoading, setIsLoading] = useState(true);

  const { user } = useUser();

  useEffect(() => {
    fetchDocument(grandDocument)
      .then((file) => {
        const documentEditorContainer = documentEditorContainerRef.current;
        if (documentEditorContainer) {
          setIsLoading(false);
          documentEditorContainer.documentEditor.open(file);
          documentEditorContainer.documentEditor.currentUser = user.email;
          documentEditorContainer.documentEditor.showRevisions = false;
          documentEditorContainer.documentEditor.enableComment = true;
          documentEditorContainer.documentEditor.enableTrackChanges = false;
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    document.getElementsByClassName("document_height")[0]?.classList.add("spreadsheet");
    return () => {
      document.getElementsByClassName("document_height")[0]?.classList.remove("spreadsheet");
    };
  }, []);

  const saveFile = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const documentEditorContainer = documentEditorContainerRef.current;
      if (!documentEditorContainer) {
        Sentry.captureException("Document component not initialized");
        return;
      }

      setIsSaving(true);

      documentEditorContainer.documentEditor
        .saveAsBlob("Docx")
        .then((blob) => new File([blob], grandDocument.filename))
        .then((file) => {
          const updateDocumentProps: UpdateDocumentProps = {
            id: grandDocument.id,
            file: file,
            name: grandDocument.name,
            team: grandDocument.team,
            directory: grandDocument.directory,
          };

          return updateDocument(updateDocumentProps);
        })
        .then((response) => {
          if (handleUpdate) {
            handleUpdate(response.data);
          }
        })
        .catch(() => {
          toast.error("Failed to update document");
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [documentEditorContainerRef, grandDocument]
  );

  const onCreated = useCallback(() => {
    const documentEditorContainer = documentEditorContainerRef.current;
    if (!documentEditorContainer) {
      return;
    }

    documentEditorContainer.documentEditor.enableWordExport = true;
    documentEditorContainer.documentEditor.enableLocalPaste = false;
    documentEditorContainer.documentEditor.enableSelection = true;
    documentEditorContainer.enableLocalPaste = false;
    documentEditorContainer.toolbar.filePicker.disabled = true;

    documentEditorContainer.documentChange = () => {
      documentEditorContainer.documentEditor.focusIn();
    };

    // https://www.syncfusion.com/forums/165784/event-for-copy-paste
    documentEditorContainer.documentEditor.keyDown = async (args) => {
      const keyCode = args.event.which || args.event.keyCode;
      const isCtrlKey = args.event.ctrlKey || args.event.metaKey ? true : keyCode === 17;

      //67 is the character code for 'C'
      if (isCtrlKey && keyCode === 67) {
        try {
          const imageFormat = documentEditorContainer.documentEditor.selection?.imageFormat;
          if (imageFormat) {
            imageFormat?.copyImageFormat(imageFormat?.image);
          } else {
            navigator.clipboard?.writeText(documentEditorContainer.documentEditor.selection.text);
          }
        } catch (err) {
          Sentry.captureException(err);
        }
      }
    };
  }, []);

  const onDownload = useCallback(() => {
    const documentEditorContainer = documentEditorContainerRef.current;
    if (!documentEditorContainer) {
      return;
    }

    const filename = grandDocument.name.split(".").slice(0, -1).join(".");
    documentEditorContainer.documentEditor.save(filename, "Docx");
  }, []);

  return (
    <>
      {/* Use create portal to set buttons on the top sidebar */}
      {buttonsContainer
        ? createPortal(
            <>
              <button
                data-testid="download-button"
                type={"button"}
                className="h-10 flex items-center btn_secondary md:mr-4 maxMd:justify-center maxMd:w-full"
                onClick={onDownload}
              >
                <DownloadIcon className="mr-2" />
                Download
              </button>
              {!currentTeam.is_all_team && (
                <button
                  data-testid="save-file-button"
                  type="button"
                  className="h-10 mt-auto flex justify-center items-center btn_primary gap-x-1 maxMd:mt-2.5 maxMd:justify-center maxMd:w-full"
                  onClick={saveFile}
                >
                  <Spinner
                    hidden={!isSaving}
                    size="sm"
                    className="mr-2 !fill-mirage !stroke-mirage"
                  />
                  Save File
                </button>
              )}
            </>,
            buttonsContainer
          )
        : null}

      <div
        ref={documentContainerRef}
        data-testid="spreadsheet-file-viewer"
        className="flex flex-col justify-center items-center w-full overflow-hidden spreadsheet-file-viewer"
      >
        <DocumentEditorContainerComponent
          id="container"
          height={`calc(100vh - ${
            documentContainerRef.current?.getBoundingClientRect()?.top || 0
          }px)`}
          serviceUrl={wordProcessorServiceUrl}
          toolbarItems={toolbarItems}
          enableToolbar={true}
          ref={documentEditorContainerRef}
          created={onCreated}
          immediateRender={true}
          enableLocalPaste={false}
          enableComment={true}
          enableTrackChanges={false}
        />
      </div>
      {isLoading ? <CentralSpinner /> : null}
    </>
  );
}
