import { Label, TextInput } from "flowbite-react";
import { FormikProps } from "formik";
import moment from "moment";

import { GetTaskResultType } from "shared/types";
import { useCompany } from "shared/context/CompanyProvider";
import { useWorkspaceModal } from "shared/store/trial";

import styles from "assets/css/task.module.css";
import { ReactComponent as WarningIcon } from "assets/images/icon/warning.svg";

import {
  isRiskRelatedTaskType,
  RelatedObjectType,
  RelatedObjectTypeName,
  TaskResultType,
} from "../types";
import { TaskStatus } from "./TaskStatus";
import { TaskPriority } from "./TaskPriority";
import { TaskTags } from "./TaskTags";
import { TaskAssignee } from "./TaskAssignee";
import { TaskDueDate } from "./TaskDueDate";
import { TaskOwner } from "./TaskOwner";
import { TaskDescription } from "./TaskDescription";
import React, { useEffect, useState } from "react";
import { addTaskFiles } from "../api/addTaskFiles";
import TaskFileListing from "modules/tasks/shared/TaskFileListing";
import Icon from "shared/components/icon/Icon";
import { useDropzone } from "react-dropzone";
import TaskLabel from "../shared/TaskLabel";
import { toast } from "react-toastify";
import TaskDisplayComments from "../shared/TaskDisplayComments";
import { useTeam } from "shared/store/settings";
import { useNavigate } from "react-router-dom";
import { getModuleURL } from "shared/helpers/getModuleURL";
import getRelatedObjectName from "../helpers/getRelatedObjectName";
import TaskDisplayValue from "../shared/TaskDisplayValue";

export const TaskForm = ({
  relatedObject,
  relatedObjectType,
  taskForm,
  formName,
  teamId,
  companyId,
  task,
  setOnSuccess,
  handleClose,
}: {
  relatedObject?: RelatedObjectType;
  relatedObjectType?: RelatedObjectTypeName;
  taskForm: FormikProps<TaskResultType>;
  formName: string;
  teamId: number;
  companyId: number;
  task?: GetTaskResultType;
  setOnSuccess?: (cb: null | ((task: GetTaskResultType) => Promise<void>)) => void;
  handleClose?: () => void;
  isTeamEditable?: boolean;
}) => {
  const { errors, values, setFieldValue, handleChange } = taskForm;

  const setIsTrialModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const [files, setFiles] = useState<File[]>([]);

  const { currentCompany } = useCompany();
  const { currentTeam, teams } = useTeam();
  const navigate = useNavigate();

  const selectedTeam = teams?.find((_) => _.id === values.team);

  const uploadFiles = async (taskId: number) => {
    const result = await Promise.allSettled(
      files.map(async (file) =>
        addTaskFiles({ file: file, taskId }, { team: selectedTeam?.id || teamId })
      )
    );

    result.forEach((res, index) => {
      if (res.status === "rejected") {
        toast.error(`Failed to upload file: ${files[index].name}`);
      }
    });
  };

  useEffect(() => {
    if (setOnSuccess) {
      setOnSuccess(async (task: GetTaskResultType) => {
        if (files.length) {
          await uploadFiles(task.id);
        }
      });
    }

    return () => {
      setOnSuccess && setOnSuccess(null);
    };
  }, [files]);

  const { getInputProps, getRootProps, open } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setFiles((state) => [...state, ...acceptedFiles]);
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (currentCompany?.trial_expired) {
            setIsTrialModalOpen(true);
            return;
          }
          taskForm.handleSubmit();
          if (handleClose) {
            handleClose();
          }
        }}
        name={formName}
        data-testid={formName}
        id={formName}
      >
        <div className="grid grid-rows-1 gap-4">
          <div>
            <div className="flex justify-between">
              <Label className={styles.fieldLabel} value="Task Name*" />
              {errors.name ? <WarningIcon /> : null}
            </div>
            <TextInput
              type="text"
              id="name"
              data-testid="task_name"
              placeholder="Enter Task Name"
              onChange={handleChange}
              value={values.name}
              color={errors.name ? "failure" : "gray"}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            {/* tasks status list */}
            <TaskStatus
              form={taskForm}
              selectedObject={values.status_object}
              teamId={selectedTeam?.id || teamId}
              companyId={companyId}
            />

            {/* tasks priority list */}
            <TaskPriority onFieldChanged={setFieldValue} priority={values.priority} />
          </div>

          {/* Tags listing */}
          <TaskTags
            teamId={selectedTeam?.id || teamId}
            tags={values.tags}
            onFieldChanged={(fieldName, data) => {
              setFieldValue(
                fieldName,
                data?.map((tag) => tag.value)
              );
            }}
          />

          {/* tasks assignees list */}
          <TaskAssignee
            assignees={values.assignees}
            onFieldChanged={setFieldValue}
            assignees_object={task?.assignees_object || []}
          />

          <div className="grid md:grid-cols-2 gap-4">
            {/* display due date of task */}
            <TaskDueDate
              due_date={values.due_date}
              onFieldChanged={setFieldValue}
              defaultDate={task?.due_date ? moment(task?.due_date).toDate() : null}
            />

            {/* task owner */}
            <TaskOwner owner_object={values.owner_object} onFieldChanged={setFieldValue} />
          </div>
          <TaskLabel title="Object" />
          <TaskDisplayValue
            onClick={() => {
              if (relatedObject) {
                handleClose && handleClose();
                if (isRiskRelatedTaskType(relatedObject)) {
                  navigate(
                    `${getModuleURL(
                      relatedObject.id,
                      relatedObjectType,
                      relatedObject.company,
                      relatedObject.register
                    )}`
                  );
                } else {
                  navigate(`${getModuleURL(relatedObject?.id, relatedObjectType)}`);
                }
              }
            }}
            className="!ml-0 !mt-0 view_task_underline"
            title={getRelatedObjectName(relatedObject)}
          />

          {/* task attachments */}
          <div>
            <TaskLabel title="Attachements" />

            <TaskFileListing newFiles={files} setNewFiles={setFiles} taskId={task?.id} />

            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex justify-end items-center w-full mt-2"
            >
              <input {...getInputProps()} />
              <button
                type="button"
                className="btn_secondary flex justify-center items-center gap-3 dark:bg-transparent"
                onClick={() => open()}
              >
                <Icon type="plus" fill={true} size="icon-sm" className="plus_icon" />
                Upload Files
              </button>
            </div>
          </div>

          {/* task description */}
          <TaskDescription
            description={values.description}
            onFieldChanged={setFieldValue}
            onMentionChange={setFieldValue}
          />

          {/* comments listing and comment form */}
          {!currentTeam?.is_all_team && task ? (
            <TaskDisplayComments currentTask={task || []} />
          ) : null}
        </div>
      </form>
    </>
  );
};
