import React, { useState } from "react";
import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import clsx from "clsx";
import { toast } from "react-toastify";

import styles from "assets/css/settings.module.css";
import ServerErrors from "shared/components/server-errors";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import useChangePassword from "../hooks/useChangePassword";
import { ChangePasswordInputData } from "../types";
import { changePassword } from "../api";

const ChangePassword = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);

  const { documentBodyRef } = useDocumentBodyRef();

  const { handleSubmit, values, errors, handleChange, handleReset } = useChangePassword(() =>
    changePasswordFn(values)
  );

  const {
    isLoading,
    mutate: changePasswordFn,
    isError,
    error,
  } = useMutation((data: ChangePasswordInputData) => changePassword(data), {
    onSuccess: () => {
      toast("Password changed successfully", { type: "success" });
      handleReset({});
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  return (
    <>
      <div>
        <Label value="Password" className="font-inter-medium !text-mirage" />
        <br />
        <button
          className="btn_secondary mt-4"
          onClick={() => setIsPasswordModalOpen(true)}
          type="button"
        >
          Set Password
        </button>
      </div>
      <Modal
        root={documentBodyRef}
        show={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center changepassword_modal"
      >
        <Modal.Header
          className={clsx(
            "font-sans-medium maxMd:p-4 mac13Inch:py-4 mac13Inch:px-6 dark:!border-thunders",
            styles.setPasswordHeader
          )}
        >
          Change Your Password
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="relative maxMd:py-5 maxMd:px-4 w-full">
            <div>
              <div className="mb-4">
                <Label value="Password*" className="font-inter-medium mb-2 block !text-mirage" />
                <TextInput
                  data-testid="password1"
                  placeholder="Enter new password"
                  type="password"
                  name="new_password1"
                  className="custom_input"
                  value={values.new_password1}
                  onChange={handleChange}
                  color={errors.new_password1 ? "failure" : "gray"}
                />
              </div>
              <div>
                <Label
                  value="Repeat Password*"
                  className="font-inter-medium mb-2 block !text-mirage"
                />
                <TextInput
                  data-testid="password2"
                  placeholder="Confirm password"
                  type="password"
                  name="new_password2"
                  className="custom_input"
                  value={values.new_password2}
                  onChange={handleChange}
                  color={errors.new_password2 ? "failure" : "gray"}
                />
              </div>
              {isError && <ServerErrors className="mt-4" error={error} />}
            </div>
          </Modal.Body>
          <Modal.Footer className="!p-4 justify-end md:!pr-6 dark:!border-thunders">
            <button className="btn_primary maxMd:w-full" data-testid="set-password">
              <Spinner
                size="md"
                light={true}
                hidden={!isLoading}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Set Password
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ChangePassword;
