import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { InviteRequestParams, InviteResposneParams } from "../types";

export default function inviteTeammates(
  data: InviteRequestParams
): Promise<AxiosResponse<InviteResposneParams>> {
  if (!data.role) {
    delete data.role;
  }

  return api.post<InviteResposneParams>(
    `companies/invitations/?company=${data.organization}`,
    data
  );
}
