import React from "react";

import Icon from "shared/components/icon/Icon";
import useHideCopilot from "shared/hooks/useHideCopilot";
import useHideIntercom from "shared/hooks/useHideIntercom";

import { FiltersProp } from "../../types";
import FilterTitle from "../FilterTitle";

export default function FilterSlider({
  filters,
  onClose,
  setCurrentFilter,
}: {
  filters: FiltersProp[];
  onClose: () => void;
  setCurrentFilter: (data: FiltersProp | null) => void;
}) {
  useHideCopilot();
  useHideIntercom();

  return (
    <>
      <div className="filter_view" onClick={() => onClose()}>
        <div onClick={(e) => e.stopPropagation()}>
          <FilterTitle
            handleClose={() => {
              onClose();
              setCurrentFilter(null);
            }}
            filterText="Filter"
          />
          <ul>
            {filters.map((filter) => (
              <li
                onClick={() => {
                  onClose();
                  setCurrentFilter(filter);
                }}
              >
                <button>
                  <span>{filter.label}</span>
                  <Icon type="rightarrow" fill={true} size="icon-sm" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
