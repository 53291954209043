import { AxiosResponse } from "axios";
import { TagsResponse } from "../types";

import api from "shared/api/v1";

export default async function getTags(params: {
  company: number;
}): Promise<AxiosResponse<TagsResponse>> {
  return await api.get<TagsResponse>(`core/tags-v2/`, {
    params,
  });
}
