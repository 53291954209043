import React from "react";

export default function BooleanInput({
  fieldName,
  isChecked = null,
  handleChnage,
}: {
  fieldName: string;
  isChecked: boolean | null;
  handleChnage: (status: boolean | null) => void;
}) {
  return (
    <div className="flex gap-4">
      <div className="items-center flex gap-2">
        <input
          id={fieldName + "_yes"}
          type="radio"
          checked={isChecked || false}
          name={fieldName + "_yes"}
          className="radioButtons"
          onChange={() => handleChnage(true)}
        />
        <label
          htmlFor={fieldName + "_yes"}
          className="font-medium text-mirage dark:!text-greychateau  cursor-pointer"
        >
          Yes
        </label>
      </div>
      <div className="items-center flex gap-2">
        <input
          id={fieldName + "_no"}
          type="radio"
          checked={isChecked === false}
          name={fieldName + "_no"}
          className="radioButtons"
          onChange={() => handleChnage(false)}
        />
        <label
          htmlFor={fieldName + "_no"}
          className="font-medium text-mirage dark:!text-greychateau  cursor-pointer"
        >
          No
        </label>
      </div>
      <div className="items-center flex gap-2">
        <label
          htmlFor="clear"
          className="font-medium underline text-mirage dark:!text-greychateau cursor-pointer"
          onClick={() => handleChnage(null)}
        >
          Clear all
        </label>
      </div>
    </div>
  );
}
