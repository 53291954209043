import { useFormik } from "formik";

import { CompanyRequestParams } from "../types";
import { companySchema } from "../validations";

const useCompanyForm = (action: CallableFunction) => {
  return useFormik<CompanyRequestParams>({
    initialValues: {
      name: "",
      is_active: true,
    },
    validateOnChange: false,
    validationSchema: companySchema,
    onSubmit: () => action(),
  });
};

export default useCompanyForm;
