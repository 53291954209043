import React, { useState } from "react";

import { Table } from "@tanstack/react-table";

import { ReactComponent as Setting } from "assets/images/icons/setting.svg";

import ActionSlider from "./ActionSlider";
import { ActionsType } from "../../types";

export default function ActionMobileView<TData>({
  actions,
  table,
}: {
  actions: ActionsType<TData>[];
  table: Table<TData>;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex items-center ml-2.5" onClick={() => setIsOpen(!isOpen)}>
        <Setting className="theme_icon w-5 h-5" />
        <span
          data-testid="action-label"
          className="ml-2 text-aurometalsaurus dark:text-greychateau text-sm font-inter-medium"
        >
          Action
        </span>
      </div>
      {isOpen ? (
        <ActionSlider actions={actions} table={table} handleFilterClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}
