import React from "react";

import { Modal as FlowbiteModal, ModalProps } from "flowbite-react";

import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import ModalHeader from "./components/ModalHeader";
import ModalBody from "./components/ModalBody";
import ModalFooter from "./components/ModalFooter";
import clsx from "clsx";

const ModalComponent: React.FC<ModalProps> = (props) => {
  const { documentBodyRef } = useDocumentBodyRef();

  const childrenLength = Array.isArray(props.children) ? props.children.length : 0;

  return (
    <FlowbiteModal
      root={documentBodyRef}
      size="lg"
      dismissible
      {...props}
      className={clsx({ modal_wrapper: childrenLength < 3 }, props.className)}
    >
      {props.children}
    </FlowbiteModal>
  );
};

ModalComponent.displayName = "Modal";
ModalHeader.displayName = "Modal.Header";
ModalBody.displayName = "Modal.Body";
ModalFooter.displayName = "Modal.Footer";

const Modal = Object.assign(ModalComponent, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});

export default Modal;
