import { useEffect } from "react";

import clsx from "clsx";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";

import Icon from "shared/components/icon/Icon";

import { SearchResultRowProps } from "../types";
import { useHome } from "../../../store/home";
import { ObjectTypeToPathMapping } from "../../../helpers/search";

const SearchResultRow = ({ item, index, selectedIndex, searchKey }: SearchResultRowProps) => {
  const navigate = useNavigate();

  const setIsSpotlightModalopen = useHome((state) => state.setIsSpotlightModalopen);

  const onClickObject = (objectType: string, objectId: string) => {
    setIsSpotlightModalopen(false);
    if (objectType === "eurlexarticle") {
      const url = encodeURIComponent(item?.document || "");
      navigate(`/eur-lex/${url}`);
      return;
    }
    const path = ObjectTypeToPathMapping(objectType, objectId);
    navigate(path);
  };

  const listenKey = (event: KeyboardEvent) => {
    if (event.key === "Enter" && selectedIndex === index) {
      onClickObject(item.object_type, item.object_id);
    }
  };

  useEffect(() => {
    const listen = (e: KeyboardEvent) => {
      listenKey(e);
    };

    window.addEventListener("keydown", listen);
    return () => {
      window.removeEventListener("keydown", listen);
    };
  });

  return (
    <>
      <li
        key={index}
        className={clsx(
          { "bg-brightgray dark:bg-balticsea": selectedIndex === index },
          "spotlight-content_item w-full hover:bg-brightgray dark:hover:bg-balticsea py-2.5 px-4 md:px-2 text-xs font-inter-medium text-aurometalsaurus flex cursor-pointer items-center justify-between border-b border-brightgray dark:border-thunders"
        )}
        data-testid="search-data-row"
        onClick={() => onClickObject(item.object_type, item.object_id)}
        onKeyDown={() => onClickObject(item.object_type, item.object_id)}
      >
        <div className="flex items-center">
          <Icon type="copyicon" fill={true} size="icon-xs" className="mr-2" />
          <span className="dark:!text-greychateau text_ellipsis">
            <Highlighter
              highlightClassName="dark:!text-white bg-transparent"
              searchWords={[searchKey]}
              autoEscape={true}
              textToHighlight={item?.name?.replace(/(\n)/g, "")}
            />
          </span>
        </div>
        <Icon type="reply" fill={false} size="icon-xs" className="mr-2" />
      </li>
    </>
  );
};

export default SearchResultRow;
