import React from "react";
import { useParams } from "react-router-dom";

const EurLexDocument = () => {
  const { url } = useParams();

  return (
    <div>
      <iframe src={`${url}#toolbar=0`} style={{ width: "100%", height: "100vh" }}></iframe>
    </div>
  );
};

export default EurLexDocument;
