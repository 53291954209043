import React, { useEffect, useState } from "react";

import getSliderThumbElement from "shared/helpers/getSliderThumbElement";

export default function SliderLevelTitle({
  label,
  index,
  sliderRef,
  values,
}: {
  label: string;
  index: number;
  sliderRef: React.MutableRefObject<HTMLDivElement | null>;
  values: Array<number>;
}) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [sliderValues, setSliderValues] = useState<Array<number>>([]);

  const getLabelWidth = (index: number): number => {
    if (index === 0) {
      return 0;
    }

    const element = getSliderThumbElement(`rc-slider-handle-${index}`, ref)[0];

    if (element instanceof HTMLDivElement) {
      return Number(element.style.left.replace("%", ""));
    }
    return 0;
  };

  useEffect(() => {
    setRef(sliderRef.current);
  }, [sliderRef.current, sliderValues]);

  useEffect(() => {
    setSliderValues(values);
  }, [values]);

  return (
    <>
      <span
        className="text-aurometalsaurus absolute text-sm bg-white w-20 dark:bg-darkjunglegreenss dark:text-greychateau"
        style={{
          left: `${index === 0 ? 0 : getLabelWidth(index) + 3}%`,
        }}
      >
        {label}
      </span>
    </>
  );
}
