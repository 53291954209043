import get from "lodash/get";

import { CompanySuggestionsDataProps } from "shared/store/table-column-selection";

import { SearchOptions } from "../types";

type SearchSuggestionResponseProps = {
  suggestionsData: CompanySuggestionsDataProps;
  key: string;
  data: SearchOptions;
  companyId: number;
};

export default function getSearchSuggestionResponseData({
  suggestionsData,
  key,
  data,
  companyId,
}: SearchSuggestionResponseProps) {
  const currentSuggestions: SearchOptions[] = get(suggestionsData, [companyId, key], []);
  const isDuplicate = currentSuggestions?.some(
    (suggestion: SearchOptions) => suggestion.value.toLowerCase() === data.value.toLowerCase()
  );

  if (!isDuplicate) {
    const newSuggestionData = {
      ...suggestionsData,
      [companyId]: {
        ...get(suggestionsData, [companyId], {}),
        [key]: [...currentSuggestions, data],
      },
    };

    return newSuggestionData;
  }

  return suggestionsData;
}
