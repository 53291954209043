import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { RiskFileResponse } from "../types";

export default function uploadRisksFiles(
  { file, risk }: { file?: File; risk: number },
  permissions: TeamParameter
): Promise<AxiosResponse<RiskFileResponse>> {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
    formData.append("name", file.name);
  }
  if (permissions.team) {
    formData.append("team", `${permissions.team}`);
  }

  formData.append("risk", `${risk}`);

  const teamParameter = permissions.isAllTeam ? "all" : permissions.team;

  return api.post<RiskFileResponse>(`risks/risks-files/`, formData, {
    params: { ...{ team: teamParameter, company: permissions.company } },
  });
}
