import { AxiosResponse } from "axios";

import api from "../../../api/v1";
import { TeamParameter } from "../../../types";

import { GlobalSearchResponse } from "../types";

export default function globalSearch({
  term,
  team,
}: {
  term: string;
  team: TeamParameter;
}): Promise<AxiosResponse<GlobalSearchResponse>> {
  return api.get<GlobalSearchResponse>("/search/global-fast/", {
    params: {
      term,
      team: team?.isAllTeam ? "all" : team?.team,
      company: team?.company,
    },
  });
}
