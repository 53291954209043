import React from "react";

import clsx from "clsx";

const WarningComponent = ({
  warningLabel,
  className,
}: {
  warningLabel?: string;
  className?: string;
}) => {
  return (
    <>
      {warningLabel ? (
        <label
          className={clsx(
            "!text-gamboge dark:!text-gamboge font-normal w-full maxMd:block label-font",
            className
          )}
        >
          {warningLabel}
        </label>
      ) : null}
    </>
  );
};

export default WarningComponent;
