import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { KanbanOrderResultType } from "../types";

export const getKanbanOrder = (
  companyId: number,
  teamId?: number
): Promise<AxiosResponse<KanbanOrderResultType[]>> => {
  const params = new URLSearchParams();
  params.append("company", companyId?.toString());
  if (teamId) params.append("team", teamId?.toString());

  return api.get<KanbanOrderResultType[]>(`tasks/kanban-order/`, { params });
};
