import { AxiosResponse } from "axios";

import { TeamId } from "shared/types";
import api from "shared/api/v1";

import { NoteFileResponse } from "../types";

export default function uploadNoteFile(
  data: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<NoteFileResponse>> {
  return api.post<NoteFileResponse>(`notes/files/`, data, {
    params: permissions,
  });
}
