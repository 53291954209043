import { useEffect, useMemo, useRef, useState } from "react";

import Slider from "rc-slider";
import clsx from "clsx";

import setElementWidth from "shared/helpers/setElementWidth";
import getSliderThumbElement from "shared/helpers/getSliderThumbElement";
import { BUSD, DEFAULT_PROGRESS_VALUE, PERCENTS } from "shared/helpers/constant";
import transform from "shared/helpers/transform";
import scale from "shared/helpers/scale";
import removeExtraDecimals from "shared/helpers/removeExtraDecimals";

import { PresetScaleType } from "modules/risks/register/types";

import SliderLevelTitle from "./SliderLevelTitle";

export const MultiSlider = ({
  handleChange,
  min,
  max,
  step,
  scaleType,
  labels,
  primaryColors,
  ringColors,
  values,
  isCustomPreset,
}: {
  values: Array<number>;
  handleChange: (data: Array<number>) => void;
  primaryColors: Array<string>;
  ringColors: Array<string>;
  min: number;
  max: number;
  step?: number;
  scaleType: PresetScaleType;
  labels: Array<string>;
  isCustomPreset: boolean;
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const widthRef = useRef<HTMLSpanElement | null>(null);
  const sliderTooltipRef = useRef<HTMLSpanElement | null>(null);
  const [sliderValues, setSliderValues] = useState<Array<number>>([]);

  const isSimpleScale = useMemo(() => (scaleType === "simple" ? true : false), [scaleType]);

  useEffect(() => {
    setElementWidth(sliderValues[0], widthRef, isSimpleScale);
  }, [sliderRef, sliderValues]);

  useEffect(() => {
    setSliderValues(values);
  }, [values]);

  const setTooltipValue = (value: number, left: string, isVisible: boolean) => {
    if (sliderTooltipRef.current) {
      sliderTooltipRef.current.innerHTML = `${getDisplayedValue(value)}  <span></span>`;
      sliderTooltipRef.current.style.left =
        Number(left.replace("%", "")) <= 3 ? `${Number(left.replace("%", ""))}%` : left;
      sliderTooltipRef.current.style.visibility = isVisible ? "visible" : "hidden";
    }
  };

  const handleHover = (event: Event) => {
    if (sliderTooltipRef.current && event.target instanceof HTMLElement) {
      setTooltipValue(Number(event?.target?.ariaValueNow), `${event?.target?.style.left}`, true);
    }
  };

  useEffect(() => {
    getSliderThumbElement("rc-slider-handle", sliderRef.current).forEach((element) => {
      element.addEventListener("mouseover", handleHover);
      element.addEventListener("mouseout", handleAfterChange);
    });

    return () => {
      getSliderThumbElement("rc-slider-handle", sliderRef.current).forEach((element) => {
        element.removeEventListener("mouseover", handleHover);
        element.removeEventListener("mouseout", handleAfterChange);
      });
    };
  }, [sliderValues, sliderTooltipRef, sliderRef.current]);

  const handleAfterChange = () => {
    if (sliderTooltipRef.current) {
      sliderTooltipRef.current.style.visibility = "hidden";
    }
  };

  function getSliderDisplayValue(value: number, scaleType: PresetScaleType) {
    const scalledValue = scale(value, scaleType);

    switch (scaleType) {
      case PERCENTS:
        return `${scalledValue}&#37;`;

      case BUSD:
        return scalledValue + " BUSD";

      default:
        return scalledValue;
    }
  }

  function getSliderTooltipWidth() {
    switch (scaleType) {
      case BUSD:
        return `-12.5%`;

      default:
        return `-11.5%`;
    }
  }

  const getDisplayedValue = (value: number): string | number => {
    return getSliderDisplayValue(isSimpleScale ? value : transform(value), scaleType);
  };

  const handleChangeSlider = (value: number | Array<number>) => {
    if (Array.isArray(value)) {
      setElementWidth(value[0], widthRef, isSimpleScale);
      handleChange(value);

      if (sliderTooltipRef.current) {
        const selectedElement = getSliderThumbElement(
          "rc-slider-handle-dragging",
          sliderRef.current
        )[0];

        if (selectedElement instanceof HTMLDivElement) {
          const indexOfSelectedElement = Number(selectedElement?.classList[1].slice(-1));
          setTooltipValue(
            removeExtraDecimals(value[indexOfSelectedElement - 1]),
            `${selectedElement.style.left}`,
            true
          );
        }
      }
    }
  };

  const getBackgroundColorForTrack = useMemo(() => {
    return primaryColors.map((color) => {
      return {
        backgroundColor: color,
      };
    });
  }, [primaryColors]);

  const getBackgroundColorForThumb = useMemo(
    () =>
      ringColors.map((ring, index) => {
        return { backgroundColor: primaryColors[index], borderColor: ring, borderWidth: "2px" };
      }),
    [ringColors, primaryColors]
  );

  return (
    <div className="relative w-full mt-8 ml-2" ref={sliderRef}>
      <Slider
        disabled={!isCustomPreset}
        range
        ariaLabelForHandle={sliderValues.map((val) => `${val}`)}
        min={min}
        max={max}
        step={step}
        count={sliderValues.length - 1}
        onAfterChange={handleAfterChange}
        onChange={handleChangeSlider}
        value={sliderValues}
        pushable
        draggableTrack={false}
        trackStyle={getBackgroundColorForTrack.slice(1)}
        handleStyle={getBackgroundColorForThumb}
        railStyle={{ backgroundColor: primaryColors.slice(-1)[0] }}
      />
      <span
        className="start-track block absolute h-1 top-[5px]"
        style={{ backgroundColor: primaryColors[0] }}
        ref={widthRef}
      />
      <span
        onMouseOver={() => {
          setTooltipValue(
            isSimpleScale ? removeExtraDecimals(DEFAULT_PROGRESS_VALUE) : DEFAULT_PROGRESS_VALUE,
            `0%`,
            true
          );
        }}
        onMouseOut={() => {
          setTooltipValue(
            isSimpleScale ? removeExtraDecimals(DEFAULT_PROGRESS_VALUE) : DEFAULT_PROGRESS_VALUE,
            `0%`,
            false
          );
        }}
        className="start-range w-[10px] h-[10px] rounded-lg bg-lightSilver block absolute top-[2px] -left-[8px]"
      />
      <span
        onMouseOver={() => {
          setTooltipValue(isSimpleScale ? transform(237) : 237, `100%`, true);
        }}
        onMouseOut={() => {
          setTooltipValue(isSimpleScale ? transform(237) : 237, `100%`, false);
        }}
        style={{
          backgroundColor: primaryColors[primaryColors.length - 1],
          borderColor: ringColors[ringColors.length - 1],
          borderWidth: "2px",
        }}
        className="end-range w-[10px] h-[10px] rounded-lg  block absolute top-[2px] -right-[8px] border-2"
      />
      <span
        ref={sliderTooltipRef}
        className={clsx("react_tooltip", { "w-[6.8rem]": scaleType === BUSD })}
        style={{
          marginLeft: getSliderTooltipWidth(),
        }}
      ></span>
      {labels.map((label, index) => {
        return (
          <SliderLevelTitle
            label={label}
            index={index}
            sliderRef={sliderRef}
            values={sliderValues}
          />
        );
      })}
    </div>
  );
};
