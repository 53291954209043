import { CompanyResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";
import { RelatedObjectType } from "modules/tasks/overview/types";

/**
 * Get the team of a related object
 */
export default function getRelatedObjectTeam(
  relatedObject: RelatedObjectType,
  company: CompanyResponse
): TeamResponse | undefined {
  const { team } = relatedObject;

  return company.teams.find((t) => t.id === team);
}
