import React from "react";

import moment from "moment";

import { InvoiceResponseProps } from "../types";

export default function BillingInvoice({ billings }: { billings?: Array<InvoiceResponseProps> }) {
  return (
    <div>
      <h4 className="text-base font-inter-medium px-4 py-3 md:pl-6 dark:text-white">Invoices</h4>
      <div className="min-w-[310px] md:min-w-[500px] overflow-x-auto">
        {billings?.map((invoice: InvoiceResponseProps, index: number) => (
          <div
            className="flex overflow-x-auto py-3 px-4 md:px-6 text-sm mac13Inch:px-7 maxMd:min-w-[460px]"
            key={index}
          >
            <span className="w-[33.5%] text-davygrey dark:text-greychateau">
              {moment(invoice.created).format("MMMM DD, YYYY")}
            </span>
            <a href={invoice.invoice_pdf} className="w-[26%] text-davygrey dark:text-greychateau">
              View invoice
            </a>
            <span className="w-[20%] text-mirage dark:text-greychateau">{invoice.amount_paid}</span>
            <span className="w-[20%] text-mirage dark:text-greychateau text-center font-inter-medium">
              {invoice.paid ? "Paid" : "Pending"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
