import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { GetTaskResultType } from "shared/types";

import { convertTaskData } from "../helpers";

export const getTaskById = async ({
  taskId,
  teamId,
  companyId,
  isAllTeam,
}: {
  taskId: number;
  teamId: number;
  companyId: number;
  isAllTeam?: boolean;
}): Promise<AxiosResponse<GetTaskResultType>> => {
  const teamParameter = isAllTeam ? "all" : teamId;
  const params = new URLSearchParams();
  params.append("company", companyId?.toString());
  params.append("team", `${teamParameter}`);

  let response = await api.get<GetTaskResultType>(`tasks/${taskId}/`, {
    params,
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertTaskData(response.data),
    };
  }

  return response;
};
