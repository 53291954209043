import React from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import { usePlans } from "shared/store/settings";

import style from "assets/css/settings.module.css";
import { PaymentMethodResponse } from "../types";

export default function BillingDetails({
  billingDetails,
}: {
  billingDetails: PaymentMethodResponse;
}) {
  const plans = usePlans((state) => state.plans);

  return (
    <div className={style.layout_card_info}>
      <div className="maxMd:px-4 md:flex flex-wrap justify-between items-center mac13Inch:px-6 mac13Inch:justify-start">
        <div className="md:w-[calc(100%-142px)]">
          <p className="!text-mirage dark:!text-greychateau text-sm !mt-0">
            This company team plan is set to{" "}
            {`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(2)} `}
            per month and will renew on{" "}
            <b className="font-bold">
              {moment(billingDetails?.created).add(1, "months").format("MMMM DD, YYYY.")}
            </b>
          </p>
        </div>
        <div className="md:text-right maxMd:mt-2">
          <Link to={`/settings/upgrade`} className="btn_secondary">
            Change Plan
          </Link>
        </div>
      </div>
    </div>
  );
}
