import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetAllRegisterRequestParams, PaginatedRegisterListResponse } from "../types";
import { convertRiskData } from "../helpers";

export const getRegisters = async (
  requestParams: GetAllRegisterRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PaginatedRegisterListResponse>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<PaginatedRegisterListResponse>(`risks/`, {
    params: {
      ...requestParams,
      ...{
        team: teamParameter,
        company: permissions.team?.company,
        ...hasNameOrdering(requestParams),
      },
    },
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertRiskData(data);
          }) || [],
      },
    };
  }
  return response;
};
