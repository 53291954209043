import { useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Spinner } from "flowbite-react";
import { createPortal } from "react-dom";
import cloneDeep from "lodash/cloneDeep";
import clsx from "clsx";

import { useRiskSettings, useSettings, useTeam } from "shared/store/settings";
import { useRisk } from "shared/store/risks";
import {
  BUSD,
  BUSD_SCALE_TYPE,
  PERCENTS_SCALE_TYPE,
  SIMPLE,
  SIMPLE_SCALE_TYPE,
} from "shared/helpers/constant";
import { getServerErrors } from "shared/helpers/util";
import GrandSpinner from "shared/components/spinner/Spinner";
import { useCompany } from "shared/context/CompanyProvider";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";

import styles from "assets/css/settings.module.css";

import { getPresets } from "modules/risks/register/api/getPresets";
import { PresetInfo, PresetScaleType } from "modules/risks/register/types";
import { updateCompanyPreset } from "modules/risks/register/api/updateCompanyPreset";

import PresetCart from "./components/PresetCart";
import PresetDropdown from "./components/PresetDropdown";
import { useUpdateRiskSettings } from "./hooks/useUpdateRiskSettings";
import PresetActions from "./components/PresetActions";
import { deleteCustomPreset, updateCustomPreset } from "./api";
import useGetCompanyPreset from "./hooks/useGetCompanyPreset";

export default function Risks() {
  const currentTeam = useTeam((state) => state.currentTeam);
  const { currentCompany } = useCompany();

  const [currentCompanyPreset] = useRisk((state) => [state.currentCompanyPreset]);

  const [presetList, setPresetsList] = useRiskSettings((store) => [
    store.presetList,
    store.setPresetsList,
  ]);

  const setIsModalOpen = useSettings((state) => state.setIsModalOpen);

  const [presetDeleteId, setPresetDeleteId] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { values, setFieldValue, resetForm, handleSubmit, errors } = useUpdateRiskSettings(() => {
    if (values.preset?.company) {
      updateCustomPresetFn(values?.preset);
      resetForm();
    } else {
      toast("Default Preset is not Updateable", { type: "warning" });
    }
  });

  const {
    isLoading: presetLoading,
    isFetching: presetFetching,
    refetch: refetchAllPresets,
  } = useQuery(
    ["getPresetsList", currentTeam?.id],
    () =>
      getPresets({
        team: {
          team: currentTeam?.id,
          company: currentTeam?.company,
          isAllTeam: currentTeam?.is_all_team,
        },
      }),
    {
      onSuccess: (response) => {
        setPresetsList(response.data);
        getCurrentPreset();
      },
      onError: (e: AxiosError) => toast(getServerErrors(e), { type: "error" }),
      enabled: !!currentTeam,
    }
  );

  const {
    data: defaultSelectedPreset,
    isLoading: currentPresetLoading,
    refetch: getCurrentPreset,
  } = useGetCompanyPreset();

  const { mutateAsync: updateCompanyPresetFn, isLoading: isPresetLoading } = useMutation(
    ["updateCompanyPreset", currentTeam?.id],
    ({ presetId }: { presetId: number }) =>
      updateCompanyPreset({
        companyId: currentTeam.company,
        presetId: presetId,
      }),
    {
      onSuccess: () => {
        toast("Preset Update Successfully", { type: "success" });
        refetchAllPresets();
      },
      onError: (e: AxiosError) => toast(getServerErrors(e), { type: "error" }),
    }
  );

  const { mutateAsync: updateCustomPresetFn, isLoading: isCustomPresetLoading } = useMutation(
    ["updateCustomPreset", currentCompany?.id],
    (preset: PresetInfo) => updateCustomPreset(preset, currentCompany?.id),
    {
      onSuccess: () => {
        refetchAllPresets();
      },
      onError: (e: AxiosError) => toast(getServerErrors(e), { type: "error" }),
    }
  );

  const { mutate: deletePresetFn, isLoading: isDeletingPreset } = useMutation(
    (presetId: number) =>
      deleteCustomPreset({ presetId }, { team: currentTeam?.id, company: currentCompany?.id }),
    {
      onSuccess: async () => {
        toast("Preset Deleted Successfully.", { type: "success" });
        await refetchAllPresets();

        setIsDeleteModalOpen(false);
        setPresetDeleteId(null);
      },
      onError: (e: AxiosError): AxiosError => {
        toast("Something went wrong", { type: "error" });
        return e;
      },
    }
  );

  const handleDeleteModal = (state: boolean, presetId?: number) => {
    setIsDeleteModalOpen(state);
    if (presetId) {
      setPresetDeleteId(presetId);
    }
  };

  function getScaleType(type?: PresetScaleType) {
    switch (type) {
      case SIMPLE:
        return SIMPLE_SCALE_TYPE;

      case BUSD:
        return BUSD_SCALE_TYPE;

      default:
        return PERCENTS_SCALE_TYPE;
    }
  }

  return (
    <>
      {presetLoading ||
      presetFetching ||
      currentPresetLoading ||
      isCustomPresetLoading ||
      isPresetLoading ? (
        <GrandSpinner />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className={styles.setting_risks}>
              <div className="md:w-2/4 mb-4">
                <PresetDropdown
                  data={presetList?.results || []}
                  title="Preset"
                  value={values?.preset?.id || presetList?.results[0].id || 1}
                  handleChange={(data) => {
                    if (data && data?.id !== values.preset?.id) {
                      updateCompanyPresetFn({ presetId: data?.id });
                    }
                  }}
                  defaultPreset={defaultSelectedPreset?.preset_info}
                  handleDeleteModal={handleDeleteModal}
                  companyPresetId={defaultSelectedPreset?.preset || null}
                />
              </div>
              <hr />
              <div className={clsx(styles.presetContainer)}>
                <div className={styles.presetTitle}>
                  <h3 className="text-lg dark:text-white">{values.preset?.name} </h3>
                  {values.preset?.id === defaultSelectedPreset?.preset ? (
                    <span className={styles.presetStatus}>Active Preset</span>
                  ) : null}
                  {values?.preset?.company ? (
                    <PresetActions
                      companyId={values?.preset?.company}
                      currentPresetID={values?.preset?.id}
                      handleDeleteModal={handleDeleteModal}
                      handleRename={(name: string) => {
                        if (currentCompanyPreset?.preset_info) {
                          updateCustomPresetFn({
                            ...currentCompanyPreset?.preset_info,
                            name,
                          });
                        }
                      }}
                      preset={cloneDeep({ ...values.preset })}
                    />
                  ) : null}
                </div>

                <PresetCart
                  handleScaleChange={(scale) => {
                    setFieldValue(
                      "preset",
                      cloneDeep({ ...values.preset, probability_scale_type: scale })
                    );
                  }}
                  errors={errors}
                  isCustomPreset={values.preset?.company ? true : false}
                  presetData={{
                    title: "Probability",
                    scale: getScaleType(values.preset?.probability_scale_type),
                    description: values.preset?.probability_description || "N/A",
                    scaleType: values.preset?.probability_scale_type || "percents",
                  }}
                  scaleValue={values.preset?.probability_scale_type || "percents"}
                  scale={["percents", "simple"]}
                  fieldName="probability_levels"
                  handleChange={(value) => {
                    const updatedPreset = {
                      ...values.preset,
                      probability_levels: value,
                    };

                    setFieldValue("preset", cloneDeep(updatedPreset));
                  }}
                  levels={cloneDeep(values.preset?.probability_levels || [])}
                />
                <hr />
                <PresetCart
                  handleScaleChange={(scale) =>
                    setFieldValue(
                      "preset",
                      cloneDeep({ ...values.preset, impact_scale_type: scale })
                    )
                  }
                  errors={errors}
                  isCustomPreset={values.preset?.company ? true : false}
                  presetData={{
                    title: "Impact",
                    scale: getScaleType(values.preset?.impact_scale_type),
                    description: values.preset?.impact_description || "N/A",
                    scaleType: values.preset?.impact_scale_type || "busd",
                  }}
                  scale={["busd"]}
                  scaleValue={values.preset?.impact_scale_type || "busd"}
                  levels={cloneDeep(values.preset?.impact_levels || [])}
                  fieldName="impact_levels"
                  handleChange={(value) => {
                    const updatedPreset = {
                      ...values.preset,
                      impact_levels: value,
                    };
                    setFieldValue("preset", cloneDeep(updatedPreset));
                  }}
                />
              </div>
              <div className="py-4 text-right sticky bottom-0 bg-white  dark:bg-darkjunglegreenss z-[1]">
                <button
                  className="btn_secondary mr-2"
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button className="btn_primary" type="submit">
                  <Spinner
                    size="md"
                    light={true}
                    hidden={!isPresetLoading || !isCustomPresetLoading}
                    className="mr-3 fill-crayolasblue stroke-crayolasblue"
                  />
                  Update
                </button>
              </div>
            </div>
          </form>
          {createPortal(
            <ConfirmModal
              isLoading={isDeletingPreset}
              isOpen={isDeleteModalOpen}
              setIsOpen={setIsDeleteModalOpen}
              action={() => {
                if (presetDeleteId) {
                  deletePresetFn(presetDeleteId);
                }
              }}
            />,
            document.body
          )}
        </>
      )}
    </>
  );
}
