import { create } from "zustand";

interface horizonScanningMenuBarProps {
  toggleMenuBar: boolean;
  setToggleMenuBar: (isToggle: boolean) => void;
}

export const useHorizonScanningMenuBar = create<horizonScanningMenuBarProps>((set) => ({
  toggleMenuBar: false,
  setToggleMenuBar: (toggleMenuBar: boolean) => set({ toggleMenuBar }),
}));
