import find from "lodash/find";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

import { ModuleProps, Permissions } from "shared/data/PermissionList";

import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

function removeExtraPermissionName(permission?: Array<string>): Array<string> {
  const fullAccess = Permissions.find((item) => item.name === "Full Access");
  let isExtraPermission = false;
  permission?.forEach((item) => {
    if (!fullAccess?.value.includes(item)) {
      isExtraPermission = true;
    }
  });
  if (isExtraPermission) {
    return fullAccess?.value || [];
  } else {
    return permission || [];
  }
}

export function getPermissionName(
  data: CompanyUserResponse | TeamResponse | null,
  module: ModuleProps
): string {
  if (!data?.custom_permissions || !data?.custom_permissions[module.key]) {
    return "No Access";
  }

  return (
    find(Permissions, (item) =>
      isEqual(
        item.value.sort(),
        removeExtraPermissionName(get(data.custom_permissions, module.key)).sort()
      )
    )?.name || "No Access"
  );
}
