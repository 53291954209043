import { create } from "zustand";

import { PayloadType } from "modules/articles/subscription/types";

interface Subscription {
  subscription: PayloadType[];
  setSubscription: (subscription: PayloadType[]) => void;
  deleteSubscription: PayloadType[];
  setDeleteSubscription: (subscription: PayloadType[]) => void;
}

export const useSubscription = create<Subscription>((set) => ({
  subscription: [],
  setSubscription: (subscription: PayloadType[]) => set({ subscription }),
  deleteSubscription: [],
  setDeleteSubscription: (deleteSubscription: PayloadType[]) => set({ deleteSubscription }),
}));

interface SubscriptionActive {
  subscriptionActive: boolean;
  setSubscriptionActive: (setSubscriptionActive: boolean) => void;
}

export const useSubscriptionActive = create<SubscriptionActive>((set) => ({
  subscriptionActive: false,
  setSubscriptionActive: (subscriptionActive: boolean) => set({ subscriptionActive }),
}));
