import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import ToggleSwitch from "shared/components/toggle-switch";

const ReminderOption = ({
  option,
  subOptionTitle,
  subOptionDescription,
  dropDown,
}: {
  option?: string;
  subOptionTitle?: string;
  subOptionDescription?: string;
  dropDown: [];
}) => {
  const [selectRange, setSelectRande] = useState<string>("never");
  const [isToggle, setIsToggle] = useState<boolean>(false);

  return (
    <div className="py-4 px-11 border-b border-brightgray dark:border-thunders">
      <h5 className="font-inter-medium mb-2 dark:text-white">{option}</h5>
      <div className="flex flex-wrap justify-between items-end">
        <div className="w-[calc(100%-44px)]">
          <h6 className="font-inter-medium dark:text-white">{subOptionTitle}</h6>
          <span className="text-sm text-aurometalsaurus dark:text-greychateau">
            {subOptionDescription}
          </span>
        </div>
        <div>
          <ToggleSwitch
            checked={isToggle}
            handleChange={(event) => setIsToggle(event.target.checked)}
            name="receive_email_notifications"
          />
        </div>
      </div>
      <div className="mt-2 custom_select">
        {dropDown ? (
          <select onChange={(event) => setSelectRande(event.target.value)}>
            <option value="never">Never</option>
            <option value="every_week">Every Week</option>
            <option value="every_2_week">Every 2 Weeks</option>
            <option value="every_month">Every Month</option>
            <option value="every_year">Every Year</option>
            <option value="custom">Custom</option>
          </select>
        ) : null}

        {selectRange === "custom" ? (
          <div className="grid grid-cols-2 gap-2 mt-2">
            <NumericFormat className="form-control" placeholder="Number" allowNegative={false} />
            <select>
              <option>Day</option>
              <option>Week</option>
              <option>Month</option>
              <option>Year</option>
            </select>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReminderOption;
