import React from "react";

import { Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ExclamationCircle } from "assets/images/icon/exclamation-circle.svg";

const MobileNavigationModal = ({ isModalOpen }: { isModalOpen: boolean }) => {
  const navigate = useNavigate();
  return (
    <Modal
      size="sm"
      show={isModalOpen}
      onClose={() => navigate("/", { replace: true })}
      dismissible={true}
      className="mobile-navigation-modal"
    >
      <Modal.Body>
        <div>
          <ExclamationCircle className="mx-auto mb-2" />
          <p className="text-sm leading-relaxed text-aurometalsaurus dark:text-white text-center">
            Navigation from mobile has not been optimized yet.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MobileNavigationModal;
