import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { ForgotPasswordRequestParams, ForgotPasswordResponseParams } from "../type";

export default function forgotPassword(
  email: ForgotPasswordRequestParams
): Promise<AxiosResponse<ForgotPasswordResponseParams>> {
  return api.post<ForgotPasswordResponseParams>("auth/password/reset/", email);
}
