import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedCompanyMember } from "../types";

export default async function getMembersByCompanyId(
  companyId: number,
  requestParams?: { ordering?: string; page?: number; search?: string }
): Promise<AxiosResponse<PaginatedCompanyMember>> {
  return api.get<PaginatedCompanyMember>(`companies/members/?company=${companyId}`, {
    params: { ...hasNameOrdering(requestParams, "user__name,user__email") },
  });
}
