import includes from "lodash/includes";

import { UtmSourceType } from "shared/types";

/**
 * Check is utmSource is UtmSource. Out UtmSource is a utm source that could be tracked. by
 * conversion tracking.
 * @param utmSource
 */
function isUtmSource(utmSource: string | null): utmSource is UtmSourceType {
  return includes(["google", "meta", "reddit", "linkedin", "twitter"], utmSource);
}

export default isUtmSource;
