import React from "react";

import toString from "lodash/toString";

import { useCompany } from "shared/context/CompanyProvider";
import Icon from "shared/components/icon/Icon";

import Avatar from "../avatar";
import { CompanyProps } from "shared/context/types/company";

type CompaniesListProps = { onCompanyClick: (company: CompanyProps) => void };

const ComapniesList = ({ onCompanyClick }: CompaniesListProps) => {
  const { currentCompany, companies } = useCompany();
  return (
    <>
      {companies?.length >= 1 &&
        companies?.map((company) => (
          <div
            key={company.id}
            className="flex items-center rounded-lg py-1.5 px-2 gap-2 hover:bg-antiflashwhite hover:dark:bg-the_end cursor-pointer"
            onClick={() => onCompanyClick(company)}
          >
            <Avatar
              className="rounded-lg"
              name={company?.name}
              url={toString(company?.image)}
              avatarFor="company"
              avatarType="squared"
              size="regular"
            />
            <div className="flex flex-wrap break-words w-[calc(100%_-_66px)]">
              <span className="text-sm font-inter-medium text-brightgrey dark:text-silverpolish">
                {company?.name}
              </span>
            </div>
            <div className="flex items-center">
              {company?.id === currentCompany?.id ? (
                <Icon type="checkmark" fill={true} size="icon-sm" className="sidebar_icon" />
              ) : null}
            </div>
          </div>
        ))}
    </>
  );
};

export default ComapniesList;
