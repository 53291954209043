import { useFormik } from "formik";

import { manageCustomPreset } from "../validation";
import { CreatePresetRequest } from "../types";

export const useManagePresetForm = (
  companyId: number,
  preset?: CreatePresetRequest,
  action?: CallableFunction
) => {
  return useFormik<CreatePresetRequest>({
    initialValues: {
      name: preset?.name || "",
      company: preset?.company || companyId || null,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: manageCustomPreset,
    onSubmit: () => {
      action && action();
    },
  });
};
