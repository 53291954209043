import React from "react";

import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import values from "lodash/values";

import Spinner from "shared/components/spinner/Spinner";

import { SearchResultResponseType } from "../types";
import NoSearchResultFound from "./NoSearchResultFound";
import SearchResultRowHeader from "./SearchResultRowHeader";
import SearchResultRow from "./SearchResultRow";

type SearchResultBodyProps = {
  globalLoader: boolean;
  searchKey: string;
  searchData: SearchResultResponseType[];
  selectedIndex: number | null;
  mapRefToElement: (el: HTMLDivElement | null, index: number) => void;
};

const SearchResultBody = (props: SearchResultBodyProps) => {
  const { globalLoader, searchKey, searchData, selectedIndex, mapRefToElement } = props;

  const groupByType = flatten(values(groupBy(searchData, (searchItem) => searchItem.object_type)));

  if (globalLoader) {
    return (
      <div className="no-searchfound spinner-center">
        <Spinner />
      </div>
    );
  }

  if (searchData?.length === 0 && searchKey !== "" && !globalLoader) {
    return <NoSearchResultFound />;
  }

  return (
    <div data-testid="search-body" className="search_body">
      {groupByType?.length > 0 ? (
        <>
          {groupByType?.map(
            (searchResult: SearchResultResponseType, index: number, searchResultArray) => (
              <div
                className="py-[5px]"
                key={searchResult.id}
                ref={(el) => mapRefToElement(el, index)}
              >
                <SearchResultRowHeader
                  searchResult={searchResult}
                  searchResultArray={searchResultArray}
                  index={index}
                />
                <SearchResultRow
                  key={searchResult.object_id}
                  item={searchResult}
                  index={index}
                  selectedIndex={selectedIndex}
                  searchKey={searchKey}
                />
              </div>
            )
          )}
        </>
      ) : null}
    </div>
  );
};

export default SearchResultBody;
