import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

import { useTeam } from "shared/store/settings";
import { useRisk } from "shared/store/risks";
import { getServerErrors } from "shared/helpers/util";

import { getPresetByCompanyId } from "modules/risks/register/api/getPresetByCompanyId";

function useGetCompanyPreset(isSuspense?: boolean) {
  const currentTeam = useTeam((state) => state.currentTeam);
  const [currentCompanyPreset, setCurrentCompanyPreset] = useRisk((store) => [
    store.currentCompanyPreset,
    store.setCurrentCompanyPreset,
  ]);

  const {
    isLoading,
    isFetching: currentPresetFetching,
    refetch: getCurrentPreset,
    isError,
    error,
  } = useQuery(
    ["getDefaultCompanyPreset", currentTeam?.id, currentTeam.company, currentCompanyPreset?.preset],
    () =>
      getPresetByCompanyId(
        { companyId: currentTeam.company },
        {
          team: {
            team: currentTeam?.id,
            company: currentTeam?.company,
            isAllTeam: currentTeam?.is_all_team,
          },
        }
      ),
    {
      onSuccess: (response) => setCurrentCompanyPreset(response.data),
      onError: (e: AxiosError) => toast(getServerErrors(e), { type: "error" }),
      enabled: !!currentTeam,
      suspense: isSuspense,
    }
  );

  return {
    isLoading: isLoading || currentPresetFetching,
    refetch: getCurrentPreset,
    data: currentCompanyPreset,
    isError,
    error,
  };
}

export default useGetCompanyPreset;
