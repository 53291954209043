import { CompanyMember } from "shared/context/types/company";

import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

import { isCompanyUserResponse } from "./isCompanyUserResponse";

export const getCompanyUserResponseData = (
  data: CompanyMember | CompanyUserResponse | TeamResponse | null
): CompanyUserResponse | null => {
  if (data && isCompanyUserResponse(data)) return data;
  return null;
};
