import React, { useState, useRef } from "react";

import { AxiosError } from "axios";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { useDarkMode, useOnClickOutside } from "usehooks-ts";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import logo from "assets/images/logo.svg";
import logoDark from "assets/images/logowhite.svg";
import debit from "assets/images/icons/debit.svg";
import credit from "assets/images/icons/credit.svg";
import visa from "assets/images/icons/visa.svg";
import amex from "assets/images/icons/amex.svg";
import styles from "assets/css/settings.module.css";
import { useCompany } from "shared/context/CompanyProvider";
import { usePlans } from "shared/store/settings";
import ServerErrors from "shared/components/server-errors";
import { countries } from "shared/helpers/settings";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import StaticDropdown from "shared/components/common-dropdown/static-dropdown";

import { PaginatedCompanyMember } from "modules/home/types";

import { SubscriptionProps } from "./types";
import { addSubscription } from "./api";
import useUpgradeForm from "./hooks/useUpgradeForm";
import usePolling from "./hooks/usePolling";
interface UpgardeModalProps {
  isUpgradePaymentModal: boolean;
  setIsUpgradePaymentModal: (isUpgradePaymentModal: boolean) => void;
  memberList?: PaginatedCompanyMember;
}

const UpgradeModal = ({
  isUpgradePaymentModal,
  setIsUpgradePaymentModal,
  memberList,
}: UpgardeModalProps) => {
  const upgradeModalRef = useRef<HTMLInputElement>(null);

  const stripe = useStripe();
  const elements = useElements();
  const { isDarkMode } = useDarkMode();

  const { currentCompany } = useCompany();
  const plans = usePlans((state) => state.plans);

  const [stripeErrors, setStripeErrors] = useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { documentBodyRef } = useDocumentBodyRef();

  const {
    isSuccess,
    isLoading,
    mutate: addSubscriptionFn,
    isError,
    error,
  } = useMutation((data: SubscriptionProps) => addSubscription(data, currentCompany?.id), {
    onSuccess: async (res) => {
      const confirm = await stripe?.confirmCardPayment(res.data?.clientSecret);
      if (confirm?.error) {
        setIsPolling(false);
        return toast("Payment Failed. Try again later.", { type: "error" });
      }
    },
    onError: (e: AxiosError): AxiosError => {
      toast("Subscription Failed. Try again later.", {
        type: "error",
      });
      return e;
    },
  });

  const { isPolling, setIsPolling } = usePolling(isSuccess, setIsUpgradePaymentModal);

  const { handleSubmit, values, errors, handleChange, setFieldValue } = useUpgradeForm(() =>
    createSubscription()
  );

  const createSubscription = async () => {
    setIsSubmitted(true);
    if (stripeErrors.cardExpiry || stripeErrors.cardNumber || stripeErrors.cardCvc) return;

    const cardElement = elements?.getElement("cardNumber");
    if (cardElement) {
      try {
        const paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: values.name,
            email: values.email,
            phone: values.phone_number,
            address: {
              line1: values.address?.line1,
              line2: values.address?.line2,
              city: values.address?.city,
              postal_code: values.address?.postal_code,
              state: values.address?.state,
              country: values.address?.country,
            },
          },
        });

        addSubscriptionFn({
          ...values,
          plan_id: plans[0].id,
          payment_method: paymentMethod?.paymentMethod?.id,
        });
      } catch (error) {
        toast("Subscription Failed. Try again later.", {
          type: "error",
        });
      }
    }
  };

  const onChange = (
    event:
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    if (!event.complete) {
      const filedError = { ...stripeErrors, [event.elementType]: true };
      setStripeErrors(filedError);
    } else {
      const filedError = { ...stripeErrors, [event.elementType]: false };
      setStripeErrors(filedError);
    }
  };

  const isCountrySelected = () => {
    return !!values.address?.country;
  };

  useOnClickOutside(upgradeModalRef, () => setIsUpgradePaymentModal(false));

  const getMonthlyAmount = () => {
    if (memberList?.count) {
      return `EUR ${((plans[0]?.tiers[0]?.unit_amount / 100) * memberList?.count).toFixed(2)}`;
    }
  };

  const shouldLoaderRender = () => {
    if (isLoading) {
      return true;
    } else if (isSuccess && isPolling) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Modal
        root={documentBodyRef}
        show={isUpgradePaymentModal}
        className={clsx(styles.upgrade_payment)}
        dismissible={true}
        size={"4xl"}
        onClose={() => {
          setIsUpgradePaymentModal(false);
        }}
      >
        <div className="md:hidden">
          <Modal.Header className="p-4">
            <span className="text-mirage dark:text-white text-lg font-inter-medium">
              Upgrade to Business
            </span>
          </Modal.Header>
        </div>

        <Modal.Body className={styles.upgrade_payment_modal} ref={upgradeModalRef}>
          <div className={styles.upgrade_modal}>
            <div className="maxMd:hidden">
              <img
                src={isDarkMode ? logoDark : logo}
                alt="logo-img"
                className="w-6 h-6 mac13Inch:w-4 mac13Inch:h-4"
              />
              <h4 className="text-mirage dark:text-white text-[1.75rem] font-sans-medium mt-2 maxMd:text-2xl mac13Inch:text-[1.5rem]">
                Upgrade to Business
              </h4>
            </div>
            <p className="text-aurometalsaurus dark:text-greychateau text-sm !mt-2 md:!mt-1">
              Write, plan, and work together in one spot.
            </p>
            <div id="select" className="mt-4">
              <div className="mb-2 block">
                <Label
                  htmlFor="countries"
                  value="Payment Information"
                  className="!font-semibold !text-mirage"
                />
              </div>
              <Select className="custom_select" id="countries" disabled>
                <option>{`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(
                  2
                )} per month`}</option>
              </Select>
            </div>
            <div className="my-4">
              <Label value="Payment Method" className="!font-semibold !text-mirage" />
              <div className={styles.paymentIcon}>
                <p className="!mt-0 mac13Inch:text-sm text-davygrey dark:text-greychateau mr-1">
                  Credit or Debit Card
                </p>
                <img src={debit} alt="debit-icon" />
                <img src={credit} alt="credit-icon" />
                <img src={visa} alt="visa-icon" />
                <img src={amex} alt="amex-icon" />
              </div>
            </div>
            <Label
              value="Billing Information"
              className="!font-semibold tracking-wide !text-mirage"
            />
            <div className="mb-4 md:mb-8 md:mt-4">
              <div className="md:mb-8 mt-2">
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Name"
                  value={values.name}
                  name="name"
                  color={errors.name ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Email"
                  value={values.email}
                  name="email"
                  color={errors.email ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Phone Number"
                  value={values.phone_number}
                  name="phone_number"
                  color={errors.phone_number ? "failure" : "gray"}
                />
                <StaticDropdown
                  placeholder="Select Country"
                  mainClassname={clsx("mb-2", {
                    [styles.selected]: isCountrySelected(),
                    [styles.selectCountry]: !isCountrySelected(),
                  })}
                  handleChange={(data) => {
                    setFieldValue("address.country", data || "");
                  }}
                  value={values.address?.country}
                  data={countries?.map((item) => ({ id: item.code, name: item.name || "" }))}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Address Line 1 (Street Address)"
                  value={values.address?.line1}
                  name="address.line1"
                  color={errors.address?.line1 ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Address Line 2  (Apartment, Suite, etc)"
                  value={values.address?.line2}
                  name="address.line2"
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="City"
                  value={values.address?.city}
                  name="address.city"
                  color={errors.address?.city ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Zip code"
                  value={values.address?.postal_code}
                  name="address.postal_code"
                  color={errors.address?.postal_code ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="State/province"
                  value={values.address?.state}
                  name="address.state"
                  color={errors.address?.state ? "failure" : "gray"}
                />
              </div>
            </div>
            {/* Card Component */}
            <Label value="Card Number" className="!font-semibold mb-2 !text-mirage" />
            <CardNumberElement
              onChange={onChange}
              id="card_number"
              options={{
                showIcon: true,
                style: {
                  base: {
                    color: isDarkMode ? "#A6A6A7" : "",
                  },
                },
              }}
              className={clsx(
                { error: isSubmitted && stripeErrors.cardNumber },
                "bg-gray-50 dark:bg-balticsea border border-gray-300 dark:border-iridium mt-1 !text-mirage text-sm rounded-lg block w-full p-2.5 card-number"
              )}
            />
            <div className="grid gap-4 md:gap-6 md:mb-6 md:grid-cols-2 my-4">
              <div>
                <Label value="Expiry" className="!font-semibold !text-mirage" />
                <CardExpiryElement
                  id="card_expiry"
                  options={{
                    style: {
                      base: {
                        color: isDarkMode ? "#A6A6A7" : "",
                      },
                    },
                  }}
                  className={clsx(
                    { error: isSubmitted && stripeErrors.cardExpiry },
                    "bg-gray-50 dark:bg-balticsea border border-gray-300 dark:border-iridium mt-1 text-gray-900 dark:text-greychateau text-sm rounded-lg block w-full p-2.5"
                  )}
                  onChange={onChange}
                />
              </div>
              <div>
                <Label value="CVC" className="!font-semibold !text-mirage" />
                <CardCvcElement
                  id="card_cvc"
                  options={{
                    style: {
                      base: {
                        color: isDarkMode ? "#A6A6A7" : "",
                      },
                    },
                  }}
                  onChange={onChange}
                  className={clsx(
                    { error: isSubmitted && stripeErrors.cardCvc },
                    "md:mb-11 bg-gray-50 dark:bg-balticsea border border-gray-300 dark:border-iridium mt-1 text-gray-900 dark:text-greychateau text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.upgrade_summary}>
            <Label
              htmlFor="countries"
              value="Order Summary"
              className="!font-inter-medium text-lg !text-mirage"
            />
            <ul className="md:mt-3.5">
              <li>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="countries"
                    value={`Business Plan (${memberList?.count} member)`}
                    className="text-base !text-mirage"
                  />
                  <h4>{getMonthlyAmount()}</h4>
                </div>
                <div className="flex md:mt-3">
                  <p>
                    {`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(2)} / user / month`}
                  </p>
                </div>
              </li>
              <li>
                <div className="flex justify-between">
                  <p>Subtotal</p>
                  <h4>{getMonthlyAmount()}</h4>
                </div>
              </li>
              <li>
                <div className="flex justify-between mt-4">
                  <p>Total</p>
                  <h4>{getMonthlyAmount()}</h4>
                </div>
              </li>
            </ul>
            <div className="flex mt-4 justify-between maxMd:flex-col">
              <button className="btn_secondary py-2.5 mb-4 w-[135px] maxMd:w-full">
                Contact sales
              </button>
              <button
                className="btn_primary py-2.5 md:mb-4 w-[155px] maxMd:w-full"
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                <Spinner
                  size="md"
                  light={true}
                  hidden={!shouldLoaderRender()}
                  className="mr-3 !fill-mirage stroke-mirage"
                />
                {shouldLoaderRender() ? "Upgrading" : "Upgrade"}
              </button>
            </div>
            {isError ? <ServerErrors className="mt-4" error={error || errors} /> : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpgradeModal;
