import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { PaginatedNotificationList } from "modules/notifications/types";

export const getNotifications = (requestParams: {
  page: number;
}): Promise<AxiosResponse<PaginatedNotificationList>> =>
  api.get<PaginatedNotificationList>("notifications/", { params: { ...requestParams } });
