import { RelatedObjectTypeName } from "modules/tasks/overview/types";

import {
  AGREEMENT_TABLE,
  ARTICLE_TABLE,
  DIRECTORIES,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  MITIGATION_TABLE,
  NOTE_TABLE,
  POLICY_TABLE,
  RISK_TABLE,
} from "./constant";
import { RISKV2 } from "../helpersV2/constant";

export const getModuleURL = (
  id: number,
  type: RelatedObjectTypeName | undefined,
  company?: number,
  list?: number
): string => {
  switch (type) {
    case AGREEMENT_TABLE:
      return `/agreements/overview/${id}/view`;
    case ARTICLE_TABLE:
      return `/articles/overview/${id}`;
    case POLICY_TABLE:
      return `/policies/overview/${id}/view`;
    case DOCUMENT_TABLE:
      return `/document/${id}`;
    case NOTE_TABLE:
      return `/notes/overview/${id}/view`;
    case RISK_TABLE:
      return `/risks/register/${id}/view`;
    case MITIGATION_TABLE:
      return `/risks/mitigations/${id}/view`;
    case INCIDENTS_TABLE:
      return `/risks/incidents/${id}/view`;
    case DIRECTORIES:
      return `/documents/${id}`;
    case RISKV2:
      return `/workspace/${company}/risks/registers/${list}/risks/${id}/edit`;
    default:
      return `/`;
  }
};
