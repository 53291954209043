import React from "react";

import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section className="bg-white dark:bg-darkjunglegreenss h-screen flex items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-mirage dark:text-white">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Something&apos;s missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Sorry, we can&apos;t find that page. You&apos;ll find lots to explore on the home page.{" "}
          </p>
          <Link to="/" className="my-4 btn_primary">
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
