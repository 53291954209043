import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedCompanyListResponse } from "../types";

function getCompanies(
  page = 1,
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<PaginatedCompanyListResponse>> {
  return api.get<PaginatedCompanyListResponse>("companies/", {
    params: { page, ...hasNameOrdering(requestParams) },
  });
}

export default getCompanies;
