import React from "react";
import ReactDOM from "react-dom/client";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { registerLicense } from "@syncfusion/ej2-base";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadAnalytics } from "./shared/analytics";
if (process.env.REACT_APP_NODE_ENV === "production") {
  if (process.env.REACT_APP_POSTHOG_KEY) {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
      api_host: "https://eu.posthog.com",
    });
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.REACT_APP_NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

loadAnalytics();

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE || "");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// class EmbeddedView extends HTMLElement {
//   connectedCallback() {
//     const mountPoint = document.createElement("html");
//     this.attachShadow({ mode: "open" }).appendChild(mountPoint);

//     const name = this.getAttribute("name");
//     const root = ReactDOM.createRoot(mountPoint);
//     root.render(<div>{name}</div>);
//   }
// }
// customElements.define("embedded-view", EmbeddedView);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
