import React from "react";

import Icon from "shared/components/icon/Icon";

export default function FilterTitle({
  handleClose,
  filterText,
}: {
  handleClose: () => void;
  filterText: string;
}) {
  return (
    <div
      className="flex items-center py-4"
      onClick={() => {
        handleClose();
      }}
    >
      <Icon type="leftarrow" fill={true} size="icon-md" className="sidebar_icon" />
      <span className="ml-2 text-lg font-inter-medium dark:text-white">{filterText}</span>
    </div>
  );
}
