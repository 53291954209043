import React, { useEffect, useState } from "react";
import { memo } from "react";
import { FilterConditon, FilterProps } from "../common-table/types";
import { FiltersProp, FilterValues } from "shared/components/common-table/types";
import { TextInput } from "flowbite-react";
import Select, { MultiValue } from "react-select";
import { useDarkMode } from "usehooks-ts";
import clsx from "clsx";
import { useQueries } from "@tanstack/react-query";
import get from "lodash/get";

import { selectControlStyleForCreate } from "shared/helpers/selectStyle";

import styles from "assets/css/agreement.module.css";

import {
  DATETIME_FILTER,
  DATE_FILTER,
  DROPDOWN_FILTER,
  TYPEAHEAD_FILTER,
  TEXT_FILTER,
  PREDEFINED_DATE_FILTER,
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  BOOLEAN_FILTER,
  PAGINATED_FILTER,
} from "../../helpers/constant";
import moment from "moment";
import CommonDatePicker from "../common-date-picker";
import { useFavoriteFilter } from "../../store/table-column-selection";
import PredefinedDateFilterInterval from "../common-table/PredefinedDateFilterInterval";
import BooleanInput from "../common-table/BooleanInput";
import { getObjectById } from "../common-table/api";
import { AsyncPaginate } from "react-select-async-paginate";
import getFilterDataByEndpoint from "../common-table/api/getFilterDataByEndpoint";
import CentralSpinner from "../spinner/CentralSpinner";

const FilterMenu = ({
  filterOption,
  filterConditions,
}: {
  filterOption?: FiltersProp;
  filterConditions: FilterConditon[];
}) => {
  const selectedValues = filterConditions?.filter(
    (condition) => condition.field === filterOption?.field
  );

  const results = useQueries({
    queries: filterOption?.responseDataDetails
      ? selectedValues.map((condition) => ({
          queryKey: [condition.value, condition.field],
          queryFn: async () =>
            await getObjectById(
              `${filterOption?.responseDataDetails?.endpoint}${condition.value}/`,
              filterOption?.responseDataDetails?.params
            ),
        }))
      : [],
  });

  const isLoading = results.some((result) => result.isLoading);
  const isSuccess = results.every((result) => result.isSuccess);

  const setSelectedFilters = useFavoriteFilter((state) => state.setSelectedFilters);

  const filterValuesMapper = (obj: FilterValues) => {
    return {
      value: obj.key,
      label: obj.value,
      name: obj.value,
    };
  };

  const [selectedData, setSelectedData] = useState<MultiValue<{ value: string; label: string }>>(
    []
  );

  const getValueFromDynamicField = (item: unknown, field: "id" | "name") => {
    if (filterOption?.responseDataDetails?.accessFieldName) {
      let filteredValue = "";
      filterOption?.responseDataDetails?.accessFieldName[field]
        ?.split(",")
        .forEach((field: string) => {
          if (filteredValue) {
            return;
          }
          filteredValue = get(item, field, "");
        });
      return filteredValue;
    }
    return null;
  };

  const getObject = (data: unknown) => {
    return {
      value: getValueFromDynamicField(data, "id") || get(data, "id", ""),
      label: getValueFromDynamicField(data, "name") || get(data, "name", ""),
    };
  };

  useEffect(() => {
    if (
      !results.find((res) => !res.data?.data) &&
      !selectedData.length &&
      selectedValues?.find((selectValue) => selectValue.field === filterOption?.field) &&
      isSuccess
    ) {
      const array = results.map((item) => getObject(item.data?.data));
      setSelectedData(array);
    }
  }, [results, isSuccess]);

  const { isDarkMode } = useDarkMode();

  const changeTextValue = (e: React.ChangeEvent<HTMLInputElement>, filterOption: FiltersProp) => {
    setSelectedFilters((_search: FilterProps) => {
      return {
        ..._search,
        conditions: [
          ..._search.conditions.filter((item: FilterConditon) => item.field != filterOption.field),
          { field: filterOption.field, operator: filterOption.operator, value: e.target.value },
        ],
      };
    });
  };

  const changeBooleanValue = (status: boolean | null, filterOption: FiltersProp) => {
    setSelectedFilters((_search: FilterProps) => {
      return {
        ..._search,
        conditions: [
          ..._search.conditions.filter((item: FilterConditon) => item.field != filterOption.field),
          {
            field: filterOption.field,
            operator: filterOption.operator,
            value: status?.toString() || "",
          },
        ],
      };
    });
  };

  const changePaginatedDropdownValue = (
    value: MultiValue<{
      value: string;
      label: string;
    }>,
    filterOption: FiltersProp
  ) => {
    setSelectedFilters((_search: FilterProps) => {
      return {
        ..._search,
        conditions: [
          ..._search.conditions.filter((item: FilterConditon) => item.field != filterOption.field),
          ...value.map((item) => ({
            field: filterOption.field,
            operator: filterOption.operator,
            value: item.value,
          })),
        ],
      };
    });
  };

  const changeDateValue = (
    date: Date | null,
    filterOption: FiltersProp,
    dateFilterType: string
  ) => {
    setSelectedFilters((_search: FilterProps) => {
      if (date == null) {
        return {
          ..._search,
          conditions: [
            ..._search.conditions.filter(
              (item: FilterConditon) =>
                !(item.field == filterOption.field && item.operator == dateFilterType)
            ),
          ],
        };
      } else {
        return {
          ..._search,
          conditions: [
            ..._search.conditions.filter(
              (item: FilterConditon) =>
                !(item.field == filterOption.field && item.operator == dateFilterType)
            ),
            {
              field: filterOption.field,
              operator: dateFilterType,
              value: moment(date)?.format("YYYY-MM-DD"),
            },
          ],
        };
      }
    });
  };

  const changeMultiValue = (
    values: MultiValue<{
      value: string;
      label: string;
      name: string;
    }>,
    filterOption: FiltersProp
  ) => {
    const multiValues = values.map((e) => {
      return {
        field: filterOption.field,
        operator: filterOption.operator,
        value: String(e.value),
      };
    });

    setSelectedFilters((_search: FilterProps) => {
      return {
        ..._search,
        conditions: [
          ..._search.conditions.filter(
            (item: FilterConditon) => !(item.field == filterOption.field)
          ),
          ...multiValues,
        ],
      };
    });
  };

  const changeSingleValue = (
    value: {
      value: string;
      label: string | null;
      name: string;
    },
    filterOption: FiltersProp
  ) => {
    setSelectedFilters((_search: FilterProps) => {
      return {
        ..._search,
        conditions: [
          ..._search.conditions.filter(
            (item: FilterConditon) => !(item.field == filterOption.field)
          ),
          {
            field: filterOption.field,
            operator: BEFORE_OPERATOR,
            value: value.value == "custom" ? "" : value.value,
          },
          {
            field: filterOption.field,
            operator: AFTER_OPERATOR,
            value: value.value == "custom" ? "" : value.value,
          },
        ],
      };
    });
  };

  const filterMenu = () => {
    const afterDate = filterConditions.find(
      (condition: FilterConditon) =>
        condition.field == filterOption?.field && condition.operator == AFTER_OPERATOR
    )?.value;

    const beforeDate = filterConditions.find(
      (condition: FilterConditon) =>
        condition.field == filterOption?.field && condition.operator == BEFORE_OPERATOR
    )?.value;

    const predefinedType =
      ["this_week", "this_month", "last_7_days", "last_30_days"].find(
        (item) => item == afterDate
      ) || "custom";

    isLoading ? <CentralSpinner /> : null;

    switch (filterOption?.type) {
      case TEXT_FILTER:
        return (
          <TextInput
            id="name"
            className="dark:placeholder-stormdust"
            placeholder={"Enter " + filterOption.label}
            onChange={(e) => {
              changeTextValue(e, filterOption);
            }}
            value={filterConditions.length > 0 ? filterConditions[0].value : ""}
          />
        );
      case PREDEFINED_DATE_FILTER:
        return (
          <>
            <Select
              classNamePrefix="multitag multitag_spacing"
              isClearable={false}
              className={clsx(styles.selectTemplate, "mb-4 md:mb-6")}
              isMulti={false}
              styles={selectControlStyleForCreate(isDarkMode)}
              value={
                predefinedType == "custom"
                  ? filterValuesMapper({ key: "custom", value: "Custom" })
                  : filterOption.values
                      ?.filter((item) => {
                        return (
                          filterConditions
                            .map((filterCondition) => filterCondition.value)
                            .indexOf(String(item.key)) >= 0
                        );
                      })
                      .map(filterValuesMapper)
              }
              placeholder={"Select " + filterOption.label}
              onChange={(e) => {
                changeSingleValue(e, filterOption);
              }}
              noOptionsMessage={() => "No options available"}
              options={filterOption.values?.map(filterValuesMapper)}
            />
            <div className="inline-flex gap-4 w-full maxMd:flex-col">
              <div className="md:w-[50%]">
                <CommonDatePicker
                  placeholder="Select Date From"
                  date={
                    afterDate
                      ? predefinedType == "custom"
                        ? moment(afterDate).toDate()
                        : PredefinedDateFilterInterval({
                            operator: AFTER_OPERATOR,
                            type: predefinedType,
                          }) || null
                      : null
                  }
                  handleChange={(date: Date | null) => {
                    changeDateValue(date, filterOption, AFTER_OPERATOR);
                  }}
                  warningLabel=""
                  disabled={predefinedType != "custom"}
                />
              </div>
              <div className="md:w-[50%]">
                <CommonDatePicker
                  placeholder="Select Date To"
                  date={
                    beforeDate
                      ? predefinedType == "custom"
                        ? moment(beforeDate).toDate()
                        : PredefinedDateFilterInterval({
                            operator: BEFORE_OPERATOR,
                            type: predefinedType,
                          }) || null
                      : null
                  }
                  handleChange={(date: Date | null) => {
                    changeDateValue(date, filterOption, BEFORE_OPERATOR);
                  }}
                  warningLabel=""
                  disabled={predefinedType != "custom"}
                />
              </div>
            </div>
          </>
        );
      case DATE_FILTER:
      case DATETIME_FILTER:
        return (
          <div className="inline-flex gap-4 w-full maxMd:flex-col">
            <div className="md:w-[50%]">
              <CommonDatePicker
                placeholder="Select Date From"
                date={afterDate ? moment(afterDate).toDate() : null}
                handleChange={(date: Date | null) => {
                  changeDateValue(date, filterOption, AFTER_OPERATOR);
                }}
                warningLabel=""
              />
            </div>
            <div className="md:w-[50%]">
              <CommonDatePicker
                placeholder="Select Date To"
                date={beforeDate ? moment(beforeDate).toDate() : null}
                handleChange={(date: Date | null) => {
                  changeDateValue(date, filterOption, BEFORE_OPERATOR);
                }}
                warningLabel=""
              />
            </div>
          </div>
        );
      case DROPDOWN_FILTER:
        return (
          <Select
            classNamePrefix="multitag multitag_spacing"
            isClearable={false}
            className={styles.selectTemplate}
            isMulti={true}
            styles={selectControlStyleForCreate(isDarkMode)}
            value={filterOption.values
              ?.filter((item) => {
                return (
                  filterConditions
                    .map((filterCondition) => filterCondition.value)
                    .indexOf(String(item.key)) >= 0
                );
              })
              .map(filterValuesMapper)}
            placeholder={"Select " + filterOption.label}
            onChange={(e) => {
              changeMultiValue(e, filterOption);
            }}
            noOptionsMessage={() => "No options available"}
            options={filterOption.values?.map(filterValuesMapper)}
          />
        );
      case TYPEAHEAD_FILTER:
        return (
          <Select
            classNamePrefix="multitag multitag_spacing"
            isClearable={false}
            className={styles.selectTemplate}
            isMulti={true}
            styles={selectControlStyleForCreate(isDarkMode)}
            value={filterOption.values
              ?.filter((item) => {
                return (
                  filterConditions
                    .map((filterCondition) => filterCondition.value)
                    .indexOf(String(item.key)) >= 0
                );
              })
              .map(filterValuesMapper)}
            placeholder={"Select " + filterOption.label}
            onChange={(e) => {
              changeMultiValue(e, filterOption);
            }}
            noOptionsMessage={() => "No options available"}
            options={filterOption.values?.map(filterValuesMapper)}
          />
        );
      case PAGINATED_FILTER:
        return (
          <AsyncPaginate
            debounceTimeout={300}
            loadOptions={async (search, prevOptions, args) => {
              const page = (args as { page: number })?.page || 1;

              const response = await getFilterDataByEndpoint(
                filterOption?.responseDataDetails?.endpoint || "",
                {
                  ...filterOption?.responseDataDetails?.params,
                  [filterOption?.responseDataDetails?.searchKey || "name__icontains"]: search,
                  ordering: filterOption?.responseDataDetails?.defaultOrdering || "name",
                  page: page,
                }
              );

              const { data } = response;
              const options = data?.results.map((item) => getObject(item));
              return {
                options: options || [],
                hasMore: !!data?.next,
                additional: {
                  page: page + 1,
                },
              };
            }}
            classNamePrefix="multitag multitag_spacing"
            isClearable={false}
            className={clsx(styles.selectTemplate, "!w-full")}
            name="receivers_provided_service"
            isMulti={true}
            placeholder={"Enter " + filterOption.label}
            styles={selectControlStyleForCreate(isDarkMode)}
            value={selectedData}
            onChange={(options) => {
              //set locally data to update the value of the dropdown
              if (options) {
                setSelectedData(options);
              } else {
                setSelectedData([]);
              }
              changePaginatedDropdownValue(options, filterOption);
            }}
            noOptionsMessage={() => "No Data Available"}
          />
        );
      case BOOLEAN_FILTER:
        return (
          <BooleanInput
            fieldName={filterOption.field}
            isChecked={
              filterConditions.length > 0 && filterConditions[0]?.value !== ""
                ? filterConditions[0]?.value === "true"
                  ? true
                  : false
                : null
            }
            handleChnage={(state) => {
              changeBooleanValue(state, filterOption);
            }}
          />
        );
      default:
        return null;
    }
  };

  return filterMenu();
};

export default memo(FilterMenu);
