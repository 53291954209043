import { create } from "zustand";

export type PaginatedSearchFieldProps = {
  category?: string;
  owner?: string;
  assignees?: string;
  commented_by?: string;
  agreement_type?: string;
  external_supplier?: string;
  internal_supplier?: string;
  party_to_agreement?: string;
  publisher?: string;
  exclude_publishers?: string;
  regions?: string;
  exclude_regions?: string;
  topics?: string;
  exclude_topics?: string;
  sections?: string;
  exclude_sections?: string;
  organization_users?: string;
  risk_type?: string;
  cause_type?: string;
  impact_types?: string;
  tags?: string;
  status?: string;
  industries?: string;
};

export type PaginationSearchModuleProps = {
  article?: PaginatedSearchFieldProps;
  policies?: PaginatedSearchFieldProps;
  task?: PaginatedSearchFieldProps;
  agreement?: PaginatedSearchFieldProps;
  agreement_category?: PaginatedSearchFieldProps;
  agreement_type?: PaginatedSearchFieldProps;
  supplier?: PaginatedSearchFieldProps;
  internal_supplier?: PaginatedSearchFieldProps;
  internal_entity?: PaginatedSearchFieldProps;
  document?: PaginatedSearchFieldProps;
  task_status?: PaginatedSearchFieldProps;
  note?: PaginatedSearchFieldProps;
  policy?: PaginatedSearchFieldProps;
  policy_template?: PaginatedSearchFieldProps;
  category?: PaginatedSearchFieldProps;
  community?: PaginatedSearchFieldProps;
  company?: PaginatedSearchFieldProps;
  risk?: PaginatedSearchFieldProps;
  mitigation?: PaginatedSearchFieldProps;
  incident?: PaginatedSearchFieldProps;
};

export type PaginationDropdownSearchProps = {
  paginationSearchData: PaginationSearchModuleProps;
  setPaginationSearchData: (paginationSearchData: PaginationSearchModuleProps) => void;
};

export const usePaginationSearch = create<PaginationDropdownSearchProps>((set) => ({
  paginationSearchData: {},
  setPaginationSearchData: (paginationSearchData: PaginationSearchModuleProps) => {
    set({ paginationSearchData: paginationSearchData });
  },
}));
