import convertToExcelFile from "./converToExcelFile";

export const emptyWorkbook = {
  jsonObject: {
    Workbook: {
      openUrl: "https://spreadsheet-dev.grand.io/api/Spreadsheet/Open",
      enableRtl: false,
      locale: "en-US",
      enablePersistence: false,
      cellStyle: {
        fontFamily: "Calibri",
        verticalAlign: "bottom",
        textIndent: "0pt",
        backgroundColor: "#ffffff",
        color: "#000000",
        textAlign: "left",
        fontSize: "11pt",
        fontWeight: "normal",
        fontStyle: "normal",
        textDecoration: "none",
        border: "",
        borderLeft: "",
        borderTop: "",
        borderRight: "",
        borderBottom: "",
      },
      showRibbon: true,
      showFormulaBar: true,
      showSheetTabs: true,
      allowEditing: true,
      allowOpen: true,
      allowSave: true,
      enableContextMenu: true,
      allowAutoFill: true,
      selectionSettings: {
        mode: "Multiple",
      },
      enableKeyboardNavigation: true,
      allowNumberFormatting: true,
      enableKeyboardShortcut: true,
      enableClipboard: true,
      allowCellFormatting: true,
      allowSorting: true,
      allowResizing: true,
      allowHyperlink: true,
      allowUndoRedo: true,
      allowFiltering: true,
      allowWrap: true,
      allowInsert: true,
      allowDelete: true,
      allowDataValidation: true,
      allowFindAndReplace: true,
      allowMerge: true,
      allowConditionalFormat: true,
      allowImage: true,
      allowChart: true,
      activeSheetIndex: 0,
      cssClass: "",
      height: "100%",
      width: "100%",
      allowScrolling: true,
      scrollSettings: {
        enableVirtualization: true,
        isFinite: false,
      },
      allowFreezePane: true,
      definedNames: [],
      isProtected: false,
      password: "",
      autoFillSettings: {
        showFillOptions: true,
      },
      showAggregate: true,
      filterCollection: [],
      sortCollection: [],
      saveUrl: "",
      sheets: [
        {
          columns: [{}, {}, {}, {}, {}, {}],
          name: "Sheet1",
          rows: [
            {
              cells: [{}, {}, {}, {}, {}, {}, {}],
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
            {
              cells: [{}, {}, {}, {}, {}, {}],
              height: 19,
            },
          ],
          selectedRange: "F12:F12",
          usedRange: {
            rowIndex: 17,
            colIndex: 5,
          },
          rowCount: 143,
          colCount: 100,
          topLeftCell: "A1",
          activeCell: "F12",
          showHeaders: true,
          showGridLines: true,
          state: "Visible",
          protectSettings: {
            selectUnLockedCells: false,
          },
          isProtected: false,
          paneTopLeftCell: "A1",
          frozenRows: 0,
          frozenColumns: 0,
          index: 0,
          id: 1,
        },
      ],
    },
  },
};

function createNewExcelFile(filename: string): Promise<File> {
  return convertToExcelFile(filename, emptyWorkbook);
}

export default createNewExcelFile;
