import { useEffect, useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";

/**
 * This component used to fire a callback when it is visible in the viewport.
 */
export default function ShowableItem({ onShow }: { onShow: () => void }) {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const isViewing = !!entry?.isIntersecting;

  useEffect(() => {
    if (isViewing) {
      onShow();
    }
  }, [isViewing]);

  return <div ref={ref} className="py-2" />;
}
