import React, { MouseEventHandler } from "react";

import { AxiosResponse } from "axios";
import moment from "moment";
import { Tooltip } from "flowbite-react";
import clsx from "clsx";

import Icon from "shared/components/icon/Icon";
import ExpandableTags from "shared/components/expandable-tags";

import { ArticlesResponseProps } from "../types";
import { SECTIONS_TAG_CLASSES, TOPICS_TAG_CLASSES } from "./constants";

const ArticleInfo = ({
  response,
  unauthorized,
  articleActions,
  prevButtonDisabled,
  nextButtonDisabled,
  onPrevClick: handlePrevClick,
  onNextClick: handleNextClick,
}: {
  response: AxiosResponse<ArticlesResponseProps>;
  unauthorized: boolean | undefined;
  articleActions: JSX.Element | null;
  prevButtonDisabled?: boolean;
  onPrevClick?: MouseEventHandler;
  nextButtonDisabled?: boolean;
  onNextClick?: MouseEventHandler;
}) => {
  return (
    <>
      <div className="max-w-[40rem]">
        <div
          className={clsx("mb-6 flex justify-between items-center", {
            "!justify-start gap-4": unauthorized,
          })}
        >
          {!unauthorized ? (
            <div className="flex">
              <button
                type="button"
                data-testid="prev-button"
                disabled={prevButtonDisabled}
                className={clsx(
                  "" + "flex",
                  "maxMd:flex-wrap",
                  "items-center",
                  { arrow_design: !prevButtonDisabled },
                  { disable_arrow: prevButtonDisabled },
                  "mr-2"
                )}
                onClick={handlePrevClick}
              >
                <Icon type="leftarrow" fill={true} size="icon-md" />
              </button>
              <button
                type="button"
                data-testid="next-button"
                disabled={nextButtonDisabled}
                className={clsx(
                  "flex",
                  "items-center",
                  { arrow_design: !nextButtonDisabled },
                  { disable_arrow: nextButtonDisabled }
                )}
                onClick={handleNextClick}
              >
                <Icon type="rightarrow" fill={true} size="icon-md" />
              </button>
            </div>
          ) : null}
          {unauthorized ? (
            <a
              className="btn_primary flex items-center !bg-darkJungleGreen"
              href="https://www.grand.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip
                content="Article shared from Grand's news monitoring service"
                className="custom_tooltip"
              >
                <Icon type="post" fill={false} size="icon-xs" className="mr-2 notification_icon" />
              </Tooltip>
              <h4
                data-testid="unauthorized-warning"
                className="text-sm text-white font-medium dark:text-mirage"
              >
                Article shared from Grand's news monitoring service
              </h4>
            </a>
          ) : null}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={response.data.url}
            className="btn_secondary dark:hover:!bg-thunders"
          >
            <span>Read Original Post</span>
          </a>
        </div>
        <a
          href={response.data.url}
          target="_blank"
          rel="noopener noreferrer"
          className="font-sans-medium text-3xl md:text-4xl dark:text-white max-w-[40rem]"
        >
          {response.data.title}
        </a>
        <div className="flex my-4 md:my-8">
          <p className="text-aurometalsaurus dark:text-greychateau">
            {moment(response.data.publication_date).format("MMM DD, YYYY").toUpperCase()}
            &nbsp;∙ {response.data.read_time ? <>{response.data.read_time} mins read time</> : null}
          </p>
        </div>
        {response?.data.summary ? (
          <div className="mt-4 md:mt-8 md:border-l-[3px] border-brightgrey dark:border-greychateau md:pl-6">
            <p className="text-aurometalsaurus text-base md:text-xl">
              <span className="font-inter-medium text-mirage dark:text-white">Summary: </span>
              <span className="dark:text-greychateau">{response.data.summary}</span>
            </p>
          </div>
        ) : null}

        <div className="flex mt-6 flex-wrap gap-2">
          <ExpandableTags
            tags={response.data.sections.map((section) => section.name)}
            tagsClassName={SECTIONS_TAG_CLASSES}
            limit={5}
          />
          <ExpandableTags
            tags={response.data.topics.map((topics) => topics.name)}
            tagsClassName={TOPICS_TAG_CLASSES}
            limit={5}
          />
        </div>
        {response?.data.image_url ? (
          <img src={response?.data.image_url} alt="article-image" className="w-full mt-6" />
        ) : null}
        {articleActions}
        {response?.data?.classification_applies ? (
          <div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[1.5rem]">
                Relevant Regulations
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.relevant_regulations
                  ? response.data.relevant_regulations
                  : "Relevant regulations not available."}
              </p>
            </div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[24px] mac13Inch:text-[24px]">
                Mitigating Efforts
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.mitigating_efforts
                  ? response.data.mitigating_efforts
                  : "Forecast not available."}
              </p>
            </div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[24px] mac13Inch:text-[24px]">
                Potential Impact
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.potential_impact
                  ? response.data.potential_impact
                  : "Impact not available."}
              </p>
            </div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[24px] mac13Inch:text-[24px]">
                Timeline
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.timeline ? response.data.timeline : "Timeline not available."}
              </p>
            </div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[24px] mac13Inch:text-[24px]">
                Institution Types
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.institution_types
                  ? response.data.institution_types
                  : "Institution Types not available."}
              </p>
            </div>
            <div className="mt-8">
              <p className="text-mirage dark:text-white font-sans-medium text-[24px] mac13Inch:text-[24px]">
                Regulatory Jurisdictions
              </p>
              <p className="text-aurometalsaurus dark:text-greychateau">
                {response?.data?.regulatory_jurisdictions
                  ? response.data.regulatory_jurisdictions
                  : "Jurisdiction not available."}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ArticleInfo;
