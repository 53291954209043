import React, { useRef } from "react";

import clsx from "clsx";
import get from "lodash/get";

import { TableFilterTypeProps, useTableFilter } from "shared/store/table-column-selection";
import { FAVORITE_TYPE } from "shared/helpers/constant";
import Icon from "shared/components/icon/Icon";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import useHideCopilot from "shared/hooks/useHideCopilot";
import useHideIntercom from "shared/hooks/useHideIntercom";

import styles from "assets/css/commonTable.module.css";

import { FilterProps, SelectProps } from "../../types";
import useFavorite from "../../hooks/useFavorite";
import FilterTitle from "../FilterTitle";

export default function FavoriteSlider({
  onClose,
  handleFilterClose,
  onFavoriteClick,
  type,
  searchText,
}: {
  onClose: () => void;
  handleFilterClose: () => void;
  onFavoriteClick: (filter: FilterProps) => void;
  type: string;
  searchText: string;
}) {
  useHideCopilot();
  useHideIntercom();

  const { response, deleteFavorite, setIsDeleteModalOpen, isDeleteModalOpen } = useFavorite();

  const [selectedFilters, setSelectedFiltersForModules] = useTableFilter((state) => [
    state.selectedFilters,
    state.setSelectedFiltersForModules,
  ]);

  const deleteId = useRef<number>(0);

  const filters =
    response?.data.results.filter((result: FilterProps) => result.object_type === type) || [];

  const isFavoritesSelected = (filter: FilterProps) => {
    const selectedFilter = get(selectedFilters, type)?.find(
      (_search: SelectProps) => _search.type === FAVORITE_TYPE && _search.value === filter.id
    );
    return !!selectedFilter;
  };

  const filterSearchIfAlreadyDeleted = () => {
    const newSearches = get(selectedFilters, type)?.filter(
      (_search: SelectProps) =>
        !(_search.type === FAVORITE_TYPE && parseInt(_search.value || "") === deleteId.current)
    );

    const data: TableFilterTypeProps = Object.assign({}, selectedFilters, {
      [type]: newSearches,
    });
    setSelectedFiltersForModules(data);
  };

  return (
    <>
      <div className="filter_view">
        <div>
          <FilterTitle handleClose={handleFilterClose} filterText="Favorites" />
          <div className="w-full py-2 px-2" data-testid="flowbite-tooltip-target">
            <button
              type="button"
              className={clsx("flex items-center justify-between w-full", {
                [styles.disableFavoriteMobile]:
                  (!get(selectedFilters, type) || get(selectedFilters, type)?.length === 0) &&
                  (!searchText || searchText.length === 0),
              })}
              onClick={() => onClose()}
            >
              <span className="!text-base !font-medium">Save Current Search</span>
              <Icon type="rightarrow" fill={true} size="icon-smd" className="sidebar_icon" />
            </button>
          </div>
          {filters?.map((filter: FilterProps) => (
            <div
              className={clsx("flex justify-between items-center w-full py-2 px-2", {
                "bg-gray-200": isFavoritesSelected(filter),
              })}
              data-testid="favorit-subdropdown"
              onClick={() => onFavoriteClick(filter)}
            >
              <span className="text-aurometalsaurus dark:text-greychateau">{filter.name}</span>
              <span
                className="pl-1.5"
                data-testid="delete-favorits"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsDeleteModalOpen(true);
                  deleteId.current = Number(filter?.id);
                }}
              >
                <Icon type="trash" fill={true} size="icon-sm" className="setingright_icon" />
              </span>
            </div>
          ))}
        </div>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={async () => {
          deleteFavorite(deleteId.current);
          filterSearchIfAlreadyDeleted();
        }}
      />
    </>
  );
}
