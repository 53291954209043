import React, { useState } from "react";

import { Button, Label, Modal, Spinner } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import styles from "assets/css/settings.module.css";
import { useHome } from "shared/store/home";
import { removeRefreshToken, removeToken } from "shared/helpers/util";

import { deleteAccount } from "../api";

const DeleteAccount = () => {
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const profile = useHome((state) => state.profile);

  const { isLoading, mutate: deleteAccountFn } = useMutation(() => deleteAccount(profile?.id), {
    onSuccess: () => {
      navigate("/signin", { replace: true });
      removeToken();
      removeRefreshToken();
    },
  });

  return (
    <>
      <div>
        <Label value="Danger Zone" className="font-inter-medium !text-mirage" />
        <br />
        <button
          type="button"
          className="mt-4 text-[#E02424] hover:text-white border border-[#E02424] hover:bg-[#E02424] focus:ring-0 focus:outline-none rounded-full text-sm px-5 py-2.5 mac13Inch:text-xs"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Account
        </button>
      </div>
      <Modal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center"
      >
        <Modal.Header className={clsx(styles.account_header, "dark:!border-thunders")}>
          Delete Account
        </Modal.Header>
        <Modal.Body className="maxMd:p-4">
          <div>
            <p className="text-base mac13Inch:text-sm leading-relaxed text-gray-500 dark:text-greychateau">
              Are you sure you want to delete this account? It cannot be reverted.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end maxMd:p-4 mac13Inch:py-4 dark:!border-thunders flex-wrap-reverse">
          <button
            className="btn_secondary font-inter-medium maxMd:w-full maxMd:mt-[18px]"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No, Cancel
          </button>
          <Button
            className="!bg-lavared md:!ml-4 hover:!bg-red-800 !rounded-full font-inter-medium focus:!ring-0 focus:!ring-transparent font-xs-mac13 maxMd:w-full maxMd:!ml-0"
            onClick={() => deleteAccountFn()}
          >
            <Spinner
              size="md"
              light={true}
              hidden={!isLoading}
              className="mr-3 !fill-red-500 !stroke-red-500"
            />
            Yes, I’m sure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccount;
