import React, { useState } from "react";

import { Modal } from "flowbite-react";
import { toast } from "react-toastify";

import Avatar from "shared/components/avatar";

import styles from "assets/css/settings.module.css";

import { ProfilePictureProps } from "../types";

const ChangePicture = ({
  profilePicture,
  setProfilePicture,
  title,
  description,
  imageURL,
  name,
}: ProfilePictureProps) => {
  const [isImageModalopen, setIsImageModalOpen] = useState<boolean>(false);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target?.files ? target?.files[0] : null;
    setIsImageModalOpen(false);
    if (file && file["type"].split("/")[0] !== "image") {
      toast("Upload a valid image", { type: "error" });
      return;
    }
    setProfilePicture(file);
  };

  const getProfilePicture = () => {
    if (profilePicture) return URL.createObjectURL(profilePicture);
    if (imageURL) return imageURL;
  };

  return (
    <>
      {isImageModalopen ? (
        <Modal
          show={isImageModalopen}
          onClose={() => setIsImageModalOpen(false)}
          className="custom_choose_file modal_mobileview_center"
          dismissible={true}
        >
          <Modal.Header className="font-sans-medium maxMd:px-4 maxMd:py-3 dark:!border-thunders">
            <span className="dark:text-white">Upload icon</span>
          </Modal.Header>
          <Modal.Body className="maxMd:p-4">
            <form method="post" className="max-w-[493px]">
              <input
                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onImageChange(e)}
              />
            </form>
          </Modal.Body>
        </Modal>
      ) : null}
      <div>
        <div className="mb-2 capitalize font-inter-medium dark:text-white mac13Inch:text-sm">
          {title}
        </div>
        <div className={styles.account_details}>
          <div className="mr-4 cursor-pointer">
            <Avatar
              name={name}
              avatarFor="user"
              avatarType="squared"
              size="lg"
              url={getProfilePicture()}
              onClick={() => setIsImageModalOpen(true)}
            />
          </div>
          <div className="my-auto block md:w-[calc(100%-96px)] w-full">
            <p className="!mt-0 maxMd:!mt-4 dark:text-greychateau">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePicture;
