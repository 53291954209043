import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Button } from "flowbite-react";

import ArticleShare from "./ArticleShare";
import Icon from "shared/components/icon/Icon";

const ArticleActionsUnauthorized = ({ response }: { response?: AxiosResponse }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);

  return (
    <div className="flex mb-4 mt-8 max-w-[40rem] w-full">
      <Button
        color="light"
        className="max-w-[40rem] w-full !text-aurometalsaurus dark:!text-greychateau dark:bg-darkjunglegreenss dark:!border-iridium dark:hover:!bg-iridium"
        onClick={() => setIsShareModalOpen(true)}
      >
        <Icon type="share" fill={false} size="icon-xs" className="mr-3 share_icon" />
        Share
      </Button>
      <ArticleShare
        response={response}
        isShareModalOpen={isShareModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
      />
    </div>
  );
};

export default ArticleActionsUnauthorized;
