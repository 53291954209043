/**
 * Cuts an array to a certain length and optionally adds an extra element
 * @param arr - The array to truncate
 * @param limit - The maximum length of the array
 * @param getExtra - A function that returns the extra element
 */
export default function truncateArr<T>(
  arr: T[],
  limit: number,
  getExtra?: (arr: T[], limit: number) => T
): T[] {
  if (arr.length <= limit) {
    return arr;
  }

  const result = arr.slice(0, limit);

  if (getExtra) {
    result.pop();
    result.push(getExtra(arr, limit - 1));
  }

  return result;
}
