import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { TaskCommentResponse } from "../types";

export default function createTaskComments(
  task: number,
  tempData: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<TaskCommentResponse>> {
  return api.post<TaskCommentResponse>(`tasks/comments/`, tempData, { params: { ...permissions } });
}
