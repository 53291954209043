import { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import toString from "lodash/toString";
import { useLocation } from "react-router-dom";
import { useDarkMode } from "usehooks-ts";
import { Spinner } from "flowbite-react";
import isEmpty from "lodash/isEmpty";

import { useCompany } from "shared/context/CompanyProvider";
import { IS_DARK_MODE, MOBILE_DEVICE } from "shared/helpers/constant";
import { getItemFromLocalStorage, setItemToLocalStorage } from "shared/helpers/util";
import { useMenu } from "shared/store/menu";
import { useSidebar } from "shared/store/sidebar";
import useScreen from "shared/hooks/useScreen";
import { QUESTIONNAIRE_MODULE, TASK_MODULE } from "shared/helpers/ability";

import styles from "assets/css/sidebar.module.css";

import { useWorkspaceModal } from "../../store/trial";
import Avatar from "../avatar";
import Icon from "../icon/Icon";
import SharedMenu from "../menu/Menu";
import SidebarModule from "./SidebarModule";
import { showUpgradeButton } from "./helpers";
import { SideBarItems } from "./types";
import getAgreementActiveSidebarItems from "./helpers/getAgreementActiveSidebarItems";

const SidebarComponent = () => {
  const deviceType = useScreen();
  const { currentCompany, companies, currentUserRole } = useCompany();
  const { pathname } = useLocation();
  const { isDarkMode, toggle } = useDarkMode();
  const [selectedModule, setSelectedModule] = useState<number | null>(null);

  const [isToggle, isVisible, sideBarItems, setSidebarItems] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.sideBarItems,
    state.setSidebarItems,
  ]);
  const hoverRef = useRef<HTMLDivElement>(null);

  const isMenuShown = useMenu((state) => state.isMenuShown);
  const setIsMenuShown = useMenu((state) => state.setIsMenuShown);
  const setIsWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const closeAllSubmenu = () => {
    sideBarItems.forEach((item: SideBarItems) => {
      item.isOpen = false;
    });
    setSidebarItems([...sideBarItems]);
    setSelectedModule(null);
  };

  const onClick = useCallback(
    (index: number) => {
      sideBarItems.forEach((item: SideBarItems, itemIndex: number) => {
        if (itemIndex === index) {
          return;
        }
        item.isOpen = false;
      });
      sideBarItems[index].isOpen = !sideBarItems[index].isOpen;
      setSelectedModule(null);
      setSidebarItems([...sideBarItems]);
    },
    [sideBarItems]
  );

  const setOpenDropnDownOnRefresh = () => {
    const items: SideBarItems[] = getAgreementActiveSidebarItems(currentCompany).map(
      (sidebar, index) => {
        if (
          sidebar.items?.find(
            (item) =>
              pathname.includes(item.path) ||
              (pathname.includes("tasks/gridview") && sidebar.subject === TASK_MODULE)
          )
        ) {
          setSelectedModule(index);
          return { ...sidebar, isOpen: true };
        }
        return sidebar;
      }
    );
    const newItems =
      currentUserRole === "GUEST"
        ? items.filter((x) => x.subject === QUESTIONNAIRE_MODULE)
        : [...items];

    setSidebarItems(newItems);
  };

  useEffect(() => {
    closeAllSubmenu();
    if (!isToggle && currentUserRole) {
      setOpenDropnDownOnRefresh();
    }
  }, [isToggle, currentCompany, currentUserRole]);

  useEffect(() => {
    const getIsDarkMode = getItemFromLocalStorage(IS_DARK_MODE);
    if (getIsDarkMode === undefined) {
      setItemToLocalStorage(IS_DARK_MODE, JSON.stringify(false));
    }
    if (getIsDarkMode !== isDarkMode) {
      toggle();
    } else {
      if (isDarkMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (!isToggle) return;
    hoverRef?.current?.addEventListener("mouseleave", closeAllSubmenu);
    return () => hoverRef?.current?.removeEventListener("mouseleave", closeAllSubmenu);
  }, [hoverRef.current, isToggle]);

  if ((!isVisible && deviceType === MOBILE_DEVICE) || isEmpty(currentCompany)) {
    return null;
  }

  return (
    <section
      data-testid="sidebar"
      ref={hoverRef}
      className={clsx(styles.sidebar, "dark:bg-darkjunglegreenss dark:border-thunders", {
        [styles.sidebarFullWidth]: !isToggle,
        [styles.sidebarToggle]: isToggle,
      })}
    >
      {/* Company Menu */}
      <div className="relative">
        <button className={styles.sidebarTop} onClick={() => setIsMenuShown(!isMenuShown)}>
          <div className={clsx(styles.sidebarTopDetails, "w-full mobile_avatar")}>
            <Avatar
              name={currentCompany?.name}
              url={toString(currentCompany.image)}
              avatarFor="company"
              avatarType="squared"
              size="md"
            />
            <div className="flex items-center justify-between w-full">
              <h3 className="text-left dark:!text-white ml-2 md:ml-4">
                {currentCompany?.name || ""}
              </h3>
              <Icon type="updown" fill={true} size="icon-md" className="sidebar_icon" />
            </div>
          </div>
        </button>
        {isMenuShown ? <SharedMenu companies={companies} /> : null}
      </div>

      {/* Sidebar Modules */}
      <SidebarModule
        sideBarItems={sideBarItems}
        onClick={onClick}
        isUpgradeVisible={showUpgradeButton(currentCompany)}
        selectedModule={selectedModule}
      />
      <div
        className={`${!isToggle ? styles.sidebarFullWidth : styles.sidebarToggle} ${
          styles.sidebarBottom
        }`}
      >
        {currentCompany?.trial_expired ? (
          <div className={clsx(styles.upgrade, "dark:border-thunders")}>
            <button className="btn_primary w-full" onClick={() => setIsWorkspaceModalOpen(true)}>
              Reactivate workspace
              <Spinner
                size="md"
                light={true}
                hidden={true}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
            </button>
          </div>
        ) : null}
      </div>
      {/* <div
        className={`${!isToggle ? styles.sidebarFullWidth : styles.sidebarToggle} ${
          styles.sidebarBottom
        }`}
      >
        {showUpgradeButton(currentCompany) ? (
          <div className={clsx(styles.upgrade, "dark:border-thunders")}>
            <button className="btn_primary w-full" onClick={() => navigateToUpgradeSettings()}>
              Upgrade Plan
              <Spinner
                size="md"
                light={true}
                hidden={true}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
            </button>
          </div>
        ) : null}
      </div> */}
    </section>
  );
};

export default SidebarComponent;
