import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { FilterProps } from "../types";

export default function getFilterById(
  filterId: number,
  team: number
): Promise<AxiosResponse<FilterProps>> {
  const params = new URLSearchParams();
  params.append("team", `${team}`);

  return api.get<FilterProps>(`core/filters/${filterId}/`, { params });
}
