import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { ReactComponent as Envelope } from "assets/images/icons/horizon-scanning/envelope.svg";
import { ReactComponent as Facebook } from "assets/images/icons/horizon-scanning/facebook.svg";
import { ReactComponent as Linkedin } from "assets/images/icons/horizon-scanning/linkedin.svg";
import { ReactComponent as X } from "assets/images/icons/horizon-scanning/x.svg";
import Icon from "shared/components/icon/Icon";
import Modal from "shared/componentsV2/common-modal";
import { FeedArticle } from "../../../types";

interface ShareArticleModalProps {
  open: boolean;
  onClose: () => void;
  article: FeedArticle | null;
}

const ShareArticleModal: React.FC<ShareArticleModalProps> = ({ article, open, onClose }) => {
  const articlePublicURL = `https://app.grand.io/articles/${article?.object_id}/public`;

  const copyLink = () => {
    navigator.clipboard.writeText(articlePublicURL);
  };

  const sliceDescription = (description: string, limit: number) => {
    if (description.length > limit) {
      return `${description.slice(0, limit)}...`;
    }
    return description;
  };

  return (
    <Modal show={open} onClose={onClose} size="xl">
      <Modal.Header>
        <span className="text-lg font-medium text-gray-800 dark:text-white">Share</span>
      </Modal.Header>
      <Modal.Body>
        <div className="grid gap-2">
          <h3 className="text-sm font-medium text-gray-800 dark:text-white">{article?.title}</h3>
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            {sliceDescription(article?.description || "", 150)}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full grid gap-4">
          <div className="grid gap-1">
            <h5 className="font-medium text-xs text-gray-600 dark:text-white">Copy link</h5>
            <div className="flex items-center gap-2 border border-gray-200 rounded-md p-2">
              <span className="w-full font-medium text-sm text-gray-700">{articlePublicURL}</span>
              <div className="cursor-pointer" onClick={copyLink}>
                <Icon type="document-duplicate" size="icon-xs" fill />
              </div>
            </div>
          </div>
          <div className="grid gap-1">
            <h5 className="font-medium text-xs text-gray-600 dark:text-white">Or share Via</h5>
            <div className="flex space-x-4">
              <FacebookShareButton url={articlePublicURL}>
                <Facebook />
              </FacebookShareButton>
              <LinkedinShareButton url={articlePublicURL}>
                <Linkedin />
              </LinkedinShareButton>
              <TwitterShareButton url={articlePublicURL}>
                <X />
              </TwitterShareButton>
              <EmailShareButton url={articlePublicURL}>
                <Envelope />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareArticleModal;
