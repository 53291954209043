import isUndefined from "lodash/isUndefined";
import { number, object } from "yup";

import { FULL_ACCESS, NO_ACCESS } from "../../constants";

export const createPermissionSchema = object({
  user: number()
    .optional()
    .test((value, ctx) => {
      if (isUndefined(value) && isUndefined(ctx.parent.team)) {
        return ctx.createError({
          message: "Either team or user should be set",
          path: "user",
        });
      }

      if (!isUndefined(value) && !isUndefined(ctx.parent.team)) {
        return ctx.createError({
          message: "Either team or user should be set",
          path: "user",
        });
      }

      return true;
    }),
  team: number().optional(),
  level: number().min(NO_ACCESS).max(FULL_ACCESS).defined().required(),
});
