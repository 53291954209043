import { create } from "zustand";

import { LabeledValue } from "shared/types";

import {
  IncidentsResponseType,
  PaginatedIncidentsListResponse,
} from "modules/risks/incidents/types";
import { MitigationType, PaginatedMitigationListResponse } from "modules/risks/mitigations/types";
import {
  CompanyPresetResponse,
  PaginatedRegisterListResponse,
  PaginatedRisksTypeListResponse,
  RegisterResponseType,
} from "modules/risks/register/types";

export const mitigationStatus: Array<LabeledValue> = [
  { id: "on_going", name: "On Going" },
  { id: "completed", name: "Completed" },
];

export const incidentsStatus: Array<LabeledValue> = [
  { id: "open", name: "Open" },
  { id: "closed", name: "Closed" },
];

export const mitigationFrequency: Array<LabeledValue> = [
  { id: "continous", name: "Continous" },
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
  { id: "quarterly", name: "Quarterly" },
  { id: "yearly", name: "Yearly" },
  { id: "one_off", name: "One Off" },
];

export type Risk = {
  mitigations: PaginatedMitigationListResponse | null;
  setMitigations: (Mitigations: PaginatedMitigationListResponse | null) => void;
  risks: PaginatedRegisterListResponse | null;
  setRisks: (Risks: PaginatedRegisterListResponse | null) => void;
  incidents: PaginatedIncidentsListResponse | null;
  setIncidents: (Incidents: PaginatedIncidentsListResponse | null) => void;
  currentMitigation: MitigationType | null;
  setCurrentMitigation: (currentMitigation: MitigationType | null) => void;
  currentRisk: RegisterResponseType | null;
  setCurrentRisk: (currentRisk: RegisterResponseType | null) => void;
  riskTypes: PaginatedRisksTypeListResponse | null;
  setRiskTypes: (riskTypes: PaginatedRisksTypeListResponse | null) => void;
  causeTypes: PaginatedRisksTypeListResponse | null;
  setCauseTypes: (causeTypes: PaginatedRisksTypeListResponse | null) => void;
  impactTypes: PaginatedRisksTypeListResponse | null;
  setImpactTypes: (impactTypes: PaginatedRisksTypeListResponse | null) => void;
  currentIncident: IncidentsResponseType | null;
  setCurrentIncident: (currentIncident: IncidentsResponseType | null) => void;

  currentCompanyPreset: CompanyPresetResponse | null;
  setCurrentCompanyPreset: (currentCompanyPreset: CompanyPresetResponse | null) => void;
};

export const useRisk = create<Risk>((set) => ({
  mitigations: null,
  setMitigations: (mitigations: PaginatedMitigationListResponse | null) => set({ mitigations }),
  risks: null,
  setRisks: (risks: PaginatedRegisterListResponse | null) => set({ risks }),
  incidents: null,
  setIncidents: (incidents: PaginatedIncidentsListResponse | null) => set({ incidents }),
  currentMitigation: null,
  setCurrentMitigation: (currentMitigation: MitigationType | null) => set({ currentMitigation }),
  currentRisk: null,
  setCurrentRisk: (currentRisk: RegisterResponseType | null) => set({ currentRisk }),
  riskTypes: null,
  setRiskTypes: (riskTypes: PaginatedRisksTypeListResponse | null) => set({ riskTypes }),
  causeTypes: null,
  setCauseTypes: (causeTypes: PaginatedRisksTypeListResponse | null) => set({ causeTypes }),
  impactTypes: null,
  setImpactTypes: (impactTypes: PaginatedRisksTypeListResponse | null) => set({ impactTypes }),
  currentIncident: null,
  setCurrentIncident: (currentIncident: IncidentsResponseType | null) => set({ currentIncident }),

  currentCompanyPreset: null,
  setCurrentCompanyPreset: (currentCompanyPreset: CompanyPresetResponse | null) =>
    set({ currentCompanyPreset }),
}));
