import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import getPublicArticle from "modules/horizonScanning/shared/api/getPublicArticle";

interface UsePublicArticleQueryProps {
  articleId?: number;
  enabled?: boolean;
  onError?: (error: AxiosError) => void;
}

const usePublicArticleQuery = ({
  articleId,
  enabled = true,
  onError,
}: UsePublicArticleQueryProps) => {
  const { data, isLoading, refetch } = useQuery(
    ["get-public-article", articleId],
    () =>
      articleId !== undefined
        ? getPublicArticle({
            articleId,
          })
        : undefined,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      enabled,
      onError: (error: AxiosError) => {
        onError?.(error);
      },
    }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default usePublicArticleQuery;
