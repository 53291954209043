import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { PolicyInputDataDownloadFile } from "../types";

export default function downloadFile(
  permission: PolicyInputDataDownloadFile
): Promise<AxiosResponse<BlobPart>> {
  return api.get<BlobPart>(`/policies/export/`, {
    params: { ...permission },
    responseType: "blob",
  });
}
