import React, { useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Dropdown } from "flowbite-react";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import clsx from "clsx";

import { ReactComponent as EllipsisIcon } from "assets/images/threedots.svg";
import styles from "assets/css/settings.module.css";
import { useCompany } from "shared/context/CompanyProvider";
import { useAuthProvider } from "shared/store/settings";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { getServerErrors } from "shared/helpers/util";

import { deleteFederatedGroup } from "../api";
import { GroupListComponentProps, SSOGroupMappingResponseProps } from "../types";
import useGetGroupList from "../hooks/useGetGroupList";

const GroupList = ({ sso, setIsGroupModalOpen }: GroupListComponentProps) => {
  const { currentCompany } = useCompany();

  const { response, refetch } = useGetGroupList(sso);

  const selectedGroup = useRef<number | null>(null);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const setGroup = useAuthProvider((state) => state.setGroup);

  const { isLoading, mutate: deleteFederatedGroupFn } = useMutation(
    (id: number | null) => deleteFederatedGroup({ company: currentCompany.id, id, sso }),
    {
      onSuccess: () => {
        refetch();
        toast("Group Deleted Successfully", { type: "success" });
        setIsConfirmModalOpen(false);
        selectedGroup.current = null;
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const onDeleteClick = (id: number) => {
    selectedGroup.current = id;
    setIsConfirmModalOpen(true);
  };

  const onManageClick = (provider: SSOGroupMappingResponseProps) => {
    setIsGroupModalOpen(true);
    setGroup(provider);
  };

  return (
    <div data-testid="group-list">
      <ul className="text-sm text-aurometalsaurus">
        {(response?.data.results || []).map(
          (group: SSOGroupMappingResponseProps, index: number) => (
            <li
              className={clsx(
                { "border-none": index + 1 === response?.data.results.length },
                "grid grid-cols-3 py-3.5 border-b border-brightgray dark:text-greychateau dark:!border-thunders"
              )}
              key={group.id}
            >
              <span>{group.name}</span>
              <span className="text-center">{group.group_id}</span>
              <div className={styles.group_container}>
                <Dropdown
                  label={<EllipsisIcon className="rotate-90 grid_clock_icon" />}
                  inline={true}
                  className="w-[9.625rem]"
                  placement="right-start"
                >
                  <Dropdown.Item
                    data-testid="manage-group-list"
                    onClick={() => onManageClick(group)}
                  >
                    Manage
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid="delete-group-list"
                    className="!text-lavared"
                    onClick={() => onDeleteClick(group.id)}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </li>
          )
        )}
      </ul>
      <ConfirmModal
        isLoading={isLoading}
        setIsOpen={setIsConfirmModalOpen}
        isOpen={isConfirmModalOpen}
        action={() => deleteFederatedGroupFn(selectedGroup.current)}
      />
    </div>
  );
};

export default GroupList;
