import React, { useMemo, useRef, useState } from "react";

import { Label, Spinner, Table, TextInput, Textarea } from "flowbite-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import isEqual from "lodash/isEqual";

import { useTeam } from "shared/store/settings";
import { getServerErrors } from "shared/helpers/util";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useHome } from "shared/store/home";
import { useCompany } from "shared/context/CompanyProvider";
import { CREATE_ACCESS_ROLE_LEVEL } from "shared/helpers/constant";
import getRoleAccessLevel from "shared/helpers/getRoleAccessLevel";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";

import SidebarTitle from "modules/settingsV2/component/SidebarTitle";
import { getNameForIcon } from "modules/settingsV2/helper";
import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";
import { getMemberByEmail } from "modules/home/api";

import style from "assets/css/settings.module.css";

import { getGroupById } from "../api";
import { GroupInvitationType, GroupUser, UpdateCompanGroupData } from "../types";
import GroupMemberListing from "../components/GroupMemberListing";
import { groupInvitation } from "../api/groupInvitation";
import EmailWithRole from "../components/EmailWithRole";
import useUpdateGroup from "../hooks/useUpdateGroup";
import { updateGroup } from "../api/updateGroup";
import ChangeGroupImage from "../components/ChangeGroupImage";
import deleteMemberById from "../api/deleteMemberById";
import useCreateGroupMember from "../hooks/useCreateGroupMember";

const GroupDetailView = () => {
  const currentTeam = useTeam((state) => state.currentTeam);

  const { currentCompany } = useCompany();

  const profile = useHome((state) => state.profile);

  const { groupId } = useParams();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [toBeDelete, setToBeDelete] = useState<number | null>(null);

  const {
    isLoading,
    data: groupResponse,
    refetch,
  } = useQuery(["getGroupById", groupId], () => {
    if (!currentTeam?.company) {
      return Promise.reject(new Error("Company is not selected"));
    }
    if (!groupId) {
      return Promise.reject(new Error("Group is not selected"));
    }

    return getGroupById(parseInt(groupId), {
      team: currentTeam?.id,
      company: currentTeam?.company,
      isAllTeam: currentTeam.is_all_team,
    });
  });

  const groupDetails = groupResponse?.data;

  const memberList: Array<GroupUser> = groupDetails?.users || [];

  const { data: companyMemberList, isLoading: isMemberLoading } = useQuery(
    ["getMemberByCompany", "settingGroupView", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const {
    values: createMemberValues,
    errors: createMemberErrors,
    resetForm: resetGroupMember,
    setFieldValue: setGroupMemberValue,
    handleSubmit: handleSubmitGroupMember,
  } = useCreateGroupMember();

  const { values, handleChange, initialValues, setFieldValue, errors } =
    useUpdateGroup(groupDetails);

  const [groupIcon, setGroupIcon] = useState<File | null>(null);

  const groupIconRef = useRef<HTMLInputElement>(null);

  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target.files ? target.files[0] : null;
    if (file) {
      setFieldValue("group_picture", file);
      setGroupIcon(file);
      updateGroupPictureFn({
        id: groupDetails?.id,
        group_picture: file,
      });
    }
  };

  const {
    data: currentMember,
    isLoading: isLoadingMember,
    isFetching: isFetchingMember,
  } = useQuery(
    ["getMemberByEmailGrooup", "settingCurrentMember", currentCompany?.id],
    () => getMemberByEmail(currentCompany?.id, { search: profile.email }),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const canEdit = useMemo(() => {
    const loggedInUser = currentMember?.data?.results?.find((data) => data.user.id === profile.id);

    return getRoleAccessLevel(loggedInUser?.role || "GUEST").level >= CREATE_ACCESS_ROLE_LEVEL;
  }, [profile, companyMemberList]);

  const { isLoading: isCreating, mutate: onSubmit } = useMutation(
    (data: { email: string }) => {
      if (!groupId) {
        return Promise.reject(new Error("Group is not selected"));
      }
      if (!data.email) {
        return Promise.reject(new Error("Email is not selected"));
      }
      return groupInvitation(
        parseInt(groupId),
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      );
    },
    {
      onSuccess: () => {
        toast("Invitation sent successfully!", { type: "success" });
        resetGroupMember();
        refetch();
      },
      onError: (e: AxiosError) => {
        getServerErrors(e).map((err: string) => toast(err, { type: "error" }));
      },
    }
  );

  const { mutate: updateGroupNameFn, isLoading: isNameUpdating } = useMutation(
    (data: Partial<UpdateCompanGroupData>) =>
      updateGroup(
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      ),
    {
      onSuccess: () => {
        toast("Group name updated successfully", { type: "success" });
        refetch();
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const { mutate: updateGroupDescriptionFn, isLoading: isDescriptionUpdating } = useMutation(
    (data: Partial<UpdateCompanGroupData>) =>
      updateGroup(
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      ),
    {
      onSuccess: () => {
        toast("Group description updated successfully", { type: "success" });
        refetch();
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const { mutate: updateGroupPictureFn, isLoading: isPictureUpdating } = useMutation(
    (data: Partial<UpdateCompanGroupData>) =>
      updateGroup(
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      ),
    {
      onSuccess: () => {
        toast("Group picture updated successfully", { type: "success" });
        refetch();
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const { mutate: deleteGroupMember, isLoading: isDeleteInvitation } = useMutation(
    (memberId: number) => {
      if (!toBeDelete) {
        Promise.reject(new Error("Member is not selected"));
      }
      if (!groupId) {
        return Promise.reject(new Error("Group is not selected"));
      }

      return deleteMemberById({
        memberId,
        groupId: parseInt(groupId),
        companyId: currentCompany.id,
      });
    },
    {
      onSuccess: () => {
        refetch();
        toast("Member deleted successfully.", { type: "success" });
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const handleChangeMember = (newData: GroupInvitationType) => {
    setGroupMemberValue("email", newData.email);
  };

  return (
    <>
      {isLoading || isDeleteInvitation || isMemberLoading || isLoadingMember || isFetchingMember ? (
        <CentralSpinner />
      ) : null}

      <div className={style.setting_layout}>
        <SidebarTitle title={groupDetails?.name || "N/A "} />
        <div className={style.layout_card_info}>
          <h5 className="font-inter-medium dark:text-white">Group Profile</h5>
          <span className="mt-2.5  mb-2 inline-block text-sm font-inter-medium dark:text-white">
            Icon
          </span>

          <div
            className={clsx("w-20 h-20 relative", {
              [style.group_loader]: isPictureUpdating,
              "cursor-pointer": canEdit,
            })}
            onClick={() => {
              if (canEdit) {
                groupIconRef?.current?.click();
              }
            }}
          >
            <ChangeGroupImage
              groupIcon={groupIcon}
              groupName={
                groupDetails?.group_picture || groupIcon ? "" : getNameForIcon(values.name || "")
              }
              group_picture={groupDetails?.group_picture || null}
            />
            <input
              data-testid="add-group-file"
              type="file"
              className="hidden"
              ref={groupIconRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onIconChange(e)}
            />
            <Spinner
              hidden={!isPictureUpdating}
              size="md"
              className="!fill-mirage stroke-mirage absolute top-8 left-[35%]"
            />
          </div>

          <div className="my-4 max-w-[714px] w-full">
            <Label value="Group Name*" className="font-inter-medium mb-2 block !text-mirage" />
            <div className="relative">
              <TextInput
                data-testid="add-group-name"
                placeholder="Enter Group Name"
                type="text"
                name="name"
                value={values.name}
                className={clsx({ [style.group_loader]: isNameUpdating }, "group_input")}
                disabled={!canEdit || isNameUpdating || isLoading}
                color={errors?.name ? "failure" : "gray"}
                onBlur={() => {
                  if (
                    values.name.trim() &&
                    !isEqual(initialValues.name.trim(), values.name.trim())
                  ) {
                    updateGroupNameFn({
                      id: groupDetails?.id,
                      name: values.name.trim(),
                    });
                  }
                }}
                onChange={handleChange}
              />
              <Spinner
                hidden={!isNameUpdating}
                size="md"
                className="!fill-mirage stroke-mirage absolute top-[9px] left-1/2"
              />
            </div>
            <div className="mt-4">
              <Label
                value="Group Description"
                className="font-inter-medium mb-2 block !text-mirage"
              />
              <div className="relative">
                <Textarea
                  data-testid="add-group-description"
                  rows={3}
                  value={values.description}
                  name="description"
                  placeholder="Enter group description"
                  onChange={handleChange}
                  className={clsx({ [style.group_loader]: isDescriptionUpdating }, "group_input")}
                  disabled={!canEdit || isDescriptionUpdating || isLoading}
                  color={errors?.description ? "failure" : "gray"}
                  onBlur={() => {
                    if (!isEqual(initialValues.description?.trim(), values.description?.trim())) {
                      updateGroupDescriptionFn({
                        id: groupDetails?.id,
                        description: values.description?.trim(),
                      });
                    }
                  }}
                />
                <Spinner
                  hidden={!isDescriptionUpdating}
                  size="md"
                  className="!fill-mirage stroke-mirage absolute top-[9px] left-1/2"
                />
              </div>
            </div>
          </div>
        </div>

        <GroupMemberListing
          membersList={memberList}
          canEdit={canEdit}
          handleDelete={(user) => {
            setToBeDelete(user?.id);
            setIsDeleteModalOpen(true);
          }}
        >
          <>
            {canEdit &&
            companyMemberList &&
            companyMemberList?.data?.results?.filter(
              (item) => !memberList.find((member) => member.user.id === item.user.id)
            ).length > 0 ? (
              <Table.Body
                className={clsx(style.group_add_member, "divide-y border-t dark:border-thunders")}
                id="newGroupMemberListing"
              >
                <Table.Row className="bg-white">
                  <Table.Cell className="min-w-[340px]">
                    <form
                      id="add_member"
                      onSubmit={handleSubmitGroupMember}
                      className={clsx({
                        "pointer-events-none": isLoading || isCreating || isDeleteInvitation,
                      })}
                    >
                      <EmailWithRole
                        className="add_member_selection"
                        selectedMember={memberList
                          .filter((data) => data.user.id)
                          .map((data) => data.user.id)}
                        data={createMemberValues}
                        handleChange={(data) => handleChangeMember(data)}
                        hasError={createMemberErrors?.email?.label ? true : false}
                      />
                    </form>
                  </Table.Cell>

                  <Table.Cell colSpan={3}>
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className="text-mirage dark:text-white"
                        onClick={() => {
                          resetGroupMember();
                        }}
                      >
                        Clear
                      </button>
                      <button
                        className="btn_primary flex gap-2"
                        type="submit"
                        form="add_member"
                        onClick={() => {
                          if (createMemberValues.email.email) {
                            onSubmit({
                              email: createMemberValues.email.email,
                            });
                          }
                        }}
                      >
                        <Spinner
                          hidden={!isCreating}
                          size="sm"
                          className="!fill-mirage stroke-mirage"
                        />
                        Add Member
                      </button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : null}
          </>
        </GroupMemberListing>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={() => {
          if (toBeDelete) {
            deleteGroupMember(toBeDelete);
            setToBeDelete(null);
            setIsDeleteModalOpen(false);
          }
        }}
      />
    </>
  );
};

export default GroupDetailView;
