import { RelationShipOwnerType } from "../../shared/helpersV2/getRelationshipOwner";

export enum FeedTypes {
  Public = "public",
  Shared = "shared",
  Private = "private",
}

export type FeedGroupItem = {
  id: number;
  unread_articles: number;
  name: string;
  description?: string;
  object_owner: number;
  created_at: Date;
  members?: RelationShipOwnerType[];
  filters: FeedFiltersType;
  regulations: string[];
  tags: string[];
  section: string;
  is_archived: boolean;
  template_todos: ToDo[];
};

export type FeedGroup = {
  type: FeedTypes;
  items: {
    count: number;
    results: FeedGroupItem[];
  };
};

export interface FeedFiltersType {
  smartFilter: string;
  keywords: { condition: string; values: string[] }[];
  publicationDate: {
    startDate?: string;
    endDate?: string;
  };
  // topics: {
  //   operator: string;
  //   value: string[];
  // } | null;
  jurisdictions: {
    operator: string;
    value: string[];
  } | null;
  institutionTypes: string[];
  regulations: string[];
  publishers?: string[];
}

export type ToDo = {
  id?: string | number;
  description: string;
  is_completed: boolean;
  is_deleted: boolean;
  parent_todo?: null | number;
};

export interface IClassificationData {
  id: string;
  name: string;
}

export enum ArticleStatus {
  pending = "Pending",
  in_progress = "In progress",
  completed = "Completed",
}

export const ArticleStatusColor: Record<keyof typeof ArticleStatus, string> = {
  completed: "bg-greenhaze",
  in_progress: "bg-yellow-300",
  pending: "bg-santagrey",
} as const;

export enum ArticleImpact {
  high = "High Impact",
  medium = "Medium Impact",
  low = "Low Impact",
}

export const ArticleImpactColor: Record<keyof typeof ArticleImpact, string> = {
  high: "#E02424",
  medium: "#E3A008",
  low: "#0E9F6E",
} as const;

export enum ArticleClassifications {
  relevantRegulations = "Relevant Regulations",
  mitigatingEfforts = "Mitigating Efforts",
  potentialImpact = "Potential Impact",
  timeline = "Timeline",
  institutionTypes = "Institution Types",
  regulatoryJurisdiction = "Regulatory Jurisdictions",
}

export type Feed = {
  id: number;
  tags: string[];
  created_at: string;
  updated_at: string;
  object_is_public: boolean;
  name: string;
  description: string;
  is_archived: boolean;
  archived_date: string | null;
  filters: FeedFiltersType;
  is_publisher_list_empty: boolean;
  object_owner: number;
  company: number;
  publishers: {
    uuid: string;
    name: string;
    region: string | null;
    link: string;
  }[];
  section: string;
  template_todos?: ToDo[];
};

export type FeedArticle = {
  title: string;
  description: string;
  keywords: string | null;
  original_title: string;
  topics: IClassificationData[] | null;
  jurisdictions: IClassificationData[] | null;
  regulations: IClassificationData[] | null;
  institution_types: IClassificationData[] | null;
  id: string;
  object_id: string;
  object_type: string;
  link: string;
  publication_date: string;
  image_url: string;
  publisher: {
    link: string;
    name: string;
    region: string | null;
    uuid: string;
  };
  is_active: boolean;
  feed_id: string;
  publisher_uuid: string;
  is_archived: boolean;
  is_read: boolean;
  is_saved: boolean;
  read_by: string;
  status: string | null;
  impact: string | null;
  tags: string[] | null;
  assignees: number[] | null;
  company_id: string | null;
  object_is_editable: boolean;

  todos?: ToDo[] | null;

  // TODO: this is not coming back from backend, we should ask what field these are
  sections?: string[];
  mitigating_efforts?: IClassificationData[] | null;
  potential_impact?: IClassificationData[] | null;
  timeline?: IClassificationData[] | null;
  has_classification?: boolean;
  comments_count: number;
};

export type PublicArticle = Omit<
  FeedArticle,
  "institution_types" | "jurisdictions" | "regulations" | "topics" | "id"
> & {
  id: number;
  institution_types: number[];
  jurisdictions: number[];
  regulations: number[];
  topics: number[];
};

export type UpdateArticleParams = {
  id: string;
  companyId: number;
  feedId: number;
  is_read?: boolean;
  is_archived?: boolean;
  is_saved?: boolean;
  impact?: string;
  status?: string;
  assignees?: number[];
  tags?: string[];
  todos?: ToDo[];
};

export type ArticleBulkActionParams = {
  article_ids: number[];
  companyId: number;
  feedId: number;
  is_read?: boolean;
  is_archived?: boolean;
  is_saved?: boolean;
};

export type UpdateArticleResponse = {
  id: string;
};

export type FeedTodo = {
  id?: number;
  description: string;
};

export type ArticleAction = "save" | "read" | "archive" | "share";

export type FilterQuery = {
  key?: string;
  operator: string;
  value: string | number | boolean | FilterQuery[] | FilterQuery;
};

export type ExportArticleParams = {
  companyId: number;
  feedId: number;
  articleIds: string[];
  selectedFields: string[];
  exportFormat: "csv" | "xlsx";
};
