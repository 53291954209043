import { useFormik } from "formik";

import { ResetPasswordRequestProps } from "../types";
import { resetPasswordSchema } from "../validation";

const useResetPassword = (action: CallableFunction) => {
  return useFormik<ResetPasswordRequestProps>({
    initialValues: {
      new_password1: "",
      new_password2: "",
      uid: "",
      token: "",
    },
    validateOnChange: false,
    validationSchema: resetPasswordSchema,
    onSubmit: () => action(),
  });
};

export default useResetPassword;
