import React from "react";

import HeaderItem from "./HeaderItem";
import Icon from "shared/components/icon/Icon";
import { GRAND_DOCUMENTATION_LINK } from "shared/helpers/constant";

const DocumentationItem = () => {
  return (
    <HeaderItem
      testId="documentation_comp"
      onClick={() => window.open(GRAND_DOCUMENTATION_LINK)}
      tooltip="Grand Documentation"
      className="header_action_buttons"
    >
      <Icon type="file" fill={true} size="icon-md" className="header-icon_dark" />
    </HeaderItem>
  );
};

export default DocumentationItem;
