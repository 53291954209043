import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";
import { usePlans } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import { useSubscriptionActive } from "shared/store/subscription";
import Icon from "shared/components/icon/Icon";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import UpgradeModal from "./UpgradeModal";
import useCheckCompanyPlan from "./hooks/useCheckCompanyPlan";
import DowngradeModal from "./DowngradeModal";
import { getPlans, getReactivate } from "./api";

const Upgrade = () => {
  const { currentCompany, updateCurrentCompany, refetch } = useCompany();
  const setPlans = usePlans((state) => state.setPlans);

  const subscriptionActive = useSubscriptionActive((state) => state.subscriptionActive);

  const [isUpgradePaymentModal, setIsUpgradePaymentModal] = useState(false);
  const [isDowngradePlans, setIsDowngradePlans] = useState(false);

  const { data: response } = useQuery(["getPlans"], getPlans, {
    onSuccess: (res) => setPlans(res.data.results),
  });

  const { mutate: reactivePlanFn } = useMutation(() => getReactivate(currentCompany?.id), {
    onSuccess: (res) => {
      updateCurrentCompany(res.data);
      toast("Reactived Successfully.", { type: "success" });
    },
  });

  const { data: companyMemberList, isLoading } = useQuery(
    ["getMemberByCompany", "settingMember", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const onUpgradeClick = () => {
    if (currentCompany.subscription && currentCompany.subscription.cancel_at_period_end) {
      reactivePlanFn();
      return;
    }
    setIsUpgradePaymentModal(true);
  };

  const { hasBusinessPlan, hasFreePlan, hasDowngradedPlan } = useCheckCompanyPlan();

  useEffect(() => {
    if (!subscriptionActive) return;
    refetch();
  }, [subscriptionActive]);

  return (
    <>
      {isLoading ? <CentralSpinner /> : null}

      {/* Downgrade and Upgrade Modals */}
      <DowngradeModal
        isDowngradePlans={isDowngradePlans}
        setIsDowngradePlans={setIsDowngradePlans}
      />
      {isUpgradePaymentModal ? (
        <UpgradeModal
          isUpgradePaymentModal={isUpgradePaymentModal}
          setIsUpgradePaymentModal={setIsUpgradePaymentModal}
          memberList={companyMemberList?.data}
        />
      ) : null}
      <div className="px-8 minMaxMd:my-5 minMaxMd:px-4 maxMd:px-4 maxMd:mb-0 mac13Inch:px-6 pb-4">
        {hasBusinessPlan ? (
          <div className="text-sm text-aurometalsaurus dark:text-greychateau my-4 ml-1">
            This workspace is on Business Plan. Enjoy our suite of products at full potential.
          </div>
        ) : null}
        {hasFreePlan ? (
          <div className="text-sm text-aurometalsaurus dark:text-greychateau my-4 ml-1">
            This workspace is on a Free Plan. Upgrade to Business Plan to enjoy our suite of
            products at full potential.
          </div>
        ) : null}

        {response?.data && response?.data?.count > 0 ? (
          <div className="grid grid-cols-3 gap-4 h-auto minMaxMd:grid-cols-2 maxMd:grid-cols-1 mt-4">
            {/* Free Plan */}
            <div className={styles.price_card}>
              <div>
                <h5 className="text-xl mac13Inch:text-sm font-inter-semibold text-mirage dark:text-white mb-4">
                  Free
                </h5>
                <p className="!mt-0">Get access to all the basic products and features.</p>
                <div className="mt-3">
                  <span className="text-[34px] mac13Inch:text-[24px] font-inter-bold text-mirage dark:text-white">
                    0 EUR
                  </span>
                </div>
                <ul className={clsx("mt-4 mb-6 space-y-5", styles.upgradeList)}>
                  <li>
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Dashboard</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Calendar</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Articles</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Agreements</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Policies</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Tasks</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Documents</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="flex justify-center">
                {hasBusinessPlan ? (
                  <button className="btn_primary w-full" onClick={() => setIsDowngradePlans(true)}>
                    Downgrade
                  </button>
                ) : null}
                {hasDowngradedPlan ? (
                  <button className="btn_secondary w-full py-[9px]">Current Plan</button>
                ) : null}
              </div>
            </div>

            {/* Business Plan */}
            <div className={styles.price_card}>
              <div>
                <h5 className="text-xl mac13Inch:text-sm font-inter-semibold text-mirage dark:text-white mb-4">
                  Business
                </h5>
                <p className="!mt-0">
                  Free features plus unlimited storage and advanced permissions.
                </p>
                <div className="mt-3">
                  <span className="text-[34px] mac13Inch:text-[24px] font-inter-bold text-mirage dark:text-white">
                    {response?.data.results[0]?.tiers[0]?.unit_amount / 100} EUR
                  </span>
                  <span className="text-aurometalsaurus dark:text-greychateau text-sm ml-2">
                    /user/month
                  </span>
                </div>
                <ul className={clsx("mt-4 mb-6 space-y-5", styles.upgradeList)}>
                  <li>
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>In Free</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Advanced Permissions</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>Unlimited Storage</span>
                    </div>
                  </li>
                </ul>
              </div>
              {hasDowngradedPlan ? (
                <button className="btn_primary w-full" onClick={onUpgradeClick}>
                  Upgrade
                </button>
              ) : null}
              {hasBusinessPlan ? (
                <button className="btn_secondary py-[9px]" type="button">
                  Current Plan
                </button>
              ) : null}
            </div>

            {/* Enterprise Plan */}
            <div className={styles.price_card}>
              <div>
                <h5 className="text-xl mac13Inch:text-sm font-inter-semibold text-mirage dark:text-white mb-4">
                  Enterprise
                </h5>
                <p className="!mt-0">Business features plus custom tooltips, dashboard, SSO.</p>
                <div className="mt-3 min-h-[51px] flex justify-center items-center">
                  <span className="text-[26px] mac13Inch:text-[24px] font-inter-bold text-mirage dark:text-white">
                    Contact Us
                  </span>
                </div>
                <ul className={clsx("mt-4 mb-6 space-y-5", styles.upgradeList)}>
                  <li>
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>In Business</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span>SSO</span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span> Custom Dashboard </span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span> Custom Tooltips </span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span> Custom Validations </span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span> Features Requests Prioritizied </span>
                    </div>
                  </li>
                  <li className="!mt-3">
                    <div className="flex items-start">
                      <Icon type="checkmark" fill={true} size="icon-smd" />
                      <span> Grand Assistant - Talk to your Data </span>
                    </div>
                  </li>
                </ul>
              </div>
              <a className="btn_primary w-full" href="mailto:hello@grand.io">
                Contact Sales
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Upgrade;
