import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export default function deleteFileById(
  fileId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`policies/files/${fileId}/`, { params: { ...permissions } });
}
