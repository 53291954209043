import get from "lodash/get";

import { RefetchOptions } from "shared/components/common-table/types";

/**
 * Converts refetch options from common table to query params that we will send
 * through axios with RequestConfig.param property
 */
function getQueryParamsFromRefetchOptions(
  refetchOptions: RefetchOptions[] = []
): Record<string, string | string[] | number | number[]> {
  return refetchOptions.reduce((acc, currentValue) => {
    if (currentValue.multiple) {
      const values = get(acc, currentValue.field, []);
      return {
        ...acc,
        [currentValue.field]: [...values, currentValue.value],
      };
    } else {
      return {
        ...acc,
        [currentValue.field]: currentValue.value,
      };
    }
  }, {});
}

export default getQueryParamsFromRefetchOptions;
