import { number, object, string } from "yup";

export const subscriptionSchema = object({
  name: string().required(),
  address: object().shape({
    line1: string().required(),
    city: string().required(),
    country: string().required(),
    postal_code: number().positive().required(),
    state: string().required(),
  }),
  phone_number: string().required(),
  email: string().email().required(),
});
