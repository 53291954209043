import { MultiValue } from "react-select";

import { TeamMemberOptionProps, optionType } from "shared/components/metadata/types";
import TeamMembers from "shared/components/metadata/team-actions/TeamMembers";
import { getOptionType } from "shared/helpers/getOptionTypeData";

import TaskLabel from "../shared/TaskLabel";
import { UserResponse } from "modules/home/types";

export const TaskAssignee = ({
  onFieldChanged,
  assignees,
  assignees_object,
}: {
  onFieldChanged: (fieldName: string, data: string[]) => void;
  assignees: number[];
  assignees_object?: UserResponse[];
}) => {
  return (
    <div>
      <TaskLabel title="Assignee" />
      <TeamMembers
        placeholder="Select usernames"
        members={assignees || []}
        members_object={assignees_object}
        onChange={(
          values?:
            | number
            | string[]
            | string
            | MultiValue<optionType>
            | MultiValue<TeamMemberOptionProps>
            | null
        ) => {
          onFieldChanged(
            "assignees",
            getOptionType(values || [])?.map((item: optionType) => item.value)
          );
        }}
      />
    </div>
  );
};
