import { useMutation } from "@tanstack/react-query";

import { TeamParameter } from "shared/types";

import { getTaskById } from "../api";

/**
 * Hook for Get task by id.
 */
export default function useGetTaskById() {
  const { mutateAsync: getTaskByIdFn } = useMutation(
    ({ taskId, team, company, isAllTeam }: { taskId: number } & TeamParameter) => {
      if (!company) {
        return Promise.reject(new Error("Company is not selected"));
      }
      if (!team) {
        return Promise.reject(new Error("Team is not selected"));
      }

      return getTaskById({
        taskId: taskId,
        teamId: team,
        companyId: company,
        isAllTeam,
      });
    }
  );

  return { getTaskByIdFn };
}
