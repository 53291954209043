import { useFormik } from "formik";
import { object, string } from "yup";

const useAddInputFilter = (action: CallableFunction) => {
  return useFormik<{ name: string }>({
    initialValues: {
      name: "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: addInputSchema,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useAddInputFilter;
export const addInputSchema = object({
  name: string().max(100).required(),
});
