import { useFormik } from "formik";

import { TeamInputData } from "../types";
import { createTeamSchema } from "../validation";

const useCreateTeamForm = (action: CallableFunction) => {
  return useFormik<TeamInputData>({
    initialValues: {
      name: "",
      description: "",
    },
    validateOnChange: false,
    validationSchema: createTeamSchema,
    onSubmit: () => action(),
  });
};

export default useCreateTeamForm;
