import { MultiValue } from "react-select";

import { TeamMemberOptionProps, optionType } from "shared/components/metadata/types";

import { isOptionType } from "./isOptionType";

export const getOptionType = (
  data:
    | number
    | string[]
    | string
    | MultiValue<optionType>
    | MultiValue<TeamMemberOptionProps>
    | null
): Array<optionType> => {
  if (isOptionType(data)) return [...data];
  return [];
};
