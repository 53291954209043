import ArticleMetadata from "../article-metadata";
import ArticleAssigneesDropdown from "./components/article-assignees-dropdown";
import ArticleImpactDropdown from "./components/article-impact-dropdown";
import ArticleStatusDropdown from "./components/article-status-dropdown";
import ArticleTagsDropdown from "./components/article-tags-dropdown";

const ArticleFormMetadata = () => {
  const formMetadata = [
    {
      label: "Status",
      meta: <ArticleStatusDropdown />,
    },
    {
      label: "Impact",
      meta: <ArticleImpactDropdown />,
    },
    {
      label: "Assigned to",
      meta: <ArticleAssigneesDropdown />,
    },
    {
      label: "Tags",
      meta: <ArticleTagsDropdown />,
    },
  ];

  return <ArticleMetadata metadatas={formMetadata} />;
};

export default ArticleFormMetadata;
