import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { StatusResultType } from "../types";
import { TeamParameter } from "shared/types";

export const getCurrentStatus = (
  statusId: number,
  team: TeamParameter
): Promise<AxiosResponse<StatusResultType>> => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;
  const params = new URLSearchParams();
  params.append("team", `${teamParameter}`);
  params.append("company", `${team.company}`);

  return api.get<StatusResultType>(`tasks/status/${statusId}`, { params });
};
