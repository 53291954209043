import React from "react";

import { Link } from "react-router-dom";

import useScreen from "shared/hooks/useScreen";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import Icon from "shared/components/icon/Icon";

import { BreadCrumbProps } from "./types";

const BreadCrumb = ({ breadCrumb }: BreadCrumbProps) => {
  const deviceType = useScreen();

  return (
    <ol className="inline-flex items-center space-x-1 md:space-x-2 BreadCrumbProp custom_list ">
      <li className="inline-flex items-center">
        <Link
          data-testid="breadcrumb-home-icon"
          to="/"
          className="inline-flex items-center text-sm text-aurometalsaurus hover:text-mirage dark:text-boulder dark:hover:text-white"
        >
          <Icon type="home" fill={true} size="icon-smd" className="mr-2.5" />
          {deviceType !== MOBILE_DEVICE ? "Home" : null}
        </Link>
      </li>
      {deviceType !== MOBILE_DEVICE ? (
        (breadCrumb || []).map((_breadCrumb, _index) => (
          <li key={_index} data-testid="any-device">
            <Link
              to={_breadCrumb?.link || ""}
              data-testid={"bread_crumb" + _index}
              className="flex items-center text-ellipsis"
            >
              <Icon type="rightarrow" fill={true} size="icon-md" />
              <p className="ml-1 text-sm font-medium text-aurometalsaurus hover:text-mirage md:ml-2 dark:text-boulder dark:hover:text-white text-left whitespace-nowrap ">
                {_breadCrumb.name || ""}
              </p>
            </Link>
          </li>
        ))
      ) : (
        <li data-testid="mobile-device">
          <Link
            to={breadCrumb[breadCrumb?.length - 2]?.link || ""}
            data-testid={"bread_crumb" + (breadCrumb?.length - 1)}
            className="flex items-center text-ellipsis"
          >
            <Icon type="leftarrow" fill={true} size="icon-md" />
            <p className="ml-1 text-sm font-medium text-aurometalsaurus hover:text-mirage md:ml-2 dark:text-boulder dark:hover:text-white text-left whitespace-nowrap text_ellipsis">
              {breadCrumb[breadCrumb?.length - 1]?.name || ""}
            </p>
          </Link>
        </li>
      )}
    </ol>
  );
};

export default BreadCrumb;
