import api from "shared/api/v1";

import { CompanyProps } from "../types";

export default function updateCompany(
  data: CompanyProps,
  id: number,
  companyPicture: File | null | string
) {
  const formData = new FormData();
  if (data["name"]) {
    formData.append("name", data["name"]);
  }
  if (companyPicture) {
    formData.append("image", companyPicture);
  }

  data.countries?.forEach((country) => formData.append("countries", `${country}`));
  data.industries?.forEach((industry) => formData.append("industries", `${industry}`));

  formData.append("receive_email_notifications", JSON.stringify(true));
  return api.patch(`companies/${id}/?company=${id}`, formData);
}
