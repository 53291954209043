import React from "react";

import Icon from "../icon/Icon";

const NoDataAvailable = () => {
  return (
    <div
      data-testid="no-data-available"
      className="text-center text-aurometalsaurus dark:text-greychateau h-full flex flex-col justify-center items-center "
    >
      <Icon type="no-data" fill={false} size="icon-md" className="mb-3 " />
      No Data Available
    </div>
  );
};

export default NoDataAvailable;
