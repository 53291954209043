import { useFormik } from "formik";

import { ChangePasswordInputData } from "../types";
import { changePasswordSchema } from "../validation";

const useChangePassword = (action: CallableFunction) => {
  return useFormik<ChangePasswordInputData>({
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    validateOnChange: false,
    validationSchema: changePasswordSchema,
    onSubmit: () => action(),
  });
};

export default useChangePassword;
