import { TeamResponse } from "modules/settings/teams/types";
import { CompanyProps } from "shared/context/types/company";

export const getMembersList = (company: CompanyProps, team: TeamResponse | null) => {
  const allMembers = company?.organization_users?.map((item) => item.user);

  const _members = allMembers?.filter((member) => {
    if (member?.id) return team?.members?.includes(member.id);
  });

  return _members;
};
