import React from "react";

import clsx from "clsx";

import gridStyles from "assets/css/gridview.module.css";

import GridCardTitle from "./GridCardTitle";
import GridCardSubTitle from "./GridCardSubTitle";
import GridCardBody from "./GridCardBody";
import GridCardTags from "./GridCardTags";
import GridCardTagsWrapper from "./GridCardTagsWrapper";
import GridCardTag from "./GridCardTag";
import GridCardFooter from "./GridCardFooter";
import GridCardFooterMain from "./GridCardFooterMain";
import GridCardFooterSide from "./GridCardFooterSide";

export type GridCardProps = {
  className?: string;
  onClick?: () => void;
  selected?: boolean;
  children: React.ReactNode;
};

function GridCardComponent({ className, onClick: handleClick, selected, children }: GridCardProps) {
  return (
    <div
      data-testid="grid-card"
      className={clsx(
        gridStyles.gridCard,
        className,
        { "!border-santagrey dark:!border-stormdust": selected },
        "dark:text-white"
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

GridCardComponent.displayName = "GridCard";
GridCardTitle.displayName = "GridCard.Title";
GridCardTitle.displayName = "GridCard.SubTitle";
GridCardBody.displayName = "GridCard.Body";
GridCardFooter.displayName = "GridCard.Footer";
GridCardTags.displayName = "GridCard.Tags";
GridCardTagsWrapper.displayName = "GridCard.TagsWrapper";
GridCardTag.displayName = "GridCard.Tag";
GridCardFooterMain.displayName = "GridCard.FooterMain";
GridCardFooterSide.displayName = "GridCard.FooterSide";

const GridCard = Object.assign(GridCardComponent, {
  Title: GridCardTitle,
  SubTitle: GridCardSubTitle,
  Body: GridCardBody,
  Footer: GridCardFooter,
  FooterMain: GridCardFooterMain,
  FooterSide: GridCardFooterSide,
  Tags: GridCardTags,
  TagsWrapper: GridCardTagsWrapper,
  Tag: GridCardTag,
});

export default GridCard;
