import { AxiosResponse } from "axios";

import api from "shared/api/v1";

export default function deleteMemberById({
  memberId,
  groupId,
  companyId,
}: {
  memberId: number;
  groupId: number;
  companyId: number;
}): Promise<AxiosResponse<"">> {
  const params = new URLSearchParams();
  params.append("company", `${companyId}`);

  return api.delete<"">(`companies/groups/${groupId}/members/${memberId}`, { params });
}
