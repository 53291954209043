import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { RiskResponseType } from "../../types";

export default async function getRiskById(
  { riskId }: { riskId: number },
  params: { company: number }
): Promise<AxiosResponse<RiskResponseType>> {
  let response = await api.get<RiskResponseType>(`risksv2/risks/${riskId}`, {
    params,
  });

  if (response && response.data) {
    response = {
      ...response,
      data: response.data,
    };
  }

  return response;
}
