import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { DeviceRegister } from "../types";

export const registerDevice = (token: string): Promise<AxiosResponse<DeviceRegister>> =>
  api.post<DeviceRegister>("notifications/device-register/", {
    registration_id: token,
    type: "web",
  });
