import React, { memo } from "react";

import styles from "assets/css/commonTable.module.css";

import Icon from "../icon/Icon";
import { SearchOptions } from "./types";

type SearchSuggestionBoxProps = {
  filteredSuggestions: SearchOptions[];
  handleSuggestionClick: (data: SearchOptions) => void;
  text: string;
  setFinalText: (text: string) => void;
};

const SearchSuggestionBox = ({
  filteredSuggestions,
  handleSuggestionClick,
  text,
  setFinalText,
}: SearchSuggestionBoxProps) => {
  return (
    <div className={styles.suggestionBoxMainDiv}>
      {text || filteredSuggestions?.length > 0 ? (
        <div className={styles.suggestionBoxSubDiv}>
          {filteredSuggestions?.length > 0 ? <h5 className={styles.historyText}>History</h5> : null}
          <ul className={styles.suggestionBoxItemList}>
            {text ? <li onClick={() => setFinalText(text)}>{text}</li> : null}
            {filteredSuggestions?.length > 0
              ? filteredSuggestions?.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    <span>{suggestion?.value}</span>
                    <Icon type="search-suggestion" fill={false} size="icon-sm" />
                  </li>
                ))
              : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default memo(SearchSuggestionBox);
