import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId } from "shared/types";

import { CompanyPresetResponse } from "../types";

export const updateCompanyPreset = ({
  companyId,
  presetId,
}: {
  companyId: CompanyId;
  presetId: number;
}): Promise<AxiosResponse<CompanyPresetResponse>> => {
  return api.patch<CompanyPresetResponse>(`risks/company-presets/${companyId}/`, {
    company: companyId,
    preset: presetId,
  });
};
