import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedMitigationFileListResponse } from "../types";

export default function getMitigationFiles(
  mitigationId: number,
  params: { page: number; ordering?: string },
  permissions: TeamParameter
): Promise<AxiosResponse<PaginatedMitigationFileListResponse>> {
  return api.get<PaginatedMitigationFileListResponse>(`risks/mitigations-files/`, {
    params: {
      ...hasNameOrdering(params),
      ...teamToQueryParams(permissions),
    },
  });
}
