import { ActionPayloadType } from "shared/components/common-table/types";

import { StatusResultType } from "modules/tasks/statuses/types";

import { isStatusResultType } from "./isStatusResultType";

export const getStatusResultData = (data: ActionPayloadType[]): Array<StatusResultType> => {
  if (isStatusResultType(data)) return [...data];
  return [];
};
