import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { PaginatedPrestInfoList } from "../types";

export const getPresets = async (
  permissions: {
    team: TeamParameter;
  },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<PaginatedPrestInfoList>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  const result = await api.get<PaginatedPrestInfoList>(`risks/presets/`, {
    params: {
      team: teamParameter,
      company: permissions.team?.company,
      ...hasNameOrdering(requestParams),
    },
  });

  return result;
};
