import { useFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";

import { useRisk } from "shared/store/risks";

import { updateRiskSettings } from "../validation";
import { RisksSettingForm } from "../types";

export const useUpdateRiskSettings = (action: CallableFunction) => {
  const currentCompanyPreset = useRisk((state) => state.currentCompanyPreset);

  return useFormik<RisksSettingForm>({
    initialValues: {
      id: 0,
      preset: cloneDeep(currentCompanyPreset?.preset_info || null),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: updateRiskSettings,
    onSubmit: () => {
      action && action();
    },
  });
};
