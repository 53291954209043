import React from "react";

import TagChip from "../gridview-chip/TagChip";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardTagsProps = {
  tags: string[];
  onClick?: (tag: string) => void;
  className?: string;
};

const GridCardTags: React.FC<GridCardTagsProps> = ({
  tags,
  onClick: handleClick,
  className,
}: GridCardTagsProps) => {
  return (
    <div className={gridStyles.gridTags}>
      {tags.map((tag) => (
        <TagChip
          key={tag}
          tag={tag}
          onClick={(e) => {
            e.stopPropagation();
            if (handleClick) {
              handleClick(tag);
            }
          }}
          className={className}
        />
      ))}
    </div>
  );
};

export default GridCardTags;
