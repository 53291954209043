import { useFormik } from "formik";

import { useTeam } from "shared/store/settings";

import { TeamInputData } from "../types";
import { createTeamSchema } from "../validation";

const useUpdateTeamForm = (action: CallableFunction) => {
  const selectedTeam = useTeam((state) => state.selectedTeam);

  return useFormik<TeamInputData>({
    initialValues: {
      name: selectedTeam?.name || "",
      description: selectedTeam?.description || "",
    },
    validateOnChange: false,
    validationSchema: createTeamSchema,
    onSubmit: () => action(),
  });
};

export default useUpdateTeamForm;
