import React, { FC } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Label, Spinner, TextInput } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";

import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";

import { inviteTeammates } from "./api";
import useInviteForm from "./hooks/useInviteForm";
import { InviteRequestParams } from "./types";

const InviteTeammates: FC<{ company: number }> = ({ company }) => {
  const navigate = useNavigate();

  const { refetch } = useCompany();

  const {
    isLoading,
    mutate: inviteFn,
    isError,
    error,
  } = useMutation(
    (data: InviteRequestParams) =>
      inviteTeammates({
        ...data,
        organization: company,
      }),
    {
      onSuccess: () => {
        refetch();
        navigate("/", { state: { isValid: true } });
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const { handleSubmit, values, errors, handleChange } = useInviteForm(() => {
    const emails = [values.email1, values.email2, values.email3];
    emails.forEach((item) => {
      item && inviteFn({ invitee_identifier: item, organization: null });
    });
  });

  return (
    <section className="auth-container">
      <div className="card card-auth">
        <h2 className="title-lg mb-3 maxMd:text-2xl dark:text-white">Invite Teammates</h2>
        <p className="max-w-[26rem] text-aurometalsaurus dark:text-greychateau">
          Get the most out of Grand by inviting your teammates
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-5">
            <Label value="Send Invitation*" className="font-inter-medium mb-2 block !text-mirage" />
            <TextInput
              name="email1"
              value={values.email1}
              placeholder={"Email address"}
              className="mb-4"
              onChange={handleChange}
              color={errors.email1 ? "failure" : "gray"}
            />
            <TextInput
              name="email2"
              value={values.email2}
              placeholder={"Email address"}
              className="mb-4"
              onChange={handleChange}
              color={errors.email2 ? "failure" : "gray"}
            />
            <TextInput
              name="email3"
              value={values.email3}
              placeholder={"Email address"}
              onChange={handleChange}
              color={errors.email3 ? "failure" : "gray"}
            />
          </div>
          {isError ? <ServerErrors className="mb-4" error={error} /> : null}
          <button className="btn_primary w-full md:py-3.5 text-base">
            <Spinner
              size="md"
              light={true}
              hidden={!isLoading}
              className="mr-3 fill-crayolasblue stroke-crayolasblue"
            />
            Send Invitation
          </button>
          <Link
            className="w-full mt-2 flex justify-center text-sm font-inter-medium text-mirage dark:text-white underline hover:no-underline"
            to="/"
          >
            Skip
          </Link>
        </form>
      </div>
    </section>
  );
};

export default InviteTeammates;
