import React, { useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Spinner } from "flowbite-react";
import Select, { GroupBase, OnChangeValue, OptionsOrGroups } from "react-select";
import { useDarkMode } from "usehooks-ts";

import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { selectControlStyleForCreate } from "shared/helpers/selectStyle";
import { useTeam } from "shared/store/settings";

import { UserResponse } from "modules/home/types";

import { addTeamMembers } from "../api";
import { ManageTeamProps, SelectOptionProps } from "../types";
import MemberList from "./MemberList";
import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

const Members = ({ refetch }: ManageTeamProps) => {
  const { currentCompany } = useCompany();
  const { isDarkMode } = useDarkMode();

  const [selectedTeam, setSelectedTeam] = useTeam((store) => [
    store.selectedTeam,
    store.setSelectedTeam,
  ]);

  const [selectedMembers, setSelectedMembers] = useState<SelectOptionProps[]>([]);

  const { data: companyMemberList, isLoading: isMemberLoading } = useQuery(
    ["getMemberByCompany", "settingMembers", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const {
    isLoading,
    mutate: addTeamMemberFn,
    isError,
    error,
  } = useMutation(
    (members: number[]) => {
      if (!selectedTeam?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }

      return addTeamMembers(selectedTeam?.id, currentCompany.id, members);
    },
    {
      onSuccess: (_, payload) => {
        refetch();
        if (payload && selectedTeam) {
          setSelectedTeam({
            ...selectedTeam,
            members: [...(selectedTeam.members || []), ...payload],
          });
        }
        setSelectedMembers([]);
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const onChange = (selectedOptions: OnChangeValue<SelectOptionProps, true>) => {
    setSelectedMembers([...selectedOptions]);
  };

  const onAddMemberClick = () => {
    if (selectedMembers.length === 0) {
      return;
    }
    const _members = selectedMembers.map((members) => members.value);
    addTeamMemberFn(_members);
  };

  const getTeamMembers = () => {
    const allMembers = companyMemberList?.data?.results?.map((item) => item?.user);
    const _members = allMembers?.filter((item: UserResponse) =>
      selectedTeam?.members?.includes(item.id)
    );
    return _members;
  };

  const filteredMembers = companyMemberList?.data?.results?.filter(
    (member) => !getTeamMembers()?.includes(member.user)
  );

  const companyMembers =
    filteredMembers?.map((item) => {
      return {
        label: item.user && item.user.email,
        value: item.user && item.user.id,
      };
    }) || [];

  return (
    <>
      {isMemberLoading ? <CentralSpinner /> : null}

      <div className="md:flex items-start justify-between relative select-member select-team-member">
        <Select
          classNamePrefix="multitag multitag_spacing"
          isClearable={false}
          isMulti
          options={
            companyMembers as
              | OptionsOrGroups<SelectOptionProps, GroupBase<SelectOptionProps>>
              | undefined
          }
          value={selectedMembers}
          onChange={onChange}
          components={{ IndicatorSeparator: () => null }}
          className="select-control form-sotrol"
          styles={selectControlStyleForCreate(isDarkMode)}
          placeholder={
            <div className="flex items-center">
              <span className="text-sm">Search for members...</span>
            </div>
          }
        />
        <button
          className="btn_primary mac13Inch:!text-xs md:ml-auto maxMd:mt-3.5 maxMd:w-full !ml-0 !text-sm !py-3"
          onClick={onAddMemberClick}
        >
          <Spinner
            size="sm"
            light={true}
            hidden={!isLoading}
            className="mr-2 fill-crayolasblue stroke-crayolasblue"
          />
          {!isLoading ? "Add Member" : "Adding"}
        </button>
      </div>
      {isError && <ServerErrors className="mt-4" error={error} />}
      <h3 className="!mt-4 font-inter-semibold !text-davygrey dark:!text-white border-b border-brightgray dark:border-thunders pb-3">
        Name
      </h3>
      <MemberList refetch={refetch} memberList={companyMemberList?.data?.results || []} />
    </>
  );
};

export default Members;
