import clsx from "clsx";

type Props = {
  type: "black" | "dark" | "white";
  children: React.ReactNode;
  side?: React.ReactNode;
};

const RiskAssessmentDot = ({ type, children, side }: Props) => {
  const dot = (
    <span
      className={clsx(
        "rounded-full min-w-5 w-100 h-5 text-xs inline-flex px-1.5 justify-center items-center overflow-hidden",
        {
          "bg-mirage text-white": type === "black",
          "bg-stormdust text-white": type === "dark",
          "bg-gray-100 border text-black border-black": type === "white",
        }
      )}
    >
      {children}
    </span>
  );

  return side ? (
    <span className={"inline-flex items-center gap-x-2 text-sm"}>
      {dot}
      {side}
    </span>
  ) : (
    dot
  );
};

export default RiskAssessmentDot;
