import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { RegisterResponseType } from "../types";
import { convertRiskData } from "../helpers";

export const createRegister = async (
  payload: {
    name: string;
    reference?: number;
  },
  team: TeamParameter
): Promise<AxiosResponse<RegisterResponseType>> => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;

  let response = await api.post<RegisterResponseType>(
    `risks/`,
    { ...payload, ...team },
    { params: { team: teamParameter, company: team?.company } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertRiskData(response.data),
    };
  }

  return response;
};
