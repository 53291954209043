import { create } from "zustand";

interface sideMenu {
  isSideMenuShown: boolean;
  setIsSideMenuShown: (isSideMenuShown: boolean) => void;
}

export const useSideMenu = create<sideMenu>((set) => ({
  isSideMenuShown: false,
  setIsSideMenuShown: (isSideMenuShown: boolean) => set({ isSideMenuShown }),
}));
