import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyProps } from "shared/context/types/company";

import { CompanyRequestParams } from "../types";

export default function createCompany(
  data: CompanyRequestParams,
  picturePath?: File | null
): Promise<AxiosResponse<CompanyProps>> {
  const formData = new FormData();
  formData.append("name", data["name"]);
  formData.append("is_active", JSON.stringify(true));

  if (picturePath) {
    formData.append("image", picturePath);
  }

  if (data.industries) {
    data.industries.forEach((industry) => formData.append("industries", `${industry}`));
  }

  if (data.countries) {
    data.countries.forEach((country) => formData.append("countries", `${country}`));
  }

  return api.post<CompanyProps>("companies/", formData);
}
