import { UserRole } from "modules/home/types";
import { ADMIN_ROLE, GUEST_ROLE, MEMBER_ROLE, OWNER_ROLE } from "./constant";

export default function getRoleAccessLevel(role: UserRole) {
  switch (role) {
    case "ADMIN":
      return { level: 3, name: "Admin", value: ADMIN_ROLE };
    case "MEMBER":
      return { level: 2, name: "Member", value: MEMBER_ROLE };
    case "OWNER":
      return { level: 4, name: "Owner", value: OWNER_ROLE };
    default:
      return { level: 1, name: "Guest", value: GUEST_ROLE };
  }
}
