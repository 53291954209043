import moment from "moment";

import CommonDatePicker from "shared/components/common-date-picker";
import { convertDate } from "shared/helpers/util";

import TaskLabel from "../shared/TaskLabel";

export const TaskDueDate = ({
  onFieldChanged,
  due_date,
  defaultDate,
}: {
  onFieldChanged: (fieldName: string, data: string | null) => void;
  due_date: string | null;
  defaultDate: Date | null;
}) => {
  return (
    <div>
      <TaskLabel title="Due Date" className="text-sm font-medium text-gray-900 dark:text-white" />
      <CommonDatePicker
        date={due_date ? moment(due_date).toDate() : null}
        handleChange={(date: Date | null) => {
          onFieldChanged("due_date", date ? convertDate(date) : null);
        }}
        defaultDate={defaultDate}
      />
    </div>
  );
};
