import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetAllMitigationRequestParams, PaginatedMitigationListResponse } from "../types";
import { convertMitigationData } from "../helpers";

export const getMitigations = async (
  requestParams: GetAllMitigationRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PaginatedMitigationListResponse>> => {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<PaginatedMitigationListResponse>(`risks/mitigations/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{ team: teamParameter, company: permissions.team?.company },
    },
  });
  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertMitigationData(data);
          }) || [],
      },
    };
  }
  return response;
};
