import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { PolicyFileResponse } from "../types";

export default function uploadPolicyFile(
  data: FormData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<PolicyFileResponse>> {
  return api.post<PolicyFileResponse>(`policies/files/`, data, { params: { ...permissions } });
}
