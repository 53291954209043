import { useEffect } from "react";

const useConditionalyBodyLock = (isVisible: boolean) => {
  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (isVisible) {
      mainContent?.classList.add("overflow-hidden");
      return () => {
        mainContent?.classList.remove("overflow-hidden");
      };
    }
  }, [isVisible]);
};

export default useConditionalyBodyLock;
