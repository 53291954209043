import React from "react";

import clsx from "clsx";

const NotCompliant = ({ count, onClick }: { count: number; onClick?: () => void }) => {
  return (
    <div
      data-testid="complient_div"
      className={clsx(
        { "cursor-pointer": onClick },
        "flex items-center gap-1 text-xs text-red-600 font-bold border border-red-500 px-2 rounded-md !bg-lavenderBlush ml-2.5"
      )}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className="tabError">
        <span>{count}</span>
      </div>
      Not compliant
    </div>
  );
};

export default NotCompliant;
