import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

import { isTeamResponse } from "./isTeamResponse";

export const getTeamResponseData = (
  data: TeamResponse | CompanyUserResponse
): TeamResponse | null => {
  if (data && isTeamResponse(data)) return data;
  return null;
};
