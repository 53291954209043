import getPercent from "./getPercent";

export default function setElementWidth(
  value: number,
  widthRef: React.MutableRefObject<HTMLSpanElement | null>,
  isSimpleScale: boolean
) {
  if (widthRef.current) {
    widthRef.current.style.width = `${isSimpleScale ? value * 100 : getPercent(value)}%`;
  }
}
