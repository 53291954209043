import clsx from "clsx";

import { UserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";
import Avatar from "shared/components/avatar";
import { getUsername } from "shared/helpers/util";

export type ActorInfoProps = {
  user: UserResponse | null;
  team: TeamResponse | null;
};

function getActorPicture({ user, team }: ActorInfoProps): string | null {
  if (user) {
    return user.profile_picture;
  }
  if (team) {
    return team.team_picture || null;
  }
  return null;
}

function getActorName({ user, team }: ActorInfoProps): string {
  if (user) {
    return getUsername(user);
  }
  if (team) {
    return team.name;
  }
  return "";
}

function getDescription({ user, team }: ActorInfoProps): string {
  if (user) {
    return user.email;
  }
  if (team) {
    const membersLength = team.members?.length || 0;
    return `${membersLength} person${membersLength === 1 ? "" : "s"}`;
  }
  return "";
}

export default function ActorInfo({ team, user }: ActorInfoProps) {
  return (
    <div className="flex flex-row items-center gap-2 justify-start w-1/2" data-testid="actor-info">
      <div className="flex flex-col items-center justify-center">
        <Avatar
          url={getActorPicture({ user, team })}
          name={getActorName({ user, team })}
          size="regular"
          avatarFor={user ? "user" : "team"}
          avatarType={user ? "rounded" : "squared"}
        />
      </div>
      <div className="flex flex-col items-start justify-center w-full">
        <div
          className={clsx(
            "text-sm",
            "font-semibold",
            "text-gray-700",
            "dark:text-gray-300",
            "text-ellipsis",
            "whitespace-nowrap",
            "overflow-x-hidden",
            "w-full"
          )}
          data-testid="actor-name"
        >
          {getActorName({ user, team })}
        </div>
        <div
          className={clsx(
            "text-xs",
            "font-normal",
            "text-gray-500",
            "dark:text-gray-400",
            "text-ellipsis",
            "whitespace-nowrap",
            "overflow-x-hidden",
            "w-full"
          )}
          data-testid="actor-description"
        >
          {getDescription({ user, team })}
        </div>
      </div>
    </div>
  );
}
