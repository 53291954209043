import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

import { RegisterResponseType } from "../types";
import { convertRiskData } from "../helpers";

export default async function connectIncidents(
  riskId: number,
  incidentIds: number[],
  permissions: TeamParameter
): Promise<AxiosResponse<RegisterResponseType>> {
  let response = await api.patch<RegisterResponseType>(
    `risks/${riskId}/`,
    { incidents: incidentIds },
    {
      params: { ...teamToQueryParams(permissions) },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertRiskData(response.data),
    };
  }

  return response;
}
