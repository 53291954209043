import { create } from "zustand";
import { FormikProps } from "formik";

import { GetTaskResultType } from "shared/types";
import { TASK_VIEW } from "shared/helpers/constant";

import { PaginatedStatusListResponse, StatusResultType } from "modules/tasks/statuses/types";
import { PaginatedTaskListResponse } from "modules/tasks/overview/types";

export type Task = {
  tasks: PaginatedTaskListResponse | null;
  setTasks: (tasks: PaginatedTaskListResponse | null) => void;
  statuses: PaginatedStatusListResponse | null;
  setStatuses: (statuses: PaginatedStatusListResponse | null) => void;
  currentTask: GetTaskResultType | null;
  setCurrentTask: (currentTask: GetTaskResultType | null) => void;
  viewType: string;
  setViewType: (data: string) => void;
};

export const useTask = create<Task>((set) => ({
  tasks: null,
  setTasks: (tasks: PaginatedTaskListResponse | null) => set({ tasks }),
  statuses: null,
  setStatuses: (statuses: PaginatedStatusListResponse | null) => set({ statuses }),
  currentTask: null,
  setCurrentTask: (currentTask: GetTaskResultType | null) => set({ currentTask }),
  viewType: TASK_VIEW,
  setViewType: (viewType: string) => set({ viewType }),
}));

type StatusEditFormStore = {
  statusEditForm: FormikProps<StatusResultType> | null;
  setStatusEditForm: (statusEditForm: FormikProps<StatusResultType> | null) => void;
};

export const useStatusEditFormStore = create<StatusEditFormStore>((set) => ({
  statusEditForm: null,
  setStatusEditForm: (statusEditForm: FormikProps<StatusResultType> | null) =>
    set({ statusEditForm }),
}));
