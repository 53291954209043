import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { ChangePasswordInputData, ChangePasswordResponse } from "../types";

export default function changePassword(
  data: ChangePasswordInputData
): Promise<AxiosResponse<ChangePasswordResponse>> {
  return api.post<ChangePasswordResponse>("auth/password/change/", data);
}
