import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { show_all_objects } from "shared/helpersV2/constant";
import { TeamId } from "shared/types";

export default function deleteFileById(
  fileId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`agreements/files/${fileId}/`, {
    params: { ...permissions, show_all_objects },
  });
}
