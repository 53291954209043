import React, { useCallback } from "react";

import { Label } from "flowbite-react";
import get from "lodash/get";

import Icon from "shared/components/icon/Icon";
import {
  BOOLEAN_FILTER,
  DATETIME_FILTER,
  DATE_FILTER,
  DROPDOWN_FILTER,
  FILTER_TYPE,
  PAGINATED_FILTER,
  PREDEFINED_DATE_FILTER,
  TEXT_FILTER,
  TYPEAHEAD_FILTER,
  UNIT_VALUE_FILTER,
} from "shared/helpers/constant";
import { TableFilterTypeProps, useTableFilter } from "shared/store/table-column-selection";
import useHideCopilot from "shared/hooks/useHideCopilot";
import useHideIntercom from "shared/hooks/useHideIntercom";

import headerStyles from "assets/css/common-header.module.css";

import { FilterOptionType, FiltersProp, SelectProps } from "../../types";
import InputFilter from "../../InputFilter";
import PredefinedDateFilter from "../../PredefinedDateFilter";
import DateFilter from "../../DateFilter";
import TypeaheadFilter from "../../components/typeahead-filter";
import FilterTitle from "../FilterTitle";
import BooleanFilter from "../../BooleanFilter";
import { toString } from "lodash";
import UnitValueFilter from "../../UnitValueFilter";
import PaginatedFilter from "../../components/paginated-filter";

export default function FilterSubView({
  currentFilter,
  onClose,
  handleFilterClose,
  setCurrentFilter,
  name,
}: {
  currentFilter: FiltersProp;
  onClose: () => void;
  handleFilterClose: () => void;
  setCurrentFilter: (data: FiltersProp | null) => void;
  name: string;
}) {
  useHideCopilot();
  useHideIntercom();

  const setSelectedFilters = useTableFilter((state) => state.setSelectedFilters);

  const createLabel = (e: FilterOptionType) => {
    return {
      label: (
        <div className="!px-0 !py-0 flex items-center bg-cyanblue !pr-2 rounded-md">
          <span className=" mr-2 bg-newcar !py-1 !px-1.5 inline-block rounded-l-[5px]">
            <Icon type="filter" fill={true} size="icon-smd" className="themesvg_white" />
          </span>
          <span className="mr-2 text-newcar text-sm">{e.label}</span>
          <span className="cursor-pointer" onClick={() => removeFilter(e)}>
            <Icon type="close" fill={true} size="icon-xs" className="close_filter_icon" />
          </span>
        </div>
      ),
      value: `${e.key}`,
      field: e.field,
      key: e.key,
      operator: e.operator,
      operators: e.operators,
      values: e.values,
      conditions: e.conditions,
    };
  };

  const onFilterClick = useCallback((e: FilterOptionType) => {
    setSelectedFilters((_search: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      if (
        e.single_option &&
        get(_search, name)?.findIndex((item: SelectProps) => item.field === e.field) != undefined
      ) {
        const data = Object.assign({}, _search, {
          [name]: [
            ...get(_search, name).filter((item: SelectProps) => item.field !== e.field),
            createLabel(e),
          ],
        });
        return data;
      }

      const data = Object.assign({}, _search, {
        [name]: get(_search, name)?.find(
          (item: SelectProps) => item.key === e.key && item.field === e.field
        )
          ? get(_search, name)
          : !get(_search, name)
          ? [createLabel(e)]
          : [...get(_search, name), createLabel(e)],
      });
      return data;
    });

    handleFilterClose();
  }, []);

  const dropdownItem = (type: string, item: FiltersProp) => {
    switch (type) {
      case TEXT_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <Label className={headerStyles.formLabel}>Contains</Label>
            <InputFilter onClick={onFilterClick} item={item} />
          </div>
        );
      case PREDEFINED_DATE_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <PredefinedDateFilter onClick={onFilterClick} item={item} />
          </div>
        );
      case DATE_FILTER:
      case DATETIME_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <DateFilter onClick={onFilterClick} item={item} type={type} />
          </div>
        );
      case DROPDOWN_FILTER:
        return (
          item?.values &&
          item?.values?.map((innerItem) => (
            <span
              onClick={() => {
                onFilterClick({
                  key: innerItem?.key,
                  label: <span>{innerItem.value}</span>,
                  value: innerItem.value,
                  field: item.field,
                  type: FILTER_TYPE,
                  operator: item.operator,
                  conditions: [
                    {
                      field: item.field,
                      operator: item.operator,
                      value: innerItem?.key?.toString(),
                    },
                  ],
                  single_option: item.single_option,
                });
              }}
              className="maxMd:block maxMd:py-2 maxMd:text-aurometalsaurus maxMd:dark:text-greychateau"
            >
              {innerItem.value}
            </span>
          ))
        );
      case PAGINATED_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <PaginatedFilter
              isLoading={item?.isLoading}
              isFetchingData={item?.isFetchingData}
              hasNextPage={item?.hasNextPage}
              isFetchingNextPage={item?.isFetchingNextPage}
              fetchNextPage={item?.fetchNextPage}
              field={item.field}
              operator={toString(item.operator)}
              onClick={onFilterClick}
              items={item?.values ?? []}
              single_option={item.single_option}
              name={name}
            />
          </div>
        );
      case TYPEAHEAD_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <TypeaheadFilter
              field={item.field}
              operator={item.operator || ""}
              onClick={onFilterClick}
              items={item?.values ?? []}
              single_option={item.single_option}
            />
          </div>
        );
      case BOOLEAN_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <BooleanFilter
              label={item.label}
              field={item.field}
              operator={toString(item.operator)}
              onClick={onFilterClick}
              single_option={item.single_option}
            />
          </div>
        );

      case UNIT_VALUE_FILTER:
        return (
          <div className={headerStyles.formElement}>
            <UnitValueFilter
              field={item.field}
              operator={toString(item.operator)}
              onClick={onFilterClick}
              single_option={item.single_option}
              values={item.values}
              label={item.label}
            />
          </div>
        );
    }
  };

  const removeFilter = (filter: FilterOptionType) => {
    setSelectedFilters((prevFilters: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      const data = Object.assign({}, prevFilters, {
        [name]: get(prevFilters, name)?.filter(
          (item: SelectProps) => !(item.field === filter.field && item.key === filter.key)
        ),
      });
      return data;
    });
  };

  return (
    <div className="filter_view">
      <div>
        <FilterTitle
          handleClose={() => {
            onClose();
            setCurrentFilter(null);
          }}
          filterText={currentFilter.label}
        />
        {dropdownItem(currentFilter?.type || "", currentFilter)}
      </div>
    </div>
  );
}
