import { useFormik } from "formik";

import { createGroupMember } from "../validation";
import { GroupInvitationType } from "../types";

const useCreateGroupMember = (action?: CallableFunction) => {
  return useFormik<GroupInvitationType>({
    initialValues: {
      email: {
        label: "",
        value: 0,
      },
    },
    validateOnChange: true,
    validationSchema: createGroupMember,
    onSubmit: () => {
      if (action) {
        action();
      }
    },
  });
};

export default useCreateGroupMember;
