import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { CompanyId, TeamId } from "shared/types";

export default function deleteCustomPreset(
  { presetId }: { presetId: number },
  permissions: { team: TeamId; company: CompanyId }
): Promise<AxiosResponse<"">> {
  return api.delete<"">(`risks/presets/${presetId}/`, { params: { ...permissions } });
}
