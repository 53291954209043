import { AxiosResponse } from "axios";

import { TeamParameter } from "shared/types";
import api from "shared/api/v1";

import { CompanyGroupResponse, GroupInvitationInputData } from "../types";

export const groupInvitation = async (
  groupId: number,
  data: GroupInvitationInputData,
  permissions: TeamParameter
): Promise<AxiosResponse<CompanyGroupResponse>> => {
  return api.post<CompanyGroupResponse>(
    `companies/groups/${groupId}/members/`,
    { ...data },
    {
      params: { company: permissions?.company },
    }
  );
};
