import React, { useCallback, useEffect } from "react";
import { Dropdown } from "flowbite-react";
import clsx from "clsx";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

import styles from "assets/css/settings.module.css";
import { useCompany } from "shared/context/CompanyProvider";
import { TEAM_MODULE, USER_MODULE } from "shared/helpers/ability";
import { initialPermissions, ModuleProps, modules, Permissions } from "shared/data/PermissionList";

import { GroupChildrenComponentProps } from "../types";

const PermissionsComponent = ({ groupMappingForm }: GroupChildrenComponentProps) => {
  const { isAdmin } = useCompany();

  const filteredModules = modules.filter((_module) => {
    if (!isAdmin && [USER_MODULE, TEAM_MODULE].indexOf(_module.key) !== -1) {
      return false;
    }
    return true;
  });

  const onPermissionChange = (module: string, permissisons: string[]) => {
    groupMappingForm.setFieldValue("permission", {
      ...groupMappingForm.values.permission,
      [module]: permissisons,
    });
  };

  const getPermissionName = useCallback(
    (key: string) => {
      if (groupMappingForm.values.permission && get(groupMappingForm.values.permission, key)) {
        return find(Permissions, (item) =>
          isEqual(
            (item.value || [])?.sort(),
            (get(groupMappingForm.values.permission, key) || [])?.sort()
          )
        )?.name;
      }
      return "No Access";
    },
    [groupMappingForm]
  );

  useEffect(() => {
    if (!groupMappingForm.values.permission)
      groupMappingForm.setFieldValue("permission", initialPermissions);
  }, []);

  return (
    <div className={styles.sso_permissions}>
      <h4 className="dark:text-white">Permissions</h4>
      <ul>
        {filteredModules?.map((module: ModuleProps, index: number) => (
          <li
            key={index}
            className={clsx({
              "border-none !pb-0 ": index === 4,
            })}
          >
            <span className="dark:text-white">{module.title}</span>
            <div
              className={clsx(
                getPermissionName(module.key) === "No Access" && styles.no_access,
                "group_mapping"
              )}
            >
              <Dropdown
                label={getPermissionName(module.key)}
                inline={true}
                placement="right-start"
                className={clsx(styles.sso_permissions_dropdown, "custom_dropdown")}
              >
                {Permissions?.map((permission, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => onPermissionChange(module.key, permission.value)}
                    className={clsx({
                      "!text-lavared": permission.name === "No Access",
                    })}
                  >
                    {permission.name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PermissionsComponent;
