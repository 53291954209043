import React from "react";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardFooterMainProps = {
  children: React.ReactNode;
};

const GridCardFooterMain: React.FC<GridCardFooterMainProps> = ({
  children,
}: GridCardFooterMainProps) => {
  return (
    <div className={gridStyles.gridCommentsContainer} data-testid="grid-card-footer-main">
      {children}
    </div>
  );
};

export default GridCardFooterMain;
