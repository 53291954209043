import React, { InputHTMLAttributes } from "react";

import clsx from "clsx";

import styles from "assets/css/formControls.module.css";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  checkboxType?: "primary";
  isIndeterminate?: boolean;
  wrapperClassName?: string;
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { checkboxType = "primary", isIndeterminate = false, wrapperClassName, ...rest } = props;

  return (
    <div className={clsx(styles.checkboxWrapper, wrapperClassName)}>
      <input
        {...rest}
        type="checkbox"
        className={clsx(
          styles.default_checkbox,
          styles[`checkbox_${checkboxType}`],
          { [styles[`checkbox_indeterminate_${checkboxType}`]]: isIndeterminate },
          props.className
        )}
      />
    </div>
  );
};
export default Checkbox;
