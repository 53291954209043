import React from "react";

import { Label, Modal, Spinner, Textarea, TextInput } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import clsx from "clsx";
import { toast } from "react-toastify";

import styles from "assets/css/task.module.css";

import ServerErrors from "shared/components/server-errors";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import { addStatus } from "../api";
import { CreateStatusType } from "../types";
import { useCreateStatusForm } from "../hooks";

const AddStatus = ({
  isStatusModalOpen,
  setIsStatusModalOpen,
  refetchStatus,
}: {
  isStatusModalOpen: boolean;
  setIsStatusModalOpen: (state: boolean) => void;
  refetchStatus: () => void;
}) => {
  const { documentBodyRef } = useDocumentBodyRef();

  const { handleSubmit, values, errors, handleChange, resetForm } = useCreateStatusForm(() => {
    onSubmit(values);
  });
  const {
    isLoading,
    mutate: onSubmit,
    isError,
    error,
  } = useMutation(
    (data: CreateStatusType) => {
      return addStatus(data);
    },
    {
      onSuccess: () => {
        toast("Status Updated Successfully!", { type: "success" });
        refetchStatus();
        setIsStatusModalOpen(false);
        resetForm();
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );
  return (
    <>
      <div data-testid="add-status">
        <Modal
          root={documentBodyRef}
          show={isStatusModalOpen}
          size="2xl"
          popup={true}
          onClose={() => {
            setIsStatusModalOpen(false);
            resetForm();
          }}
          dismissible={true}
          className={styles.taskModalStyle}
        >
          <Modal.Header
            className={clsx(styles.taskModalHeader, "dark:!border-thunders mac13Inch:items-center")}
          >
            <div className="text-lg mac13Inch:text-base font-sans-medium dark:!text-white !text-mirage">
              Create Status
            </div>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body className="!py-[30px] mac13Inch:!py-6">
              <div className="space-y-6 ">
                <div className="mb-4">
                  <Label value="Name*" className="font-medium mb-2 block !text-mirage" />
                  <TextInput
                    data-testid="add-status-name"
                    name="name"
                    placeholder="Enter Status Name"
                    value={values.name}
                    onChange={handleChange}
                    color={errors.name ? "failure" : "gray"}
                  />
                </div>
                <div className="mb-4">
                  <Label value="Description" className="font-medium mb-2 block !text-mirage" />
                  <Textarea
                    data-testid="add-status-description"
                    rows={4}
                    name="description"
                    placeholder="Enter Description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                {isError ? <ServerErrors className="mb-4" error={error} /> : null}
              </div>
            </Modal.Body>
            <Modal.Footer className="border-t dark:!border-thunders mac13Inch:py-4">
              <button className="btn_primary flex ml-auto" type="submit">
                Create Status
                <Spinner
                  hidden={!isLoading}
                  size="sm"
                  className="ml-1 !fill-mirage stroke-mirage"
                />
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default AddStatus;
