import React from "react";
import clsx from "clsx";
import { getRiskHeatMapColor } from "../../shared/helpers/getRiskHeatMapColor";
import RiskAssessmentDot from "./RiskAssessmentDot";
import { CompanyPresetResponse } from "../../register/types";
import { OverviewRiskAssessment } from "../types";

type OverviewRiskAssessmentWithExtra = OverviewRiskAssessment & {
  xPos: number;
  yPos: number;
};

type OverviewRiskAssessmentWithRiskArea = Omit<
  OverviewRiskAssessment,
  "team" | "id" | "reference"
> & {
  average_impact: number;
  average_probability: number;
  average_level: number;
  xPos: number;
  yPos: number;
};

type Props = {
  currentCompanyPreset: CompanyPresetResponse | null;
  isRiskAreaVisible?: boolean;
  formattedData: OverviewRiskAssessmentWithExtra[] | OverviewRiskAssessmentWithRiskArea[];
  tableData?: {
    impact: number;
    probability: number;
  } | null;
  setTableData?: (
    data: {
      impact: number;
      probability: number;
    } | null
  ) => void;
  unauthorized?: boolean;
  language?: "en" | "sv";

  config?: {
    x: {
      title?: string;
      values?: string[];
    };
    y: {
      title?: string;
      values?: string[];
    };
  };
};

const RiskMatrix: React.FunctionComponent<Props> = ({
  currentCompanyPreset,
  formattedData,
  tableData,
  setTableData,
  isRiskAreaVisible,
  // this param is used to determine if the user is unauthorized to view the risk matrix,
  // and if so, we will not show the risk count in the matrix but instead the refs of the risks
  unauthorized = false,
  language,
  config,
}) => {
  const i18n = {
    en: {
      yTitle: "PROBABILITY",
      xTitle: "IMPACT",
    },
    sv: {
      yTitle: "SANNOLIKHET",
      xTitle: "KONSEKVENS",
    },
  }[language || "en"];

  const xValues =
    config?.x?.values ||
    currentCompanyPreset?.preset_info.impact_levels?.map((data) => data.name) ||
    [];
  const yValues =
    config?.y?.values ||
    currentCompanyPreset?.preset_info.probability_levels?.map((data) => data.name) ||
    [];

  const xTitle = config?.x?.title?.toUpperCase() || i18n.xTitle;
  const yTitle = config?.y?.title?.toUpperCase() || i18n.yTitle;
  const xLength = (xValues?.length || 0) >= 10 ? 10 : xValues?.length;
  const yLength = (yValues.length || 0) >= 10 ? 10 : yValues.length;
  const xLevels =
    xValues?.length > 0
      ? xValues.map((item) => ({ name: item }))
      : Array(xLength)
          .fill("")
          .map((item, index) => currentCompanyPreset?.preset_info?.impact_levels?.[index] || item);

  const yLevels =
    yValues?.length > 0
      ? yValues.map((item) => ({ name: item }))
      : Array(xLength)
          .fill("")
          .map(
            (item, index) => currentCompanyPreset?.preset_info?.probability_levels?.[index] || item
          );

  return (
    <div
      className={
        "grid grid-cols-[auto_auto_1fr] auto-cols-min auto-rows-min w-full gap-x-4 gap-y-4 basis-[70%] flex-grow"
      }
    >
      <div className="[writing-mode:vertical-lr] rotate-180 text-center row-span-1">{yTitle}</div>
      <div
        className={
          "flex flex-col text-xs font-normal justify-around [&>div]:py-6 [&>div]:my-1 [&>div]:text-right text-gray-500 dark:text-greychateau"
        }
      >
        {yLevels
          ?.map((level) => {
            return <div key={level.name}>{level.name}</div>;
          })
          .reverse()}
      </div>
      <div
        className={`grid flex-grow w-full h-full`}
        style={{
          gridTemplateRows: `repeat(${yLength}, minmax(0, 1fr))`,
          gridTemplateColumns: `repeat(${xLength}, minmax(0, 1fr))`,
        }}
      >
        {Array.from({ length: yLength }, (_, y) => {
          return Array.from({ length: xLength }, (_, x) => {
            const probability = yLength - y;
            const impact = x + 1;
            const riskValue = probability * impact;

            const contents = isRiskAreaVisible
              ? (formattedData as OverviewRiskAssessmentWithRiskArea[])
                  .filter(
                    (risk: { xPos: number; yPos: number; probability: number; impact: number }) => {
                      return risk.xPos === x && risk.yPos === yLength - 1 - y;
                    }
                  )
                  .sort((a, b) => a.average_level - b.average_level)
              : (formattedData as OverviewRiskAssessmentWithExtra[])
                  .filter(
                    (risk: { xPos: number; yPos: number; probability: number; impact: number }) => {
                      return risk.xPos === x && risk.yPos === yLength - 1 - y;
                    }
                  )
                  .sort(
                    (
                      a: { probability: number; impact: number },
                      b: { probability: number; impact: number }
                    ) => a.probability * a.impact - b.probability * b.impact
                  );

            const selected = tableData?.probability === probability && tableData?.impact === impact;

            return (
              <div
                className={clsx(
                  "relative rounded px-4 py-6 m-1 flex justify-center items-center text-white box-border",
                  {
                    "cursor-pointer": contents.length > 0,
                    "cursor-not-allowed": contents.length === 0,
                  }
                )}
                style={{
                  backgroundColor: getRiskHeatMapColor(
                    probability - 1 || 0,
                    x || 0,
                    yLength,
                    xLength
                  ),
                }}
                onClick={() => {
                  if (contents.length === 0) {
                    return;
                  }
                  if (setTableData) {
                    if (selected) {
                      setTableData(null);
                    } else {
                      setTableData({
                        probability,
                        impact,
                      });
                    }
                  }
                }}
              >
                <div className={"absolute top-0 left-0 py-0 px-1"}>
                  {selected || unauthorized ? (
                    <div
                      className={clsx({
                        "grid grid-cols-4 gap-x-0.5 my-1 gap-y-[0.0625rem]": !isRiskAreaVisible,
                      })}
                    >
                      {contents
                        .slice(0, contents.length > 12 ? 11 : 12)
                        .map((risk: Record<string, string | number>) => {
                          return !isRiskAreaVisible ? (
                            <RiskAssessmentDot type="dark">{risk.reference}</RiskAssessmentDot>
                          ) : (
                            <RiskAssessmentDot type="dark">{risk.name}</RiskAssessmentDot>
                          );
                        })}
                      {contents.length > 12 ? (
                        <RiskAssessmentDot type="white">...</RiskAssessmentDot>
                      ) : null}
                    </div>
                  ) : contents.length > 0 ? (
                    <div>
                      {isRiskAreaVisible &&
                        contents
                          .flat()
                          .map((risk) => (
                            <RiskAssessmentDot type="black">{risk.name}</RiskAssessmentDot>
                          ))}
                      {!isRiskAreaVisible && (
                        <RiskAssessmentDot type="black">{contents.length}</RiskAssessmentDot>
                      )}
                    </div>
                  ) : null}
                </div>
                <span
                  className={clsx({ "opacity-0": selected && contents.length > 5 })}
                  style={{
                    color: getRiskHeatMapColor(
                      probability - 1 || 0,
                      x || 0,
                      yLength,
                      xLength,
                      "text"
                    ),
                  }}
                >
                  {riskValue}
                </span>
              </div>
            );
          });
        })}
      </div>
      <div className={"col-start-3"}>
        <div
          className={
            "flex flex-row text-xs font-normal justify-around [&>div]:px-4 [&>div]:mx-1 [&>div]:text-center text-gray-500 dark:text-greychateau"
          }
        >
          {xLevels?.map((level) => {
            return <div key={level.name}>{level.name} </div>;
          })}
        </div>
      </div>
      <div className={"col-start-3 text-center"}>{xTitle}</div>
    </div>
  );
};

export default RiskMatrix;
