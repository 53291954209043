import Icon from "shared/components/icon/Icon";
import Badge from "shared/componentsV2/form-controls/badge";
import { useArticleDetails } from "../../ArticleDetails";
import { useTodosFormState } from "../article-to-dos-modal/ArticleToDosModal";

const ArticleHeader = () => {
  const { article, setShowToDosModal, hideToDos } = useArticleDetails();

  const [todos] = useTodosFormState();

  const totalTodosAmount = todos.filter(({ is_deleted }) => !is_deleted).length;
  const completedTodosAmount = todos.filter(
    ({ is_completed, is_deleted }) => is_completed && !is_deleted
  ).length;

  return (
    <div className="grid gap-3">
      {article.title ? (
        <h1 className="text-xl font-semibold text-richblack dark:text-lighthouse">
          {article.title}
        </h1>
      ) : null}
      {article.original_title ? (
        <div className="flex gap-1 text-xs">
          <span className="font-semibold">Original title:</span>
          <span className="font-normal">{article.original_title}</span>
        </div>
      ) : null}
      <div className="flex gap-2">
        {article.has_classification ? (
          <Badge
            badgeColor="#4B5563"
            icon={<Icon type="squares-check" size="icon-xxs" />}
            badgeTitle="Classified"
          />
        ) : null}
        {!hideToDos ? (
          <Badge
            className="!border-brightgray dark:border-thunders cursor-pointer"
            icon={<Icon type="to-do" size="icon-xxs" />}
            badgeColor="#4B5563"
            badgeBgColor="transparent"
            badgeTitle={
              <div className="flex gap-1 cursor-pointer">
                To do
                <span className="text-newcar">{`${completedTodosAmount}/${totalTodosAmount}`}</span>
              </div>
            }
            badgeType="secondary"
            onClick={() => setShowToDosModal?.(true)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ArticleHeader;
