import api from "shared/api/v1";
import { AgreementFilesType } from "../types";
import { AxiosResponse } from "axios";
import { show_all_objects } from "shared/helpersV2/constant";

export default function getAgreementFileById(
  permissions: { team: number },
  fileId?: number
): Promise<AxiosResponse<AgreementFilesType>> {
  return api.get<AgreementFilesType>(`agreements/files/${fileId}/`, {
    params: { ...permissions, show_all_objects },
  });
}
