import clsx from "clsx";
import { Spinner, Tooltip } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "shared/components/icon/Icon";
import { useArticleDetails } from "../../ArticleDetails";
import { useCompany } from "shared/context/CompanyProvider";
import styles from "assets/css/articlesV2.module.css";

const ActionBar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyActionsRef = useRef<HTMLDivElement>(null);
  const { usersList } = useCompany();

  const {
    feed,
    article,
    disableNextArticle,
    disablePreviousArticle,
    hideNonShareActions,
    hideNavigation,
    loadingNavigation,
    onNextArticle,
    onPreviousArticle,
    onMarkAsRead,
    onArchive,
    onBookmark,
    openShareModal,
  } = useArticleDetails();

  const readByUser =
    usersList?.find(({ user }) => user.id === Number(article.read_by))?.user.name ??
    article.read_by;

  const actions = [
    {
      tooltip: article.is_read
        ? `This article was marked as read${readByUser ? ` by ${readByUser}` : ""}`
        : "Mark as read",
      icon: "book-open",
      fill: article.is_read,
      badgeColor: article.is_read ? "dodgerblue" : "inherit",
      onClick: onMarkAsRead,
      hide: hideNonShareActions,
    },
    {
      tooltip: "Save",
      icon: "bookmark",
      fill: article.is_saved,
      badgeColor: article.is_saved ? "dodgerblue" : "inherit",
      onClick: onBookmark,
      hide: hideNonShareActions,
    },
    {
      tooltip: "Ignore and archive",
      icon: "archive",
      fill: !article.is_archived,
      badgeColor: !article.is_archived ? "inherit" : "dodgerblue",
      onClick: onArchive,
      hide: hideNonShareActions,
    },
    {
      tooltip: "Share",
      icon: "share",
      onClick: () => openShareModal?.(article),
    },
  ];

  useEffect(() => {
    if (stickyActionsRef.current?.parentElement) {
      const handleParentScroll = (event: Event) => {
        if (event.target instanceof HTMLDivElement && event.target.scrollTop >= 32) {
          setIsSticky(true);
          return;
        }

        setIsSticky(false);
      };

      stickyActionsRef.current?.parentElement.addEventListener("scroll", handleParentScroll);

      return () => {
        stickyActionsRef.current?.parentElement?.removeEventListener("scroll", handleParentScroll);
      };
    }
  }, []);

  return (
    <div
      className={clsx(
        "sticky -top-8 flex items-center justify-between gap-2",
        styles.article_details_actions,
        {
          "py-3 px-8 shadow-sm bg-white dark:bg-mirage !col-start-1 !col-end-4": isSticky,
        }
      )}
      ref={stickyActionsRef}
    >
      {!hideNonShareActions ? (
        <div className="flex gap-2 vendor_pagination_button">
          {feed ? (
            <Link to={feed.url} className="font-medium text-xs">
              <button className="border px-1.5 py-0.5 h-5 rounded-md">{feed.name}</button>
            </Link>
          ) : null}
          <Spinner
            hidden={!loadingNavigation}
            size="sm"
            className="leading-5 !fill-mirage stroke-mirage"
          />
          {!hideNavigation && !loadingNavigation ? (
            <div className="flex">
              <button
                className={clsx(
                  "border border-r-0 font-medium text-xs px-1.5 py-0.5 h-5 rounded-l-md",
                  {
                    "cursor-not-allowed": disablePreviousArticle,
                    "opacity-20 pointer-events-none": !onPreviousArticle,
                  }
                )}
                onClick={onPreviousArticle}
                disabled={disablePreviousArticle || !onPreviousArticle}
              >
                <Icon type="leftarrow" fill={true} size="icon-xs" className="pagination_arrow" />
              </button>
              <button
                className={clsx("border font-medium text-xs px-1.5 py-0.5 h-5 rounded-r-md", {
                  "cursor-not-allowed": disableNextArticle,
                  "opacity-20 pointer-events-none": !onNextArticle,
                })}
                onClick={onNextArticle}
                disabled={disableNextArticle || !onNextArticle}
              >
                <Icon type="rightarrow" fill={true} size="icon-xs" className="pagination_arrow" />
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <Link
          to="https://www.grand.io"
          className="font-medium text-xs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="px-2 py-2 rounded-md flex items-center gap-2 !bg-darkJungleGreen">
            <Icon type="post" fill={false} size="icon-xs" className="notification_icon" />
            <h4
              data-testid="unauthorized-warning"
              className="text-xs text-white font-medium dark:text-mirage"
            >
              Article shared from Grand's news monitoring service
            </h4>
          </button>
        </Link>
      )}
      <div className="flex gap-3">
        {actions.map((action) =>
          !action.hide ? (
            <Tooltip key={action.icon} className="whitespace-nowrap" content={action.tooltip}>
              <div onClick={action.onClick}>
                <Icon
                  className="cursor-pointer"
                  type={action.icon}
                  size="icon-xs"
                  fill={action.fill}
                  badgeColor={action.badgeColor}
                />
              </div>
            </Tooltip>
          ) : null
        )}
      </div>
    </div>
  );
};

export default ActionBar;
