import { RelatedObjectType } from "modules/tasks/overview/types";
import { CompanyResponse, UserResponse } from "modules/home/types";
import getRelatedObjectTeam from "../../helpers/getRelatedObjectTeam";

/**
 * Get the users that are available for sharing a related object.
 * Users that are already in the team of the related object are not available.
 */
export default function getAvailableUsers(
  company: CompanyResponse,
  relatedObject: RelatedObjectType,
  excludedUsers?: number[]
): UserResponse[] {
  const excludedUserSet = new Set(excludedUsers);

  const users = company.organization_users
    .map((companyUser) => companyUser.user)
    .filter((user) => !excludedUserSet.has(user.id));

  const team = getRelatedObjectTeam(relatedObject, company);

  if (team) {
    const usersIdsInTeam = new Set(team.members || []);
    return users.filter((user) => !usersIdsInTeam.has(user.id));
  }

  return users;
}
