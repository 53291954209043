import { useEffect } from "react";

const useKeyPress = (action: CallableFunction) => {
  const downHandler = (key: string) => {
    if (key === "Escape") {
      action();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => downHandler(e.key));
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", (e) => downHandler(e.key));
    };
  }, []);
};

export default useKeyPress;
