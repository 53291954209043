const baseUrl = process.env.REACT_APP_SPREADSHEET_CONVERTER_BACKEND_URL;

export const SPREADSHEET_OPEN_URL = `${baseUrl}/api/Spreadsheet/Open`;
export const SPREADSHEET_SAVE_URL = `${baseUrl}/api/Spreadsheet/Save`;

// trailing slash is mandatory
// export const wordProcessorServiceUrl = `${baseUrl}/api/DocumentEditor/`;
// copy paste only work in this service URL of library.
export const wordProcessorServiceUrl = process.env.REACT_APP_WORD_PROCESSOR_BACKEND_URL;

export const DOCUMENT_EDITOR_OPEN_URL = `${wordProcessorServiceUrl}/Import`;

export const FILE_VIEWER_BUTTON_CONTAINER_ID = "file-viewer-button-container";
