/**
 * Loads google tag manager if the environment variable is set
 */
export default function loadGoogleTagManager() {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    const targetId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    gtag("js", new Date());
    gtag("config", targetId, {});
  } else {
    // eslint-disable-next-line
    window.gtag = function (...args: any) {} as unknown as Gtag.Gtag;
  }
}
