import { TabsProps } from "shared/componentsV2/metadata/types";
import { MenuModeType } from "shared/typesV2";
import { create } from "zustand";

interface horizonScanningViewModeProps {
  menuMode: MenuModeType;
  activeTab: TabsProps;
  setMenuMode: (menuMode: MenuModeType) => void;
  setActiveTab: (activeTab: TabsProps) => void;
}

export const useHorizonScanningViewMode = create<horizonScanningViewModeProps>((set) => ({
  menuMode: "slide",
  activeTab: "general",
  setMenuMode: (menuMode: MenuModeType) => set({ menuMode }),
  setActiveTab: (activeTab: TabsProps) => set({ activeTab }),
}));
