export default function trackPageVisit() {
  if (process.env.REACT_APP_META_PIXEL_ID) {
    fbq("track", "PageView");
  }

  if (process.env.REACT_APP_REDDIT_PIXEL_ID) {
    window.rdt("track", "PageVisit");
  }

  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
}
