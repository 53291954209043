import { createContext } from "react";

import { createContextualCan } from "@casl/react";

import { AppAbility } from "../../helpers/ability";

/**
 * @type {import("react").Context<ICTXDefault>}
 **/

export const AbilityContext = createContext<AppAbility>(null as unknown as AppAbility);

/**
 * @type {import("@casl/react").FunctionComponent<BoundCanProps<T>>}
 **/

export default createContextualCan(AbilityContext.Consumer);
