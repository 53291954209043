import { create } from "zustand";

interface CompanyDropdown {
  isMenuShown: boolean;
  setIsMenuShown: (isMenuShown: boolean) => void;
}

export const useCompanyDropdown = create<CompanyDropdown>((set) => ({
  isMenuShown: false,
  setIsMenuShown: (isMenuShown: boolean) => set({ isMenuShown }),
}));
