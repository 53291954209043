import React from "react";

import { Navigate } from "react-router-dom";

import { useCompany } from "shared/context/CompanyProvider";
import getVisibleModuleForCompany from "shared/helpers/getVisibleModuleForCompany";

export default function CheckPermission() {
  const { currentCompany, ability, currentUserRole } = useCompany();
  const visibleModule = getVisibleModuleForCompany(currentCompany, ability, currentUserRole);
  return <Navigate to={visibleModule?.path || "/not-found"} />;
}
