import { CurrencyType } from "modules/agreements/overview/types";

const countryCodeList = ["USD", "EUR", "GBP", "SEK", "DKK", "NOK"];

export default function convertCurrencyData(currencies: Array<CurrencyType>): Array<CurrencyType> {
  const filteredCountryList = countryCodeList.reduce(
    (prev: CurrencyType[], currencyCode: string) => {
      const currency = currencies.find((currency) => currency.code === currencyCode);

      if (currency) {
        prev = [...prev, currency];
      }
      return prev;
    },
    []
  );

  const response = currencies.filter((currency) => !countryCodeList.includes(currency.code));

  return [...filteredCountryList, ...response];
}
