import { MouseEvent } from "react";
import clsx from "clsx";

import CentralSpinner from "shared/components/spinner/CentralSpinner";
import AddTaskComponent from "shared/components/add-task-component";
import { GetTaskResultType } from "shared/types";
import TasksCardView from "shared/components/tasks-card-view";
import Icon from "shared/components/icon/Icon";

import headerStyles from "assets/css/common-header.module.css";

import { PaginatedTaskListResponse, RelatedObjectType } from "modules/tasks/overview/types";

import { MetaTable } from "../types";

export type ScheduledTasksProps = {
  type: MetaTable;
  isLoading?: boolean;
  taskList?: PaginatedTaskListResponse;
  relatedObject: RelatedObjectType;
  onCreateTask?: () => void;
  onTaskClick?: (task: GetTaskResultType) => void;
  onTaskDelete?: (task: GetTaskResultType) => void;
  onTaskEdit?: (task: GetTaskResultType) => void;
};

function ScheduledTasks({
  isLoading,
  taskList,
  onCreateTask: createTask,
  onTaskClick: selectTask,
  onTaskDelete: deleteTask,
  onTaskEdit: editTask,
}: ScheduledTasksProps) {
  const handleCreateTask = (event: MouseEvent) => {
    event.preventDefault();
    if (createTask) {
      createTask();
    }
  };

  return (
    <div className="metadata-tabs pb-4 maxMd:pb-6" data-testid="scheduled-tasks">
      <h1 className={clsx(headerStyles.subscriptionTitle, "metadata-tabs__header")}>
        Scheduled Tasks
      </h1>
      <div data-testid="task-list-container">
        {!isLoading && taskList ? (
          <div data-testid="task-list">
            {taskList?.results?.map((item) => (
              <TasksCardView
                key={item.id}
                task={item}
                className={clsx("!border-santagrey", "dark:!border-stormdust")}
                onEdit={(task) => {
                  if (editTask) {
                    editTask(task);
                  }
                }}
                onDelete={(task) => {
                  if (deleteTask) {
                    deleteTask(task);
                  }
                }}
                onCardClick={() => {
                  if (selectTask) {
                    selectTask(item);
                  }
                }}
              />
            ))}
          </div>
        ) : null}
        {isLoading ? <CentralSpinner /> : null}
        {!isLoading && !taskList ? (
          <div className="text-center text-gray-500">Can't fetch tasks</div>
        ) : null}
      </div>
      <AddTaskComponent
        icon={<Icon type="plus" fill={true} size="icon-smd" />}
        label="Add task"
        onClick={handleCreateTask}
      />
    </div>
  );
}

export default ScheduledTasks;
