import { create } from "zustand";

interface Menu {
  isMenuShown: boolean;
  setIsMenuShown: (isMenuShown: boolean) => void;
}

export const useMenu = create<Menu>((set) => ({
  isMenuShown: false,
  setIsMenuShown: (isMenuShown: boolean) => set({ isMenuShown }),
}));
