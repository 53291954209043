import React from "react";
import Icon from "../icon/Icon";

/**
 *
 * @returns display disable Label
 */
const OwnerLabel = () => {
  return (
    <div
      className="flex justify-between items-center cursor-not-allowed dowun_arrow"
      data-testid="owner_label"
    >
      <p className="!text-gray-500 dark:!text-greychateau text-sm">Full Access</p>
      <Icon type="down" fill={false} size="icon-sm" className="ml-2" />
    </div>
  );
};

export default OwnerLabel;
