import { FilterValues } from "shared/components/common-table/types";

import { ArticleFilterListResponse } from "../types";
import articleFilterToFilterValues from "./articleFilterToFilterValues";

/**
 * Convert article filter response to filter values.
 * If response is undefined, return empty array.
 */
export default function getFillterValuesFromArticleFilterResponse(
  response?: ArticleFilterListResponse
): FilterValues[] {
  if (!response) {
    return [];
  }
  return response?.results?.map(articleFilterToFilterValues);
}
