import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Checkbox from "shared/componentsV2/form-controls/checkbox";
import { ToDo as TToDo } from "modules/horizonScanning/types";
import styles from "assets/css/articlesV2.module.css";
import TodoRowActions from "modules/horizonScanning/feeds/components/create-from-scratch-modal/components/to-do/to-do-row-actions";

interface IToDoProps {
  todo: TToDo;
  disabled?: boolean;
  onChangeTodo: (newValues: Partial<TToDo>) => void;
}

const TodoItem = ({ todo, onChangeTodo, disabled }: IToDoProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = (value: string) => {
    if (value.trim() === "") {
      setIsEditing(false);
      return;
    }

    onChangeTodo({
      description: value,
    });

    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEdit(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleEdit(e.currentTarget.value);
    e.currentTarget.value = "";
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.select();
      }, 100);
    }
  }, [isEditing]);

  return (
    <li
      className={clsx(
        styles.article_to_do,
        "grid items-center grid-cols-[auto_1fr_auto] gap-2 rounded-lg px-3 py-2 bg-ghostwhite",
        {
          "line-through text-aurometalsaurus": todo.is_completed && !isEditing,
        }
      )}
    >
      <Checkbox
        checked={todo.is_completed}
        wrapperClassName="!p-0"
        className="w-3.5 h-3.5"
        onChange={() =>
          onChangeTodo({
            is_completed: !todo.is_completed,
          })
        }
      />

      {isEditing ? (
        <input
          ref={inputRef}
          defaultValue={todo.description}
          className="w-full bg-transparent text-sm font-medium grow !px-0 !py-1"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      ) : (
        <span
          onClick={() =>
            onChangeTodo({
              is_completed: !todo.is_completed,
            })
          }
          tabIndex={0}
        >
          {todo.description}
        </span>
      )}

      {!disabled ? (
        <TodoRowActions
          onEdit={() => {
            setIsEditing(true);
          }}
          onDelete={() =>
            onChangeTodo?.({
              is_deleted: true,
            })
          }
        />
      ) : null}
    </li>
  );
};

export default TodoItem;
