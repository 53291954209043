import { PresetScaleType } from "modules/risks/register/types";

import { DEFAULT_PROGRESS_VALUE } from "./constant";

export default function scale(value: number, type: PresetScaleType): number {
  if (type === "simple") {
    return Number((100 * value).toFixed(5));
  } else {
    if (value === DEFAULT_PROGRESS_VALUE) {
      return DEFAULT_PROGRESS_VALUE;
    } else {
      return Number((DEFAULT_PROGRESS_VALUE + (99 - DEFAULT_PROGRESS_VALUE) * value).toFixed(5));
    }
  }
}
