import { useFormik } from "formik";

import { useTeam } from "shared/store/settings";
import { addStatusSchema } from "modules/tasks/overview/components/validation";

import { CreateStatusType } from "../types";

export const useCreateStatusForm = (action: CallableFunction) => {
  const currentTeam = useTeam((state) => state.currentTeam);
  return useFormik<CreateStatusType>({
    initialValues: {
      name: "",
      description: "",
      team: currentTeam?.id || 0,
    },
    validateOnChange: false,
    validationSchema: addStatusSchema,
    onSubmit: () => {
      action();
      // resetForm();
    },
  });
};
