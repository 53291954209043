import { useEffect } from "react";

const useSetSettingsBg = () => {
  const body = document.getElementsByTagName("body")[0];

  useEffect(() => {
    body?.classList.add("setting_bg");
    return () => body?.classList.remove("setting_bg");
  }, []);
};

export default useSetSettingsBg;
