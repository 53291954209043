import { DocumentFileType } from "../type";

/**
 * Get document file type
 */
export default function getDocumentFileType({ filetype }: { filetype: string }): DocumentFileType {
  if (filetype === "pdf") {
    return "pdf";
  }

  if (["jpg", "png", "jpeg", "svg"].includes(filetype)) {
    return "image";
  }

  if (["xlsx", "xls"].includes(filetype || "")) {
    return "spreadsheet";
  }

  if (["docx", "doc"].includes(filetype || "")) {
    return "document";
  }

  return "other";
}
