import every from "lodash/every";
import isObject from "lodash/isObject";
import has from "lodash/has";

import { PolicyFileResponse } from "../types";

/**
 * Type guard to check is data is PolicyFileResponse
 */
export default function isPolicyFileResponse(data: unknown): data is PolicyFileResponse {
  return every([
    isObject(data),
    has(data, "id"),
    has(data, "name"),
    has(data, "file"),
    has(data, "policy"),
  ]);
}
