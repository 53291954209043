import getFilteredAPIData from "shared/helpers/getFilteredAPIData";

const fieldsList = [
  { key: "assignees", value: "id" },
  { key: "owner", value: "id" },
  { key: "tags", value: "slug" },
];

export default function convertTaskData<TData>(data: TData): TData {
  return getFilteredAPIData(data, fieldsList);
}
