import isArray from "lodash/isArray";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export default function getFilteredAPIData<TData>(
  data: TData,
  fieldsList: Array<{ key: string; value: string }>
) {
  let updateData = data;
  fieldsList.forEach((field) => {
    if (
      isArray(get(data, field.key)) &&
      get(data, field.key) !== null &&
      !isEmpty(get(data, field.key))
    ) {
      if (typeof get(data, field.key)[0] === "object" && get(data, field.key) !== null) {
        updateData = {
          ...updateData,
          [`${field.key}_object`]: get(data, field.key),
          [field.key]:
            get(data, field.key).map((item: { id: number }) => get(item, field.value)) || [],
        };
      }
      return;
    }
    if (
      typeof get(data, field.key) === "object" &&
      !isArray(get(data, field.key)) &&
      get(data, field.key) !== null
    ) {
      updateData = {
        ...updateData,
        [`${field.key}_object`]: get(data, field.key),
        [field.key]: get(get(data, field.key), field.value) || null,
      };
      return;
    }
  });

  return updateData;
}
