import { create } from "zustand";

interface cacheUniqStore {
  cacheId: number;
  incCacheId: () => void;
  resetCacheId: () => void;
}

export const useCacheCounter = create<cacheUniqStore>((set) => ({
  cacheId: 0,
  incCacheId: () => set((state) => ({ cacheId: state.cacheId + 1 })),
  resetCacheId: () => set(() => ({ cacheId: 0 })),
}));
