import React, { ChangeEvent, useRef, useState } from "react";
import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import _ from "lodash";
import clsx from "clsx";

import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import { getServerErrors } from "shared/helpers/util";
import { useCompany } from "shared/context/CompanyProvider";
import { useAuthProvider } from "shared/store/settings";
import { CreateIcon } from "shared/components/icons/Icons";
import { getFileNameFromS3URL } from "shared/helpers/util";
import useKeyPress from "shared/hooks/useKeyPress";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import StaticDropdown from "shared/components/common-dropdown/static-dropdown";

import useAddProviderForm from "../hooks/useAddProviderForm";
import { SSOProviderProps, SSOUserFederatedProps } from "../types";
import Domains from "./Domains";
import { addProvider, updateProvider, updateProviderFederated } from "../api";
import GroupList from "../federated-group/GroupList";
import GroupModal from "../federated-group/GroupModal";

const SSOURL = `${process.env.REACT_APP_SSO_CALLBACK}`;

type ProvidersModalProps = {
  refetch: () => void;
};

const ProvidersModal = ({ refetch }: ProvidersModalProps) => {
  const { currentCompany, refetch: refetchCompany } = useCompany();

  const addProviderModal = useAuthProvider((state) => state.addProviderModal);

  const provider = useAuthProvider((state) => state.provider);

  const setProvider = useAuthProvider((state) => state.setProvider);

  const setAddProviderModal = useAuthProvider((state) => state.setAddProviderModal);

  const [isGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);

  const xmlFileRef = useRef<HTMLInputElement | null>(null);

  const [metaDataDocumentType, setMetaDataDocumentType] = useState<string>("URL");

  const { documentBodyRef } = useDocumentBodyRef();

  const providerForm = useAddProviderForm(() => onMutate());

  const errorHandler = (error: AxiosError) => {
    return getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
  };

  const successHandler = (message: string) => {
    refetch();
    toast(`${message} Provider Successfully.`, { type: "success" });
    setAddProviderModal(false);
    providerForm.resetForm();
  };

  const { isLoading: isUpdating, mutate: ssoManageMutationFn } = useMutation(
    (data: SSOProviderProps) => {
      if (!provider?.id) {
        return Promise.reject(new Error("Provider is not selected"));
      }
      return updateProvider(data, provider?.id);
    },
    {
      onSuccess: () => {
        successHandler("Updated");
        setProvider(null);
      },
      onError: (error: AxiosError) => errorHandler(error),
    }
  );

  const { mutate: ssoFederatedMutationFn } = useMutation((data: SSOUserFederatedProps) => {
    if (!provider?.id) {
      return Promise.reject(new Error("Provider is not selected"));
    }

    return updateProviderFederated(data, provider?.id);
  });

  const { isLoading, mutate: ssoMutationFn } = useMutation(
    (data: SSOProviderProps) => addProvider(data),
    {
      onSuccess: () => successHandler("Added"),
      onError: (error: AxiosError) => errorHandler(error),
    }
  );

  const onMutate = () => {
    const payload = providerForm.values;
    if (!payload.metadata_file && !payload.metadata_url) {
      providerForm.setErrors({ metadata_url: "" });
      toast("Either meta data file or meta data url is required", {
        type: "error",
      });
      return;
    }
    if (metaDataDocumentType === "URL") {
      payload.metadata_file = "";
    } else if (metaDataDocumentType === "File") {
      payload.metadata_url = "";
    }

    if (_.isEmpty(provider)) {
      ssoMutationFn({ ...payload, company: currentCompany.id });
    } else {
      const updateRequestPayload = _.isEqual(
        providerForm.initialValues.metadata_file,
        payload.metadata_file
      )
        ? _.omit(payload, "metadata_file")
        : payload;
      ssoManageMutationFn({
        ...updateRequestPayload,
        company: currentCompany.id,
      });
    }
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target?.files ? target?.files[0] : null;

    if (file && file["type"].split("/")[1] !== "xml") {
      toast("Upload a valid file", { type: "error" });
      return;
    }

    providerForm.setFieldValue("metadata_file", file);
  };

  const onGroupModalClose = () => {
    setAddProviderModal(false);
    providerForm.resetForm();
    setProvider(null);
    setAddProviderModal(false);
    refetchCompany();
  };

  const onUseFederatedGroup = (e: ChangeEvent<HTMLInputElement>) => {
    providerForm.setFieldValue("use_federated_groups", e.target.checked);
    ssoFederatedMutationFn({
      use_federated_groups: e.target.checked,
      company: currentCompany.id,
    });
  };

  useKeyPress(() => setAddProviderModal(false));

  return (
    <Modal
      dismissible={true}
      show={addProviderModal}
      onClose={onGroupModalClose}
      size={"4xl"}
      root={documentBodyRef}
      className="modal_mobileview_center provider-modal"
    >
      <Modal.Header className="dark:!border-thunders">
        <div className="font-inter-semibold text-lg">
          {!_.isEmpty(provider) ? "Update" : "Add"} Provider
        </div>
      </Modal.Header>
      <Modal.Body className="h-[60vh] md:h-[72vh] overflow-y-auto maxMd:px-4">
        <div className="flex justify-between mb-4">
          <div>
            <Label value="Enable Provider" className="font-inter-medium mb-2 block text-mirage" />
            <p className="text-aurometalsaurus dark:text-greychateau text-sm !mt-0">
              Enable or disable use of provider
            </p>
          </div>
          <div>
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                data-testid="enable-provider"
                type="checkbox"
                name="enabled"
                className="sr-only peer custom_checkbox"
                onChange={(e) => providerForm.setFieldValue("is_active", e.target.checked)}
                checked={providerForm.values.is_active}
              />
              <div className="bg-gray-300 w-11 h-6 peer-focus:outline-none rounded-full peer dark:bg- peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 dark:peer-checked:bg-iridium peer-checked:bg-mirage"></div>
            </label>
          </div>
        </div>
        <div className="mb-4">
          <Label value="Provider Name*" className="font-inter-medium mb-2 block text-mirage" />
          <TextInput
            data-testid="provider-name"
            placeholder="Enter Provider Name"
            name="name"
            value={providerForm.values.name}
            onChange={providerForm.handleChange}
            color={providerForm.errors.name ? "failure" : "gray"}
          />
        </div>
        <div className="mb-4">
          <Label value="Metadata Document*" className="font-inter-medium mb-2 block text-mirage" />
          <input
            id="url"
            type="radio"
            name="metadata_document"
            className="radioButtons"
            onChange={() => setMetaDataDocumentType("URL")}
            defaultChecked={metaDataDocumentType === "URL"}
          />
          <label htmlFor="url" className="ml-2 text-sm font-medium text-mirage dark:text-gray-300">
            URL
          </label>
          <input
            id="file"
            type="radio"
            name="metadata_document"
            className="radioButtons ml-4"
            onChange={() => setMetaDataDocumentType("File")}
          />
          <label
            htmlFor="file"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            File
          </label>
          {metaDataDocumentType === "File" && (
            <div className="flex items-center">
              <input
                data-testid="metadata-file"
                ref={xmlFileRef}
                type="file"
                className="hidden"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onImageChange(e)}
              />
              <button
                type="button"
                className="btn_secondary flex justify-center items-center gap-2 my-3 "
                onClick={() => xmlFileRef.current?.click()}
              >
                <UploadIcon className="upload_icon" />
                Upload Files
              </button>
              {!provider?.metadata_file && (
                <p className="text-sm ml-4 dark:text-white">
                  {_.isObject(providerForm.values.metadata_file)
                    ? providerForm.values.metadata_file.name
                    : ""}
                </p>
              )}
              {provider?.metadata_file && (
                <a
                  href={`${provider?.metadata_file}`}
                  className="text-sm ml-4 underline dark:text-white"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {getFileNameFromS3URL(`${provider?.metadata_file}`)}
                </a>
              )}
            </div>
          )}
          {metaDataDocumentType === "URL" && (
            <TextInput
              data-testid="metadata-url"
              placeholder="Provide metadata document endpoint URL"
              name="metadata_url"
              className="mt-3"
              onChange={providerForm.handleChange}
              value={providerForm.values.metadata_url}
              color={
                !providerForm.isValid &&
                !providerForm.values.metadata_file &&
                !providerForm.values.metadata_url
                  ? "failure"
                  : "gray"
              }
            />
          )}
        </div>
        <div className="mb-4">
          <Label value="Reply URL" className="font-inter-medium mb-2 block text-mirage" />
          <TextInput disabled value={SSOURL} data-testid="reply_url" />
        </div>
        <div className="mb-4">
          <Label value="Entity ID" className="font-inter-medium mb-2 block text-mirage" />
          <TextInput disabled value={SSOURL} data-testid="entity_id" />
        </div>
        <div className="mb-4">
          <Label
            value="Assertion Consumer Services (ACS) URL*"
            className="font-inter-medium mb-2 block text-mirage"
          />

          <StaticDropdown
            placeholder="Select SSO Type"
            mainClassname="sso_select"
            hasError={providerForm.errors.sso_type ? true : false}
            handleChange={(data) => {
              providerForm.setFieldValue("sso_type", data || "");
            }}
            value={providerForm.values.sso_type}
            data={[
              { id: "microsoft", name: "microsoft" },
              { id: "google", name: "google" },
            ]}
          />
        </div>
        <div className="mb-4">
          <Label value="Enter Domains*" className="font-inter-medium mb-2 block text-mirage" />
          <p className="text-sm mb-2 text-aurometalsaurus dark:text-greychateau">
            Define the email domains associated with this authentication provider. These domains
            will determine if a user is presented this provider as an option during login.
          </p>
          <Domains providerForm={providerForm} />
          <p
            className={clsx(
              {
                "!text-red-500":
                  providerForm.errors.domains && providerForm.values.domains.length > 0,
              },
              "text-xs mt-2 text-aurometalsaurus dark:text-greychateau"
            )}
          >
            Enter an email domain or a regular expression that matches multiple domains.
          </p>
        </div>
        {!_.isEmpty(provider) && (
          <>
            <div className="flex justify-between mb-4">
              <div>
                <Label
                  value="Enable Federated Groups"
                  className="font-inter-medium mb-2 block text-mirage"
                />
                <p className="text-aurometalsaurus dark:text-greychateau text-sm !mt-0">
                  Enable or disable federated groups
                </p>
              </div>
              <div>
                <label className="inline-flex relative items-center cursor-pointer groups_switch">
                  <input
                    data-testid="use-federated-groups"
                    type="checkbox"
                    className="sr-only peer "
                    onChange={onUseFederatedGroup}
                    checked={providerForm.values.use_federated_groups}
                  />
                  <div className="bg-gray-300 w-11 h-6 peer-focus:outline-none rounded-full peer dark:bg- peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 dark:peer-checked:bg-iridium peer-checked:bg-mirage"></div>
                </label>
              </div>
            </div>
            {providerForm.values.use_federated_groups && (
              <>
                <div className="mb-4">
                  <Label
                    value="Group Attribute"
                    className="font-inter-medium mb-2 block text-mirage"
                  />
                  <TextInput
                    data-testid="groups-attribute"
                    placeholder="Enter key from identity provider XML"
                    name="groups_attribute"
                    value={providerForm.values.groups_attribute}
                    onChange={providerForm.handleChange}
                  />
                </div>
                <button
                  data-testid="add-group-mapping"
                  type="button"
                  className="btn_secondary flex justify-center items-center gap-2"
                  onClick={() => setIsGroupModalOpen(true)}
                >
                  <CreateIcon />
                  Add Group Mapping
                </button>
                <GroupList sso={provider.id} setIsGroupModalOpen={setIsGroupModalOpen} />
                <GroupModal
                  sso={provider.id}
                  isGroupModalOpen={isGroupModalOpen}
                  setIsGroupModalOpen={setIsGroupModalOpen}
                />
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="dark:!border-thunders p-4 maxMd:flex-col">
        <button
          className="btn_secondary ml-auto maxMd:w-full maxMd:mb-[18px]"
          onClick={onGroupModalClose}
          type="button"
        >
          Cancel
        </button>
        <button
          data-testid="submit"
          className="btn_primary maxMd:w-full"
          onClick={() => providerForm.handleSubmit()}
        >
          <Spinner
            size="sm"
            light={true}
            hidden={!isLoading || !isUpdating}
            className="mr-3 !fill-mirage stroke-mirage"
          />
          {!_.isEmpty(provider) ? "Update" : "Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProvidersModal;
