import { Document } from "../type";
import getDocumentFileType from "./getDocumentFileType";

/**
 * Fetches spreadsheet from the server.
 * Rejects if passed document is not an spreadsheet.
 */
export default function fetchSpreadsheet(document: Document): Promise<File> {
  if (getDocumentFileType(document) !== "spreadsheet") {
    return Promise.reject(new Error("Passed document is not an spreadsheet."));
  }
  return fetchFile(document.file, document.filename);
}

export async function fetchFile(fileUrl: string, fileName: string): Promise<File> {
  const response = await fetch(fileUrl, { mode: "cors" });
  const blob = await response.blob();
  return new File([blob], fileName);
}
