import { useEffect, useRef } from "react";

const useDocumentBodyRef = () => {
  const documentBodyRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);

  return { documentBodyRef: documentBodyRef.current ?? undefined };
};

export default useDocumentBodyRef;
