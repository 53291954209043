import { AxiosResponse } from "axios";
import { useFormik } from "formik";

import { ChangeVATorGSTProps } from "../types";
import { changeVATorGSTSchema } from "../validation";

const useUpdateVATorGSTForm = (action: CallableFunction, billingDetails?: AxiosResponse) => {
  return useFormik<ChangeVATorGSTProps>({
    initialValues: {
      eu_vat: billingDetails?.data.billing_details.eu_vat || "",
    },
    validateOnChange: false,
    validationSchema: changeVATorGSTSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useUpdateVATorGSTForm;
