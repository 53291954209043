import { forwardRef, useEffect } from "react";

import { FILE_VIEWER_BUTTON_CONTAINER_ID } from "../../constants";

export type FileViewerButtonsContainerProps = {
  onMounted: () => void;
};

const FileViewerButtonsContainer = forwardRef<HTMLDivElement, FileViewerButtonsContainerProps>(
  ({ onMounted: setMounted }, ref) => {
    useEffect(() => {
      setMounted();
    }, []);

    return (
      <div
        ref={ref}
        className="flex flex-row maxMd:flex-col maxMd:w-full"
        id={FILE_VIEWER_BUTTON_CONTAINER_ID}
        data-testid={FILE_VIEWER_BUTTON_CONTAINER_ID}
      ></div>
    );
  }
);

export default FileViewerButtonsContainer;
