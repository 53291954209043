import { useQuery } from "@tanstack/react-query";

import { RelatedObjectType } from "modules/tasks/overview/types";
import { useTeam } from "shared/store/settings";
import loadAll from "shared/helpers/loadAll";

import { getPermissionsList } from "../api";
import { MetaTable } from "../types";
import isPermissionModule from "../helpers/isPermissionModule";

export default function usePermissionList({
  type,
  relatedObject,
}: {
  type: MetaTable;
  relatedObject: RelatedObjectType;
}) {
  const { currentTeam } = useTeam();

  function loader(page: number) {
    if (!isPermissionModule(type)) {
      return Promise.reject("Invalid permission module");
    }

    if (!relatedObject.id) {
      return Promise.reject("Invalid related object id");
    }

    return getPermissionsList(
      type,
      relatedObject.id,
      {
        team: currentTeam?.id,
        isAllTeam: currentTeam.is_all_team,
        company: currentTeam?.company,
      },
      page
    ).then((response) => {
      return response.data;
    });
  }

  return useQuery(["permissions", type, relatedObject.id], () => loadAll(loader));
}
