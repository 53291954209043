/* eslint-disable @typescript-eslint/no-explicit-any */
import { StylesConfig } from "react-select";
import { CSSProperties } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectControlStyleForTagsCreateV2 = (
  isDarkMode = false,
  overrideProperties?: {
    control?: CSSProperties;
  }
): StylesConfig<any> => {
  return {
    multiValue: (data: any, state: any) => {
      return {
        display: "flex",
        color: state.data.className === "normalTag" ? "#E5E7EB" : "red",
        borderRadius: "0.375rem",
        background: isDarkMode ? "#E1EFFE" : "#E1EFFE",
        option: {},
        div: {
          color: isDarkMode ? "#1C64F2" : "#1C64F2",
          paddingTop: "2px",
          paddingBottom: "2px",
          borderRadius: "0.375rem",
          fontWeight: "500",
          fontSize: 12,
          svg: {
            width: "14px",
            height: "14px",
            fill: isDarkMode ? "#1C64F2" : "#1C64F2",
            ":hover": {
              fill: isDarkMode ? "#1C64F2" : "!red",
            },
          },

          ":hover": {
            backgroundColor: !isDarkMode ? "#E1EFFE" : "#E1EFFE",
            borderTopRightRadius: "0.375rem",
            borderBottomRightRadius: "0.375rem",
            color: isDarkMode ? "#1C64F2" : "#1C64F2",
          },
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",

            color: "transparent",
            fontSize: 14,
            display: "flex",
            section: {
              // backgroundColor: state.data.className === "normalTag" ? "#E5E7EB" : "#1D1D1F",
              backgroundColor: state.data.className === "normalTag" ? "red" : "red",

              img: {
                margin: state.data.icon ? "0.375rem 0.3125rem" : "",
              },
            },
            span: {
              padding: state.data.icon ? "0.2rem 0.5rem" : "0px !important",
            },
          },
        },
        ".css-xb97g8, .css-pv2a3q": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "600",
      padding: "6px 8px",
      cursor: "pointer",
      color: !isDarkMode
        ? state.isFocused
          ? "#111827"
          : "#111827"
        : state.isFocused
        ? "#C9C9C9"
        : "#C6C6C6",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#262628"
        : "#1d1d1f",
      fontSize: "12px",
      lineHeight: "18px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#343435" : "#343435",
      "&:hover": {
        backgroundColor: !isDarkMode ? "#F6F6F6" : "#262628",
        color: !isDarkMode ? "#374151" : "#C9C9C9",
      },
    }),
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,
        display: state.hasValue ? "flex" : "grid",
      };
    },
    container: (provided: any) => {
      return {
        ...provided,
        width: "100%",
        position: "relative",
        "&:focus-visible": { outline: "none" },
      };
    },
    control: (provided: any) => {
      return {
        ...provided,
        paddingTop: "4px",
        paddingBottom: "4px",
        fontSize: "14px",

        color: !isDarkMode ? "red" : "#1E429F",
        borderRadius: "8px",
        backgroundColor: !isDarkMode ? "#FFFFFF" : "#1D1D1F",

        minHeight: "2.375rem",

        borderColor: isDarkMode ? "#343435" : "#E5E7EB",
        boxShadow: "none",
        "&:hover": {
          borderColor: !isDarkMode ? "#E5E7EB" : "#343435",
        },
        ...(overrideProperties?.control || {}),
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20",
      borderRadius: "8px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)",
      borderColor: isDarkMode ? "#343434" : "#E4E6EA",
      borderWidth: "1px",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#262628"
        : "#1d1d1f",
    }),
    menuList: (provided: any) => ({
      ...provided,
      zIndex: "50",
      borderRadius: "8px",
      padding: "4px",
    }),
    group: (provided: any) => ({
      ...provided,
      padding: "0px",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      maxWidth: "8rem",
      fontFamily: "InterMedium",
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      svg: {
        height: "1rem",
        width: "1rem",
        color: "#6b7280",
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        height: "1rem",
        width: "1rem",
      },
    }),
  };
};

export const selectControlStyleForCreateV2 = (isDarkMode = false): StylesConfig<any> => {
  return {
    multiValue: (data: any, state: any) => {
      return {
        display: "flex",
        margin: "0.25rem",
        color: state.data.className === "normalTag" ? "#E5E7EB" : "#fff",
        borderRadius: "0.375rem",
        background: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        option: {},
        div: {
          color: isDarkMode ? "#FFFFFF" : "#1D1D1F",
          fontWeight: "600",
          fontSize: 14,
          svg: {
            width: "20px",
            height: "20px",
            fill: isDarkMode ? "#7A7A7C" : "#D1D5DB",
            ":hover": {
              fill: isDarkMode ? "#fff" : "#1D1D1F",
            },
          },
          ":hover": {
            backgroundColor: !isDarkMode ? "#F6F6F6" : "#3A3A3C",
            color: isDarkMode ? "#fff" : "initial",
          },
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",
            color: "transparent",
            fontSize: 14,

            display: "flex",
            section: {
              backgroundColor: state.data.className === "normalTag" ? "#E5E7EB" : "#1D1D1F",

              img: {
                margin: state.data.icon ? "0.375rem 0.3125rem" : "",
              },
            },
            span: {
              padding: state.data.icon ? "0.2rem 0.5rem" : "0px !important",
            },
          },
        },
        ".css-xb97g8, .css-pv2a3q": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "600",
      cursor: "pointer",
      color: !isDarkMode
        ? state.isFocused
          ? "#374151"
          : "#374151"
        : state.isFocused
        ? "#C9C9C9"
        : "#C7C7C7",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#262628"
        : "#1d1d1f",
      fontSize: "12px",
      lineHeight: "18px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#343435" : "#343435",
      "&:hover": {
        backgroundColor: !isDarkMode ? "#F6F6F6" : "#262628",
        color: !isDarkMode ? "#374151" : "#C9C9C9",
      },
    }),
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,
        display: state.hasValue ? "flex" : "grid",
      };
    },
    container: (provided: any) => {
      return {
        ...provided,
        width: "100%",
        position: "relative",
        "&:focus-visible": { outline: "none" },
      };
    },
    control: (provided: any) => {
      return {
        ...provided,
        padding: "0.1rem",
        fontSize: "14px",

        color: !isDarkMode ? "#FFFFFF" : "#1E429F",
        borderRadius: "8px",
        backgroundColor: !isDarkMode ? "#FFFFFF" : "#1D1D1F",

        minHeight: "2.625rem",
        borderColor: isDarkMode ? "#343435" : "#E5E7EB",
        boxShadow: "none",
        "&:hover": {
          borderColor: !isDarkMode ? "#E5E7EB" : "#343435",
        },
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20",
      borderRadius: "8px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)",
      borderColor: isDarkMode ? "#343434" : "#E4E6EA",
      borderWidth: "1px",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#262628"
        : "#1d1d1f",
    }),
    menuList: (provided: any) => ({
      ...provided,
      zIndex: "50",
      borderRadius: "8px",
      padding: "4px",
    }),
    group: (provided: any) => ({
      ...provided,
      padding: "0px",
    }),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectErrorStylesV2 = (isDarkMode = false): StylesConfig<any> => {
  return {
    multiValue: (data: any, state: any) => {
      return {
        display: "flex",
        margin: "0.25rem",
        color: state.data.className === "normalTag" ? "#E5E7EB" : "#fff",
        borderRadius: "0.375rem",
        background: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        fontSize: 14,
        option: {},
        div: {
          color: isDarkMode ? "#FFFFFF" : "#111928",
          borderRadius: "0.375rem",
          fontWeight: "600",
          fontSize: 12,
          svg: {
            width: "20px",
            height: "20px",
            fill: isDarkMode ? "#7A7A7C" : "#D1D5DB",
          },
          ":hover": {
            backgroundColor: isDarkMode ? "#3A3A3C" : "transparent",
            color: isDarkMode ? "#fff" : "initial",
          },
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",
            color: "transparent",

            display: "flex",
            section: {
              backgroundColor: state.data.className === "normalTag" ? "#E5E7EB" : "#111928",
              img: {
                margin: state.data.icon ? "0.375rem 0.3125rem" : "",
              },
            },
            span: {
              padding: state.data.icon ? "0.2rem 0.5rem" : "",
            },
          },
        },
        ".css-xb97g8, .css-pv2a3q": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "600",
      color: isDarkMode
        ? state.isFocused
          ? "#fff"
          : "#646466"
        : state.isFocused
        ? "#000"
        : "#6B7280",
      backgroundColor: isDarkMode
        ? state.isFocused
          ? "#3A3A3C"
          : "#1D1D1F"
        : state.isFocused
        ? "#F3F4F6"
        : "#F9FAFB",
      fontSize: "12px",
      lineHeight: "18px",
      borderColor: state.isFocused ? "#111928" : "#D1D5DB",
      "&:hover": {
        backgroundColor: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        color: isDarkMode ? "#FFFFFF" : "#111928",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
    }),
    control: (provided: any) => {
      return {
        ...provided,
        padding: "0.1rem",
        color: isDarkMode ? "#FFFFFF" : "#1E429F",
        borderRadius: "8px",
        minHeight: "2.625rem",
        borderColor: isDarkMode ? "red" : "red",
        backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
        boxShadow: "none",
        fontSize: 14,
        "&:hover": {
          borderColor: isDarkMode ? "red" : "red",
        },
      };
    },
  };
};
