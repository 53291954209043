import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

export default function deleteRiskFileById(
  fileId: number,
  permissions: TeamParameter
): Promise<AxiosResponse<string>> {
  const teamParameter = permissions.isAllTeam ? "all" : permissions.team;

  return api.delete<string>(`risks/risks-files/${fileId}/`, {
    params: { ...{ team: teamParameter, company: permissions.company } },
  });
}
