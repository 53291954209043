import React from "react";

import { Table } from "@tanstack/react-table";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import { useDocuments } from "shared/store/documents";
import useHideCopilot from "shared/hooks/useHideCopilot";
import useHideIntercom from "shared/hooks/useHideIntercom";

import headerStyles from "assets/css/common-header.module.css";

import { ActionsType } from "../../types";
import FilterTitle from "../FilterTitle";

export default function ActionSlider<TData>({
  actions,
  table,
  handleFilterClose,
}: {
  actions: ActionsType<TData>[];
  table: Table<TData>;
  handleFilterClose: () => void;
}) {
  useHideCopilot();
  useHideIntercom();

  const { pathname } = useLocation();
  const selectedDocuments = useDocuments((state) => state.selectedDocuments);

  const onActionClicked = (item: ActionsType<TData>) => {
    if (selectedDocuments.length && pathname.includes("documents")) {
      if (item?.action) item?.action([]);
      return;
    }

    if (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) {
      if (item?.action)
        item?.action(
          table?.getSelectedRowModel()?.rows.map((val) => {
            return val?.original;
          })
        );
    }
    handleFilterClose();
  };

  return (
    <div className="filter_view">
      <div>
        <FilterTitle handleClose={handleFilterClose} filterText="Action" />
        {actions?.map((item) => {
          return (
            <div
              className={clsx(headerStyles.items, "flex items-center py-2")}
              onClick={() => onActionClicked(item)}
            >
              <span className="w-4 h-4">{item.icon}</span>
              <span className="text-aurometalsaurus dark:text-greychateau ml-2">{item.key}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
