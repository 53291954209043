import { Params } from "react-router";
import get from "lodash/get";

import {
  AGREEMENT_TYPE,
  NOTE_TYPE,
  POLICY_TYPE,
  RISK_TYPE,
  UploadableModuleType,
} from "shared/helpers/constant";

import { TeamResponse } from "modules/settings/teams/types";

import { PrepareUploadFileFormData } from "../types";
import { isUploadableModule } from "./index";

const defaultFormData = () => null;

function prepareUploadFileFormData(
  file: File,
  currentTeam: TeamResponse,
  entityName: UploadableModuleType,
  entityId: number
): FormData {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  formData.append("team", currentTeam.id.toString());
  formData.append(entityName, entityId.toString());

  return formData;
}

function getParamIdByType(type: UploadableModuleType): string {
  switch (type) {
    case AGREEMENT_TYPE: {
      return "agreementId";
    }
    case POLICY_TYPE: {
      return "policyId";
    }
    case NOTE_TYPE: {
      return "noteId";
    }
    case RISK_TYPE: {
      return "riskId";
    }
    default: {
      const _exhaustiveCheck: never = type;
      console.warn("Exhaustive check failed: ", _exhaustiveCheck);
      return "";
    }
  }
}

function getEntityIdByType(type: UploadableModuleType, params: Params): number {
  const paramId = getParamIdByType(type);
  const entityId = get(params, paramId, "");

  if (!entityId) {
    throw new Error(`Entity id not found for type: ${type}`);
  }

  return +entityId;
}

export default function prepareUploadFileFormDataFactory(
  type: string,
  currentTeam: TeamResponse,
  params: Params
): PrepareUploadFileFormData {
  return (file: File) => {
    if (!isUploadableModule(type)) {
      return defaultFormData();
    }

    const entityId = getEntityIdByType(type, params);

    return prepareUploadFileFormData(file, currentTeam, type, entityId);
  };
}
