import { useEffect } from "react";

const useHideIntercom = () => {
  const copilotCard = document.querySelector(".intercom-launcher");

  useEffect(() => {
    copilotCard?.classList.add("hidden");
    return () => copilotCard?.classList.remove("hidden");
  }, []);
};

export default useHideIntercom;
