import { useFormik } from "formik";

import { InviteRequestParams } from "../types";
import { inviteSchema } from "../validations";

const useInviteForm = (action: CallableFunction) => {
  return useFormik<InviteRequestParams>({
    initialValues: {
      organization: null,
      invitee_identifier: "",
      email1: "",
      email2: "",
      email3: "",
    },
    validateOnChange: false,
    validationSchema: inviteSchema,
    onSubmit: () => action(),
  });
};
export default useInviteForm;
