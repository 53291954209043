import React from "react";

import { Table } from "flowbite-react";

import { useTeam } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import { getDate, getUsername } from "shared/helpers/util";
import { getMembersList } from "shared/helpers/getMemersList";
import Icon from "shared/components/icon/Icon";
import Avatar from "shared/components/avatar";

import styles from "assets/css/settings.module.css";

import { TeamResponse } from "../types";
import ManageTeams from "../manage-team/ManageTeam";

const TeamsList = ({
  refetch,
  teamList,
}: {
  refetch: () => void;
  teamList: Array<TeamResponse>;
}) => {
  const { currentCompany } = useCompany();

  const setSelectedTeam = useTeam((store) => store.setSelectedTeam);

  const [setIsManageModalOpen, filteredTeam, isFiltered, isManageModalOpen] = useTeam((state) => [
    state.setIsManageModalOpen,
    state.filteredTeam,
    state.isFiltered,
    state.isManageModalOpen,
  ]);

  const getTeamList = () => {
    return isFiltered ? filteredTeam : teamList;
  };

  const onClick = (id: number) => {
    const _currentTeam = teamList?.find((item) => item.id === id);
    if (_currentTeam) {
      setSelectedTeam(_currentTeam);
      setIsManageModalOpen(true);
    }
  };

  return (
    <>
      <div className={styles.team_list}>
        <Table className="maxMd:min-w-[720px]">
          <Table.Head className={styles?.table_header}>
            <Table.HeadCell className="text-sm text-mirage py-4 maxMd:!px-4">Team</Table.HeadCell>
            <Table.HeadCell className="text-sm text-mirage py-4 maxMd:!px-4">Owner</Table.HeadCell>
            <Table.HeadCell className="text-sm text-mirage py-4 maxMd:!px-4">
              Members
            </Table.HeadCell>
            <Table.HeadCell className="text-sm text-mirage py-4 maxMd:!px-4">
              Created At
            </Table.HeadCell>
            <Table.HeadCell className="text-sm text-mirage py-4 maxMd:!px-4">
              Updated At
            </Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only"></span>
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y ">
            {getTeamList().length > 0 ? (
              getTeamList()
                .filter((team) => !team.is_all_team)
                .map((team: TeamResponse, teamIndex: number) => (
                  <Table.Row className="bg-white" key={teamIndex}>
                    {/* Name and Icon */}
                    <Table.Cell className={`font-medium items-baseline maxMd:px-4`}>
                      <div className="text-aurometalsaurus dark:text-greychateau flex items-center">
                        <div className="w-6 h-6 mr-3">
                          <Avatar
                            name={team?.name}
                            avatarFor="team"
                            avatarType="squared"
                            size="sm"
                            url={team?.team_picture}
                            className="cursor-default"
                          />
                        </div>
                        <span>{team?.name}</span>
                      </div>
                    </Table.Cell>
                    {/* Owner */}
                    <Table.Cell>
                      <div className="flex items-center text-aurometalsaurus dark:text-greychateau">
                        <div className="w-6 h-6 mr-3">
                          <Avatar
                            name={getUsername(team?.owner)}
                            avatarFor="user"
                            avatarType="squared"
                            size="sm"
                            url={team?.owner?.profile_picture}
                            className="cursor-default"
                          />
                        </div>
                        {team?.owner?.email}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {getMembersList(currentCompany, team)?.length !== 0
                        ? `${getMembersList(currentCompany, team)?.length} member`
                        : `No members`}
                    </Table.Cell>
                    <Table.Cell>{getDate(new Date())}</Table.Cell>
                    <Table.Cell>{getDate(new Date())}</Table.Cell>
                    <Table.Cell>
                      <button
                        className="w-6 h-6 block ml-auto dots_hover"
                        onClick={() => onClick(team?.id)}
                      >
                        <Icon type="dots-horizontal" fill={false} size="icon-md" />
                      </button>
                    </Table.Cell>
                  </Table.Row>
                ))
            ) : (
              <Table.Row className="bg-white opacity-50">
                <Table.Cell
                  className={`font-medium items-baseline maxMd:px-4  py-6 text-sm text-center`}
                  colSpan={5}
                >
                  No Team Available
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      {isManageModalOpen ? <ManageTeams refetch={refetch} /> : null}
    </>
  );
};

export default TeamsList;
