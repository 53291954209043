import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import teamToQueryParams from "shared/helpers/teamToQueryParams";

export default function deleteMitigationFile(
  mitigationId: number,
  id: number,
  permissions: TeamParameter
): Promise<AxiosResponse<void>> {
  return api.delete<void>(`risks/mitigations-files/${id}/`, {
    params: {
      mitigation: mitigationId,
      ...teamToQueryParams(permissions),
    },
  });
}
