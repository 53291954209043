import React from "react";

export type GridCardFooterSideProps = {
  children: React.ReactNode;
};

const GridCardFooterSide: React.FC<GridCardFooterSideProps> = ({
  children,
}: GridCardFooterSideProps) => {
  return <>{children}</>;
};

export default GridCardFooterSide;
