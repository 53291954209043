import { FC, SVGProps, useEffect, useState } from "react";

function useDynamicSVGImport(name: string) {
  const [icon, setIcon] = useState<FC<SVGProps<SVGSVGElement>> | null>(null);

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const importIcon = async (): Promise<void> => {
      try {
        const newIcon = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../../assets/images/icons/${name}.svg`
          )
        ).default;
        setIcon(newIcon);
        setLoaded(true);
      } catch (err) {
        console.error(err);
      }
    };
    importIcon();
  }, [name]);

  return { SvgIcon: icon, loaded };
}

export default useDynamicSVGImport;
