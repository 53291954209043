interface IMetadataFieldProps {
  label: string;
  meta: JSX.Element | string;
}

const MetadataField = ({ label, meta }: IMetadataFieldProps) => {
  return (
    <div className="flex items-center gap-3">
      <label className="text-sm text-aurometalsaurus font-normal min-w-[180px]">{label}</label>
      {meta}
    </div>
  );
};

export default MetadataField;
