import React, { ButtonHTMLAttributes, forwardRef } from "react";

import clsx from "clsx";

import styles from "assets/css/formControls.module.css";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  btnTitle?: string;
  icon?: JSX.Element | null;
  btnIconAlign?: "left" | "right";
  btnType?: "primary" | "secondary" | "tertiary" | "plain" | "light" | "danger";
  btnSize?: "xs" | "sm" | "md" | "lg";
  iconOnly?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    btnType = "primary",
    btnIconAlign = "left",
    icon,
    btnTitle,
    btnSize = "md",
    iconOnly = false,
    type = "button",
    ...rest
  } = props;

  return (
    <button
      {...rest}
      ref={ref}
      type={type}
      className={clsx(
        { "flex gap-2 items-center": icon },
        styles.default_button,
        styles[`button_${btnType}`],
        {
          [styles[`button_${btnSize}`]]: btnTitle,
          [styles[`button_icon_${btnSize}`]]: !btnTitle,
        },

        props.className
      )}
    >
      {btnIconAlign === "left" && icon ? icon : null}

      {!iconOnly ? btnTitle || null : null}

      {btnIconAlign === "right" && icon ? icon : null}

      {props.children}
    </button>
  );
});

export default Button;
