import React, { useRef, useState } from "react";

import { Modal } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";

import { useTeam } from "shared/store/settings";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useHome } from "shared/store/home";
import { scrollListToDown } from "shared/helpers/search";

import { globalSearch } from "../api";
import { SearchResultResponseType } from "../types";
import SpotlightSearchBottomControls from "./SpotlightSearchBottomControls";
import useHandleKey from "../hooks/useHandleKey";
import SearchResultBody from "./SearchResultBody";
import SearchInput from "./SearchInput";

const SpotlightSearch = () => {
  // global state parameters necessary for search
  const { currentTeam } = useTeam();

  const setIsSpotlightModalopen = useHome((state) => state.setIsSpotlightModalopen);

  // spotlight search modal open and close
  const searchResultRef = useRef<HTMLDivElement[]>([]);

  const searchModalRef = useRef<HTMLDivElement | null>(null);

  // search state parameters
  const [searchData, setSearchData] = useState<SearchResultResponseType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [searchKey, setSearchKey] = useState<string>("");

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading: globalLoader, mutate: globalSearchFn } = useMutation(
    ["globalSearchFn"],
    (value: string) =>
      globalSearch({
        term: value,
        team: {
          team: currentTeam.id,
          isAllTeam: currentTeam.is_all_team,
          company: currentTeam.company,
        },
      }),
    {
      onSuccess: (res) => {
        setSearchData(res?.data?.hits);
      },
    }
  );

  const selectUp = () => {
    const node = searchResultRef.current;

    if (selectedIndex === null || selectedIndex === 0) {
      setSelectedIndex(searchData?.length - 1);
      if (node && searchData?.length > 0) {
        node[searchData?.length - 1].scrollIntoView();
      }
      return;
    }
    if (node && searchData?.length > 0) {
      node[selectedIndex - 1].scrollIntoView();
    }
    setSelectedIndex(selectedIndex - 1);
  };

  const selectDown = () => {
    const node = searchResultRef.current;

    if (selectedIndex === null || selectedIndex === searchData?.length - 1) {
      if (node && searchData?.length > 0) {
        node[0].scrollIntoView();
      }
      setSelectedIndex(0);
      return;
    }
    setSelectedIndex(selectedIndex + 1);
    if (node && searchData?.length > 0) {
      if (searchModalRef?.current) {
        scrollListToDown(searchModalRef?.current, node, selectedIndex);
      }
    }
  };

  const mapRefToElement = (element: HTMLDivElement | null, index: number) => {
    if (element) {
      searchResultRef.current[index] = element;
    }
  };

  useHandleKey(selectUp, selectDown);

  return (
    <Modal
      root={documentBodyRef}
      className="spotlight-modal"
      size={"3xl"}
      show={true}
      dismissible={true}
      onClose={() => setIsSpotlightModalopen(false)}
      data-testid="spotlight-search"
    >
      <div ref={searchModalRef}>
        <Modal.Header className="h-14 p-4 md:!p-0 flex items-center search_text dark:!border-thunders relative dark:bg-darkjunglegreenss cursor-text">
          <span className="text-lg font-inter-medium md:hidden">Search</span>
          <div className="maxMd:hidden">
            <SearchInput
              searchKey={searchKey}
              setSearchData={setSearchData}
              globalSearchFn={globalSearchFn}
              setSearchKey={setSearchKey}
              setSelectedIndex={setSelectedIndex}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="spotlight-content px-0 md:px-1.5 pb-0 md:pb-1 pt-0 dark:bg-darkjunglegreenss maxMd:overflow-hidden">
          <div className="md:hidden">
            <SearchInput
              searchKey={searchKey}
              setSearchData={setSearchData}
              globalSearchFn={globalSearchFn}
              setSearchKey={setSearchKey}
              setSelectedIndex={setSelectedIndex}
            />
          </div>
          <SearchResultBody
            globalLoader={globalLoader}
            searchData={searchData}
            searchKey={searchKey}
            selectedIndex={selectedIndex}
            mapRefToElement={mapRefToElement}
          />
        </Modal.Body>
        <Modal.Footer className="spotlight-footer h-9 dark:!border-thunders dark:bg-darkjunglegreenss">
          <SpotlightSearchBottomControls resultLength={searchData?.length} />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SpotlightSearch;
