import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { createPortal } from "react-dom";
import { ModalBodyProps, ModalFooterProps, ModalHeaderProps, ModalProps } from "flowbite-react";

import Modal from "shared/componentsV2/common-modal";
import { CommonFormType } from "shared/typesV2";

import { VendorListResponse } from "modules/vendors/listing/types";
import { VendorResponse } from "modules/vendors/types";

import styles from "assets/css/task.module.css";

type TModalComponentProps<DataType> = {
  handleSubmit?: (data: DataType) => void;
  handleClose?: () => void;
  handleIsLoading?: (loading: boolean) => void;
  handleIsVednorListFormDirty?: (isFormDirty: boolean) => void;
  handleDeleteList?: () => void;
  isLoading?: boolean;
  isVednorListFormDirty?: boolean;
  show?: boolean;
  isEditMode?: boolean;
  selectedListOption?: VendorListResponse;
  selectedVendors?: VendorResponse[];
  bodyRef?: React.MutableRefObject<{
    handleClear: () => void;
    form: CommonFormType<DataType>;
  } | null>;

  canEdit?: boolean;
};

export type TModalComponent<DataType> = React.ComponentType<TModalComponentProps<DataType>>;

type TCustomizedCreateModalProps<DataType = unknown> = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data: DataType) => void;
  ModalComponentsProps?: {
    Root?: ModalProps & {
      onShow: () => void;
      onDelete?: () => void;
      isEditMode?: boolean;
      selectedListOption?: VendorListResponse;
      selectedVendors?: VendorResponse[];

      canEdit?: boolean;
    };
    Body?: ModalBodyProps;
    Header?: ModalHeaderProps;
    Footer?: ModalFooterProps;
  };
  ModalComponents: {
    Body?: React.ForwardRefExoticComponent<
      React.RefAttributes<unknown> & TModalComponentProps<DataType>
    >;
    Header?: TModalComponent<DataType>;
    Footer?: TModalComponent<DataType>;
  };
};

export type TModalComponentsProps<DataType = unknown> =
  TCustomizedCreateModalProps<DataType>["ModalComponentsProps"];

export type TModalComponents<DataType = unknown> =
  TCustomizedCreateModalProps<DataType>["ModalComponents"];

export default function CustomizedCreateModal<DataType = unknown>({
  show,
  handleClose,
  handleSubmit,
  ModalComponentsProps,
  ModalComponents,
}: TCustomizedCreateModalProps<DataType>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVednorListFormDirty, setIsVednorListFormDirty] = useState<boolean>(false);

  const {
    isEditMode,
    selectedListOption,
    selectedVendors,
    onDelete,
    onClose: onCloseRoot,
    onShow,
    canEdit = true,
    ...RootProps
  } = ModalComponentsProps?.Root ?? {};

  // we must have the same handleClear function inside the CreateModalBody component then we can call from inside dropdown.
  const childRef = useRef<{
    handleClear: () => void;
    form: CommonFormType<DataType>;
  } | null>(null);

  const onClose = () => {
    handleClose();
    onCloseRoot?.();
  };

  const handleIsLoading = (loading: boolean): void => {
    setIsLoading(loading);
  };

  const handleIsVednorListFormDirty = (isFormDirty: boolean): void => {
    setIsVednorListFormDirty(isFormDirty);
  };

  useEffect(() => {
    if (show) {
      onShow?.();
    }
  }, [show]);

  const portalRoot = document.getElementById("modal-root");

  return (
    <>
      {createPortal(
        <Modal
          root={portalRoot as HTMLElement}
          size="2xl"
          popup={true}
          onClose={onClose}
          className={clsx(styles.taskModalStyle, "vendor_list_modal")}
          {...RootProps}
          show={show}
        >
          {ModalComponents.Header ? (
            <Modal.Header
              className={clsx(
                styles.taskModalHeader,
                "dark:!border-thunders items-center w-full vendor_list_title"
              )}
              {...ModalComponentsProps?.Header}
            >
              <ModalComponents.Header
                handleSubmit={handleSubmit}
                handleClose={onClose}
                handleIsLoading={handleIsLoading}
                handleIsVednorListFormDirty={handleIsVednorListFormDirty}
                isVednorListFormDirty={isVednorListFormDirty}
                handleDeleteList={onDelete}
                isLoading={isLoading}
                isEditMode={isEditMode}
                selectedListOption={selectedListOption}
                show={show}
                bodyRef={childRef}
                canEdit={canEdit}
              />
            </Modal.Header>
          ) : null}

          {ModalComponents.Body ? (
            <Modal.Body className="!py-6 !px-0 !rounded-b" {...ModalComponentsProps?.Body}>
              <ModalComponents.Body
                handleSubmit={handleSubmit}
                handleClose={onClose}
                handleIsLoading={handleIsLoading}
                handleIsVednorListFormDirty={handleIsVednorListFormDirty}
                isVednorListFormDirty={isVednorListFormDirty}
                isLoading={isLoading}
                show={show}
                isEditMode={isEditMode}
                selectedListOption={selectedListOption}
                selectedVendors={selectedVendors}
                ref={childRef}
              />
            </Modal.Body>
          ) : null}

          {ModalComponents.Footer ? (
            <Modal.Footer className="!py-6 !px-0" {...ModalComponentsProps?.Footer}>
              <ModalComponents.Footer
                handleSubmit={handleSubmit}
                handleClose={onClose}
                handleIsLoading={handleIsLoading}
                isLoading={isLoading}
                show={show}
                bodyRef={childRef}
              />
            </Modal.Footer>
          ) : null}
        </Modal>,
        portalRoot as Element
      )}
    </>
  );
}
