import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import isNumber from "lodash/isNumber";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import CommonCreateTable from "shared/components/common-create-table/CommonCreateTable";
import { useCompany } from "shared/context/CompanyProvider";
import { DEFAULT_LEVELS } from "shared/helpers/constant";

import styles from "assets/css/settings.module.css";

import { SelectOptionProps } from "modules/settings/teams/types";
import { PresetInfo } from "modules/risks/register/types";

import PresetModal from "../preset-modal/PresetModal";
import { createPreset } from "../api";
import { CreatePresetRequest } from "../types";

function PresetDropdown({
  data,
  value,
  title,
  companyPresetId,
  handleChange,
  handleDeleteModal,
  defaultPreset,
}: {
  data: PresetInfo[];
  value: number | null;
  title: string;
  companyPresetId: number | null;
  handleChange: (data: PresetInfo | null) => void;
  handleDeleteModal: (state: boolean, presetId?: number) => void;
  defaultPreset?: PresetInfo;
}) {
  const { currentCompany } = useCompany();

  const currentCompanyPreset = useMemo(() => defaultPreset, [defaultPreset]);

  const [selectedPreset, setSelectedPreset] = useState<SelectOptionProps | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangePreset = (preset: number | string) => {
    let template = null;

    const hasPreset = data.find((item) => item.id == preset);

    if (hasPreset) {
      template = {
        label: (
          <div className={clsx("text-sm !pb-0", styles.presetTitle)}>
            {hasPreset?.name}
            {hasPreset.id === companyPresetId ? (
              <span className={styles.presetStatus}>Active Preset</span>
            ) : null}
          </div>
        ),
        value: Number(hasPreset?.id) || 0,
      };
    }

    setSelectedPreset(template);
  };

  useEffect(() => {
    if (value) {
      handleChangePreset(value);
    }
  }, [data, value]);

  const {
    isLoading: isLoading,
    mutate: handleSubmitPreset,
    isError,
    error,
  } = useMutation(
    ({ name, company }: CreatePresetRequest) => {
      return createPreset({ name, company }, DEFAULT_LEVELS, currentCompanyPreset);
    },
    {
      onSuccess: (res) => {
        toast("Risk Created Successfully!", { type: "success" });

        handleChange(res.data || null);

        setIsModalOpen(false);
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const optionsPreset = useMemo(() => {
    const customPresets = data?.filter((_preset) => isNumber(_preset?.company));
    const defaultPresets = data?.filter((_preset) => !isNumber(_preset?.company));
    if (defaultPresets && customPresets) {
      const resultantPresets = [...defaultPresets, ...customPresets];

      return resultantPresets.map((_preset) => {
        return {
          label: (
            <div className={clsx("text-sm !pb-0", styles.presetTitle)}>
              {_preset?.name}
              {_preset?.id === companyPresetId ? (
                <span className={styles.presetStatus}>Active Preset</span>
              ) : null}
            </div>
          ),
          value: _preset?.id,
          company: _preset?.company,
          name: _preset?.name,
        };
      });
    }
  }, [data, companyPresetId]);

  let isFirstNonNullCompany = false;

  const optionsWithCustomPreset =
    optionsPreset &&
    optionsPreset.map((option) => {
      if (isNumber(option.company) && !isFirstNonNullCompany) {
        isFirstNonNullCompany = true;
        return { ...option, isFirstNonNullCompany: true };
      } else {
        return { ...option, isFirstNonNullCompany: false };
      }
    });

  useEffect(() => {
    if (value) {
      handleChangePreset(value);
    }
  }, [companyPresetId, value]);

  return (
    <div className="maxMd:flex-col risk_multiselect">
      <CommonCreateTable
        fieldName="preset"
        title={title}
        value={selectedPreset || ""}
        hasCustomValue={true}
        placeholder="Select Preset"
        labelClassName={clsx(styles.fieldTitle)}
        handleSelectChange={(fieldname, preset_id) => {
          handleChange(data.find((preset) => preset.id === preset_id) || null);
        }}
        dropDownClassName={clsx(styles.agreementDropDown)}
        optionsRisks={optionsWithCustomPreset || []}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        CustomMenuTitle="Add new preset"
        NoOptionsTitle="No Data Available"
        handleDeleteValue={(value: number) => handleDeleteModal(true, value)}
        setIsDeleteModalOpen={() => handleDeleteModal(true)}
      />

      <PresetModal
        currentCompany={Number(currentCompany?.id)}
        handleSubmitPreset={({ company, name }) => {
          handleSubmitPreset({
            company,
            name,
          });
        }}
        handleClose={(status) => setIsModalOpen(status)}
        isModalOpen={isModalOpen}
        isLoading={isLoading}
        error={error}
        isError={isError}
      />
    </div>
  );
}

export default PresetDropdown;
