import React from "react";

import { useHome } from "shared/store/home";
import Icon from "shared/components/icon/Icon";
import SpotlightSearch from "shared/components/spotlight-search/components";

import HeaderItem from "./HeaderItem";
import { createPortal } from "react-dom";

const SearchItem = () => {
  const [isSpotlightModalopen, setIsSpotlightModalopen] = useHome((state) => [
    state.isSpotlightModalopen,
    state.setIsSpotlightModalopen,
  ]);

  return (
    <>
      <HeaderItem
        testId="search_comp"
        onClick={() => setIsSpotlightModalopen(true)}
        tooltip="Search"
        className="header_action_search"
      >
        <Icon type="search" fill={false} size="icon-sm" />
      </HeaderItem>
      {isSpotlightModalopen ? createPortal(<SpotlightSearch />, document.body) : null}
    </>
  );
};

export default SearchItem;
