import { useQuery } from "@tanstack/react-query";
import getJurisdictions from "../api/getJurisdictions";

const useJurisdictionsQuery = () => {
  const { data, isLoading, refetch } = useQuery(["get-jurisdictions"], () => getJurisdictions({}));

  return {
    data: data?.data,
    isLoading,
    refetch,
  };
};

export default useJurisdictionsQuery;
