import React, { memo, useEffect, useState } from "react";

import get from "lodash/get";
import { InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useDarkMode } from "usehooks-ts";
import { AxiosResponse } from "axios";
import clsx from "clsx";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";

import { AgreementFormValues } from "shared/types";
import { selectControlStyle, selectErrorStyles } from "shared/helpers/selectStyle";
import { useDropdownPaginationSearch } from "shared/store/dropdownPaginationSearch";
import getDropdownPaginatedSearchResult from "shared/helpers/getDropdownPaginationSearchResult";
import { usePaginationSearch } from "shared/store/commonTable";
import { DEBOUNCE_DELAY } from "shared/helpersV2/constant";

import styles from "assets/css/agreement.module.css";
import headerStyles from "assets/css/common-header.module.css";

import { optionType } from "../metadata/types";
import { getPaginatedSearchDataResult } from "../common-table/helpers";

function SearchableDropdownPagination<T extends object>({
  handleChangeSelectedOption,
  selectedValue,
  hasError,
  placeholder,
  className,
  isLoading,
  data,
  handleChange,
  mainClassname,
  hasNextPage,
  fetchNextPage,
  formName,
  fieldName,
  moduleName,
}: {
  handleChangeSelectedOption?: (data: optionType | null) => void;
  selectedValue?: Pick<
    {
      id: number | string;
      name: string;
    },
    "id"
  > | null;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
  mainClassname?: string;
  data: AgreementFormValues<Pick<{ id: number | string; name: string }, "id">>;
  value: string; // will remove later.
  handleChange: (data: string | null, options?: T) => void;
  nextPage?: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => Promise<
    InfiniteQueryObserverResult<AxiosResponse<unknown, unknown>, unknown>
  >;
  formName?: string;
  fieldName?: string;
  moduleName?: string;
}) {
  const [selectedOption, setSelectedOption] = useState<optionType | null>(null);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { isDarkMode } = useDarkMode();
  const [paginationSearchData, setPaginationSearchData] = useDropdownPaginationSearch((state) => [
    state.paginationSearchData,
    state.setPaginationSearchData,
  ]);

  const [paginatedFilterSearchData, setPaginationFilterSearchData] = usePaginationSearch(
    (state) => [state.paginationSearchData, state.setPaginationSearchData]
  );

  const [options, setOptions] = useState<Array<optionType>>([]);

  useEffect(() => {
    setOptions(
      data?.map((item: Pick<{ id: number | string; name: string }, "id">) => {
        return {
          id: Number(item.id || "0"),
          label: get(item, "name", ""),
          value: `${item.id}`,
          data: item,
        };
      })
    );
    setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (!isEmpty(selectedValue)) {
      setSelectedOption({
        id: Number(get(selectedValue, "id")),
        label: get(selectedValue, "name", "") || get(selectedValue, "email", ""),
        value: `${get(selectedValue, "id")}`,
        data: selectedValue,
      });
    } else {
      setSelectedOption(null);
    }
  }, [selectedValue]);

  useEffect(() => {
    handleInputChange("");
    return () => {
      refetchData("");
    };
  }, [fieldName]);

  const refetchData = (searchData: string) => {
    if (formName && fieldName) {
      setPaginationSearchData(
        getDropdownPaginatedSearchResult(paginationSearchData, formName, fieldName, searchData)
      );
    }
    if (moduleName && fieldName) {
      setPaginationFilterSearchData(
        getPaginatedSearchDataResult(paginatedFilterSearchData, moduleName, fieldName, searchData)
      );
    }
  };

  const handleInputChange = (searchData: string) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimer = setTimeout(() => {
      refetchData(searchData);
    }, DEBOUNCE_DELAY);

    setDebounceTimeout(newTimer);
  };

  const loadMoreOptions = async () => {
    if (hasNextPage && !isFetching && fetchNextPage) {
      setIsFetching(true);
      await fetchNextPage();
    }
  };

  return (
    <div className={className} data-testid="select_dropdown">
      <Select
        options={options}
        filterOption={() => true}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={loadMoreOptions}
        onChange={(valueOption) => {
          if (valueOption && !Array.isArray(valueOption)) {
            setSelectedOption(valueOption);
            handleChange(valueOption.value || null, valueOption);

            if (handleChangeSelectedOption) {
              handleChangeSelectedOption(valueOption);
            }
            return;
          }
          if (valueOption === null) {
            setSelectedOption(null);
            handleChange(null, valueOption);

            if (handleChangeSelectedOption) {
              handleChangeSelectedOption(null);
            }
          }
        }}
        isLoading={isLoading}
        menuPlacement="bottom"
        isSearchable
        isClearable
        placeholder={placeholder}
        classNamePrefix="multitag multitag_spacing searchable_dropdown"
        className={clsx(headerStyles?.searchField, styles.multiDropdownSelect, mainClassname)}
        value={[selectedOption]}
        styles={hasError ? selectErrorStyles(isDarkMode) : selectControlStyle(isDarkMode)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#E5E7EB",
          },
        })}
      />
    </div>
  );
}

export default memo(SearchableDropdownPagination);
