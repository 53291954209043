/* eslint-disable @typescript-eslint/no-explicit-any */
import { StylesConfig } from "react-select";

/* eslint-disable @typescript-eslint/no-explicit-any */

export const selectControlStyleForCreate = (isDarkMode = false): StylesConfig<any> => {
  return {
    multiValue: (data: any, state: any) => {
      return {
        display: "flex",
        margin: "0.25rem",
        color: state.data.className === "normalTag" ? "#E5E7EB" : "#fff",
        borderRadius: "0.375rem",
        background: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        option: {},
        div: {
          color: isDarkMode ? "#FFFFFF" : "#1D1D1F",
          fontWeight: "500",
          fontSize: 14,
          svg: {
            width: "20px",
            height: "20px",
            fill: isDarkMode ? "#7A7A7C" : "#D1D5DB",
            ":hover": {
              fill: isDarkMode ? "#fff" : "#1D1D1F",
            },
          },
          ":hover": {
            backgroundColor: isDarkMode ? "#3A3A3C" : "transparent",
            color: isDarkMode ? "#fff" : "initial",
          },
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",
            color: "transparent",

            display: "flex",
            section: {
              backgroundColor: state.data.className === "normalTag" ? "#E5E7EB" : "#1D1D1F",
              img: {
                margin: state.data.icon ? "0.375rem 0.3125rem" : "",
              },
            },
            span: {
              padding: state.data.icon ? "0.2rem 0.5rem" : "0px !important",
            },
          },
        },
        ".css-xb97g8, .css-pv2a3q": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "500",
      cursor: "pointer",
      color: isDarkMode
        ? state.isFocused
          ? "#FFFFFF"
          : "#A6A6A7"
        : state.isFocused
        ? "#000000"
        : "#6B7280",
      backgroundColor: isDarkMode
        ? state.isFocused
          ? "#3A3A3C"
          : "#2D2D30"
        : state.isFocused
        ? "#F3F4F6"
        : "#F9FAFB",
      fontSize: "12px",
      lineHeight: "18px",
      borderColor: state.isFocused ? "#1D1D1F" : "#D1D5DB",
      "&:hover": {
        backgroundColor: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        color: isDarkMode ? "#FFFFFF" : "#1D1D1F",
      },
    }),
    control: (provided: any, state: any) => {
      return {
        ...provided,
        padding: "0.1rem",
        color: isDarkMode ? "#FFFFFF" : "#1E429F",
        borderRadius: "8px",
        backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
        minHeight: "2.625rem",
        borderColor: state.isFocused ? "#111928" : "#D1D5DB" && isDarkMode && "#2D2D30",
        boxShadow: "none",
        "&:hover": {
          borderColor: isDarkMode ? "#2D2D30" : "rgb(209 213 219)",
        },
      };
    },
  };
};

export const selectControlStyle = (isDarkMode = false): StylesConfig<any> => {
  return {
    multiValue: () => {
      return {
        display: "flex",
        margin: "0.25rem",
        borderRadius: "0.5rem",
        padding: "0px",
        div: {
          color: "#1e429f",
          backgroundColor: "#E1EFFE",
          margin: 0,
          padding: "0px",

          borderRadius: "0.375rem 0 0 0.375rem",
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",
            display: "flex",
            section: {
              backgroundColor: "#E1EFFE",
            },
          },
        },
        ".css-xb97g8": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "500",
      color: isDarkMode
        ? state.isFocused
          ? "#fff"
          : "#646466"
        : state.isFocused
        ? "#000"
        : "#6B7280",
      backgroundColor: isDarkMode
        ? state.isFocused
          ? "#3A3A3C"
          : "#2D2D30"
        : state.isFocused
        ? "#F3F4F6"
        : "#F9FAFB",
      fontSize: "12px",
      lineHeight: "18px",
      borderColor: state.isFocused ? "#1D1D1F" : "#D1D5DB",
      "&:hover": {
        backgroundColor: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        color: isDarkMode ? "#FFFFFF" : "#1D1D1F",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
      zIndex: 10000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (provided, state) => {
      return {
        ...provided,
        padding: "0.1rem",
        color: "#1E429F",
        borderRadius: "8px",
        maxHeight: "null",
        backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
        boxShadow: state.isFocused ? "none" : "none",
        borderColor: state.isFocused ? "#3A3A3C" : "#E5E7EB",
        "&:hover": {
          borderColor: isDarkMode ? "#3A3A3C" : "#1D1D1F",
        },
      };
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectErrorStyles = (isDarkMode = false): StylesConfig<any> => {
  return {
    multiValue: (data: any, state: any) => {
      return {
        display: "flex",
        margin: "0.25rem",
        color: state.data.className === "normalTag" ? "#E5E7EB" : "#fff",
        borderRadius: "0.375rem",
        background: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        option: {},
        div: {
          color: isDarkMode ? "#FFFFFF" : "#111928",
          borderRadius: "0.375rem",
          fontWeight: "500",
          fontSize: 12,
          svg: {
            width: "20px",
            height: "20px",
            fill: isDarkMode ? "#7A7A7C" : "#D1D5DB",
          },
          ":hover": {
            backgroundColor: isDarkMode ? "#3A3A3C" : "transparent",
            color: isDarkMode ? "#fff" : "initial",
          },
          section: {
            borderRadius: "0.375rem 0 0 0.375rem",
            color: "transparent",

            display: "flex",
            section: {
              backgroundColor: state.data.className === "normalTag" ? "#E5E7EB" : "#111928",
              img: {
                margin: state.data.icon ? "0.375rem 0.3125rem" : "",
              },
            },
            span: {
              padding: state.data.icon ? "0.2rem 0.5rem" : "",
            },
          },
        },
        ".css-xb97g8, .css-pv2a3q": {
          borderRadius: "0 0.375rem 0.375rem 0",
          padding: "0.2rem",
        },
        ".css-xb97g8:hover": {
          backgroundColor: "#1E429F",
          color: "white",
        },
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "500",
      color: isDarkMode
        ? state.isFocused
          ? "#fff"
          : "#646466"
        : state.isFocused
        ? "#000"
        : "#6B7280",
      backgroundColor: isDarkMode
        ? state.isFocused
          ? "#3A3A3C"
          : "#2D2D30"
        : state.isFocused
        ? "#F3F4F6"
        : "#F9FAFB",
      fontSize: "12px",
      lineHeight: "18px",
      borderColor: state.isFocused ? "#111928" : "#D1D5DB",
      "&:hover": {
        backgroundColor: isDarkMode ? "#3A3A3C" : "#F3F4F6",
        color: isDarkMode ? "#FFFFFF" : "#111928",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
    }),
    control: (provided: any) => {
      return {
        ...provided,
        padding: "0.1rem",
        color: isDarkMode ? "#FFFFFF" : "#1E429F",
        borderRadius: "8px",
        minHeight: "2.625rem",
        borderColor: isDarkMode ? "red" : "red",
        backgroundColor: isDarkMode ? "#28282A" : "#F9FAFB",
        boxShadow: "none",
        "&:hover": {
          borderColor: isDarkMode ? "red" : "red",
        },
      };
    },
  };
};
