import { useMemo, useCallback } from "react";
import Select, { SingleValue } from "react-select";
import { useDarkMode } from "usehooks-ts";

import { PERMISSION_LABEL } from "../../constants";
import { selectControlStyle, selectErrorStyles } from "shared/helpers/selectStyle";

export type LevelSelectProps = {
  name: string;
  value?: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
  hasError?: boolean;
  errorText?: string;
};

export default function LevelSelect({
  name,
  value,
  defaultValue,
  onChange: handleChange,
  hasError,
  errorText,
}: LevelSelectProps) {
  const { isDarkMode } = useDarkMode(false);

  const options = useMemo(() => {
    return Object.entries(PERMISSION_LABEL).map(([value, label]) => ({
      value: +value,
      label: label.toString(),
    }));
  }, []);

  const defaultOption = useMemo(() => {
    return options.find((option) => option.value === defaultValue);
  }, [defaultValue]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [value]);

  const change = useCallback(
    (newValue: SingleValue<{ value: number; label: string }>) => {
      if (newValue) {
        handleChange?.(newValue.value);
      }
    },
    [handleChange]
  );

  return (
    <div className="flex flex-col teamuserSelectOption" data-testid="level-select-container">
      <Select<{ value: number; label: string }, false>
        name={name}
        value={selectedOption}
        defaultValue={defaultOption}
        options={options}
        onChange={change}
        styles={{
          ...selectControlStyle(isDarkMode),
          ...(hasError ? selectErrorStyles(isDarkMode) : {}),
        }}
        className="w-full"
        placeholder="Permission"
      />
      {hasError ? (
        <div className="text-red-500 text-xs" data-testid="level-select-error">
          {errorText}
        </div>
      ) : null}
    </div>
  );
}
