import omit from "lodash/omit";

import { SearchDataProps } from "shared/store/table-column-selection";

import { SearchOptions } from "../types";

type SearchResponseProps = {
  searchData: SearchDataProps;
  moduleName: string;
  data: SearchOptions;
};

export default function getSearchResponseData({
  searchData,
  moduleName,
  data,
}: SearchResponseProps) {
  if (data?.value === "") {
    return omit(searchData, moduleName);
  }
  return { ...searchData, [moduleName]: data };
}
