import { ReactElement, cloneElement } from "react";

import { useMutation } from "@tanstack/react-query";
import { getToken } from "firebase/messaging";

import { registerDevice } from "modules/notifications/api";

import { messaging } from "../../helpers/firebase";
import { isNotificationSupported } from "../../helpers/util";

interface Props {
  requestPermission: () => Promise<NotificationPermission | undefined>;
}

const BrowserNotification = (props: { children: (p: Props) => ReactElement }) => {
  const { mutateAsync: registerDeviceFn } = useMutation((token: string) => registerDevice(token));
  const requestPermission = async () => {
    try {
      if (isNotificationSupported()) {
        if (Notification.permission === "granted") {
          return Notification.permission;
        }
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          if (messaging) {
            const token = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            });
            await registerDeviceFn(token);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return Notification.permission;
  };

  return cloneElement(props.children({ requestPermission }));
};

export default BrowserNotification;
