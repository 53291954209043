import React, { useRef, useState } from "react";

import { useOnClickOutside } from "usehooks-ts";
import clsx from "clsx";

import Icon from "shared/components/icon/Icon";

import { PresetInfo } from "modules/risks/register/types";

import styles from "assets/css/settings.module.css";

import PresetModal from "../preset-modal/PresetModal";

export default function PresetActions({
  currentPresetID,
  companyId,
  handleDeleteModal,
  handleRename,
  preset,
}: {
  currentPresetID: number;
  companyId: number;
  handleDeleteModal: (state: boolean, presetId?: number) => void;
  handleRename: (name: string) => void;
  preset: PresetInfo;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div className="relative" ref={ref}>
      <span
        className={clsx("cursor-pointer", styles.presetActionIcon)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon type="threedots" fill={true} size="icon-sm" />
      </span>
      {isOpen ? (
        <div>
          <ul className={styles.presetActionDropdown}>
            <li
              onClick={() => {
                setIsOpen(false);
                setIsModalOpen(true);
              }}
            >
              <Icon type="pencil" size="icon-xs" /> Rename Preset
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteModal(true, currentPresetID);
                setIsOpen(false);
              }}
            >
              <Icon type="trash-outline" size="icon-xs" /> Delete Preset
            </li>
          </ul>
        </div>
      ) : null}
      <PresetModal
        currentCompany={companyId}
        handleSubmitPreset={(preset) => {
          handleRename(preset.name);
          setIsModalOpen(false);
        }}
        handleClose={(status) => setIsModalOpen(status)}
        isModalOpen={isModalOpen}
        preset={preset}
      />
    </div>
  );
}
