import React, { useEffect, useState } from "react";

import FilterSlider from "./FilterSlider";
import FilterSubView from "./FilterSubView";
import FilterDropDownLabel from "../../FilterDropDownLabel";
import { FiltersProp } from "../../types";

export default function FiltersMobileView({
  filters,
  displayFilters,
  name,
}: {
  filters: FiltersProp[];
  displayFilters: boolean;
  name: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<FiltersProp | null>(null);

  useEffect(() => {
    const foundItem = filters?.find((item) => currentFilter?.field === item?.field);
    if (foundItem) {
      setCurrentFilter(foundItem);
    }
  }, [filters]);

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)}>
        <FilterDropDownLabel displayFilters={displayFilters} />
      </button>
      {isOpen ? (
        <FilterSlider
          filters={filters}
          onClose={() => {
            setIsOpen(false);
            setIsSubMenuOpen(true);
          }}
          setCurrentFilter={setCurrentFilter}
        />
      ) : null}

      {currentFilter && isSubMenuOpen ? (
        <FilterSubView
          name={name}
          currentFilter={currentFilter}
          handleFilterClose={() => {
            setIsOpen(false);
            setIsSubMenuOpen(false);
          }}
          onClose={() => {
            setIsOpen(true);
            setIsSubMenuOpen(false);
          }}
          setCurrentFilter={setCurrentFilter}
        />
      ) : null}
    </>
  );
}
