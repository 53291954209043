import { CustomFlowbiteTheme } from "flowbite-react";

const commonTheme: CustomFlowbiteTheme["tab"] = {
  tablist: {
    tabitem: {
      base: "flex items-center justify-center maxMd:h-12 maxMd:w-10",
    },
  },
};

export default commonTheme;
