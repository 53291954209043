import { useEffect } from "react";

import posthog from "posthog-js";

import { useCompany } from "shared/context/CompanyProvider";

import useCheckCompanyPlan from "modules/settingsV2/upgrade/hooks/useCheckCompanyPlan";
import { CountryResponseParams, IndustryResponseParams } from "modules/auth/on-boarding/types";

import useCompanyInfo from "./useCompanyInfo";

const useSetPropertiesToPosthog = () => {
  const { currentCompany } = useCompany();
  const { hasBusinessPlan, hasFreePlan } = useCheckCompanyPlan();
  const { industries, countries } = useCompanyInfo({
    industryKey: "getIndustriesForPosthog",
    countryKey: "getCountriesForPosthog",
  });

  useEffect(() => {
    if (currentCompany?.id && industries && countries) {
      const _filteredIndustry = industries
        ?.filter((industry: IndustryResponseParams) =>
          currentCompany.industries.includes(industry.id)
        )
        ?.map((industry: IndustryResponseParams) => industry?.name)
        ?.join(", ");

      const _filteredCountry = countries
        ?.filter((country: CountryResponseParams) => currentCompany.countries.includes(country.id))
        ?.map((country: CountryResponseParams) => country?.name)
        ?.join(", ");

      const getSubscriptionPlan = () =>
        [
          currentCompany?.is_enterprise && "Enterprise",
          hasBusinessPlan && "Business",
          hasFreePlan && "Free",
        ]
          .filter(Boolean)
          ?.join(", ");

      posthog.capture("Company Properties", {
        $set: {
          companyID: currentCompany?.id,
          companyName: currentCompany?.name,
          companyCountry: _filteredCountry && _filteredCountry?.length > 0 ? _filteredCountry : "",
          companyIndustry:
            _filteredIndustry && _filteredIndustry?.length > 0 ? _filteredIndustry : "",
          subscriptionPlan: getSubscriptionPlan()?.length > 0 ? getSubscriptionPlan() : "",
          articlesEnabled: currentCompany?.modules?.articles?.enabled,
          agreementsEnabled: currentCompany?.modules?.agreements?.enabled,
          policiesEnabled: currentCompany?.modules?.policies?.enabled,
          risksEnabled: currentCompany?.modules?.risks?.enabled,
        },
      });

      currentCompany?.organization_users?.map((user) => {
        posthog.capture("User Properties", {
          $set: {
            userID: user?.user?.email,
            userRole: user?.role,
            dateJoined: user?.created ? new Date(user.created) : "",
            lastLoginDate: user?.user?.last_login ? new Date(user.user.last_login) : "",
          },
        });
      });
    }
  }, [currentCompany, industries, countries]);
};

export default useSetPropertiesToPosthog;
