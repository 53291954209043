import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { FilterProps, FilterResponse } from "../types";

export default function createFilter(
  data: FilterProps,
  permissions: { team: TeamId }
): Promise<AxiosResponse<FilterResponse>> {
  return api.post<FilterResponse>(`core/filters/`, data, { params: { ...permissions } });
}
