import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { convertPolicyData } from "../helpers";

export default async function getPolicyById(
  policyId: string,
  permissions: { team: TeamParameter }
) {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get(`policies/${policyId}/`, {
    params: { ...{ team: teamParameter, company: permissions.team?.company } },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertPolicyData(response.data),
    };
  }
  return response;
}
