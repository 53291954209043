import React from "react";

import { Dropdown } from "flowbite-react";

import { ModuleProps, PermissionType } from "shared/data/PermissionList";
import { getPermissionName } from "shared/helpers/getPermissionName";

import { CompanyUserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";
import { ReactComponent as CheckIcon } from "assets/images/icon/check.svg";

/**
 *
 * @param data company user data or team data.
 * @param onPermissionChange this function  is called when permission was changed.
 * @param module has key and title to display Permission Name
 * @param permission it has module wise permisson
 * @returns select permission based on permission param and display all available permission
 */
export default function PermissionOptions({
  data,
  module,
  onPermissionChange,
  permission,
}: {
  data: CompanyUserResponse | TeamResponse | null;
  module: ModuleProps;
  permission: PermissionType;
  onPermissionChange: (module: ModuleProps, permission: PermissionType) => void;
}) {
  return (
    <Dropdown.Item onClick={() => onPermissionChange(module, permission)}>
      <div className={`flex justify-between gap-2 w-28 items-end  `}>
        <p
          data-testid={"permission_" + permission?.name}
          className={`${
            permission?.name?.toLowerCase() === "No Access".toLowerCase()
              ? "!text-lavared"
              : "dark:!text-greychateau"
          }`}
        >
          {permission?.name}
        </p>
        {getPermissionName(data, module)?.toLowerCase() === permission?.name.toLowerCase() ? (
          <CheckIcon className="subscriptions_right" />
        ) : (
          ""
        )}
      </div>
    </Dropdown.Item>
  );
}
