import { array, object, string } from "yup";

const objectSchema = object().shape({
  name: string().trim().required("Name is required"),
});
export const updateRiskSettings = object({
  preset: object({
    probability_levels: array().of(objectSchema).min(3).required(),
    impact_levels: array().of(objectSchema).min(3).required(),
  }),
});

export const manageCustomPreset = object({
  name: string().trim().required("Name is required"),
});
