import React from "react";

import clsx from "clsx";
import { useWizard } from "react-use-wizard";

import Icon from "shared/components/icon/Icon";

import style from "assets/css/settings.module.css";

export default function StepFormHeader({ headerTitles }: { headerTitles: Array<string> }) {
  const { activeStep } = useWizard();

  return (
    <div className={style.create_groupHeader}>
      {headerTitles.map((title, index) => (
        <div className="flex gap-4 items-center pl-5 py-4 text-brightgrey text-lg rounded-lg dark:text-brightgray">
          <div
            className={clsx(
              "w-12 h-12 rounded-full flex justify-center items-center bg-antiflashwhite border-lightSilver text-mirage border dark:bg-white dark:text-mirage",
              {
                "bg-mirage text-white border-mirage": activeStep === index,
                "bg-greenhaze dark:bg-greenhaze border-greenhaze": activeStep > index,
              }
            )}
          >
            {activeStep > index ? (
              <Icon type="right" size="icon-sm" className={style.successIcon} />
            ) : (
              index + 1
            )}
          </div>
          {title}
          {headerTitles.length !== index + 1 ? (
            <Icon type="chevron-right-thin" size="icon-lg" />
          ) : null}
        </div>
      ))}
    </div>
  );
}
