import { create } from "zustand";

import { UserResponse } from "modules/home/types";

interface SharedObjectModal {
  isSharedObjectModalOpen: boolean;
  setIsSharedObjectModalOpen: (isOpen: boolean) => void;
  sharedObjectModalBaseUrl: string;
  name?: string;
  transferOwnerEndpoint?: string;
  objectOwner?: UserResponse;
  refetchData?: CallableFunction;
  paramProps?: Record<string, unknown>;
  openSharedObjectModal: (
    baseUrl: string,
    name: string,
    objectOwner?: UserResponse,
    transferOwnerEndpoint?: string,
    refetchData?: CallableFunction,
    paramProps?: Record<string, unknown>
  ) => void;
  setObjectOwner: (newObjectOwner?: UserResponse) => void;
}

export const useSharedObjectModal = create<SharedObjectModal>((set) => ({
  isSharedObjectModalOpen: false,
  setIsSharedObjectModalOpen: (isSharedObjectModalOpen: boolean) =>
    set({ isSharedObjectModalOpen }),
  sharedObjectModalBaseUrl: "",
  openSharedObjectModal: (
    baseUrl: string,
    name: string,
    objectOwner?: UserResponse,
    transferOwnerEndpoint?: string,
    refetchData?: CallableFunction,
    paramProps?: Record<string, unknown>
  ) =>
    set({
      isSharedObjectModalOpen: true,
      sharedObjectModalBaseUrl: baseUrl,
      name,
      objectOwner,
      transferOwnerEndpoint,
      refetchData,
      paramProps,
    }),
  setObjectOwner: (newObjectOwner?: UserResponse) => set({ objectOwner: newObjectOwner }),
}));
