import { defaultSideBarItems } from "shared/helpers/sidebar";

import { CompanyResponse } from "modules/home/types";

export default function getAgreementActiveSidebarItems(company: CompanyResponse) {
  const isEnterprise = company.is_enterprise;

  const sidebarItems = defaultSideBarItems(company.id)?.map((item) => {
    if (item?.label === "Agreements") {
      if (isEnterprise) {
        return {
          ...item,
          items: [
            ...(item?.items?.filter((label) => label?.label != "Validations") || []),
            {
              label: "Validations",
              path: "/agreements/validations",
            },
          ],
        };
      } else {
        return {
          ...item,
          items: item?.items?.filter((label) => label?.label != "Validations"),
        };
      }
    }
    return item;
  });
  return sidebarItems;
}
