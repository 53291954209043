import { Table } from "@tanstack/react-table";
import clsx from "clsx";

import Icon from "../icon/Icon";

import headerStyles from "assets/css/common-header.module.css";

function Pagination<TData>({ table, className }: { table: Table<TData>; className?: string }) {
  return (
    <>
      <div className={clsx(headerStyles.paginationBlock, className)}>
        <div className={clsx("flex maxMd:flex-wrap md:relative md:justify-end gap-3")}>
          <div className="flex maxMd:order-2 maxMd:w-full maxMd:justify-center">
            <button
              className="md:w-6"
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table?.getCanPreviousPage()}
            >
              <Icon type="leftarrow" fill={true} size="icon-md" className="icon_hovereffect" />
              <span>Previous</span>
            </button>
            <button
              className="md:w-6"
              onClick={() => {
                table.nextPage();
              }}
              disabled={table.getPageCount() <= table.getState().pagination.pageIndex}
            >
              <span>Next</span>
              <Icon type="rightarrow" fill={true} size="icon-md" className="icon_hovereffect" />
            </button>
          </div>
          <span className="flex items-center md:gap-1 text-sm text-aurometalsaurus dark:text-greychateau maxMd:w-full maxMd:order-1 maxMd:justify-center maxMd:mb-4">
            <div>Show</div>
            <strong className="text-mirage dark:text-white mx-1">
              {table.getState().pagination.pageIndex}
            </strong>
            of
            <strong className="text-mirage dark:text-white mx-1">
              {table.getPageCount() || ""}
            </strong>
          </span>
        </div>
      </div>
    </>
  );
}

export default Pagination;
