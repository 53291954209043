import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { GetTagsRequestParams, TagsResponseType } from "../types";

export default function getTagsList(
  requestParams: GetTagsRequestParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<TagsResponseType>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get(`core/tags/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{
        team: teamParameter,
        company: permissions.team?.company,
      },
    },
  });
}
