import { AxiosResponse } from "axios";

import { TeamParameter } from "shared/types";
import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { RiskFileResponse } from "../types";

export default function getRiskFile(
  id: number,
  permissions: { team: TeamParameter },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<RiskFileResponse>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<RiskFileResponse>(`risks/risks-files/${id}/`, {
    params: {
      team: teamParameter,
      company: permissions.team?.company,
      ...hasNameOrdering(requestParams),
    },
  });
}
