import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { MitigationType } from "../types";
import { convertMitigationData } from "../helpers";

export const createMitigation = async (data: {
  name: string;
  team: TeamParameter;
}): Promise<AxiosResponse<MitigationType>> => {
  const teamParameter = data.team?.isAllTeam ? "all" : data.team?.team;

  let response = await api.post<MitigationType>(
    `risks/mitigations/`,
    { ...data, ...data.team },
    { params: { team: teamParameter, company: data.team?.company } }
  );
  if (response && response.data) {
    response = {
      ...response,
      data: convertMitigationData(response.data),
    };
  }

  return response;
};
