import React from "react";

import { TextInput } from "flowbite-react";
import { useFormik } from "formik";
import { object, string } from "yup";

import headerStyles from "assets/css/common-header.module.css";
import { useTeam } from "shared/store/settings";

import { FilterConditon, FilterProps, SelectProps } from "./types";
import useFavorite from "./hooks/useFavorite";
import { ARTICLE_TABLE } from "shared/helpers/constant";

export const inputSchema = object({
  name: string().max(100).trim().required(),
});

function InputFavorite({
  type,
  search,
  onFavoriteClick,
}: {
  onFavoriteClick: (filter: FilterProps) => void;
  type: string;
  search: SelectProps[];
}) {
  const currentTeam = useTeam((state) => state.currentTeam);

  const { addFavorite } = useFavorite();

  const { handleSubmit, values, handleChange, errors, resetForm } = useFormik<{
    name: string;
  }>({
    initialValues: {
      name: "",
    },
    validateOnChange: false,
    validationSchema: inputSchema,
    onSubmit: () => {
      addFavorite(prepareFilter(), {
        onSuccess: (res) => {
          onFavoriteClick(res.data);
          resetForm();
        },
      });
    },
  });
  const prepareFilter = (): FilterProps => {
    let conditions: FilterConditon[] = [];

    search?.forEach((_search) => {
      if (_search.conditions && _search.conditions.length > 0) {
        conditions = [...conditions, ..._search.conditions];
      }
    });

    // Saved filters for articles must belong to the team, not the user
    let sharedWithTeam = {};
    if (type === ARTICLE_TABLE) {
      sharedWithTeam = { shared_with_team: true };
    }
    return {
      name: values.name,
      object_type: type,
      team: currentTeam.id,
      conditions,
      ...sharedWithTeam,
    };
  };

  return (
    <div className={headerStyles.formElement}>
      <form onSubmit={handleSubmit} id="add_favorite" className="grid gap-2">
        <TextInput
          className={headerStyles.formInput}
          value={values.name}
          name="name"
          onChange={handleChange}
          color={errors.name ? "failure" : "gray"}
          autoComplete="off"
          disabled={search?.length === 0}
          placeholder="Enter name of search"
        />
        <div className="!w-fit maxMd:!w-full">
          <button
            type="submit"
            className={headerStyles.btn_primary__imp}
            disabled={search?.length === 0}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default InputFavorite;
