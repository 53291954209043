import { create } from "zustand";

export type DropdownPaginatedSearchFieldProps = {
  category?: string;
  agreement_type?: string;
  external_supplier?: string;
  party_to_agreement?: string;
  publisher?: string;
  exclude_publishers?: string;
  regions?: string;
  exclude_regions?: string;
  topics?: string;
  exclude_topics?: string;
  sections?: string;
  exclude_sections?: string;
  status?: string;
  parent?: string;
  enterprise_validation?: string;
  internal_supplier?: string;
};

export type OwnerDropdownPaginatedSearchFieldProps = {
  search?: string;
  incident_reporter?: string;
  person_responsible?: string;
};

export type DropdownPaginationSearchModuleProps = {
  detailAgreementForm?: DropdownPaginatedSearchFieldProps;
  taskForm?: DropdownPaginatedSearchFieldProps;
  assessmentAgreementForm?: DropdownPaginatedSearchFieldProps;
  addSubContractorForm?: DropdownPaginatedSearchFieldProps;
  editSubContractorForm?: DropdownPaginatedSearchFieldProps;
  policyTemplateForm?: DropdownPaginatedSearchFieldProps;
  addAgreementForm?: DropdownPaginatedSearchFieldProps;
  metaDataOwnerForm?: OwnerDropdownPaginatedSearchFieldProps;
  incidentForm?: OwnerDropdownPaginatedSearchFieldProps;
  mitigationForm?: OwnerDropdownPaginatedSearchFieldProps;
};

export type PaginationDropdownSearchProps = {
  paginationSearchData: DropdownPaginationSearchModuleProps;
  setPaginationSearchData: (paginationSearchData: DropdownPaginationSearchModuleProps) => void;
};

export const useDropdownPaginationSearch = create<PaginationDropdownSearchProps>((set) => ({
  paginationSearchData: {},
  setPaginationSearchData: (paginationSearchData: DropdownPaginationSearchModuleProps) => {
    set({ paginationSearchData: paginationSearchData });
  },
}));
