import React from "react";

import isToday from "date-fns/isToday";

import TodayDate from "./TodayDate";
import BeforeDate from "./BeforeDate";

export type DueDateProps = {
  dueDate: string | null | undefined;
};

export default function DueDate({ dueDate }: DueDateProps) {
  if (!dueDate) return null;

  const date = new Date(dueDate);

  return isToday(date) ? <TodayDate /> : <BeforeDate due_date={date} />;
}
