import React from "react";

import { Dropdown } from "flowbite-react";
import { PermissionListingProps } from "./types";
import clsx from "clsx";

import { getPermissionName } from "shared/helpers/getPermissionName";
import { ModuleProps, modules, Permissions, PermissionType } from "shared/data/PermissionList";
import { useCompany } from "shared/context/CompanyProvider";
import { TEAM_MODULE, USER_MODULE } from "shared/helpers/ability";
import { shouldActive } from "shared/helpers/util";

import styles from "assets/css/customModal.module.css";

import PermissionOptions from "./PermissionOptions";
import OwnerLabel from "./OwnerLabel";

/**
 *
 * @param data company user data or team data?.
 * @param updatePermissionFn this function  is called when permission was changed.
 * @param isOwner user is owner or not
 * @returns listing all the permissions of the team or user
 */
const PermissionListing = ({
  data,
  updatePermissionFn,
  isOwner,
  isLoading,
}: PermissionListingProps) => {
  const { isAdmin } = useCompany();

  const onPermissionChange = (module: ModuleProps, permission: PermissionType) => {
    if (data) {
      data.custom_permissions = {
        ...data?.custom_permissions,
        [module.key]: permission?.value || [],
      };

      const payload = { ...data, id: data?.id, custom_permissions: data?.custom_permissions || [] };

      updatePermissionFn(payload);
    }
  };

  const filteredModules = modules.filter((_module) => {
    if (!isAdmin && [USER_MODULE, TEAM_MODULE].indexOf(_module.key) !== -1) {
      return false;
    }
    return true;
  });

  return (
    <>
      {filteredModules?.map((module: ModuleProps, index: number) => (
        <div
          className={clsx(
            styles.customBody,
            "flex border-b border-brightgray dark:border-thunders items-center justify-between"
          )}
          key={index}
        >
          <div className="py-3 mac13Inch:text-sm font-inter-medium dark:text-white">
            <h2 data-testid={"module" + module.title}>{module.title}</h2>
          </div>
          <div className={styles.dropdownButton}>
            {!isOwner ? (
              <div
                className={clsx({
                  active: shouldActive(getPermissionName(data, module)),
                })}
              >
                {!isLoading ? (
                  <Dropdown
                    label={
                      <span
                        className={`${
                          getPermissionName(data, module)?.toLowerCase() ===
                          "No Access".toLowerCase()
                            ? "!text-lavared !font-normal"
                            : "dark:!text-greychateau"
                        }`}
                      >
                        {getPermissionName(data, module)}
                      </span>
                    }
                    placement="right-start"
                    className="custom_dropdown"
                  >
                    {Permissions?.map((permission, permissionIndex) => (
                      <PermissionOptions
                        key={permissionIndex}
                        data={data}
                        module={module}
                        onPermissionChange={onPermissionChange}
                        permission={permission}
                      />
                    ))}
                  </Dropdown>
                ) : null}
              </div>
            ) : (
              <OwnerLabel />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default PermissionListing;
