import React from "react";
import { Dropdown } from "flowbite-react";
import clsx from "clsx";

import { useCompany } from "shared/context/CompanyProvider";
import { CompanyMember, CompanyProps } from "shared/context/types/company";
import styles from "assets/css/members.module.css";

import { TeamResponse } from "../teams/types";

export const getTeamsByMember = (teamMember: CompanyMember, company: CompanyProps) => {
  return company?.teams.filter((team) => {
    if (teamMember.user?.id) {
      return team.members?.includes(teamMember.user?.id);
    }
  });
};

const TeamsSpace = ({ member }: { member: CompanyMember }) => {
  const { currentCompany } = useCompany();

  const teamsByMember: TeamResponse[] = getTeamsByMember(member, currentCompany);

  return (
    <div data-testid="team-space">
      {teamsByMember?.length > 0 && (
        <Dropdown
          label={`${teamsByMember.length} Team Spaces`}
          inline={true}
          placement="bottom"
          className={clsx(
            styles.memberDropDown,
            "min-w-[12rem] maxMd:!w-[12rem] md:!min-w-[10rem] md:!w-[190px] !top-[50px]"
          )}
        >
          {teamsByMember.map((ele) => (
            <Dropdown.Item
              className="!text-aurometalsaurus dark:!text-greychateau flex-wrap"
              key={ele.id}
            >
              {ele.name} -
              <p className="!text-santagrey dark:!text-stormdust ml-0.5">{` ${ele.members?.length} members`}</p>
            </Dropdown.Item>
          ))}
        </Dropdown>
      )}
    </div>
  );
};

export default TeamsSpace;
