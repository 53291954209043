import { SideBarItems } from "shared/components/sidebar/types";
import {
  QUESTIONNAIRE_MODULE,
  REPORT_MODULE,
  VENDOR_MODULE,
  VIEW_ACTION,
} from "shared/helpers/ability";

const defaultSideBarItems = (companyId: number): SideBarItems[] => [
  {
    label: "Vendors",
    action: VIEW_ACTION,
    subject: VENDOR_MODULE,
    path: `/workspace/${companyId}/vendors`,
    items: [{ label: "Overview", path: "vendors" }],
  },
  {
    label: "Dashboards & Reports",
    action: VIEW_ACTION,
    subject: REPORT_MODULE,
    path: `/workspace/${companyId}/reports`,
    isBeta: true,
    visibleToProd: false,
  },
  {
    label: "Questionnaires",
    action: VIEW_ACTION,
    subject: QUESTIONNAIRE_MODULE,
    path: `/workspace/${companyId}/questionnaires`,
    isBeta: true,
    visibleToProd: false,
  },
];

export { defaultSideBarItems };
