import moment from "moment";
import { AFTER_OPERATOR, BEFORE_OPERATOR } from "../../helpers/constant";

function PredefinedDateFilterInterval({ operator, type }: { operator?: string; type: string }) {
  if (type == "this_week") {
    if (operator == AFTER_OPERATOR) {
      return moment().startOf("week").add(1, "day").toDate();
    }
    if (operator == BEFORE_OPERATOR) {
      return moment().endOf("week").add(1, "day").toDate();
    }
  } else if (type == "this_month") {
    if (operator == AFTER_OPERATOR) {
      return moment().startOf("month").toDate();
    }
    if (operator == BEFORE_OPERATOR) {
      return moment().endOf("month").toDate();
    }
  } else if (type == "last_7_days") {
    if (operator == BEFORE_OPERATOR) {
      return new Date();
    }
    if (operator == AFTER_OPERATOR) {
      return new Date(new Date().setDate(new Date().getDate() - 7));
    }
  } else if (type == "last_30_days") {
    if (operator == BEFORE_OPERATOR) {
      return new Date();
    }
    if (operator == AFTER_OPERATOR) {
      return new Date(new Date().setDate(new Date().getDate() - 30));
    }
  }
}

export default PredefinedDateFilterInterval;
