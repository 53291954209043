import omit from "lodash/omit";
import get from "lodash/get";

import { DropdownPaginationSearchModuleProps } from "./../store/dropdownPaginationSearch";

export default function getDropdownPaginatedSearchResult(
  currentData: DropdownPaginationSearchModuleProps,
  formName: string,
  field: string,
  searchData: string
): DropdownPaginationSearchModuleProps {
  if (searchData && searchData?.trim()?.length > 0) {
    return {
      ...currentData,
      [formName]: {
        ...get(currentData, formName, {}),
        [field]: searchData,
      },
    };
  }
  return {
    ...currentData,
    [formName]: omit(get(currentData, formName), [field]),
  };
}
