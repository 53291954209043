import { useEffect } from "react";

const useHideCopilot = () => {
  const copilotCard = document.querySelector(".assistant_card");

  useEffect(() => {
    copilotCard?.classList.add("hidden");
    return () => copilotCard?.classList.remove("hidden");
  }, []);
};

export default useHideCopilot;
