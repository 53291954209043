import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { PresetInfo, PresetLevelType } from "modules/risks/register/types";

import { CreatePresetRequest } from "../types";

export default function createPreset(
  data: CreatePresetRequest,
  defaultLevels: Array<PresetLevelType>,
  currentPreset?: PresetInfo
): Promise<AxiosResponse<PresetInfo>> {
  let payload = {
    ...currentPreset,
    ...data,
  };

  if (!currentPreset) {
    payload = { ...payload, impact_levels: defaultLevels, probability_levels: defaultLevels };
  }
  return api.post(`risks/presets/`, payload);
}
