import React from "react";

import styles from "assets/css/metadata.module.css";

import TaskDisplayValue from "modules/tasks/overview/shared/TaskDisplayValue";

import { ActivityLog } from "./ActivityLog";
import CentralSpinner from "../spinner/CentralSpinner";
import { ActivityType } from "../metadata/types";

export const ActivityLogList = ({
  activityLog,
  isLoading,
  onItemClick,
}: {
  activityLog: ActivityType[];
  isLoading: boolean;
  onItemClick: (flag: boolean, activity: ActivityType) => void;
}) => {
  return (
    <>
      {isLoading ? <CentralSpinner /> : null}

      {activityLog?.length > 0 ? (
        <ul className={styles.activityLogList}>
          {activityLog.map((_activity: ActivityType, index: number) => (
            <ActivityLog
              activity={_activity}
              index={index}
              key={_activity.created_at}
              onItemClick={onItemClick}
            />
          ))}
        </ul>
      ) : !isLoading ? (
        <TaskDisplayValue title="No activity yet" />
      ) : null}
    </>
  );
};
