import { useFormik } from "formik";

import { SubscriptionProps } from "../types";
import { subscriptionSchema } from "../validation";

const useUpgradeForm = (action: CallableFunction) => {
  return useFormik<SubscriptionProps>({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        postal_code: "",
        state: "",
        country: "",
      },
    },
    validateOnChange: false,
    validationSchema: subscriptionSchema,
    onSubmit: () => action(),
  });
};

export default useUpgradeForm;
